import { useState, useEffect } from "react";

import PropTypes from 'prop-types';

import utils from "../../utils/utils";

import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../utils/tiktok-brands.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const DiscoveryPlatforms = ({ sendSelectedPlatform }) => {
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("");

  const socialIcons = {
    instagram: <InstagramIcon sx={{ color: "#BC2A8D" }} />,
    twitter: <TwitterIcon sx={{ color: "#00ACEE" }} />,
    tiktok: (
      <TikTokIcon
        style={{ width: "24px", height: "24px" }}
        sx={{ color: "#000000" }}
      />
    ),
    youtube: <YouTubeIcon sx={{ color: "#FF0100" }} />,
    linkedin: <LinkedInIcon sx={{ color: "#0a66c2" }} />,
    facebook: <FacebookIcon sx={{ color: "#1b74e4" }} />,
  };

  useEffect(() => {
    const platform = [{"id":1,"name": "linkedin"}]
    setPlatforms(platform);
    if (platform && platform.length > 0) {
      updateSelectedPlatform(platform[0].name);
    }
    /* utils.getPlatforms().then((data) => {
      setPlatforms(platform);
      if (data && data.length > 0) {
        updateSelectedPlatform(data[0].name);
      }
    });*/
  }, []);

  const updateSelectedPlatform = (platformName) => {
    if (platformName === selectedPlatform) {
      return;
    }
    const prevSelectedPlatform_id = "platformName-" + selectedPlatform;
    const prevEle = document.getElementById(prevSelectedPlatform_id);
    if (prevEle) {
      prevEle.style.display = "none";
    }
    const newSelectedPlatform_id = "platformName-" + platformName;
    const newEle = document.getElementById(newSelectedPlatform_id);
    if (newEle) {
      newEle.style.display = "inline";
    }
    setSelectedPlatform(platformName);
    sendSelectedPlatform(platformName);
  }

  const handlePlatformIconClick = (platformName) => {
    updateSelectedPlatform(platformName);
  }

  return (platforms && platforms.length > 0) ? (
    <div id="platformSelection" style={{marginBottom: "10px"}}>
      {platforms.map((platform) => (
        <span key={"platformSelection-"+platform.name}>
          <label className="platform-icon" style={{marginRight: "10px"}} onClick={() => handlePlatformIconClick(platform.name)}>{socialIcons[platform.name]}</label>
          <label className="platform-name" id={"platformName-"+platform.name} style={{marginRight: "10px", display: "none"}}>{platform.name}</label>
        </span>
      ))}
    </div>
  ):(
    <p>Loading ...</p>
  );
}

DiscoveryPlatforms.propTypes = {
  sendSelectedPlatform: PropTypes.func.isRequired,
}


export default DiscoveryPlatforms;