import React, { useState, useRef, useEffect } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  Button,
  Grid,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Autocomplete,
  TextField,
} from "@mui/material";
import MyPaper from "../../components/general/MyPaper";
import utils from "../../utils/utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { snapshotHeaderIcons, textColor } from "../../components/campaignAnalysis/snapshot";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const styles = {
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  typography: {
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    color: "#33CC99",
    borderBottom: "2px solid #33CC99",
    marginBottom: "20px",
  },
  noData: {
    position: "relative",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontSize: "large",
    fontStyle: "italic",
  },
  centerElement: {
    position: "relative",
    left: "50%",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
  fetchingDetails: {
    marginTop: "20px",
    paddingLeft: "50px",
  },
  marginOnLeft: {
    marginLeft: "5px",
  },
  marginOnBottom: {
    marginBottom: "20px",
    boxShadow: "none",
  },
  buttonGo: {
    background: "linear-gradient(to right, #2ca786 0%, #34CC99 100%)",
    color: "white",
    border: "none",
    "&:hover": {
      background: "linear-gradient(to right, #2ca786 0%, #34CC99 100%)",
    },
  },
};
const selectStyles = {
  minWidth: "10vw",
  borderRadius: "8px",
  "& .MuiSelect-select": {
    py: 0.5,
    px: 2,
    borderRadius: "8px",
    backgroundColor: "white",
    "&:focus": {
      backgroundColor: "white",
    },
  },
  color: "black",
};

const menuProps = {
  PaperProps: {
    sx: {
      borderRadius: "5px",
      width: 100,
      maxHeight: 300,
      width: "var(--dropdown-width)",
      overflow: "auto",
      "& .MuiMenu-paper": {
        padding: 0,
        "& .MuiMenuItem-root": {
          py: 0.5,
          px: 2,
          borderRadius: "5px",
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
          "&.Mui-selected": {
            backgroundColor: "#e0e0e0",
          },
          "& .MuiListItemIcon-root": {
            minWidth: 24,
            color: "rgba(0, 0, 0, 0.54)",
            mr: 2,
          },
          "& .MuiListItemText-primary": {
            fontSize: "0.875rem",
            fontWeight: "500",
            color: "rgba(0, 0, 0, 0.87)",
          },
        },
      },
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        borderRadius: "10px",
        outline: "1px solid slategrey",
      },
    },
  },
};
export default function CampaignFilters(props) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const last6MonthsStart = moment().subtract(6, "month").startOf("month").toDate();
      const today = moment().toDate();
      inputRef.current.value = `From ${last6MonthsStart.toLocaleDateString()} to ${today.toLocaleDateString()}`;
    }
  }, []);

  const handleApply = (event, picker) => {
    if (inputRef.current) {
      inputRef.current.value = `From ${picker.startDate.format("MM/DD/YYYY")} to ${picker.endDate.format("MM/DD/YYYY")}`;
    }
    props.handleCalendarChange(picker.startDate, picker.endDate);
  };

  const handleAutocompleteChange = (event, value) => {
    props.handleInfluencersChange({ target: { value: value.map((v) => v.Iid) } });
  };

  return (
    <MyPaper
      sx={{
        ...(props.hideFilterBy ? styles.marginOnBottom : {}),
        boxShadow: "none",
        background: "none",
        width: "100%",
      }}
    >
      <Stack direction="row" justifyContent="flex-end" sx={{ width: "100%" }}>
        <Grid container spacing={2} sx={{ justifyContent: "flex-end" }}>
          <Grid item>
            <Stack spacing={3} sx={{ marginLeft: "5px", width: "14vw" }}>
              <div style={{ position: "relative", width: "94%", marginLeft: "5px" }}>
                <label
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "-12px",
                    fontSize: "12px",
                    transition: "all 0.3s",
                    pointerEvents: "none",
                    background: "#fff",
                    padding: "0 5px",
                    zIndex: 1,
                  }}
                >
                  Range
                </label>
                <DateRangePicker
                  sx={selectStyles}
                  key={props.datePickerRef?.current}
                  MenuProps={menuProps}
                  initialSettings={{
                    startDate: props.startDate,
                    endDate: props.endDate,
                    ranges: {
                      Today: [moment().toDate(), moment().toDate()],
                      Yesterday: [moment().subtract(1, "days").toDate(), moment().subtract(1, "days").toDate()],
                      "This Week": [moment().startOf("week").toDate(), moment().toDate()],
                      "Last Week": [
                        moment().subtract(1, "weeks").startOf("week").toDate(),
                        moment().subtract(1, "weeks").endOf("week").toDate(),
                      ],
                      "Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
                      "Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
                      "This Month": [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month").toDate(),
                        moment().subtract(1, "month").endOf("month").toDate(),
                      ],
                      "Last 6 Months": [moment().subtract(6, "month").startOf("month").toDate(), moment().toDate()],
                    },
                  }}
                  onApply={handleApply}
                  onCallback={props.handleCalendarChange}
                >
                  <input
                    ref={inputRef}
                    readOnly
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      height: "2rem",
                      fontSize: "15px",
                      position: "relative",
                      zIndex: 0,
                    }}
                    type="text"
                    className="form-control"
                  />
                </DateRangePicker>
              </div>
            </Stack>
          </Grid>

          <Grid item>
            <FormControl size="small" hidden={props.hidePlatform}>
              <InputLabel id="platform-select-label" sx={{ borderRadius: "3px" }}>
                Platform
              </InputLabel>
              <Select
                sx={{
                  ...selectStyles,
                  minWidth: "none",
                  width: "6vw",
                }}
                MenuProps={menuProps}
                style={{ color: "black" }}
                labelId="platform-select-label"
                id="platform-select"
                value={props.selectedPlatform}
                label="Platform"
                onChange={props.handleChangePlatform}
                variant="outlined"
                renderValue={(selected) => (
                  <span style={textColor[selected]}>
                    {snapshotHeaderIcons[selected]}{" "}
                    {selected === -1
                      ? "All"
                      : utils.capitalizeFirstLetter(props.platforms.find((plat) => plat.id === selected).name)}
                  </span>
                )}
              >
                <MenuItem key={-1} value={-1}>
                  <ListItemText primary="All" />
                </MenuItem>
                {Array.isArray(props.platforms) &&
                  props.platforms.length > 0 &&
                  props.platforms.map((plat) => (
                    <MenuItem key={plat.id} value={plat.id}>
                      <ListItemText
                        primary={
                          <span style={textColor[plat.name.toLowerCase()]}>{snapshotHeaderIcons[plat.name.toLowerCase()]}</span>
                        }
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {props.hideFilterBy === false && (
            <Grid item>
              <FormControl sx={{ marginLeft: "5px" }} size="small">
                <InputLabel id="filterBy-select-label">Filter By</InputLabel>
                <Select
                  MenuProps={menuProps}
                  style={{ color: "black" }}
                  labelId="filterBy-select-label"
                  id="filterBy-select"
                  value={props.filterBy}
                  label="Filter By"
                  onChange={props.handleFilterBy}
                  variant="outlined"
                  sx={selectStyles}
                >
                  <MenuItem value={"day"}>Day</MenuItem>
                  <MenuItem disabled={props.disabledFilterByWeek} value={"week"}>
                    Week
                  </MenuItem>
                  <MenuItem disabled={props.disabledFilterByMonth} value={"month"}>
                    Month
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <FormControl sx={props.hideFilterBy ? styles.marginOnLeft : ""} hidden={!props.hideAdvancedFilters}>
            <Grid item sx={{ paddingTop: "18px" }}>
              <Button variant="contained" onClick={props.applyFilters} endIcon={<ArrowForwardIcon />}>
                GO
              </Button>
            </Grid>
          </FormControl>

          <Grid item>
            <FormControl size="small" hidden={props.hideAdvancedFilters}>
              <InputLabel id="lists-select-label">Lists</InputLabel>
              <Select
                sx={selectStyles}
                MenuProps={MenuProps}
                labelId="lists-select-label"
                label="Lists"
                multiple
                value={props.selectedListIds}
                onChange={props.handleListChange}
                renderValue={(selected) => {
                  if (selected.length === 1) {
                    return selected.length + " List Selected";
                  } else {
                    return selected.length + " Lists Selected";
                  }
                }}
              >
                <MenuItem
                  value={-1}
                  classes={{
                    root: props.isAllListSelected ? styles.selectedAll : "",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      style={{
                        color: "#33CC99",
                      }}
                      checked={props.isAllListSelected}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </MenuItem>
                {Array.isArray(props.optionsLists) &&
                  props.optionsLists.length > 0 &&
                  props.optionsLists.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <ListItemIcon>
                        <Checkbox
                          style={{
                            color: "#33CC99",
                          }}
                          checked={props.selectedListIds.indexOf(option.id) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {props.hideInfluencers === false && (
            <Grid item>
              <FormControl size="small" hidden={props.hideAdvancedFilters}>
                <Autocomplete
                  sx={{
                    ...selectStyles,
                    minWidth: "none",
                    width: "12vw",
                  }}
                  multiple
                  limitTags={0}
                  id="autocomplete-influencers"
                  options={props.optionsInfluencers}
                  getOptionLabel={(option) => option.Name}
                  value={
                    props.optionsInfluencers.length > 0 &&
                    props.optionsInfluencers.filter((option) => props.selectedInfluencersIds.includes(option.Iid))
                  }
                  onChange={handleAutocompleteChange}
                  isOptionEqualToValue={(option, value) => option.Iid === value.Iid}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Influencers"
                      placeholder="Influencers"
                      variant="outlined"
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "2px 1px",
                          minHeight: "10px",
                          borderRadius: "8px",
                          "& .MuiAutocomplete-input": {
                            minHeight: "10px",
                            padding: "2px 4px",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "1",
                          top: "-2px",
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ color: "#33CC99" }} checked={selected} />
                      <ListItemText primary={option.Name} />
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
          )}

          {props.hideTasks === false && (
            <Grid item>
              <FormControl size="small" hidden={props.hideAdvancedFilters}>
                <InputLabel id="tasks-select-label">Tasks</InputLabel>
                <Tooltip title={props.optionsTasks.length === 0 ? "No Task Available" : ""} arrow placement="bottom">
                  <Select
                    sx={selectStyles}
                    MenuProps={MenuProps}
                    disabled={props.optionsTasks.length === 0}
                    labelId="tasks-select-label"
                    label="Tasks"
                    multiple
                    value={props.selectedTasksIds}
                    onChange={props.handleTasksChange}
                    renderValue={(selected) => {
                      if (selected.length === 1) {
                        return selected.length + " Task Selected";
                      } else {
                        return selected.length + " Tasks Selected";
                      }
                    }}
                  >
                    {props.optionsTasks.length !== 0 && (
                      <MenuItem
                        value={-1}
                        classes={{
                          root: props.isAllTasksSelected ? styles.selectedAll : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            style={{
                              color: "#33CC99",
                            }}
                            checked={props.isAllTasksSelected}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    )}
                    {Array.isArray(props.optionsTasks) &&
                      props.optionsTasks.length > 0 &&
                      props.optionsTasks.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <ListItemIcon>
                            <Checkbox
                              style={{
                                color: "#33CC99",
                              }}
                              checked={props.selectedTasksIds.indexOf(option.id) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option.title} />
                        </MenuItem>
                      ))}
                  </Select>
                </Tooltip>
              </FormControl>
            </Grid>
          )}

          <Grid item sx={{ minWidth: "10vw" }}>
            <FormControl sx={{ marginLeft: "5px" }} hidden={props.hideAdvancedFilters}>
              <Button
                variant="contained"
                onClick={props.applyFilters}
                disabled={props.disableRefreshButton}
                style={styles.buttonGo}
              >
                GO
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ width: "86.3%", marginTop: "1rem" }}>
        <Grid container spacing={2} sx={{ justifyContent: "flex-end" }} >
          {props.hideStatus === false && (
            <Grid item>
              <FormControl size="small" hidden={props.hidePlatform}>
                <InputLabel id="platform-select-label" sx={{ borderRadius: "3px" }}>
                  Status
                </InputLabel>
                <Select
                  sx={selectStyles}
                  MenuProps={menuProps}
                  style={{ color: "black" }}
                  labelId="status-select-label"
                  id="status-select"
                  value={props.selectedStatus}
                  label="Status"
                  onChange={props.handleStatusChange}
                  variant="outlined"
                  renderValue={(selected) => {
                    if (selected === -1) {
                      return "All";
                    } else {
                      return selected;
                    }
                  }}
                >
                  <MenuItem key={-1} value={-1}>
                    <ListItemText primary="All" />
                  </MenuItem>
                  {Array.isArray(props.optionsStatus) &&
                    props.optionsStatus.length > 0 &&
                    props.optionsStatus.map((plat) => (
                      <MenuItem key={plat} value={plat}>
                        <ListItemText
                          primary={plat}
                        />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>)}

          {props.hideTagType === false && (
            <Grid item>
              <FormControl size="small" hidden={props.hideAdvancedFilters}>
                <InputLabel id="postType-select-label">Tag Type</InputLabel>
                <Tooltip title={props.optionsTagTypes.length === 0 ? "No Tag Type Available" : ""} arrow placement="bottom">
                  <Select
                    sx={selectStyles}
                    MenuProps={MenuProps}
                    disabled={props.optionsTagTypes.length === 0}
                    labelId="post-select-label"
                    label="Tag Type"
                    multiple
                    value={props.selectedTagType}
                    onChange={props.handleTagTypeChange}
                    renderValue={(selected) => {
                      if (selected.length === 1) {
                        return selected.length + "Tag Type Selected";
                      } else {
                        return selected.length + "Tags Types Selected";
                      }
                    }}
                  >
                    {props.optionsTagTypes.length !== 0 && (
                      <MenuItem
                        value={-1}
                        classes={{
                          root: props.isAllTagTypeSelected ? styles.selectedAll : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            style={{
                              color: "#33CC99",
                            }}
                            checked={props.isAllTagTypeSelected}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    )}
                    {Array.isArray(props.optionsTagTypes) &&
                      props.optionsTagTypes.length > 0 &&
                      props.optionsTagTypes.map((option, index) => (
                        <MenuItem key={index} value={option.postType}>
                          <ListItemIcon>
                            <Checkbox
                              style={{
                                color: "#33CC99",
                              }}
                              checked={props.selectedTagType.indexOf(option.postType) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option.postType} />
                        </MenuItem>
                      ))}
                  </Select>
                </Tooltip>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Stack>
    </MyPaper >
  );
}
