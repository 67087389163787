/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api933a27b9",
            "endpoint": "https://6oaa6oini1.execute-api.us-east-1.amazonaws.com/develop",
            "region": "us-east-1"
        },
        {
            "name": "oryxapiprod",
            "endpoint": "https://ekm254m5g9.execute-api.us-east-1.amazonaws.com/develop",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:d982aa9a-3c4e-4f1a-a337-a11bf79db069",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_If5B2SqSi",
    "aws_user_pools_web_client_id": "6j5fitmfhl2nn069m9ma7jltr7",
    "oauth": {
        "domain": "oryxprod4f8294c5-4f8294c5-develop.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.staging.oryx.aifluence.co/campaigns/",
        "redirectSignOut": "https://www.staging.oryx.aifluence.co/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
