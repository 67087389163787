import React, { useEffect, useState, useRef } from "react";
import { API_HOST, INF_API_HOST } from "../../../config/main.json";
import utils from "../../../utils/utils";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import enums from "../../../utils/enums";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getTimeZone, onDownloadHandle } from "../../../utils/exportData";
import SearchItem from "../../../pages/account/SearchItem";
import ExportFiles from "../../../pages/account/ExportFiles";
import {
    Typography,
    Stack,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Grid,
    Divider,
    Box,
    Snackbar,
    Tooltip,
    Chip,
    Modal,
    FormControl,
    Autocomplete,
    TextField,
    TableCell,
    TableRow,
    InputLabel,
    Select,
} from "@mui/material";
import {
    ErrorOutline,
    Info,
    RunningWithErrors,
    Timer,
    ThumbUp,
    Article,
    Poll,
    Mic,
    Videocam,
    HistoryToggleOff,
    LocalOffer,
    Warning,
} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import { getAllLists } from "../../../services/list/ListDetails";
import CampaignFilters from "../../../pages/account/CampaignFilters";
import { getPublishedPostAnalysisTableHeaders } from "../../../utils/tableHeaders";
import Loader from "../../../pages/account/Loader";
import CustomTable from "../../../common/CustomTable/CustomTable";
import { snapshotHeaderIcons, textColor } from "../snapshot";
import InfoIcon from "@mui/icons-material/Info";
import PostActions from "./PostActions";
import MyPaper from "../../general/MyPaper";
import TopNPosts from "../../../pages/account/TopNPosts";
import styled from 'styled-components';
import { InfoToolTipPreview } from "../../../utils/toolTip";

const TableContainer = styled.div`
border : red solid 2px 
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;
`;

const StyledTable = styled.div`
  width: 76vw;
  overflow-x: auto;
  min-width: 100%
`;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
let campId = 0;


let hideOnExport = ["save-engagement-analysis", "save-phase-analysis"];

const PublishedPostAnalysis = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const status = params.has("unresolved") ? "Invalid URL" : -1;
    const [optionsLists, setOptionsLists] = useState([]);
    const [optionsInfluencers, setOptionsInfluencers] = useState([]);
    const [filters, setFilters] = useState({ selectedListIds: [], selectedInfluencersIds: [] });  // details of the campaign
    const [fields, setFields] = useState({});
    const [platforms, setPlatforms] = useState([]);
    const [topPosts, setTopPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const [langList, setLangList] = useState({ all: [] });
    const [startDate, setStartDate] = useState(moment().subtract(6, "month").startOf("month").toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [posts, setPosts] = useState([]);
    const [isSubmit, setIsSubmit] = useState(true);
    const [rowsSelected, setRowsSelected] = useState([]); // Tracks selected rows
    const [message, setMessage] = useState(''); // Holds messages for the user
    const [notification, setNotification] = useState(false); // Tracks if a notification should be shown
    const [removePostsModal, setRemovePostsModal] = useState(false); // Manages the visibility of a modal dialog
    const [selectedPlatform, setSelectedPlatform] = useState(-1);
    const [selectedPlatformName, setSelectedPlatformName] = useState("all");
    const [disabledFilterByWeek, setDisabledFilterByWeek] = useState();
    const [disabledFilterByMonth, setDisabledFilterByMonth] = useState();
    const [hideAdvancedFilters, setHideAdvancedFilters] = useState(false);
    const [selectedListIds, setSelectedListIds] = useState([]);
    const [selectedInfluencersIds, setSelectedInfluencersIds] = useState([]);
    const [disableRefreshButton, setDisableRefreshButton] = useState(false);
    const [totalRecords, setTotalRecords] = useState();
    const [dataFound, setDataFound] = useState(true);
    const [anchorEl, setAnchorEl] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tagValue, setTagValue] = useState('');
    const [currentPostId, setCurrentPostId] = useState("");
    const [editTagModal, setEditTagModal] = useState(false);
    const [tagType, setTagType] = useState('add');
    const [postIdDBSelected, setPostIdDBSelected] = useState(-1);
    const [currentInfluencerId, setCurrentInfluencerId] = useState("");
    const [postIdPlatform, setPostIdPlatform] = useState("");
    const [editPostIdModal, setEditPostIdModal] = useState(false);
    const [lpId, setLpId] = useState(0);
    const [postLang, setPostLang] = useState("English");
    const [influencersList, setinfluencersList] = useState([]);
    const [editStoryDataModal, setEditStoryDataModal] = useState(false);
    const [editStoryDataPublishedDate, setEditStoryDataPublishedDate] = useState("");
    const [editStoryDataLikes, setEditStoryDataLikes] = useState(0);
    const [editStoryDataComments, setEditStoryDataComments] = useState(0);
    const [editStoryDataShares, setEditStoryDataShares] = useState(0);
    const [editStoryDataViews, setEditStoryDataViews] = useState(0);
    const [screenshotModal, setScreenshotModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedPost, setSelectedPost] = useState(null);
    const [editSelectedImage, setEditSelectedImage] = useState(false);
    const [newPostId, setNewPostId] = useState("");
    const [newInfluencerId, setNewInfluencerId] = useState("");
    const [newScreenshot, setNewScreenshot] = useState(null);
    const [tablePageNo, setTablePageNo] = useState(0); // refers to page no of pagination (default - 0)
    const [tableRowsPerPage, setTableRowsPerPage] = useState(10); //refers to no of rows dispaly at a time on page
    const [sortCol, setSortCol] = useState("createdAt"); //refers on what base all col are sorted
    const [sortOrder, setSortOrder] = useState("desc"); //refers on what base all rows are sorted
    const [account] = useState(0); //  refers to  account (0 - aifluence)
    const [mimeType] = useState("csv"); //refers to the type in which we want to export
    const [isExport, setIsExport] = useState(0); // refers we want to export or not, 0 - not, 1 - yes
    const [filter, setFilter] = useState("");
    const [searchBy] = useState("post");
    const [campaignName] = useState("");
    const [client] = useState(0);
    const [milestoneIds] = useState([-1]);
    const [resetPageNo, setResetPageNo] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [selectedTasksIds, setSelectedTasksIds] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(status);
    const [selectedTagType, setSelectedTagType] = useState([]);
    const [optionsTasks, setOptionstasks] = useState([]);
    const [optionsTagTypes, setOptionsTagTypes] = useState([]);
    const [optionsStatus, setOptionsStatus] = useState([]);

    const deleted = props.deleted;
    const linkToAddPost = "/campaigns/addPost?id=" + campId;
    const datePickerRef = useRef(Date.now());
    const timeZone = getTimeZone(); // to set the time on platform
    const styles = {
        typography: {
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            color: "#33CC99",
            borderBottom: "2px solid #33CC99",
            marginBottom: "20px",
            marginTop: "20px",
        },
    };

    useEffect(async () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        campId = parseInt(params.get("id"));
        const InfId = await getAllList(campId.toString(), "all");
        utils.getAllInfluencers(InfId, "all", campId).then((data) => {
            if (data) {
                setOptionsInfluencers(data);
            }
        });
        getCampaignDetails(campId);
        getAllPlatforms();
        getTopNPosts(campId);
        getCampaignTagsData(campId);
        getTranslatorInfo();
        getAllStatus(campId);
        getTaskList(campId);
        getTagTypes(campId);
    }, [])


    const getAllList = async (campId, platform) => {
        const data = await getAllLists(campId, platform);
        if (data) {
            setOptionsLists(data);
            const idsArray = data.map(object => object.id);
            return idsArray;
        }
    };

    const getCampaignDetails = (campId) => {
        utils.getCampaignData(campId).then((data) => {
            setFields(data);
        })
    };

    const getTaskList = (campId) => {
        utils.getTaskListData(campId).then((data) => {
            if (data !== null) {
                setOptionstasks(data);
            }
        })
    };

    const getTagTypes = (campId) => {
        utils.getTagTypeData(campId).then((data) => {
            if (data !== null) {
                setOptionsTagTypes(data);
            }
        })
    };

    const getAllStatus = (campId) => {
        utils.getStatusData(campId).then((data) => {
            if (data !== null) {
                setOptionsStatus(data);
            }
        })
    };


    const getAllPlatforms = () => {
        utils.getPlatforms()
            .then((data) => {
                setPlatforms(data);
            })
    };

    const getTopNPosts = () => {
        utils.getTopNPosts(campId)
            .then((data) => {
                calculateN(data);
            })
            .catch((err) => {
                console.log(err);
                setTopPosts([]);
            })
            .finally(() => {
                setLoading(true);
            });
    };

    const calculateN = (data) => {
        if (data && data.length >= 10) {
            setTopPosts(data.slice(0, 10));
        } else if (data && data.length < 10 && data.length >= 5) {
            setTopPosts(data.slice(0, 5));
        } else if (data && data.length < 5 && data.length > 0) {
            setTopPosts(data.slice(0, 3));
        } else {
            setTopPosts([]);
        }
    };

    const getCampaignTagsData = (campId) => {
        utils.getCampaignTags(campId).then((data) => {
            setTags(data);
        })
    };

    const getTranslatorInfo = () => {
        axios.get(`${API_HOST}/translator/info`)
            .then((res) => {
                setLangList(res.data?.body?.listing);
            })
    };

    useEffect(() => {
        fetchData();
    }, [
        deleted,
        isSubmit,
        tablePageNo,
        tableRowsPerPage,
        sortCol,
        sortOrder,
        isExport,
        filter,
        mimeType,
    ]);

    const fetchData = () => {
        if (isSubmit) {
            setIsSubmit(false)
        }
        setLoading(() => false);
        getPostsListData(
            campId,
            selectedListIds,
            selectedPlatform.toString(),
            tablePageNo,
            tableRowsPerPage,
            sortCol,
            sortOrder,
            isExport,
            filter,
            moment(startDate).format("YYYY-MM-DD").toString(),
            moment(endDate).format("YYYY-MM-DD").toString(),
            mimeType,
            campaignName,
            account,
            client,
            timeZone,
            selectedTasksIds,
            milestoneIds,
            deleted,
            selectedInfluencersIds,
            selectedStatus,
            selectedTagType

        );
    };

    const getPostsListData = async (
        campId,
        selectedListIds,
        selectedPlatform,
        tablePageNo,
        tableRowsPerPage,
        sortCol,
        sortOrder,
        isExport,
        filter,
        startDate,
        endDate,
        mimeType,
        campaignName,
        account,
        client,
        timeZone,
        selectedTasksIds,
        milestoneIds,
        deleted,
        selectedInfluencersIds,
        selectedStatus,
        selectedTagType
    ) => {
        setLoading(false);

        let deleteVlue;

        if (deleted == "0") {
            deleteVlue = false;
        }
        else {
            deleteVlue = true;
        }

        const res = await utils.getPostsList(
            campId,
            selectedListIds,
            parseInt(selectedPlatform),
            tablePageNo + 1,
            tableRowsPerPage,
            sortCol,
            sortOrder,
            isExport,
            filter,
            startDate,
            endDate,
            mimeType,
            campaignName,
            account,
            client,
            timeZone,
            selectedTasksIds,
            milestoneIds,
            deleteVlue,
            selectedInfluencersIds,
            selectedStatus,
            selectedTagType
        );

        if (isExport === 1) {
            const csvData = res;
            const csvBlob = new Blob([csvData], { type: 'text/csv' });
            onDownloadHandle(csvBlob, "Posts." + mimeType);
            setIsExport(0);
        }

        const { total_records, records } = res;

        if (total_records > 0 && records.length!=0) {
            setPosts(records)
            setTotalRecords(total_records);
            setIsSubmit(false);
            setDataFound(true);
            setLoading(true);
        }
        else {
            setIsSubmit(false);
            setDataFound(false);
            setLoading(true);
        }
    };

    const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
        setTablePageNo(newPage.page);
        setResetPageNo(false);
        setIsChanged(!isChanged);
        setTableRowsPerPage(newPage.rowsPerPage);
        setSortCol(newPage.orderBy);
        setSortOrder(newPage.order);
    };

    const handleCheckBox = (rowSelected) => {
        // setRowsSelected(rowSelected);       
        const selectedArray = Object.keys(rowSelected).map(key => parseInt(key) - 1);
        setRowsSelected(selectedArray);
    }

    const removePosts = () => {
        if (rowsSelected.length === 0) {
            setMessage("Please select posts first");
            setNotification(true);
            return;
        }
        setRemovePostsModal(true);
    };

    const handleRemovePostsModalClose = () => {
        setRemovePostsModal(false);
    };

    const removePostsModalConfirmation = () => {
        handleRemovePostsModalClose();
        if (rowsSelected.length == 0) {
            setMessage("Please select posts first");
            setNotification(true);
            return;
        }
        removePostsFromCampaign();
    };

    const getPidsFromRowsSelected = () => {
        const pidsSelected = [];
        rowsSelected.forEach((rowId) => {
            pidsSelected.push(posts[rowId]["postId"]);
        });
        return pidsSelected;
    };

    const removePostsFromCampaign = () => {
        if (rowsSelected.length === 0) {
            setMessage('please select posts first');
            setNotification(true);
            return;
        }

        const pidsSelected = getPidsFromRowsSelected();

        const optionAxios = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        let msg = 'Posts deleted successfully!';

        axios.post(`${API_HOST}/campaign/posts/remove`, { cid: campId, postIds: pidsSelected }, optionAxios)
            .then((res) => {
                if (res["data"]["status"] !== 'success') {
                    msg = 'Error...Please try again!';
                }
            })
            .catch((error) => {
                console.log(error);
                msg = 'Something went wrong!!';
            })
            .finally(() => {
                setMessage(msg);
                setNotification(true);
                window.location.reload();
            });
    };

    const handleTasksChange = (event) => {
        let value = event.target.value;
        let allIds = optionsTasks.map((e) => e.id);
        let selected = selectedTasksIds.length === optionsTasks.length ? [] : allIds;

        if (value[value.length - 1] === -1) {
            setSelectedTasksIds(selected);
            return;
        }
        setSelectedTasksIds(value);
    };

    const handleStatusChange = (event) => {
        let value = event.target.value;
        setSelectedStatus(value);
    };

    const handleTagTypeChange = (event) => {
        let value = event.target.value;
        let allIds = optionsTagTypes.map((e) => e.postType);
        let selected = selectedTagType.length === optionsTagTypes.length ? [] : allIds;

        if (value[value.length - 1] === -1) {
            setSelectedTagType(selected);
            return;
        }
        setSelectedTagType(value);
    };

    const isAllListSelected =
        optionsLists.length > 0 && selectedListIds.length === optionsLists.length;

    const isAllInfluencerSelected =
        optionsInfluencers.length > 0 &&
        selectedInfluencersIds.length === optionsInfluencers.length;

    const isAllTasksSelected =
        optionsTasks.length > 0 &&
        selectedTasksIds.length === optionsTasks.length;

    const isAllTagTypeSelected =
        optionsTagTypes.length > 0 &&
        selectedTagType.length === optionsTagTypes.length;


    const handleChangePlatform = async (event) => {
        const platformValue = event.target.value;
        let platform = platforms.find(e => e.id === platformValue);

        if (platform === undefined) {
            platform = { id: 0, name: "all" };
        }

        setSelectedPlatform(platformValue);
        setSelectedPlatformName(platform.name);
        setSelectedListIds([]);
        setSelectedInfluencersIds([]);

        // getAllList(campId.toString(), platform.name);

        const InfId = await getAllList(campId.toString(), platform.name);
        utils.getAllInfluencers(InfId, platform.name, campId).then((data) => {
            if (data) {
                setOptionsInfluencers(data);
            }
        });
    };

    const handleCalendarChange = (start, end) => {
        const formattedStart = moment(start).format("YYYY-MM-DD");
        const formattedEnd = moment(end).format("YYYY-MM-DD");
        setStartDate(formattedStart);
        setEndDate(formattedEnd);

        let days = moment(end).diff(moment(start), "days");

        if (days >= 0 && days <= 6) {
            setDisabledFilterByWeek(true);
            setDisabledFilterByMonth(true);
        } else if (days > 6 && days <= 29) {
            setDisabledFilterByWeek(false);
            setDisabledFilterByMonth(true);
        } else {
            setDisabledFilterByWeek(false);
            setDisabledFilterByMonth(false);
        }
    };

    const handleListChange = (event) => {
        let value = event.target.value;
        let allIds = optionsLists.map(e => e.id);
        let selected = selectedListIds.length === optionsLists.length ? [] : allIds;

        if (value[value.length - 1] === -1) {
            setSelectedListIds(selected);
            getAllInfluencers(selected);
            return;
        }

        setSelectedListIds(value);
        setSelectedInfluencersIds([]);
        getAllInfluencers(value);
    };

    const getAllInfluencers = (lids) => {
        utils.getAllInfluencers(lids, selectedPlatformName, campId).then((data) => {
            if (data) {
                setOptionsInfluencers(data);
            }
        });
    };

    const handleInfluencersChange = (event) => {
        let value = event.target.value;
        let allIds = optionsInfluencers.map((e) => e.Iid);
        let selected = selectedInfluencersIds.length === optionsInfluencers.length ? [] : allIds;
        if (value[value.length - 1] === -1) {
            setSelectedInfluencersIds(selected);
            return;
        }
        setSelectedInfluencersIds(value);
    };

    const applyFilters = () => {
        setIsSubmit(true);
        setTablePageNo(0);
    };

    const showHideAdvancedFilters = () => {
        setHideAdvancedFilters(!hideAdvancedFilters);
    };

    const downloadCampaignData = (event) => {
        const downloadAs = event.target.value;
        const today = new Date();
        const filename = fields.name + "-" + today.getDate() + (today.getMonth() + 1) + today.getFullYear() + "." + downloadAs;
        let input = document.getElementById("post-analysis");
        hideElementsBeforeCampaignExport();
        setLoading(true); // Update the loading state

        if (downloadAs === "pdf") {
            var w = input.offsetWidth;
            var h = input.offsetHeight;
            html2canvas(input).then((canvas) => {
                const img = canvas.toDataURL("image/jpeg", 1);
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
                var doc = new jsPDF("p", "px", [w, h]);
                const pdfWidth = doc.internal.pageSize.getWidth();
                const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
                doc.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
                doc.save(filename);
            });
        }
        else if (downloadAs === "jpeg") {
            html2canvas(input).then((canvas) => {
                var a = document.createElement("a");
                a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
                a.download = filename;
                a.click();
            });
        }
        else if (downloadAs === "png") {
            html2canvas(input).then((canvas) => {
                var a = document.createElement("a");
                a.href = canvas.toDataURL("image/png");
                a.download = filename;
                a.click();
            });
        }

        showElementsAfterCampaignExport();
    };

    const hideElementsBeforeCampaignExport = () => {
        for (let index in hideOnExport) {
            const elementId = hideOnExport[index];
            const element = document.getElementById(elementId);
            if (element) {
                element.style.display = "none";
            }
        }
    };

    const showElementsAfterCampaignExport = () => {
        for (let index in hideOnExport) {
            const elementId = hideOnExport[index];
            const element = document.getElementById(elementId);
            if (element) {
                element.style.display = "block";
            }
        }
    };

    const editTag = (postId, tag) => {
        const tagType = tag === "" ? "add" : "edit";
        setTagValue(tag);
        setCurrentPostId(postId);
        setEditTagModal(true);
        setTagType(tagType);
    };

    const closeNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setNotification(false);
    };

    const handleEditTagModalClose = () => {
        setEditTagModal(false);
    };

    const saveTageValue = (postId, tag) => {
        const finalTag = tag.trim();
        const optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const body = {
            cid: campId,
            pid: postId,
            tag: finalTag,
        };
        axios.post(API_HOST + "/post/edittag", body, optionAxios)
            .then((res) => {
                fetchData();
            })
            .finally(() => {
                handleEditTagModalClose();
                getCampaignTagsData(campId);
            });
    };


    const getPublishedDate = (postedOn) => {
        const parts = postedOn.split('-');
        const dateString = parts[1]?.trim();
        return dateString
    }

    const extractTiming = (text) => {
        // Check if text starts with '0'
        if (text.startsWith('0')) {
            return 'few minutes ago';
        }

        // Find the index of 'ago' and return the substring
        const index = text.indexOf('ago');
        if (index !== -1) {
            return text.substring(0, index + 3);
        }

        return text;
    };

    const renderStatusIcon = (status) => {
        const lowerCaseStatus = status.toLowerCase();

        if (lowerCaseStatus === "processing" || lowerCaseStatus === "awaiting response") {
            return <Timer style={{ color: "#fada0c", width: "50%" }} />;
        } else if (lowerCaseStatus === "successfully refreshed" || lowerCaseStatus === "success") {
            return <ThumbUp style={{ color: "#04cf55", width: "50%" }} />;
        } else if (lowerCaseStatus === "invalid url") {
            return <ErrorOutline style={{ color: "red", width: "50%" }} />;
        } else {
            return <RunningWithErrors style={{ color: "red", width: "50%" }} />;
        }
    }

    function getTagColors(value) {
        let hash = 0;
        for (let i = 0; i < value?.length; i++) {
            hash = value.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
            let v = (hash >> (i * 8)) & 0xFF;
            color += ("00" + v.toString(16)).substr(-2);
        }

        const style = {
            backgroundColor: color,
            color: "white"
        };

        return style;
    }

    const editPost = (postIdDB, currentPostId, currentInfluencerId, platform, lid, lpId, postLanguage) => {
        axios.get(`${API_HOST}/list/influencers/id/${lid}`).then((res) => {

            setPostIdDBSelected(postIdDB);
            setCurrentPostId(currentPostId);
            setCurrentInfluencerId(currentInfluencerId);
            setPostIdPlatform(platform);
            setEditPostIdModal(true);
            setLpId(lpId);
            setPostLang(postLanguage);

            if (res.data == null || res.data.length === 0) {
                return;
            }

            let organicIdsArray = [];
            let directIdsArray = [];
            res.data.forEach((influencer) => {
                if (influencer["type"] === "1") {
                    directIdsArray.push(parseInt(influencer["iid"], 10));
                } else {
                    organicIdsArray.push(parseInt(influencer["iid"], 10));
                }
            });

            const optionAxios = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            };

            // get influencers details
            axios.post(
                `${INF_API_HOST}/influencer/specific/list`,
                {
                    organicIds: organicIdsArray.join(","),
                    directIds: directIdsArray.join(","),
                    platform: platform,
                },
                optionAxios
            ).then((res) => {
                let influencersList = res["data"];
                if (influencersList == null) {
                    setinfluencersList([]);
                }
                setinfluencersList(influencersList)

            }).catch((err) => {
                console.log("Error:", err);
            });
        });
    };

    //called when Update Stats is clicked
    const editStory = (currentPostId, currentPlatform, publishedDate, likes, comments, shares, views) => {
        setEditStoryDataModal(true);
        setCurrentPostId(currentPostId);
        setPostIdPlatform(currentPlatform);
        setEditStoryDataPublishedDate(publishedDate);
        setEditStoryDataLikes(likes);
        setEditStoryDataComments(comments);
        setEditStoryDataShares(shares)
        setEditStoryDataViews(views);
    };

    const refreshPerPostData = (data) => {
        const postData = {
            cid: campId,
            lid: data.lid,
            iid: data.iid,
            platform: data.platformId,
            postType: "post",
            postIds: data.id,
            postUrl: data.postLink,
        };
        const msg = "Your request has been queued. Please check again in a few minutes";
        const optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        axios.post(`${API_HOST}/orca/refresh/post`, postData, optionAxios)
            .then((res) => {
                setNotification(true);
                setMessage(msg);
            })
            .catch((error) => {
                console.log("Some error occurred: ", error);
            });
    };

    const storyScreenshot = (post) => {
        setScreenshotModal(true);
        setCurrentPostId(post.id);
        setSelectedImage(post.imageLink);
        setSelectedPost(post);
        setEditSelectedImage(post.imageLink === "");
    };

    const restorePost = (currentPostId) => {
        const body = {
            cid: campId.toString(),
            postId: currentPostId,
            status: "0",
        };
        const optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        axios.post(`${API_HOST}/post/status/update`, body, optionAxios)
            .then((res) => {
                if (res.data) {
                    setMessage("Post Restored Successfully");
                    setNotification(true);
                    // Refresh the page
                    window.location = window.location.href;
                }
            });
    };


    const handleEditStoryDataModalClose = () => {
        setEditStoryDataModal(false);
        setCurrentPostId(0);
        setEditStoryDataPublishedDate("");
        setEditStoryDataLikes(0);
        setEditStoryDataComments(0);
        setEditStoryDataShares(0);
        setEditStoryDataViews(0);
    };

    const handleEditPostIdModalClose = () => {
        setPostIdDBSelected(-1);
        setCurrentPostId("");
        setCurrentInfluencerId("");
        setPostIdPlatform("");
        setEditPostIdModal(false);
        setLpId(0);
    };

    const handleNewPostIdChange = (e) => {
        setNewPostId(e.target.value);
    };

    const handleChangeInfluencer = (e, value) => {
        setNewInfluencerId(value["influencer_id"]);
    };

    const updatePostId = () => {
        if (newPostId !== "") {
            const currentPlatform = getAllPlatforms(currentPostId);
            const newPlatform = getAllPlatforms(newPostId);
            if (currentPlatform !== newPlatform) {
                setMessage("Incorrect platform");
                setNotification(true);
                return;
            }
        }

        let respMsg = "Post Information updated successfully!";
        const optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const payload = {
            listPostMapId: postIdDBSelected,
            updatedPostUrl: newPostId,
            updatedInfluencerId: newInfluencerId,
            lpId: lpId,
            postLang: postLang,
        };

        axios.post(`${API_HOST}/postId/update`, payload, optionAxios)
            .then(res => {
                respMsg = res.data.Msg;
            })
            .catch(err => {
                console.log(err);
                respMsg = "Could not update Post Id";
            })
            .finally(() => {
                setMessage(respMsg);
                setNotification(true);
                setEditPostIdModal(false);

                setTimeout(() => {
                    fetchData();
                    getTopNPosts(campId);
                }, 2000);
            });
    };


    const handleEditStoryPublishedDate = (e) => {
        setEditStoryDataPublishedDate(e.target.value);
    }

    const handleEditStoryDataLikes = (e) => {
        setEditStoryDataLikes(e.target.value);
    }

    const handleEditStoryDataComments = (e) => {
        setEditStoryDataComments(e.target.value);
    }

    const handleEditStoryDataShares = (e) => {
        setEditStoryDataShares(e.target.value);
    }

    const handleEditStoryDataViews = (e) => {
        setEditStoryDataViews(e.target.value);
    }

    const updateStoryData = () => {
        if (
            editStoryDataPublishedDate === "" &&
            editStoryDataLikes === "" &&
            editStoryDataComments === "" &&
            editStoryDataShares === "" &&
            editStoryDataViews === ""
        ) {
            setMessage("Stats entered cannot be blank");
            setNotification(true);
            return;
        }

        let respMsg = "Story Stats updated successfully!";
        const optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        const payload = {
            postId: currentPostId,
            cid: campId,
            shares: parseInt(editStoryDataShares),
            likes: parseInt(editStoryDataLikes),
            comments: parseInt(editStoryDataComments),
            // views: parseInt(editStoryDataViews),
            postType: 3,
            platform: enums.platformIdMapping[utils.capitalizeFirstLetter(postIdPlatform)],
        };

        axios.post(`${API_HOST}/story/update`, payload, optionAxios)
            .then((res) => {
                respMsg = res["data"]["Msg"];
            })
            .catch((err) => {
                console.log(err);
                respMsg = "Could not update Story";
            })
            .finally(() => {
                setMessage(respMsg);
                setNotification(true);
                setEditStoryDataModal(false);

                setTimeout(() => {
                    fetchData();
                    getTopNPosts(campId);
                }, 2000);
            });
    };

    const handleScreenshotModalClose = () => {
        setScreenshotModal(false);
    };

    const handleUpload = (event) => {
        if (event.target.files.length !== 0) {
            const file = event.target.files[0];
            setNewScreenshot(file);
        } else {
            setNewScreenshot(null);
        }
    };

    const editScreenshot = () => {
        const imageFormData = new FormData();
        imageFormData.append("attachment", newScreenshot);
        imageFormData.append("postId", currentPostId);
        imageFormData.append("cid", selectedPost.cid);
        imageFormData.append("lid", selectedPost.lid);
        imageFormData.append("iid", selectedPost.iid);

        axios.post(`${API_HOST}/post/story/editImage`, imageFormData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then((res) => {
                handleScreenshotModalClose();
                applyFilters();
                setTablePageNo(0);
            })
    };

    // function to set search value in "filter" variable and change page no to 0
    const sendSearchedValue = (event) => {
        setFilter(event);
        setTablePageNo(0);
    };

    // function to set in which from(csv) we want to export and decide whether we want to export or not
    const sendExportSelected = (event) => {
        setIsExport(event === "csv" ? 1 : 0);
    };

    useEffect(() => {
        const data = [];
        if (posts) {
            posts.forEach((item, index) => {
                const {
                    comment,
                    createdAt,
                    engagement,
                    engagementRate,
                    groupId,
                    groupName,
                    id,
                    impressions,
                    infId,
                    infName,
                    lastRefreshedOn,
                    like,
                    milestone,
                    photo_url,
                    platform,
                    platformId,
                    postId,
                    postType,
                    postTypeName,
                    postUrl,
                    postedOn,
                    profileClick,
                    profileUrl,
                    reach,
                    reply,
                    retweet,
                    share,
                    status,
                    tag,
                    title,
                    total_followers,
                    userName
                } = item;


                let iids = infId === "" ? 0 : infId;
                let publishedDate = getPublishedDate(postedOn);
                const lastRefreshedAgo = extractTiming(lastRefreshedOn);
                var splitedDate = new Date(createdAt).toUTCString().split(" ");

                const postObject = {
                    serialNumber: index + 1,
                    ...item,
                    postLink: `#${userName}`,
                    postStats: {
                        engagement,
                        likes: like,
                        comments: comment,
                        share,
                        reply,
                        retweet,
                        profileClick,
                        platform: platformId,
                        impressions,
                    },
                    listName: utils.capitalizeFirstLetter(groupName),
                    engagementRate: engagementRate >= 0 ? `${engagementRate}%` : "0%",
                    profileData: [
                        {
                            socialLink: item["profileUrl"],
                        },
                    ],
                    timeData: {
                        createdDate: `${splitedDate[0]} ${splitedDate[1]} ${splitedDate[2]} ${splitedDate[3]}`,
                        createdTime: `${splitedDate[4]} ${splitedDate[5]}`,
                    },
                    postData: {
                        id: item["postId"],
                        name: item["infName"],
                        lid: item["groupId"],
                        iid: iids,
                        lpId: item["id"],
                        reach: item["reach"],
                        status: item.status,
                        postType: item.postType,
                        postLink: item.postUrl,
                        platform: item.platform,
                        platformId: item.platformId
                    },

                    postLanguage: item.postLang, // need to check 
                    postType: enums.postTypeMapping[item.postType],
                    postRefresh: {
                        status: item.status,
                        time: lastRefreshedOn
                    },
                    hiddenPostLink: item.postUrl,
                    hiddenEngagement: item.engagement,
                    hiddenInfName: item["infName"],
                };

                platforms.forEach((platform) => {
                    if (platform["id"] === platformId) {
                        postObject.postData["platform"] = platform["name"];
                        postObject.postData["platformId"] = platform["id"];
                    }
                });

                postObject.hiddenDate = `${postObject.timeData.createdDate} at ${postObject.timeData.createdTime}`;
                postObject.tagData = {
                    id: item.id,
                    text: item.tag,
                    style: getTagColors(item.tag),
                };

                postObject.actions = (
                    <PostActions
                        postObject={postObject}
                        editPost={editPost}
                        editStory={editStory}
                        refreshPerPostData={refreshPerPostData}
                        editTag={editTag}
                        storyScreenshot={storyScreenshot}
                        deleted={deleted}
                        restorePost={restorePost}
                    />
                );

                postObject.expandedRow = (
                    <>
                        <TableRow style={{ backgroundColor: "#f6f1f1", width: "100%" }}>
                            <TableCell colSpan={3}>
                                <h6 style={{ paddingLeft: "30px" }}>
                                    <b className="MuiFormHelperText-root">List Name</b>
                                    <br />
                                    {postObject.listName}
                                </h6>
                            </TableCell>
                            <TableCell colSpan={2}>
                                <h6>
                                    <b className="MuiFormHelperText-root">Added On</b>
                                    <div>
                                        <div>
                                            <p>{postObject.timeData["createdDate"]}</p>
                                        </div>
                                    </div>
                                </h6>
                            </TableCell>
                            <TableCell colSpan={2}>
                                <h6>
                                    <b className="MuiFormHelperText-root">Added at</b>
                                    <div>
                                        <div>
                                            <p>{postObject.timeData["createdTime"]}</p>
                                        </div>
                                    </div>
                                </h6>
                            </TableCell>
                            <TableCell colSpan={2}>
                                <h6>
                                    <b className="MuiFormHelperText-root">Last Refreshed</b>
                                    <div>
                                        <div>
                                            <p>{postObject.postRefresh["time"]}</p>
                                        </div>
                                    </div>
                                </h6>
                            </TableCell>
                        </TableRow>
                    </>
                );

                const open = anchorEl.map((val) => val !== null);

                const object = {
                    id: index + 1,
                    post_URL: (
                        <>
                            <Grid container spacing={1} sx={{ justifyContent: "flex-start", width: "170px" }}>
                                {status.toLowerCase() !== "invalid url" && reach === 0 && (
                                    <Grid item xs={1.5}>
                                        <Tooltip title="Reach is missing">
                                            <Warning fontSize="medium" sx={{ color: "#FFA500" }} />
                                        </Tooltip>
                                    </Grid>
                                )}
                                <Grid item sx={{ width: "30px", marginLeft: status.toLowerCase() !== "invalid url" && reach === 0 ? "4px" : "0px" }}>
                                    <Typography sx={textColor[platform]}>{snapshotHeaderIcons[platform]}</Typography>
                                </Grid>
                                <Grid item md={8} sm={5}>
                                    <Tooltip title={postUrl}>
                                        <Grid item xs={12} className={styles.bodycell} style={{ marginTop: 5 }}>
                                            <InfoToolTipPreview postUrl={postUrl} platform={platform} postId={postId} />
                                        </Grid>
                                    </Tooltip>
                                    <div style={{ fontStyle: "italic", paddingLeft: "1px", color: "#999" }}>by {infName}</div>
                                </Grid>
                            </Grid>
                        </>
                    ),
                    tagData: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {tag !== "" ? (
                                    <Chip label={tag} style={{ backgroundColor: "#818ffb", color: "white" }} />
                                ) : (
                                    <Tooltip title="Add Tag">
                                        <IconButton onClick={() => editTag(id, "")}>
                                            <LocalOffer fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    postType: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                <Tooltip title={postTypeName}>
                                    <div>
                                        {postTypeName == "post" && <Article />}
                                        {postTypeName == "poll" && <Poll />}
                                        {postTypeName == "space" && <Mic />}
                                        {postTypeName == "reel" && <Videocam />}
                                        {postTypeName == "story" && <HistoryToggleOff />}
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    publishedAt: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {publishedDate !== "" && publishedDate !== "0000-00-00" ? (
                                    <p className={styles.bodycell}>{publishedDate}</p>
                                ) : (
                                    <p className={styles.bodycell}>{"N.A."}</p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    taskName: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {title !== "" ? (
                                    <p className={styles.bodycell}>{title}</p>
                                ) : (
                                    <p className={styles.bodycell}>{"-"}</p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    milestone: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {milestone !== "" ? (
                                    <p className={styles.bodycell}>{milestone}</p>
                                ) : (
                                    <p className={styles.bodycell}>{"-"}</p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    totalEngagement: (
                        <Grid container sx={{ paddingTop: "20px" }}>
                            <Grid item xs={2}>
                                {engagement > 0 ? (
                                    <p className={styles.bodycell}>{utils.convertToInternationalNumberSystem(engagement)}</p>
                                ) : (
                                    <p className={styles.bodycell}>
                                        <span style={{ paddingLeft: "20px" }}>-</span>
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Tooltip
                                    title={
                                        engagement > 0 ? (
                                            <>
                                                <Typography color="inherit">Engagement Breakup</Typography>
                                                <hr />
                                                {like > 0 && (
                                                    <>
                                                        <b>{"Likes :"}</b> <span>{utils.convertToInternationalNumberSystem(like)}</span>
                                                    </>
                                                )}
                                                {comment > 0 && (
                                                    <>
                                                        <br />
                                                        <b>{"Comments :"}</b> <span>{utils.convertToInternationalNumberSystem(comment)}</span>
                                                    </>
                                                )}
                                                {share > 0 && (
                                                    <>
                                                        <br />
                                                        <b>{"Shares :"}</b> <span>{utils.convertToInternationalNumberSystem(share)}</span>
                                                    </>
                                                )}
                                                {retweet > 0 && (
                                                    <>
                                                        <br />
                                                        <b>{"Retweet :"}</b> <span>{utils.convertToInternationalNumberSystem(retweet)}</span>
                                                    </>
                                                )}
                                                {profileClick > 0 && (
                                                    <>
                                                        <br />
                                                        <b>{"Profile Click :"}</b>{" "}
                                                        <span>{utils.convertToInternationalNumberSystem(profileClick)}</span>
                                                    </>
                                                )}
                                                {reply > 0 && (
                                                    <>
                                                        <br />
                                                        <b>{"Reply :"}</b> <span>{utils.convertToInternationalNumberSystem(reply)}</span>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }
                                >
                                    <InfoIcon fontSize="small" style={{ opacity: engagement > 0 ? "0.5" : "0" }} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    actuallReach: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {impressions > 0 ? (
                                    <p className={styles.bodycell}>{utils.convertToInternationalNumberSystem(impressions)}</p>
                                ) : (
                                    <p className={styles.bodycell}>
                                        <span style={{ paddingLeft: "20px" }}>-</span>
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    potentialReach: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                <p className={styles.bodycell}>{utils.convertToInternationalNumberSystem(reach)}</p>
                            </Grid>
                        </Grid>
                    ),
                    engagementRate: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>

                                <p className={styles.bodycell}>{engagementRate}%</p>

                            </Grid>
                        </Grid>
                    ),
                    status: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                <Tooltip title={status.toLowerCase() === "processing" || status.toLowerCase() === "awaiting response" ? "Your request has been scheduled. Please check in few minutes" : status}>
                                    <p className={styles.bodycell}>{renderStatusIcon(status)}</p>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    lisName: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>

                                <p className={styles.bodycell}>{groupName}</p>

                            </Grid>
                        </Grid>
                    ),
                    lastRefreshed: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>

                                <p className={styles.bodycell}>{lastRefreshedAgo}</p>

                            </Grid>
                        </Grid>
                    ),

                    actions: (
                        <Grid container>
                            <Grid item xs={3}>
                                <>
                                    <PostActions
                                        postObject={postObject}
                                        editPost={editPost}
                                        editStory={editStory}
                                        refreshPerPostData={refreshPerPostData}
                                        editTag={editTag}
                                        storyScreenshot={storyScreenshot}
                                        deleted={deleted}
                                        restorePost={restorePost}
                                    />
                                </>
                            </Grid>
                        </Grid>
                    ),
                };

                data.push(object);
            });
            setTableData(data);
        }
    }, [posts, anchorEl]);

    const publishedPostAnalysisTableHeaders = getPublishedPostAnalysisTableHeaders;

    const renderContentHandler = () => {
        let content = null;
        if (loading) {
            if (dataFound) {
                content = (
                    <>
                        {/* <div id="table" style={{ width: "76vw", overflowX: "auto" }}> */}
                        <TableContainer>
                            <StyledTable>
                                <CustomTable
                                    tableHeaders={publishedPostAnalysisTableHeaders}
                                    bodyColumns={tableData}
                                    sendInformation={handleInformationChange}
                                    totalRows={totalRecords}
                                    pageNo={tablePageNo}
                                    rowsPerPageNo={tableRowsPerPage}
                                    sortOrder={sortOrder}
                                    sortOrderBy={sortCol}
                                    rowSelectionChanged={handleCheckBox}
                                />
                            </StyledTable>
                        </TableContainer>
                        {/* </div> */}
                    </>
                );
            }
            else {
                content = (
                    <MyPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <p style={{ padding: "40px", textAlign: "center" }}> You don't have any Posts at the moment.</p>
                            </Grid>
                        </Grid>
                    </MyPaper>
                );
            }
        } else content = <Loader />;

        return content;
    };

    return (
        <>

            <Box
                ml={1}
                sx={{
                    width: "100%",
                    ...(!utils.showActionButtons([1], fields.current_status) && { marginBottom: "60px" }),
                }}
            >
                <Stack direction="row" justifyContent="end" sx={{ marginBottom: "15px" }}>
                    {utils.showActionButtons([1, 3], fields.current_status) && posts.length > 0 && (
                        <Button sx={{ marginRight: "30px" }} variant="contained" onClick={removePosts}>
                            Remove Posts
                        </Button>
                    )}
                    {utils.showActionButtons([1], fields.current_status) && (
                        <Button sx={{ marginRight: "30px" }} variant="contained" component={Link} to={linkToAddPost}>
                            + Add Posts{" "}
                        </Button>
                    )}
                </Stack>
            </Box>

            <div id="post-analysis">
                <div>
                    <MyPaper>
                        <CampaignFilters
                            name={fields.name}
                            id={campId}
                            selectedPlatform={selectedPlatform}
                            selectedPlatformName={selectedPlatformName}
                            platforms={platforms}
                            startDate={startDate}
                            endDate={endDate}
                            filterBy={filters.filterBy}
                            disabledFilterByWeek={disabledFilterByWeek}
                            disabledFilterByMonth={disabledFilterByMonth}
                            hideAdvancedFilters={hideAdvancedFilters}
                            selectedListIds={selectedListIds}
                            selectedInfluencersIds={selectedInfluencersIds}
                            disableRefreshButton={disableRefreshButton}
                            isAllListSelected={isAllListSelected}
                            optionsLists={optionsLists}
                            isAllInfluencerSelected={isAllInfluencerSelected}
                            optionsInfluencers={optionsInfluencers}
                            handleChangePlatform={handleChangePlatform}
                            handleCalendarChange={handleCalendarChange}
                            //handleFilterBy={handleFilterBy}
                            handleListChange={handleListChange}
                            handleInfluencersChange={handleInfluencersChange}
                            datePickerRef={datePickerRef}
                            applyFilters={applyFilters}
                            //setState={setState}
                            showHideAdvancedFilters={showHideAdvancedFilters}
                            hideFilterBy={true}
                            hidePlatform={false}
                            hideInfluencers={false}
                            hideTasks={false}
                            hideRange={false}
                            alignWidth={"50%"}
                            handleDownloadData={downloadCampaignData}
                            currentStatus={fields.current_status}
                            disableExport={true}
                            selectedTasksIds={selectedTasksIds}
                            isAllTasksSelected={isAllTasksSelected}
                            optionsTasks={optionsTasks}
                            handleTasksChange={handleTasksChange}
                            hideStatus={false}
                            selectedStatus={selectedStatus}
                            handleStatusChange={handleStatusChange}
                            optionsStatus={optionsStatus}
                            hideTagType={false}
                            optionsTagTypes={optionsTagTypes}
                            selectedTagType={selectedTagType}
                            handleTagTypeChange={handleTagTypeChange}
                            isAllTagTypeSelected={isAllTagTypeSelected}
                        />
                    </MyPaper>
                    <Snackbar
                        open={notification}
                        autoHideDuration={3000}
                        onClose={closeNotification}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <Alert onClose={closeNotification} severity="success" sx={{ width: "100%" }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </div>
            </div>

            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <SearchItem sendSearchedValue={sendSearchedValue} searchBy={searchBy} />
                <ExportFiles sendExportSelected={sendExportSelected} />
            </Box>

            <Box my={1}>{renderContentHandler()}</Box>

            <div>
                <MyPaper
                    sx={{
                        marginTop: "20px",
                        height: "auto",
                        display: topPosts.length > 0 ? "block" : "none",
                    }}
                >
                    <Stack direction="row" justifyContent="start">
                        <Typography variant="h4" sx={styles.typography}>
                            Top {topPosts.length} Posts
                        </Typography>
                    </Stack>
                    <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {topPosts.map((post) => (
                            <Grid key={post.postId} item xs={1} sm={2} md={4}>
                                <TopNPosts
                                    EngRate={post.EngRate}
                                    Engagement={post.Engagement}
                                    PostImageUrl={post.PostImageUrl}
                                    PostTitle={post.PostTitle}
                                    PostUrl={post.posturl}
                                    Platform={post.platform}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </MyPaper>

            </div>

            {/* modal for removing posts from a campaign */}
            <Modal
                className="styles_modal"
                aria-labelledby="modal-remove_posts_from_campaign-label"
                aria-describedby="modal-remove_posts_from_campaign-description"
                open={removePostsModal}
                onClose={handleRemovePostsModalClose}
                closeAfterTransition
            >
                <Fade in={removePostsModal}>
                    <div
                        style={{
                            backgroundColor: "white",
                            outline: "none",
                            padding: "10px",
                            width: "35rem",
                        }}
                    >
                        <div id="modal-remove_posts_from_campaign-heading" className="styles_modal_heading">
                            <h2 id="modal-remove_posts_from_campaign-heading-text" style={{ padding: "10px" }}>
                                Remove Posts From List
                            </h2>
                            <IconButton onClick={handleRemovePostsModalClose}>
                                {" "}
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <hr />
                        <div id="modal-remove_posts_from_campaign-body" style={{ padding: "10px" }}>
                            Are you sure want to remove?
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                <Button style={{ margin: "0.5rem" }} variant="contained" onClick={handleRemovePostsModalClose}>
                                    No
                                </Button>
                                <Button style={{ margin: "0.5rem" }} variant="contained" onClick={removePostsModalConfirmation}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* modal for editing tags */}
            <Modal
                className="styles_modal"
                aria-labelledby="modal-edit_tags-label"
                aria-describedby="modal-edit_tags-description"
                open={editTagModal}
                onClose={handleEditTagModalClose}
                closeAfterTransition
            >
                <Fade in={editTagModal}>
                    <div
                        style={{
                            backgroundColor: "white",
                            outline: "none",
                            padding: "10px",
                            width: "35rem",
                        }}
                    >
                        <div id="modal-edit_tags-heading" className="styles_modal_heading">
                            <h2 id="modal-edit_tags-heading-text" style={{ padding: "10px" }}>
                                {tagType === "add" && "Add Tag"}
                                {tagType === "edit" && "Edit Tag"}
                            </h2>
                            <IconButton onClick={handleEditTagModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <hr />
                        <div id="modal-edit_tags-body" style={{ padding: "10px" }}>
                            <Grid container>
                                <Grid iten xs={12}>
                                    <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1 }}>
                                        <Autocomplete
                                            id="editTagInput"
                                            freeSolo
                                            options={tags.map((option) => option)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Tag"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        inputProps: {
                                                            ...params.inputProps,
                                                            maxLength: 20,
                                                        },
                                                        endAdornment: tagValue?.length + "/20",
                                                    }}
                                                    helperText="Choose existing or type to add a new tag"
                                                />
                                            )}
                                            value={tagValue}
                                            onInputChange={(e, value) => setTagValue(value)}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                {tagType === "edit" && (
                                    <Button
                                        style={{ margin: "0.5rem" }}
                                        variant="outlined"
                                        onClick={() => saveTageValue(currentPostId, "")}
                                    >
                                        Clear
                                    </Button>
                                )}
                                <Button
                                    style={{ margin: "0.5rem" }}
                                    variant="contained"
                                    onClick={() => saveTageValue(currentPostId, tagValue)}
                                >
                                    {tagType === "add" && "Add"}
                                    {tagType === "edit" && "Save"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* modal for editing postId */}
            <Modal
                keepMounted
                className="styles_modal"
                aria-labelledby="modal-edit_postId-label"
                aria-describedby="modal-edit_postId-description"
                open={editPostIdModal}
                onClose={handleEditStoryDataModalClose}
            >
                <Fade in={editPostIdModal}>
                    <div className="syles_paper">
                        <div id="modal-edit_postId-heading" className="styles_modal_heading">
                            <p>
                                <div id="modal-edit_postId-heading-text" style={{ padding: "10px" }}>
                                    <b>Edit Post </b>{" "}
                                    <div className="tooltipLink">
                                        <span className="tooltipInnerLink"> {currentPostId}</span>
                                        {/* <span className="tooltiptext">{currentPostId}</span> */}
                                    </div>
                                </div>
                            </p>
                            <IconButton onClick={handleEditPostIdModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <hr />

                        <Grid item xs={12}>
                            <Box>
                                <form id="updatePostIdForm">
                                    {
                                        <p>
                                            Please provide a new <b>Post URL</b>
                                        </p>
                                    }
                                    <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            id="newPostId"
                                            refs="this.state.newPostId"
                                            label="Update Post Url"
                                            onChange={handleNewPostIdChange}
                                        />
                                    </FormControl>
                                    {
                                        <p>
                                            Please select a new <b>Influencer</b>
                                        </p>
                                    }
                                    <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1 }}>
                                        <Autocomplete
                                            disableClearable
                                            options={influencersList}
                                            getOptionLabel={(influencer) => influencer.name}
                                            onChange={handleChangeInfluencer}
                                            id="influencer-data"
                                            renderInput={(params) => <TextField {...params} label="Influencer" />}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="source-label">Post Language Type</InputLabel>
                                        <Select
                                            onChange={(event) => setPostLang(event.target.value)}
                                            variant="outlined"
                                            label="Post Language Type"
                                            value={postLang}
                                        >
                                            {langList.all.map((lang) => (
                                                <MenuItem key={lang} value={lang}>
                                                    {lang}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <br />
                                        <div> * You can update either Post URL or Influencer name or both.</div>
                                    </FormControl>
                                </form>
                            </Box>
                        </Grid>

                        <hr />

                        <div
                            id="modal-edit_postId-body"
                            style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                paddingBottom: "10px",
                                paddingTop: "0px !important",
                            }}
                        >
                            <Stack direction="row" justifyContent="center">
                                <Button
                                    variant="contained"
                                    id="edit_postId-submit-button"
                                    onClick={updatePostId}
                                    style={{ width: "100%" }}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* modal for editing story data */}
            <Modal
                className="styles_modal"
                aria-labelledby="modal-edit-story-data-label"
                aria-describedby="modal-edit-story-data-description"
                open={editStoryDataModal}
                onClose={handleEditStoryDataModalClose}
                closeAfterTransition
            >
                <Fade in={editStoryDataModal}>
                    <div className="syles_paper">
                        <div id="modal-edit-story-data-heading" className="styles_modal_heading">
                            <p>
                                <div id="modal-edit-story-data-heading-text" style={{ padding: "10px" }}>
                                    <b>Update Stats</b>{" "}
                                    <div className="tooltipLink">
                                        <span className="tooltipInnerLink"> {currentPostId}</span>
                                    </div>
                                </div>
                            </p>
                            <IconButton onClick={handleEditStoryDataModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <hr />

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={10}>
                            <Grid item xs={2}>
                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                    <TextField
                                        id="date"
                                        label="Published Date"
                                        type="date"
                                        defaultValue="2021-12-15"
                                        InputProps={{
                                            inputProps: {
                                                min: moment(new Date(startDate)).format("YYYY-MM-DD"),
                                                max: moment(new Date()).format("YYYY-MM-DD"),
                                            },
                                        }}
                                        value={editStoryDataPublishedDate}
                                        onChange={handleEditStoryPublishedDate}
                                        sx={{ width: 220 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                    <ThumbUpIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                                    <TextField
                                        id="input-with-sx"
                                        label="Likes"
                                        variant="standard"
                                        value={editStoryDataLikes}
                                        onChange={handleEditStoryDataLikes}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                    <CommentIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                                    <TextField
                                        id="input-with-sx"
                                        label="Comments"
                                        variant="standard"
                                        value={editStoryDataComments}
                                        onChange={handleEditStoryDataComments}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                    <ShareIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                                    <TextField
                                        id="input-with-sx"
                                        label="Shares"
                                        variant="standard"
                                        value={editStoryDataShares}
                                        onChange={handleEditStoryDataShares}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                    <VisibilityIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                                    <TextField
                                        id="input-with-sx"
                                        label="Views"
                                        variant="standard"
                                        value={editStoryDataViews}
                                        onChange={handleEditStoryDataViews}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <hr />

                        <div
                            id="modal-story-data-body"
                            style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                paddingBottom: "10px",
                                paddingTop: "0px !important",
                            }}
                        >
                            <Stack direction="row" justifyContent="center">
                                <Button
                                    variant="contained"
                                    id="edit-story-data-button"
                                    onClick={updateStoryData}
                                    style={{ width: "100%" }}
                                >
                                    Update
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* modal for story screenshots */}
            <Modal
                className="styles_modal"
                aria-labelledby="modal-story_screenshot-label"
                aria-describedby="modal-story_screenshot-description"
                open={screenshotModal}
                onClose={handleScreenshotModalClose}
                closeAfterTransition
            >
                <Fade in={screenshotModal}>
                    <div className="syles_paper">
                        <div id="modal-story_screenshot-heading" className="styles_modal_heading">
                            <p>
                                <div id="modal-edit-story-data-heading-text" style={{ padding: "10px" }}>
                                    <b>Story Screenshot</b>{" "}
                                    <div className="tooltipLink">
                                        <span className="tooltipInnerLink"> {currentPostId}</span>
                                    </div>
                                </div>
                            </p>
                            <IconButton onClick={handleScreenshotModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <hr />
                        <div id="modal-story_screenshot-body" style={{ padding: "10px" }}>
                            {!editSelectedImage && <img alt="" src={selectedImage} style={{ maxHeight: "50vh" }} />}
                            {editSelectedImage && (
                                <>
                                    <input
                                        accept="image/png, image/jpeg"
                                        type="file"
                                        onChange={(evnt) => handleUpload(evnt)}
                                        id="upload-image"
                                        hidden
                                    />
                                    <Box
                                        sx={{
                                            border: "2px dashed gray",
                                            width: "400px",
                                            height: "150px",
                                            cursor: "pointer",
                                            m: "10px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        onClick={() => {
                                            document.getElementById("upload-image").click();
                                        }}
                                    >
                                        {newScreenshot === null && "Click here to upload"}
                                        {newScreenshot !== null && newScreenshot.name}
                                    </Box>
                                </>
                            )}
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                {selectedImage !== "" && editSelectedImage && (
                                    <Button
                                        style={{ margin: "0.5rem" }}
                                        variant="outlined"
                                        onClick={() => setEditSelectedImage(false)}
                                    >
                                        Cancel
                                    </Button>
                                )}

                                {editSelectedImage && (
                                    <Button
                                        style={{ margin: "0.5rem" }}
                                        variant="contained"
                                        onClick={editScreenshot}
                                        disabled={newScreenshot === null}
                                    >
                                        Upload
                                    </Button>
                                )}
                                {!editSelectedImage && (
                                    <Button
                                        style={{ margin: "0.5rem" }}
                                        variant="contained"
                                        onClick={() =>
                                            setEditSelectedImage(!editSelectedImage)
                                        }
                                    >
                                        Edit
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )

}
export default PublishedPostAnalysis;
