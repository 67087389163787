import { useEffect, useRef, useState } from "react";

import { PropTypes } from "prop-types";

import {
  Autocomplete,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";

import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import "./styles.css";
import { PROFILE_IMG } from "../../config/main.json";


const ViewTagDialog = ({
  influencerAccounts,
  platform,
  influencerAccount,
  viewTagDialog,
  closeViewTagDialog,
  setInfluencerAccountList,
  sendInformation,
}) => {
  const [scroll, setScroll] = useState("paper");
  const descriptionElementRef = useRef(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [tags, setTags] = useState([]);
  const [existingTags, setExistingTags] = useState([]);

  useEffect(() => {
    if (viewTagDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      if (influencerAccount?.tags?.length>0) {
        setIsUpdate(true);
        setExistingTags(influencerAccount.tags);
      } else {
        setIsUpdate(false);
        setExistingTags([]);
      }
    }
  }, [viewTagDialog]);

  const handleClose = () => {
    closeViewTagDialog(false);
  };

  const updateInformation = () => {
    sendInformation({ id: influencerAccount.id, tags, isUpdate });
  };

  const handleTagsAddition = (v) => {
    setExistingTags(v);
  }

  return(
    <Dialog
      open={viewTagDialog}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <CardMedia
              component="img"
              image={influencerAccount.photoUrl}
              alt={influencerAccount.name}
              onError={e => {
                e.target.src = PROFILE_IMG;
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} className="profileHeading">
              {influencerAccount.name}
              </Grid>
              <Grid item xs={12} className="location">
                <LocationOn />{" "}
                {influencerAccount.location != ""
                  ? influencerAccount.location
                  : "Location not found"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Typography sx={textColor[platform?.toLowerCase()]}>
              {snapshotHeaderIcons[platform?.toLowerCase()]}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Stack spacing={2}>
          
            <Autocomplete
              fullWidth
              multiple
              limitTags={1}
              id="tags_autocomplete"
              options={[]}
              freeSolo
              value={existingTags}
              onChange={(_e, v) => handleTagsAddition(v)}
              renderInput={(params) => (
                <TextField
                {...params}
                label="Enter Tags"
                />
              )}
            />
          </Stack>

        </DialogContentText>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={updateInformation}>
            {!isUpdate ? "Update tags" : "Add tags"}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

ViewTagDialog.propType = {
  influencerAccounts: PropTypes.array,
  platform: PropTypes.string.isRequired,
  influencerAccount: PropTypes.object.isRequired,
  viewTagDialog: PropTypes.bool.isRequired,
  closeViewTagDialog: PropTypes.func.isRequired,
  setInfluencerAccountList: PropTypes.func,
  sendInformation: PropTypes.func,
}

export default ViewTagDialog;