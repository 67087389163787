/* types
  select: always searchable, multiple is options 
*/

const enums_discovery = {
  filterData_influencer: {
    "instagram": [
      {name: "bio", title: "Bio", type: "textArea", multiple: true, isObject: false},
      {name: "minFollowers", title: "Min Followers", type: "number", isObject: true},
      {name: "maxFollowers", title: "Max Followers", type: "number", isObject: true},
      {name: "languages", title: "Languages", type: "select", searchable: true, multiple: true, isObject: true},
      {name: "industries", title: "Industries", type: "select", searchable: true, multiple: false, isObject: true}
    ],
    "linkedin": [
      {name: "bio", title: "Bio", type: "textArea", multiple: true, isObject: false},
      {name: "interests_groups", title: "Interests / Groups", type: "textArea", multiple: true, isObject: false},
      {name: "minFollowers", title: "Min Followers", type: "number", isObject: true},
      {name: "maxFollowers", title: "Max Followers", type: "number", isObject: true},
      {name: "languages", title: "Languages", type: "select", searchable: true, multiple: true, isObject: true},
    ],
    "facebook": [],
    "twitter": [],
    "tiktok": [],
    "youtube": []
  },
  filterData_audience: {
    "instagram": [
      {name: "minFollowers", title: "Min Followers", type: "number"},
      {name: "maxFollowers", title: "Max Followers", type: "number"},
      {name: "languages", title: "Languages", type: "select", searchable: true, multiple: false, isObject: true},
      {name: "locations", title: "Locations", type: "select", searchable: true, multiple: true, isObject: true},
    ],
    "linkedin": [
      {name: "minFollowers", title: "Min Followers", type: "number"},
      {name: "maxFollowers", title: "Max Followers", type: "number"},
      {name: "languages", title: "Languages", type: "select", searchable: true, multiple: false, isObject: true},
      {name: "locations", title: "Locations", type: "select", searchable: true, multiple: true, isObject: true},
    ],
    "facebook": [],
    "twitter": [],
    "tiktok": [],
    "youtube": []
  },
  filterationData_influencer: {
    "instagram": {
      "bio": [
        {"id": 1, "value": "hi there"},
        {"id": 2, "value": "hello"},
        {"id": 3, "value": "good morning"},
        {"id": 4, "value": "greetings"},
        {"id": 5, "value": "Avid reader"},
        {"id": 6, "value": "Footballer | Cricketer"},
        {"id": 7, "value": "All in for a fast food joint."},
      ],
      "languages": ["hindi", "Englisch", "punjabi", "bangla", "marathi","bhojpuri","kannada", "malayalam","telugu", "tamil", "assammesse"],
      "industries": ["Hotel","IT","Civil","Aviation","Power","HoReCa","Sports"],
      "minFollowers": [
        {"id": "0", "value": 0},
        {"id": "1k", "value": 1000},
        {"id": "5k", "value": 5000},
        {"id": "10k", "value": 10000}
      ],
      "maxFollowers": [
        {"id": "0", "value": 0},
        {"id": "1k", "value": 1000},
        {"id": "5k", "value": 5000},
        {"id": "10k", "value": 10000}
      ]
    },
    "linkedin": {
      "languages": ["hindi"],
      "industries": ["Hotel","IT","Civil","Aviation","Power","HoReCa","Sports"],
      "minFollowers": [
        {"id": "0", "value": "0"},
        {"id": "1k", "value": "1000"},
        {"id": "5k", "value": "5000"},
        {"id": "10k", "value": "10000"}
      ],
      "maxFollowers": [
        {"id": "0", "value": "0"},
        {"id": "1k", "value": "1000"},
        {"id": "5k", "value": "5000"},
        {"id": "10k", "value": "10000"}
      ]
    },
  },
  filterationData_audience: {
    "instagram": {
      "languages": ["hindi", "Englisch", "punjabi", "bangla", "marathi","bhojpuri","kannada", "malayalam","telugu", "tamil", "assammesse"],
      "locations": ["Delhi, India","Bengaluru, India","Bangalore, India","New Delhi, India","Mumbai, Maharashtra, India","Pune, India"],
      "minFollowers": [
        "0", "1k", "5k", "10k"
      ],
      "maxFollowers": [
        "0", "1k", "5k", "10k", "100k"
      ]
    },
    "linkedin": {
      "languages": ["hindi"],
      "locations": ["Delhi"],
      "minFollowers": [
        "0", "1k", "5k", "10k"
      ],
      "maxFollowers": [
        "0", "1k", "5k", "10k", "100k"
      ]
    }
  },
  /* keep enums below this point */
  initialOrder: "inf_score"
}

export default enums_discovery;