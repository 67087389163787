import { PropTypes } from "prop-types";

const ProfileTab_Content = ({ contentDetails }) => {

  return (
    <></>
  );

}

export default ProfileTab_Content;

ProfileTab_Content.propTypes = {
  contentDetails: PropTypes.object.isRequired
}