import React, { createContext, useContext, useState } from 'react';

const BreadcrumbsContext = createContext();

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [activeTab, setActiveTab] = useState('');

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs, activeTab, setActiveTab }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
