import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCampaignNameForBreadcrumbs } from "../../../services/breadCrumbs/breadCrumbsServices";
import { getAccountList } from "../../../services/account/AccountDetails";
import { getBrandList } from "../../../services/brand/BrandDetails";
import { getListData } from "../../../services/list/ListDetails";
import utils from "../../../utils/utils";
import { Menu, MenuItem, IconButton, TextField, List, ListItem, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useBreadcrumbs } from "../../../context/BreadcrumbsProvider";

const specialPathsConfig = {
  advance: { default: "/advance/discovery", dynamic: "/advance" },
  tools: { default: "/tools/translate", dynamic: "/tools" },
  bdTools: { default: "/bdTools/campaignBrief", dynamic: "/bdTools" },
  linkedin: { default: "/linkedin/discovery", dynamic: "/linkedin" },
  userAccess: { default: "/userAccess/aifluence", dynamic: "/userAccess" },
};

const BreadCrumbs = () => {
  const { breadcrumbs, setBreadcrumbs, activeTab } = useBreadcrumbs();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBreadcrumb, setSelectedBreadcrumb] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [lists, setLists] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [filteredLists, setFilteredLists] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const accId = query.get("accId");
  const pathname = location.pathname;

  useEffect(() => {
    const pathnames = pathname.split("/").filter((x) => x);
    let initialBreadcrumbs = [];

    if (pathname.includes("/campaigns")) {
      initialBreadcrumbs.push({ name: "Campaigns", path: "/campaigns" });
      if (id) {
        getCampaignNameForBreadcrumbs(0).then((data) => {
          if (Array.isArray(data)) {
            const matchingCampaign = data.find((camp) => camp.id.toString() === id);
            if (matchingCampaign) {
              setCampaigns(data);
              setFilteredCampaigns(data);
              initialBreadcrumbs.push({
                name: matchingCampaign.name,
                path: generateDynamicPath("/campaigns", pathnames, id, accId),
                hasDropdown: true,
                type: "campaign",
              });
            }
          }
          addRemainingPaths(pathnames, initialBreadcrumbs);
          setBreadcrumbs(initialBreadcrumbs);
        });
      } else {
        addRemainingPaths(pathnames, initialBreadcrumbs);
        setBreadcrumbs(initialBreadcrumbs);
      }
    } else if (pathname.includes("/account")) {
      initialBreadcrumbs.push({ name: "Accounts", path: "/accounts" });
      if (id) {
        utils.getAccountSumary(id).then((data) => {
          if (data) {
            initialBreadcrumbs.push({ name: data.accountName, path: `/account/dashboard?id=${id}`, hasDropdown: true, type: "account" });
          }
          getAccountList(0, 1, 0, 1, 100, "updatedAt", "desc", "", "csv", "empowr", "Asia/Calcutta", "").then(data => {

            if (data && data.accountList) {
              setAccounts(data.accountList);
              setFilteredAccounts(data.accountList);
            }
            addRemainingPaths(pathnames, initialBreadcrumbs);
            setBreadcrumbs(initialBreadcrumbs);
          });
        });
      } else {
        addRemainingPaths(pathnames, initialBreadcrumbs);
        setBreadcrumbs(initialBreadcrumbs);
      }
    } else if (pathname.includes("/brand")) {
      initialBreadcrumbs.push({ name: "Brands", path: "/brands" });
      if (id) {
        getBrandList(0, 1, 0, 1, 100, "b.updatedAt", "desc", "", "csv", "oryx", "Asia/Calcutta").then((data) => {
          if (data && data.accountList) {
            const matchingBrand = data.accountList.find((brand) => brand.id.toString() === id);
            if (matchingBrand) {
              setBrands(data.accountList);
              setFilteredBrands(data.accountList);
              initialBreadcrumbs.push({
                name: matchingBrand.name,
                path: generateDynamicPathWithAccId(`/brand`, pathnames, id, matchingBrand.accId),
                hasDropdown: true,
                type: "brand",
              });
            }
          }
          addRemainingPaths(pathnames, initialBreadcrumbs);
          setBreadcrumbs(initialBreadcrumbs);
        });
      } else {
        addRemainingPaths(pathnames, initialBreadcrumbs);
        setBreadcrumbs(initialBreadcrumbs);
      }
    } else if (pathname.includes("/list")) {
      initialBreadcrumbs.push({ name: "Lists", path: "/list" });
      if (id) {
        getListData(0, 1, 10, "createdOn", "desc", 0, "", null, null, false, "", "", 0, 0, 0, "oryx", 1).then((data) => {
          if (data && data.records) {
            const matchingList = data.records.find((list) => list.id.toString() === id);
            if (matchingList) {
              setLists(data.records);
              setFilteredLists(data.records);
              initialBreadcrumbs.push({
                name: matchingList.groupName,
                path: `/list/details?id=${id}&name=${matchingList.groupName}&cid=${matchingList.campaignId}&cname=${matchingList.campaignName}&platform=${matchingList.platform}&aname=${matchingList.accountName}&type=unarchived`,
                hasDropdown: true,
                type: "list",
              });
            }
          }
          addRemainingPaths(pathnames, initialBreadcrumbs);
          setBreadcrumbs(initialBreadcrumbs);
        });
      } else {
        addRemainingPaths(pathnames, initialBreadcrumbs);
        setBreadcrumbs(initialBreadcrumbs);
      }
    } else {
      addRemainingPaths(pathnames, initialBreadcrumbs);
      setBreadcrumbs(initialBreadcrumbs);
    }
  }, [location, activeTab]);

  const generateDynamicPath = (base, pathnames, id, accId) => {
    const lastPath = pathnames[pathnames.length - 1];
    let dynamicPath = `${base}/${lastPath}?id=${id}&accId=${accId}`;
    const subPaths = ["linkAnalysis/urls", "linkAnalysis", "contracts/templates", "contracts/send", "sentiment/analysis", "Applinks/manage"];
    subPaths.forEach((subPath) => {
      if (pathname.includes(subPath)) {
        dynamicPath = `${base}/${subPath}?id=${id}&accId=${accId}`;
      }
    });
    return dynamicPath;
  };

  const generateDynamicPathWithAccId = (base, pathnames, id, accId) => {
    const lastPath = pathnames[pathnames.length - 1];
    if (pathname.includes("edit")) {
      return `${base}/edit?id=${id}&accId=${accId}`;
    }
    return `${base}?id=${id}`;
  };

  const addRemainingPaths = (pathnames, initialBreadcrumbs) => {
    pathnames.forEach((path, index) => {
      let name = path.charAt(0).toUpperCase() + path.slice(1);
      let breadcrumbPath = `/${pathnames.slice(0, index + 1).join("/")}`;

      if (!specialPathsConfig[path] && !["campaigns", "account", "accounts", "brand", "brands", "list", "lists"].includes(path)) {
        initialBreadcrumbs.push({ name, path: breadcrumbPath });
      }

      if (specialPathsConfig[path]) {
        breadcrumbPath = handleSpecialPaths(path, pathnames, index);
        initialBreadcrumbs.push({ name: name, path: breadcrumbPath });
      }
    });

    if (activeTab && pathname.includes("/postAnalysis")) {
      initialBreadcrumbs.push({ name: activeTab, path: "" });
    }
  };

  const handleSpecialPaths = (path, pathnames, index) => {
    const nextPath = index < pathnames.length - 1 ? pathnames[index + 1] : null;
    const config = specialPathsConfig[path];
    return nextPath ? `${config.dynamic}/${nextPath}` : config.default;
  };

  const handleMenuClick = (event, breadcrumb) => {
    setAnchorEl(event.currentTarget);
    setSelectedBreadcrumb(breadcrumb);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBreadcrumb(null);
  };

  const handleSelect = (type, item) => {
    handleMenuClose();
    const basePath = pathname.split('/').slice(0, 2).join('/');
    const newPath = type === "brand" 
      ? generateDynamicPathWithAccId(`${basePath}/details`, pathname.split('/').slice(2), item.id, item.accId)
      : type === "list" 
      ? `/list/details?id=${item.id}&name=${item.groupName}&cid=${item.campaignId}&cname=${item.campaignName}&platform=${item.platform}&aname=${item.accountName}&type=unarchived`
      : generateDynamicPath(basePath, pathname.split('/').slice(2), item.id, item.companyId);
    window.location.href = newPath;
  };

  const handleSearch = (event, type) => {
    const searchValue = event.target.value.toLowerCase();
    if (type === "campaign") {
      const filtered = campaigns.filter((campaign) => campaign.name.toLowerCase().includes(searchValue));
      setFilteredCampaigns(filtered);
    } else if (type === "account") {
      const filtered = accounts.filter((account) => account.name.toLowerCase().includes(searchValue));
      setFilteredAccounts(filtered);
    } else if (type === "brand") {
      const filtered = brands.filter((brand) => brand.name.toLowerCase().includes(searchValue));
      setFilteredBrands(filtered);
    } else if (type === "list") {
      const filtered = lists.filter((list) => list.groupName.toLowerCase().includes(searchValue));
      setFilteredLists(filtered);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          {breadcrumb.hasDropdown ? (
            <span style={{ display: "flex", alignItems: "center", cursor: "pointer", fontWeight: "bold", color: "#000000" }}>
              <span onClick={(event) => handleMenuClick(event, breadcrumb)}>{breadcrumb.name}</span>
              <IconButton size="small" onClick={(event) => handleMenuClick(event, breadcrumb)}>
                <KeyboardArrowDownIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl) && selectedBreadcrumb === breadcrumb} onClose={handleMenuClose}>
                <List style={{ width: "350px", maxHeight: "300px" }}>
                  <ListItem style={{ padding: "0 12px" }}>
                    <TextField
                      placeholder={`Search ${
                        breadcrumb.type === "campaign"
                          ? "Campaigns"
                          : breadcrumb.type === "account"
                          ? "Accounts"
                          : breadcrumb.type === "brand"
                          ? "Brands"
                          : "Lists"
                      }`}
                      onChange={(e) => handleSearch(e, breadcrumb.type)}
                      fullWidth size="small" sx={{ px: "12px" }}
                    />
                  </ListItem>
                  <Divider sx={{ borderColor: '#d0d0d0', marginTop:'4px' }} />
                  {(breadcrumb.type === "campaign"
                    ? filteredCampaigns
                    : breadcrumb.type === "account"
                    ? filteredAccounts
                    : breadcrumb.type === "brand"
                    ? filteredBrands
                    : filteredLists
                  ).map((item) => (
                    <MenuItem key={item.id} onClick={() => handleSelect(breadcrumb.type, item)}>
                      {item.name || item.groupName}
                    </MenuItem>
                  ))}
                </List>
              </Menu>
              {index < breadcrumbs.length - 1 && <span style={{ margin: "0 8px", color: "#000000" }}>&gt;</span>}
            </span>
          ) : (
            <span
              style={{
                cursor: index === 0 ? "pointer" : "default",
                fontWeight: "bold",
                color: index === 0 ? "#1461CC" : "#000000",
              }}
              onClick={() => index === 0 && (window.location.href = breadcrumb.path)}
            >
              {breadcrumb.name}
              {index < breadcrumbs.length - 1 && <span style={{ margin: "0 8px", color: "#000000" }}>&gt;</span>}
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default BreadCrumbs;
