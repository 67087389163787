import { useState, useEffect } from "react";

import DiscoveryPlatforms from "./DiscoveryPlatforms";
import DiscoveryFilters from "./DiscoveryFilters";
import DiscoveryAppliedFilters from "./DiscoveryAppliedFilters";
import DiscoveryAdvanceFilters from "./DiscoveryAdvanceFilters";
import DiscoveryTable from "./DiscoveryTable";
import enums_discovery from "./enums_discovery";
import FetchingDetailsLoader from "../general/FetchingDetailsLoader";
import axios from "axios";
import { API_HOST } from "../../config/main.json";

const Discovery = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("");

  const [appliedPrimaryFilters, setAppliedPrimaryFilters] = useState({});
  const [appliedAdvanceFilters_inf, setAppliedAdvanceFilters_inf] = useState({});
  const [appliedAdvanceFilters_aud, setAppliedAdvanceFilters_aud] = useState({});
  
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [advFilters_inf, setAdvFilter_inf] = useState([]);
  const [advFilters_aud, setAdvFilter_aud] = useState([]);
  const [advFilterData_inf, setAdvFilterData_inf] = useState({});
  const [advFilterData_aud, setAdvFilterData_aud] = useState({});

  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [sortOn, setSortOn] = useState("following");
  const [sortOrder, setSortOrder] = useState("desc");
  const [tableData, setTableData] = useState({});
  const [tablePageNo, setTablePageNo] = useState(1); // page no starting from 1, as opposed to mui table (which starts from 0)
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const [tableDataUpdated, setTableDataUpdated] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [resetPageNo, setResetPageNo] = useState(false);
  const [languages, setLanguages] = useState([]);


  /* resest everything except selected platform */
  const resetEverything = () => {
    setAppliedPrimaryFilters({});
    setAppliedAdvanceFilters_inf({});
    setAppliedAdvanceFilters_aud({});
    setShowAdvancedFilters(false);
  }

  /* */
  useEffect(() => {
    if (!selectedPlatform) {
      return;
    }

    const queryJson = getQueryJson(appliedPrimaryFilters, appliedAdvanceFilters_inf, appliedAdvanceFilters_aud, 1, tableRowsPerPage, sortOn, sortOrder);
    getDataFromBackend(1, tableRowsPerPage, true, queryJson);
    setResetPageNo(true);
  }, [selectedPlatform, filterChange]);

  const getDataFromBackend = (pageNo, rowsPerPage, resetData=false, queryJson='') => {
    setTableDataLoaded(false);
    const to = pageNo * rowsPerPage;
    let rowsLoaded = 0;
    if (tableData?.Records?.length > 0){
      rowsLoaded = tableData?.Records?.length;
    }
    if (rowsLoaded < to || resetData === true) {
      const optionAxios = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      if (queryJson === "") {
        queryJson = '{"page":'+pageNo+',"platform":"linkedin"}';
      }
      axios.post(API_HOST+"/discovery", queryJson, optionAxios)
        .then((res) => {
          const newData = res.data;
          // adding platform explicitly as of now, retrieve from db later.
          newData["platforms"] = [{ "id": 1, "name": "linkedin", }];
          let records = [];
          if(resetData){
            setTableData({});
          } else {
            if (tableData?.Records?.length > 0) {
              records.push(...tableData.Records);
            }
            if (newData?.Records?.length > 0) {
              records.push(...newData.Records);
            }
            newData.Records = records;
          }
          setTableData(newData);
        })
        .catch((e) => {
          console.log("could not obtain data", e);
        })
        .finally(() => {
          setTableDataLoaded(true);
          const dataUpdated = !tableDataUpdated;
          setTableDataUpdated(dataUpdated);
        });
    } else {
      setTableDataLoaded(true);
    }
  }

  /* platform selection */
  const newPlatformSelected = (platform) => {
    setSelectedPlatform(platform);
    resetEverything();
    /* get table data and advance filter data */
    const filterData_influencer = (enums_discovery.filterData_influencer[platform])?(enums_discovery.filterData_influencer[platform]):([]);
    const filterData_audience = (enums_discovery.filterData_audience[platform])?(enums_discovery.filterData_audience[platform]):([]);
    let enumData = enums_discovery.filterationData_influencer
    const filterationData_influencer = (enumData[platform])?(enumData[platform]):({});
    const filterationData_audience = (enums_discovery.filterationData_audience[platform])?(enums_discovery.filterationData_audience[platform]):({});
    setAdvFilter_inf(filterData_influencer);
    setAdvFilter_aud(filterData_audience);
    setAdvFilterData_inf(filterationData_influencer);
    setAdvFilterData_aud(filterationData_audience);
  }

  /* primary filters - search button clicked */
  const searchPrimaryFilters = (interests, keywords, locations) => {
    // fetch data based on these values
    setAppliedPrimaryFilters({
      "Interest": interests, 
      "Keyword": keywords, 
      "Location": locations,
    });
    const isChanged = !filterChange;
    setFilterChange(isChanged)
  }

  /* primary filters - filter button clicked */
  const showAdvanceFilters = () => {
    setShowAdvancedFilters(true);
  }

  /* remove all primary filters */
  const removeAllPrimaryFilters = () => {
    setAppliedPrimaryFilters({});
  }

  /* remove all advance filters */
  const removeAllAdvanceFilters = () => {
    setAppliedAdvanceFilters_inf({});
    setAppliedAdvanceFilters_aud({});
  }

  const applyAdvFilters = (infDataSelected, audDataSelected) => {
    setAppliedAdvanceFilters_inf(infDataSelected);
    setAppliedAdvanceFilters_aud(audDataSelected);
    const isChanged = !filterChange;
    setFilterChange(isChanged)
  }
  const clearAdvFilters = () => {
    setAppliedAdvanceFilters_inf({});
    setAppliedAdvanceFilters_aud({});
  }

  const hideAdvanceFilters = () => {
    setShowAdvancedFilters(false);
  }

  const setInfluencerList = (updatedInfluencersList) => {
    setTableData(updatedInfluencersList);
  }

  /* table pagination interaction - retrieve data again */
  const tablePageChanged = (pageNo, rowsPerPage) => {
    let newPage =  pageNo + 1;
    setTablePageNo(newPage);
    setResetPageNo(false);
    let resetData = true;
    if (rowsPerPage === -1) {
      rowsPerPage = tableRowsPerPage;
      resetData = false;
    }
    const queryJson = getQueryJson(appliedPrimaryFilters, appliedAdvanceFilters_inf, appliedAdvanceFilters_aud, newPage, rowsPerPage, sortOn, sortOrder)
    getDataFromBackend(newPage, rowsPerPage, resetData, queryJson);
  }
  const tableRowsPerPageChanged = (rowsPerPage) => {
    setTableRowsPerPage(rowsPerPage);
    const queryJson = getQueryJson(appliedPrimaryFilters, appliedAdvanceFilters_inf, appliedAdvanceFilters_aud, 1, rowsPerPage, sortOn, sortOrder)
    getDataFromBackend(1, rowsPerPage, true, queryJson);
  }

  /*
    columnName: name of the column. Don't confuse with title.
    order: "asc" | "desc"
  */
  const sortOrderChanged = (columnName, order) => {
    setSortOn(columnName);
    setSortOrder(order);
    const queryJson = getQueryJson(appliedPrimaryFilters, appliedAdvanceFilters_inf, appliedAdvanceFilters_aud, 1, tableRowsPerPage, sortOn, sortOrder)
    // when order changes, reset current data.
    getDataFromBackend(1, tableRowsPerPage, true, queryJson);
  }

  const getQueryJson = (primaryFilters, infDataSelected, audDataSelected, pageNo, rowsPerPage, sortOn, sortOrder) => {
    // checkMe: make this dynamic later on.
    return {
      "page": pageNo,
      "rows":rowsPerPage,
      "platform": "linkedin",
      "sortcol":sortOn,
      "sortorder":sortOrder,
      "interests": primaryFilters['Interest'],
      "keywords": primaryFilters['Keyword'],
      "location": primaryFilters['Location'],
      "bio":infDataSelected['Bio'],
      "interests_groups": infDataSelected['Interests / Groups'],
      "minFollower":parseInt(infDataSelected['Min Followers']),
      "maxFollower":parseInt(infDataSelected['Max Followers']),
     // "industries":infDataSelected['Industries'],
      "languages":infDataSelected['Languages']
    };
  }

  return(
    <>
      <DiscoveryPlatforms 
        sendSelectedPlatform={newPlatformSelected} 
      />
      <br/>
      { 
        selectedPlatform &&
        <DiscoveryFilters
          primary_searchBtnClicked={searchPrimaryFilters}
          primary_filterBtnClicked={showAdvanceFilters}
          defaultValues={appliedPrimaryFilters}
        />
      }
      {
        (
          (appliedPrimaryFilters && Object.keys(appliedPrimaryFilters).length > 0) || 
          (appliedAdvanceFilters_inf && Object.keys(appliedAdvanceFilters_inf).length > 0) ||
          (appliedAdvanceFilters_aud && Object.keys(appliedAdvanceFilters_aud).length > 0)
        ) &&
        <DiscoveryAppliedFilters
          appliedPrimaryFilters={appliedPrimaryFilters}
          appliedAdvanceFilters_inf={appliedAdvanceFilters_inf}
          appliedAdvanceFilters_aud={appliedAdvanceFilters_aud}
          removeAllPrimaryFilters={removeAllPrimaryFilters}
          removeAllAdvanceFilters={removeAllAdvanceFilters}
        />
      }
      {
        showAdvancedFilters &&
        <DiscoveryAdvanceFilters
          applyAdvFilters={applyAdvFilters}
          clearAdvFilters={clearAdvFilters}
          advFilters_inf={advFilters_inf}
         // advFilters_aud={advFilters_aud}
          advFilterData_inf={advFilterData_inf}
          advFilterData_aud={advFilterData_aud}
          advance_CloseBtnClicked={hideAdvanceFilters}
          defaultValues={{Influencer: appliedAdvanceFilters_inf, Audience: appliedAdvanceFilters_aud}}
        />
      }
      {
        <DiscoveryTable
          platform={selectedPlatform}
          tableDataLoaded={tableDataLoaded}
          tableData={tableData}
          tableDataUpdated={tableDataUpdated}
          setInfluencerList={setInfluencerList}
          tablePageChanged={tablePageChanged}
          tableRowsPerPageChanged={tableRowsPerPageChanged}
          sortOrderChanged={sortOrderChanged}
          updatePageNo={resetPageNo}
        />
      }
    </>
  )
}

export default Discovery;