import axios from "axios";
import { API_HOST } from "../../config/main.json";

const getCampaignNameForBreadcrumbs = async (id) => {
    const response = await axios({
      url: `${API_HOST}/campaign/accountId/${id}`,
      method: "GET",
    });
    if (response) {
      return response.data;
    }
  
    return false;
  };
  export { getCampaignNameForBreadcrumbs};