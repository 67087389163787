

const utils_discovery = {
  getTableRowHeader: (platform) => {
    let connectionLabel = "Followers";
    return [
      {
        id: 1,
        label: `${platform} Account`,
        field: "profile",
      },{
        id: 2,
        label: connectionLabel,
        field: "following",
      },/*{
        id: 3,
        label: "Eng. Rate(ER)",
        field: "engagementrate",
      },*/{
        id: 4,
        label: "Location",
        field: "location",
      },{
        id: 6,
        label: "",
        field: "action",
      }
    ]
  }
}

export default utils_discovery;