import React, { useEffect, useState } from "react";
import {
    Box,
    Modal,
    Fade,
    Grid,
    FormControl,
    TextField,
    Snackbar,
    Menu,
    MenuItem,
    InputLabel,
    Select,
    Typography,
    ButtonGroup,
    Divider,
    Autocomplete
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import tableStyle from "../../styles/theme/pageStyling/TableStyle.module.css";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { validateAiFluenceEmails, validateBusinessEmails } from "../../services/account/verification";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { API_HOST, HEIMDALL_HOST } from "../../config/main.json";
import {
    Close,
    Delete,
    MoreVertOutlined,
    Unarchive,
    Visibility,
} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import utils from "../../utils/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getTimeZone, onDownloadHandle } from "../../utils/exportData";
import SearchItem from "./SearchItem";
import ExportFiles from "./ExportFiles";
import { getBrandsTableHeaders } from "../../utils/tableHeaders";
import CustomTable from "../../common/CustomTable/CustomTable";
import Loader from "./Loader";
import { pink } from "@mui/material/colors";
import { getBrandList, getAccountsBrandList } from "../../services/brand/BrandDetails";
import EditIcon from "@mui/icons-material/Edit";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BrandTable = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const accId = params.has("accId") ? Number(params.get("accId")) : 0;
    const [brandPlatform, setBrandPlatform] = useState(props.platfrom ? props.platfrom : "oryx"); //defines which type of brands wil be display oryx/empowr
    const [type, setType] = useState(1); // present type of brand (1- Active, 0- Archived, 2- Deleted)
    const [selectedTab, setSelectedTab] = useState("0");
    const [searchBy] = useState("Brand Name"); // as we seacrch only by brand Name(used in palceholder)
    const [filter, setFilter] = useState(""); // refers to the value/brand we are searching in seacrch field
    const [tablePageNo, setTablePageNo] = useState(0); // refers to page no of pagination (default - 0)
    const [isExport, setIsExport] = useState(0); // refers we want to export or not, 0 - not, 1 - yes
    const [isLoaded, setIsLoaded] = useState(false);
    const [brandData, setBrandData] = useState([])//  contains detailed list of all the brands
    //const [brand, setBrand] = useState(0); //  refers to  a single brand by which we are searching in the filter by brand field
    const [dataFound, setDataFound] = useState(false); // shows that we have/recive brands data from API or not
    const [tableData, setTableData] = useState([]);
    const [anchorEl, setAnchorEl] = useState([]);
    const [notification, setNotification] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [campaignList, setCampaignList] = useState([]);// list of campaign for a particular brand( when click on "view all campaign" this list apperas )
    const [accountName, setAccountName] = useState(""); // contains name of the selected account
    const [accountId, setAccountId] = useState(0);
    const [account, setAccount] = useState(accId); //  refers to  account (0 - aifluence)
    const [addUserModal, setAddUserModal] = useState(false) // controlls the modal for ading a user
    const [businessTypeValue, setBusinessTypeValue] = useState(0)//// type of business i.e 1- Product/Service or Brand, 2-Marketing  agency, 3- NGO/NPO, Donor or a Charity
    const [membershipPlan, setMembershipPlan] = useState(0);
    const [membershipDetails, setMembershipDetails] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [agencyId, setAgencyId] = useState(0);
    const [addAgencyClientModal, setAddAgencyClientModal] = useState(false);// controlls the modal for ading a clent in agency
    const [totalRecords, setTotalRecords] = useState(); // refers to total no of campaigns
    const [resetPageNo, setResetPageNo] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [tableRowsPerPage, setTableRowsPerPage] = useState(10); //refers to no of rows dispaly at a time on page
    const [sortCol, setSortCol] = useState("b.updatedAt"); //refers on what base all col are sorted
    const [sortOrder, setSortOrder] = useState("desc"); //refers on what base all rows are sorted
    const [mimeType] = useState("csv"); //refers to the type in which we want to export
    const [totalActive, setTotalActive] = useState(0); //  refers to no of total active brand
    const [totalInactive, setTotalInactive] = useState(0); // refers to no of total archived brand
    const [totalDeleted, setTotalDeleted] = useState(0); // refers to no of total deletd brand
    const [accounts, setAccounts] = useState([]);
    const [diaplayAccountName, setDiaplayAccountName] = useState("");

    const navigate = useNavigate();
    const moment = require('moment');
    const timeZone = getTimeZone(); // to set the time on platform
    const user = useSelector((state) => state.user);
    const styles = {
        noData: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            fontSize: "large",
            fontStyle: "italic",
        },
    };


    const getAccountsList = (platform, type) => {
        axios.get(`${API_HOST}/account/campaign/list/${type}/${platform}`).then((res) => {
          const accountData = res.data;
          setAccounts(accountData);
          if (params.has("accId")) {
            const selected = accountData.find((acc) => acc.id === account);
            if (selected) {
              setDiaplayAccountName(selected.name);
            }
          }
        });
      };
    
      useEffect(() => {
        getAccountsList(brandPlatform, type);
      }, []);

    const fetchData = () => {
        setIsLoaded(() => false);
        let type = 1;
        if (props.type === 0) {
            setType(0);
            setSelectedTab("1")
            getAccountsList(brandPlatform, 0);
            type = 0;
        } else if (props.type === 2) {
            setType(2);
            setSelectedTab("2")
            getAccountsList(brandPlatform, 2);
            type = 2;
        } else {
            setType(1);
            setSelectedTab("0")
            getAccountsList(brandPlatform, 1);
            type = 1;
          }
        getBrandsList(
            account,
            type,
            isExport,
            tablePageNo,
            tableRowsPerPage,
            sortCol,
            sortOrder,
            filter,
            mimeType,
            brandPlatform,
            timeZone
        );
    };

    useEffect(() => {
        fetchData();
    }, [account, type, isExport, tablePageNo, tableRowsPerPage, sortCol, sortOrder, filter, mimeType, brandPlatform]);

    const getBrandsList = async (
        account,
        type,
        isExport,
        tablePageNo,
        tableRowsPerPage,
        sortCol,
        sortOrder,
        filter,
        mimeType,
        brandPlatform,
        timeZone
    ) => {
        setIsLoaded(false);
        // API used to get the details of the campaigns
        let res;
        if (params.has("accId")) {
            res = await getAccountsBrandList(
                account,
                type,
                isExport,
                tablePageNo + 1,
                tableRowsPerPage,
                sortCol,
                sortOrder,
                filter,
                mimeType,
                brandPlatform,
                timeZone,
            );
        }
        else {
            res = await getBrandList(
                account,
                type,
                isExport,
                tablePageNo + 1,
                tableRowsPerPage,
                sortCol,
                sortOrder,
                filter,
                mimeType,
                brandPlatform,
                timeZone
            );
        }

        if (isExport === 1) {
            onDownloadHandle(res, "Brands." + mimeType);
            setIsExport(0);
        }
        //console.log(res)

        const { total_records, accountList, active, inactive, deleted } = res;
        setTotalActive(active); // active - gives a number that represent total active brand
        setTotalInactive(inactive); // inactive - gives a number that represent total archived brand
        setTotalDeleted(deleted); // deleted - gives a number that represent total deleted brand
        if (total_records > 0 && accountList) {
            setBrandData(accountList); // accountList - gives the list of brands of a particular page (not all)
            setTotalRecords(total_records); // total_records - refers to no of brand individualy ( active,archived,deleted )
            setDataFound(true);
        } else {
            setDataFound(false);
        }

        setIsLoaded(true);
    };



    // function to set search value in "filter" variable and change page no to 0
    const sendSearchedValue = (event) => {
        setFilter(event);
        setTablePageNo(0);
    };

    // function to set in which from(csv) we want to export and decide whether we want to export or not
    const sendExportSelected = (event) => {
        setIsExport(event === "csv" ? 1 : 0);
    };

    const closeAddUserModal = () => {
        setAddUserModal(false);
    }

    const handleAddUser = (event) => {
        event.preventDefault();
        setAddUserModal(false);
        //add user if exists
        if (userName && userEmail) {
            const name = userName;
            const email = userEmail.toLowerCase().trim();;
            const clientId = agencyId.toString();
            let role = "Admin";
            let roleId = 1;
            if (agencyId > 0) {
                role = "Member";
                roleId = 3;
            } else {
                role = "Admin";
                roleId = 1;
            }
            const users = [{ name, email, role, roleId }];
            const data = {
                users,
                companyName: this.state.accountName,
                agencyId: this.state.accountId.toString(),
                clientId,
                addedBy: user.attributes.email,
            };

            const optionAxios = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            };
            setNotification(true);
            setMessage("Please wait... we are adding the user in our database");
            setSeverity("info");

            axios.post(HEIMDALL_HOST + "/users", data, optionAxios).then((res) => {
                if (res.data.statusCode === 200) {
                    setNotification(true);
                    setMessage("User Added!");
                    setSeverity("success");
                } else {
                    setNotification(true);
                    setMessage("User could not be added");
                    setSeverity("error");
                }
            });
        }
    }

    const handleMembershipChange = (field, event) => {
        const clientId = accountId.toString();
        const membershipId = event.target.value;
        utils.changeMembership({ clientId, membershipId }).then((res) => {
            if (res.status === "success") {
                setNotification(true);
                setMessage("Membership changed successfully!");
                setSeverity("success");
            } else {
                setNotification(true);
                setMessage(res.status);
                setSeverity("error");
            }
        });
    }

    const getMembershipDetails = (id) => {
        utils.getMembershipDetails(id).then((data) => {
            setMembershipDetails(data);
        });
    }

    const handleNameChange = (event) => {
        setUserName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);;
    }

    const handleAgencyChange = (event) => {
        setAgencyId(event.target.value);
    }

    const getAgencyClientList = (rowID) => {
        utils.getAgencyClientList(rowID).then((data) => {
            setAgencyList(data);
        });
    }

    const closeNotification = () => {
        setNotification(false);
    };

    const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
        setTablePageNo(newPage.page);
        setResetPageNo(false);
        setIsChanged(!isChanged);
        setTableRowsPerPage(newPage.rowsPerPage);
        setSortCol(newPage.orderBy);
        setSortOrder(newPage.order);
    };

    const getUpdatedDate = (createdOn) => {
        const date = moment(createdOn, "YYYY-MM-DD HH:mm:ss");
        const formattedDate = date.format("Do MMMM YYYY");
        return formattedDate;
      }

    useEffect(() => {
        const handleClick = (event, index) => {
            const anchorEl = [];
            for (let i = 0; i < brandData.length; i++) {
                if (i === index) anchorEl.push(event.currentTarget);
                else anchorEl.push(null);
            }
            setAnchorEl(anchorEl);
        };

        const handleClose = (i) => {
            const anchorEl = [];
            for (let i = 0; i < brandData.length; i++) {
                anchorEl.push(null);
            }
            setAnchorEl(anchorEl);
        };

        const data = [];
        if (brandData) {
            brandData.forEach((item, index) => {
                const {
                    id,
                    name,
                    accId,
                    accName,
                    campaignCount,
                    campaignStatus,
                    createdOn,
                    updatedAt
                } = item;

                let liveCampaigns = 0;
                const statusBreakup = campaignCount > 0 ? campaignStatus.split(",") : [];
                statusBreakup.filter((statusValue) => {
                    const [status, statusCount] = statusValue.split(":");
                    if (status.toLowerCase() === "live") liveCampaigns = parseInt(statusCount);
                });

                let updatedDate = getUpdatedDate(updatedAt);

                const actions = (
                    <div>
                        <>
                            <MenuItem key={1} component={Link} to={"../brand/details?id=" + id} id={"Edit-" + id}>
                                <Visibility sx={{ color: "#3498db" }} /> &nbsp;&nbsp; View Brands Details
                            </MenuItem>
                        </>

                        <>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem key={2} component={Link} to={`../brands/edit?id=${id}&accId=${accId}`} id={`Edit-${id}`}>
                                <EditIcon sx={{ color: "green" }} /> &nbsp;&nbsp; Edit This Brand
                            </MenuItem>
                        </>

                        {type === 1 && (
                            <Tooltip title={"You can only archieve brands with no running/live campaigns"}>
                                <>
                                    <Divider sx={{ my: 0.5 }} />
                                    <MenuItem
                                        key={3}
                                        id={"Archive-" + id}
                                        onClick={(event) => {
                                            handleClose(index);
                                            if (window.confirm("Archive the brand?")) {
                                                var body = {};
                                                body["id"] = String(id);
                                                body["status"] = 0;
                                                var optionAxios = {
                                                    headers: {
                                                        "Content-Type": "application/x-www-form-urlencoded",
                                                    },
                                                };
                                                axios.post(`${API_HOST}/brand/edit/${id}`, body, optionAxios).then((res) => {
                                                    // window.location = "/brands/"; //This line of code will redirect you once the submission is succeed
                                                    fetchData();
                                                });
                                            }
                                        }}
                                        disabled={campaignCount > 0 && liveCampaigns > 0}
                                    >
                                        {" "}
                                        <ArchiveIcon sx={{ color: "#95a5a6" }} /> &nbsp;&nbsp; Archive This Brand{" "}
                                    </MenuItem>
                                </>
                            </Tooltip>
                        )}

                        {type === 0 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={4}
                                    id={"Unarchive-" + id}
                                    onClick={(event) => {
                                        handleClose(index);
                                        if (window.confirm("Unarchive the brand?")) {
                                            var body = {};
                                            body["id"] = String(id);
                                            body["status"] = 1;
                                            var optionAxios = {
                                                headers: {
                                                    "Content-Type": "application/x-www-form-urlencoded",
                                                },
                                            };
                                            axios.post(`${API_HOST}/brand/edit/${id}`, body, optionAxios).then((res) => {
                                                // window.location = "/brands/"; //This line of code will redirect you once the submission is succeed
                                                fetchData();
                                            });
                                        }
                                    }}
                                    disabled={campaignCount > 0 && liveCampaigns > 0}
                                >
                                    {" "}
                                    <Unarchive sx={{ color: "#95a5a6" }} /> &nbsp;&nbsp; Unarchive This Brand{" "}
                                </MenuItem>
                            </>
                        )}

                        {(type === 0 || type === 1) && (
                            <Tooltip title={"You can only delete brand with no running/live campaigns"}>
                                <>
                                    <Divider sx={{ my: 0.5 }} />
                                    <MenuItem
                                        key={5}
                                        id={"Delete-" + id}
                                        onClick={(event) => {
                                            handleClose(index);
                                            if (window.confirm("Do you want to delete this brand?")) {
                                                var body = {};
                                                body["id"] = String(id);
                                                body["status"] = 2;
                                                var optionAxios = {
                                                    headers: {
                                                        "Content-Type": "application/x-www-form-urlencoded",
                                                    },
                                                };
                                                axios.post(`${API_HOST}/brand/edit/${id}`, body, optionAxios).then((res) => {
                                                    fetchData();
                                                });
                                            }
                                        }}
                                        disabled={campaignCount > 0 && liveCampaigns > 0}
                                    >
                                        {" "}
                                        <Delete sx={{ color: pink[500] }} /> &nbsp;&nbsp; Delete This Brand{" "}
                                    </MenuItem>
                                </>
                            </Tooltip>
                        )}

                        {type === 2 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={5}
                                    id={"Delete-" + id}
                                    onClick={(event) => {
                                        handleClose(index);
                                        if (window.confirm("Delete this brand forever?")) {
                                            var optionAxios = {
                                                headers: {
                                                    "Content-Type": "application/x-www-form-urlencoded",
                                                },
                                            };
                                            axios.get(`${API_HOST}/brand/delete/${id}`, optionAxios).then((res) => {
                                                // window.location = "/brands/"; //This line of code will redirect you once the submission is succeed
                                                fetchData();
                                            });
                                        }
                                    }}
                                    disabled={campaignCount > 0 && liveCampaigns > 0}
                                >
                                    {" "}
                                    <Delete sx={{ color: pink[500] }} /> &nbsp;&nbsp; Delete This Brand{" "}
                                </MenuItem>
                            </>
                        )}
                    </div>
                );

                const open = anchorEl.map((val) => val !== null);

                const object = {
                    id: index + 1,
                    name: (
                        <>
                            <Grid container spacing={1} sx={{ justifyContent: "flex-start" }}>
                                <Grid item md={8} sm={5}>
                                    <Box
                                        component={"a"}
                                        sx={{
                                            cursor: "pointer",
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 800,
                                            fontSize: "1.1rem",
                                            lineHeight: "1rem",
                                            color: "#1461cc",
                                        }}
                                        href={"../brand/details?id=" + id}
                                    >
                                        {name}
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    ),
                    accountName: (
                        <Grid container >
                            <Grid item xs={12} sx={{ paddingTop: "1px", paddingLeft: "35%", textAlign: "left" }}>
                                {accName}
                            </Grid>
                        </Grid>
                    ),
                    noOfCampaigns: (
                        <Grid container sx={{ paddingTop: "20px", paddingLeft: "20px" }}>
                            <Grid item xs={2}>
                                {campaignCount > 0 ? (
                                    <p className={styles.bodycell}>{campaignCount}</p>
                                ) : (
                                    <p className={styles.bodycell}>
                                        <span style={{ paddingLeft: "20px" }}>-</span>
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Tooltip
                                    title={
                                        campaignCount > 0 ? (
                                            <>
                                                <Typography color="inherit">Campaign Breakup</Typography>
                                                <hr />
                                                {statusBreakup.map((statusValue) => {
                                                    const [status, statusCount] = statusValue.split(":");
                                                    return (
                                                        <>
                                                            <span
                                                                style={{ fontSize: "14px", marginBottom: "15px" }}
                                                            >{`${status} Campaigns : ${statusCount}`}</span>
                                                            <br />
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }
                                >
                                    <InfoIcon fontSize="small" style={{ opacity: campaignCount > 0 ? "0.5" : "0" }} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    updatedAt: (
                        <Grid container>
                          <Grid item xs={12} sx={{ paddingTop: "20px", paddingLeft: "10px" }}>
                            {updatedDate !== "-" ? (
                              <p className={styles.bodycell}>{updatedDate}</p>
                            ) : (
                              <p className={styles.bodycell}>-</p>
                            )}
                          </Grid>
                        </Grid>
                      ),
                    action: (
                        <Grid container>
                            <Grid item xs={3}>
                                <>
                                    {" "}
                                    <IconButton onClick={(event) => handleClick(event, index)} size="small" sx={{ ml: 2 }}>
                                        <MoreVertOutlined />
                                    </IconButton>
                                    <Box style={{ borderRadius: "30%" }}>
                                        <Menu
                                            anchorEl={anchorEl[index]}
                                            getContentAnchorEl={null}
                                            open={open[index]}
                                            onClose={handleClose}
                                            className={tableStyle.menuitem}
                                        >
                                            {actions}
                                        </Menu>
                                    </Box>
                                </>
                            </Grid>
                        </Grid>
                    ),
                };
                data.push(object);
            });
            setTableData(data);
        }
    }, [brandData, anchorEl]);

    const brandsTableHeaders = getBrandsTableHeaders;

    const renderContentHandler = () => {
        let content = null;
        if (isLoaded) {
            if (dataFound) {
                content = (
                    <>
                        <div id="table">
                            {/* This is a custom made component, take care while changing field names */}
                            <CustomTable
                                tableHeaders={brandsTableHeaders}
                                bodyColumns={tableData}
                                sendInformation={handleInformationChange}
                                totalRows={totalRecords}
                                pageNo={tablePageNo}
                                rowsPerPageNo={tableRowsPerPage}
                                sortOrder={sortOrder}
                                sortOrderBy={sortCol}
                            />
                        </div>
                    </>
                );
            } else {
                content = (
                    <Grid container spacing={2}>
                        {/* No brand found */}
                        <Grid style={styles.noData} item xs={12}>
                            You don't have any brand at the moment.
                        </Grid>
                    </Grid>
                );
            }
        } else content = <Loader />;

        return content;
    };

    const tabArray = [
        {
            label: `Active Brands (${totalActive})`,
            id: "brand-details-tab-0",
            to: `/brands?platform=${brandPlatform}`,
            component: Link,
        },
        {
            label: `Archived Brands (${totalInactive})`,
            id: "brand-details-tab-1",
            to: `/brands/archived?platform=${brandPlatform}`,
            component: Link,
        },
        {
            label: `Deleted Brands (${totalDeleted})`,
            id: "brand-details-tab-2",
            to: `/brands/deleted?platform=${brandPlatform}`,
            component: Link,
        },
    ]

    const handleAccountChange = (event, value) => {
        if (value !== null) {
          const selected = accounts.find((acc) => acc.name === value);
          if (selected) {
            setAccount(selected.id);
            setDiaplayAccountName(selected["name"]);
          } else {
            // If no valid account is found, reset the state
            setAccount(0);
            setDiaplayAccountName('');
          }
        } else {
          // If value is null (selection is cleared), reset the state
          setAccount(0);
          setDiaplayAccountName('');
        }
      };

    const brandPlatformHandler = (platform) => {
        setBrandPlatform(platform);
        setTablePageNo(0);
        if (type === 2) {
            navigate(`/brands/deleted?platform=${platform}`);
        }
        else if (type === 0) {
            navigate(`/brands/archived?platform=${platform}`);
        }
        else {
            navigate(`/brands?platform=${platform}`);
        }
    };

    return (
        <>
            <Box mt={4} mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography variant={"h5"} mr={2}>
                        Platform:{" "}
                    </Typography>
                    <ButtonGroup variant="outlined" aria-label="platform button group" disableElevation>
                        <Button
                            variant={brandPlatform === "oryx" ? "contained" : "outlined"}
                            onClick={() => brandPlatformHandler("oryx")}
                        >
                            Oryx
                        </Button>
                        <Button
                            variant={brandPlatform === "empowr" ? "contained" : "outlined"}
                            onClick={() => brandPlatformHandler("empowr")}
                        >
                            Empowr
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <SearchItem sendSearchedValue={sendSearchedValue} searchBy={searchBy} />
                    <ExportFiles sendExportSelected={sendExportSelected} />
                </Box>
            </Box>

            <Box m={3}>
                <CustomTabs tabArray={tabArray} selectedTab={selectedTab} />
                <Stack direction="row" justifyContent="end">
                    <Button variant="contained" component={Link} to="../brands/add">
                        + Create A Brand{" "}
                    </Button>
                </Stack>
            </Box>
            <Box m={1}>
                <Stack direction="row" justifyContent="end">
                    <Grid container spacing={0.5}>
                        <Grid item xs={12} sm={9} md={9}></Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Autocomplete
                                size="small"
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        fontSize: "0.875rem",
                                        borderRadius: "10px",
                                    },
                                    "& .MuiInputLabel-root": {
                                        fontSize: "0.875rem",
                                    },
                                }}
                                disablePortal
                                id="filter-by-account"
                                options={accounts.map((option) => option.name)}
                                value={diaplayAccountName}
                                onChange={handleAccountChange}
                                renderInput={(params) => (
                                    <TextField {...params} label="Filter by Account" variant="outlined" size="small" MenuProps={MenuProps} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box my={1}>{renderContentHandler()}</Box>

            {/*Handles the modal to invite a user in the brand*/}
            <Modal
                className="styles_modal"
                aria-labelledby="modal-add_user-label"
                aria-describedby="modal-add_user-description"
                open={addUserModal}
                onClose={closeAddUserModal}
                closeAfterTransition
            >
                <Fade in={addUserModal}>
                    <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
                        <div id="modal-add_user-heading" className="styles_modal_heading">
                            <h2 id="modal-add_user-heading-text" style={{ padding: "10px" }}>
                                {(accountId === 0 || membershipPlan > 0) && <span>Invite New User</span>}
                                {accountId !== 0 && membershipPlan === 0 && <span>Change Membership</span>}
                            </h2>
                            <IconButton onClick={closeAddUserModal}>
                                <Close />
                            </IconButton>
                        </div>
                        <hr />
                        <div id="modal-add_brand_to_account-body" style={{ padding: "10px" }}>
                            {accountId !== 0 && membershipPlan === 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Box>
                                            <form id="addBrandForm" onSubmit={handleAddUser}>
                                                <div>
                                                    <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label">Plan Subscription</InputLabel>
                                                            <Select
                                                                onChange={() => handleMembershipChange("businessMembership")}
                                                                variant="outlined"
                                                                label="Plan Subscription"
                                                                required
                                                            >
                                                                {membershipDetails.map((btype) => (
                                                                    <MenuItem key={btype.id} value={btype.id}>
                                                                        {btype.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </div>
                                            </form>
                                        </Box>
                                    </Grid>
                                    <hr />
                                </>
                            )}
                            <Grid item xs={12}>
                                <Box>
                                    {accountId !== 0 && membershipPlan === 0 && (
                                        <>
                                            {" "}
                                            <h4>
                                                Invite New User <span>(Optional)</span>
                                            </h4>{" "}
                                        </>
                                    )}
                                    <form id="addBrandForm" onSubmit={handleAddUser}>
                                        <div>
                                            <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                <FormControl
                                                    style={{
                                                        width: "220px",
                                                        marginBottom: "15px",
                                                        flex: 1,
                                                        marginRight: "20px",
                                                    }}
                                                >
                                                    <TextField
                                                        onChange={handleNameChange}
                                                        variant="outlined"
                                                        label="Full Name"
                                                        required={accountId !== 0 && membershipPlan === 0 ? false : true}
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    style={{
                                                        width: "220px",
                                                        marginBottom: "15px",
                                                        flex: 1,
                                                        marginRight: "20px",
                                                    }}
                                                >
                                                    <TextField
                                                        onChange={handleEmailChange}
                                                        variant="outlined"
                                                        label="Email"
                                                        required={accountId !== 0 && membershipPlan === 0 ? false : true}
                                                    />
                                                    {validateBusinessEmails(userEmail) && (
                                                        <p style={{ padding: "10px", color: "red" }}>Please use business email only</p>
                                                    )}
                                                    {validateAiFluenceEmails(userEmail, accountName) && (
                                                        <p style={{ padding: "10px", color: "red" }}>
                                                            You can only invite AiFluence users under the selected account
                                                        </p>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            {businessTypeValue === 2 && (
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl
                                                        style={{
                                                            width: "220px",
                                                            marginBottom: "15px",
                                                            flex: 1,
                                                            marginRight: "20px",
                                                        }}
                                                    >
                                                        <InputLabel id="demo-simple-select-standard-label">Agency Client</InputLabel>
                                                        <Select onChange={handleAgencyChange} variant="outlined" label="Agency Client">
                                                            {agencyList?.map((btype) => (
                                                                <MenuItem key={btype.id} value={btype.id}>
                                                                    {btype.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}
                                        </div>
                                        <button
                                            variant="contained"
                                            className="btn btn-success float-right"
                                            style={{
                                                float: "right",
                                                marginBottom: "15px",
                                                marginRight: "20px",
                                            }}
                                            type="submit"
                                        >
                                            {(accountId === 0 || membershipPlan > 0) && <span>Invite User</span>}
                                            {accountId !== 0 && membershipPlan === 0 && <span>Update Account</span>}
                                        </button>
                                    </form>
                                </Box>
                            </Grid>
                        </div>
                    </div>
                </Fade>
            </Modal>

            <Snackbar
                open={notification}
                autoHideDuration={2000}
                onClose={closeNotification}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={closeNotification} severity={severity} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}
export default BrandTable;