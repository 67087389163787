import { PropTypes } from "prop-types";

const ProfileTab_Manage = ({ manageDetails }) => {

  return (
    <></>
  );

}

export default ProfileTab_Manage;

ProfileTab_Manage.propTypes = {
  manageDetails: PropTypes.object.isRequired
}