import React from "react";
import ListTable from "./ListTable";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DeletedList = () => {
  const location = useLocation();
  const platform = new URLSearchParams(location.search).get("platform")

  return <ListTable type={3} platfrom={platform} />;
};

export default DeletedList;