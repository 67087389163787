import "../../../styles/internal/Influencers.css";
import { useState } from "react";
import { useEffect } from "react";
import { INF_API_HOST, PROFILE_IMG } from "../../../config/main.json";
import {
  FormControl,
  Box,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  Backdrop,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { Link } from 'react-router-dom';
import MyPaper from "../../general/MyPaper";
import { BadgeTwoTone } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import utils from "../../../utils/utils";

const SocialInfluencers = () => {
  const [influencer, setInfluencer] = useState({});
  const [backdrop, setBackdrop] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [selectedTab] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const infId = params.get("id");
  const platform = params.get("platform");
  const callType = params.get("type");
  useEffect(() => {
    //fetchData();
    getData();
  }, []);

  const getData = () => {
    const urls = [
        INF_API_HOST + "/profile/social_platform/" + infId+"/"+platform+"/"+callType
    ];

    Promise.all(urls.map(url =>
      fetch(url)
      .then(checkStatus)
      .then(parseJSON)
      .catch(error => console.log('There was a problem!', error))
    ))
    .then(response => {
      const socialData = response[0][0];
      influencer["name"] = socialData["first_name"] + " " + socialData["last_name"];
      if(socialData['first_name'] === "") {
        influencer['name'] = socialData['name'];
      }
      influencer["profilePhoto"] = PROFILE_IMG;
      if(socialData["photo_url"] !== undefined && socialData["photo_url"] !== "") {
        influencer["profilePhoto"] = socialData["photo_url"];
      }
      influencer["bio"] = socialData["bio"];
      influencer["gender"] = socialData["gender"] || "N/A";
      influencer['followers'] = socialData['total_followers'];
      influencer['friends'] = socialData['friends_count'];
      influencer['socialLink'] = "#"+socialData['username'];
      if(socialData['platform'] ==='instagram') {
        influencer['socialLink'] = "https://www.instagram.com/"+socialData['username'];
      }
      setInfluencer(influencer);
      setBackdrop(false);
      setLoaded(true);
    })
  };

  function checkStatus(response) {
    if (response.ok) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  }

  function parseJSON(response) {
    return response.json();
  }


  return loaded ? (
    <>
      <Box m={3}>
        <Tabs
          value={selectedTab}
          style={{ width: "300px", float: "left" }} variant="scrollable" scrollButtons="auto"
        >
          <Tab label="Social Details" id="account-details-tab-0" />
        </Tabs>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyPaper>
            <Typography variant="h5" sx={{ mb: 2 }}>
              <Stack direction="row" justifyContent="start" style={{ width: "300px", float: "left" }} variant="scrollable" scrollButtons="auto">
              <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
              Account Details
              </Stack>
              <Stack direction="row" justifyContent="end">
                <Button variant="contained" component={Link} to={"../discovery/view?id=" + infId+"&platform="+platform+"&type="+callType}>More Detail </Button>
              </Stack>
            </Typography>

            <Divider />

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <div className="general-info">
                    <div className="general-info__picture-holder">
                      <div className="general-info__picture-frame">
                        <a href={influencer['socialLink']} target="_blank" rel="noreferrer">
                          <img
                            alt=""
                            className="general-info__picture"
                            src={influencer["profilePhoto"]}
                            onError={utils.addDefaultSrc}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="general-info__holder">
                      <div>
                        <strong className="fname">
                          <a href={influencer['socialLink']} target="_blank" rel="noreferrer">{influencer["name"]}</a>
                        </strong>
                        <span className="ng-star-inserted">
                          {influencer["bio"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Gender: <span>{influencer["gender"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>

            <br /><br />

            <Typography variant="h5" sx={{ mb: 2 }}>
              <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
              Social Details
            </Typography>
            <Divider />
            <Grid container spacing={1} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Followers: {influencer['followers']}<span></span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Friends: {influencer['friends']}<span></span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <Divider />
          </MyPaper>
        </Grid>
      </Grid>
    </>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
      open={backdrop}
    >
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
};

export default SocialInfluencers;
