import { CheckCircle, Close, CloudUpload, Download, Info } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { API_HOST, UNAUTH_API_HOST } from "../../config/main.json";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../utils/utils";
import { useEffect } from "react";
import moment from "moment";
import slides from "../../common/googleSlides.png";
import FileSaver from "file-saver";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const reportThemeOptions = [
  {
    primary: "#e64b3c",
    primaryDark: "#c0382a",
    secondary: "#7f7f7f",
    secondaryDark: "#606060",
  },
  {
    primary: "#f77d0a",
    primaryDark: "#ba5d06",
    secondary: "#7f7f7f",
    secondaryDark: "#606060",
  },
  {
    primary: "#4057ed",
    primaryDark: "#202f8c",
    secondary: "#7f7f7f",
    secondaryDark: "#606060",
  },
];

const GeneratePptReport = ({ pptReportId, setPptReportId, needPastReports }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openMissingCountriesModal, setOpenMissingCountriesModal] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [reportTitle, setReportTitle] = useState("Title");
  const [reportBrand, setReportBrand] = useState("Brand");
  const [reportType, setReportType] = useState("");
  const [dataRefreshedOn, setDataRefreshedOn] = useState("");
  const [reportTheme, setReportTheme] = useState(reportThemeOptions[0]);
  const [reportLogo, setReportLogo] = useState(null);
  const [reportHistory, setReportHistory] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [logoUpdated, setLogoUpdated] = useState(false);
  const [statusES, setStatusES] = useState();
  const [progress, setProgress] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [status, setStatus] = useState("Started");
  const [markNew, setMarkNew] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (pptReportId > 0) {
      openPPTGenerationModal(pptReportId);
    }
  }, [pptReportId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= currentProgress) {
          clearInterval(interval);
        }
        if (prev === 100) {
          setMarkNew(true);
          getReportHistory(selectedId);
          setGenerating(false);
          statusES.close();
          dispatch(
            openSnackbar({
              message: "Presentation created successfully",
              severity: "success",
            })
          );

          if (!needPastReports) {
            closeModal();
          }
        }
        return prev < currentProgress ? prev + 1 : prev;
      });
    }, 100);
  }, [currentProgress]);

  const openPPTGenerationModal = (id) => {
    axios.get(API_HOST + "/lists/checkCountries/" + id).then((res) => {
      if (res.data) {
        getReportFields(id);
        getReportHistory(id);
        setSelectedId(id);
        setOpenModal(true);
        setMarkNew(false);
      } else {
        setOpenMissingCountriesModal(true);
      }
    });
  };

  const getReportFields = (id) => {
    axios.get(API_HOST + "/report/fields/" + id).then((res) => {
      const data = res.data;
      setReportTitle(data.title);
      setReportBrand(data.account);
      setDataRefreshedOn(utils.convertDate(data.lastRefreshedTime, "onlydate"));
      if (data.status === 4) {
        setReportType("End of Campaign Report");
      } else if (data.status === 1) {
        const start = moment(data.startDate.split(" ")[0], "YYYY-MM-DD");
        const end = moment();

        const diff = Math.floor(end.diff(start, "days") / 7);
        setReportType(`Week ${diff} Report`);
      }
    });
  };

  const closeModal = () => {
    setReportTitle("");
    setReportBrand("");
    setReportType("");
    setReportTheme(reportThemeOptions[0]);
    setReportLogo(null);
    setLogoUpdated(false);
    setReportHistory([]);
    setGenerating(false);
    setOpenModal(false);
    setOpenMissingCountriesModal(false);
    setPptReportId(0);
  };

  const getReportHistory = (id) => {
    axios.get(API_HOST + "/report/history/" + id).then((res) => {
      const data = res.data || [];
      setReportHistory(data);
    });
  };

  const handleUpload = (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      setReportLogo(file || "");
      setLogoUpdated(true);
    } else {
      setReportLogo(null);
      setLogoUpdated(false);
    }
  };

  const handleGenerateReport = (event) => {
    event.preventDefault();

    setGenerating(true);
    setProgress(0);
    setCurrentProgress(0);
    setStatus("Started");

    const email = user.attributes["email"];

    const formData = new FormData();
    formData.append("logo", reportLogo);
    formData.append("cid", selectedId);
    formData.append("account", reportBrand);
    formData.append("title", reportTitle);
    formData.append("reportType", reportType);
    formData.append("colors", JSON.stringify(reportTheme));
    formData.append("owner", email);
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios.post(`${API_HOST}/report/create`, formData, optionAxios).then((res) => {
      const { reportId } = res.data;
      if (window.EventSource) {
        const source = new EventSource(`${API_HOST}/report/status/${reportId}`);
        source.addEventListener(
          "open",
          (e) => {
            source.onmessage = (event) => {
              let data = JSON.parse(event.data);
              setStatus(data.status);
              setCurrentProgress(data.progress);
              if (data.status === "error") {
                dispatch(
                  openSnackbar({
                    message: "An error occurred while creating the presentation!",
                    severity: "error",
                  })
                );
                setGenerating(false);
                setMarkNew(false);
                getReportHistory(selectedId);
              }
            };
          },
          false
        );
        setStatusES(source);
      }
    });
  };

  const downloadPpt = (title, id) => {
    axios
      .get(API_HOST + "/report/download/" + id, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        responseType: "blob",
      })
      .then((res) => {
        const data = res.data || [];
        FileSaver(data, title + ".pptx");
      });
  };

  return (
    <>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-generate_reports-label"
        aria-describedby="modal-generate_reports-description"
        open={openModal}
        onClose={!generating && closeModal}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <div className="syles_paper" style={{ width: "50%" }}>
            <div id="modal-generate_reports-heading" className="styles_modal_heading">
              <h2 id="modal-generate_reports-heading-text" style={{ padding: "10px 10px 0px 10px" }}>
                Reports
              </h2>
              {!generating && (
                <IconButton onClick={closeModal}>
                  <Close />
                </IconButton>
              )}
            </div>
            <hr />
            <div id="modal-generate_reports-body" style={{ padding: "0px 10px" }}>
              <h4 style={{ paddingBottom: "10px" }}>Generate Report</h4>
              <form onSubmit={handleGenerateReport}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <TextField
                        onChange={(e) => setReportTitle(e.target.value)}
                        variant="outlined"
                        label="Report Title"
                        required={true}
                        value={reportTitle}
                        disabled={generating}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <TextField
                        onChange={(e) => setReportBrand(e.target.value)}
                        variant="outlined"
                        label="Customer/Account Name"
                        required={true}
                        value={reportBrand}
                        disabled={generating}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <TextField
                        onChange={(e) => setReportType(e.target.value)}
                        variant="outlined"
                        label="Report Type"
                        required={true}
                        value={reportType}
                        disabled={generating}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Report Theme</InputLabel>
                      <Select
                        variant="outlined"
                        label="Report Theme"
                        required={true}
                        value={reportTheme}
                        renderValue={(theme) => {
                          return (
                            <div
                              style={{
                                height: "20px",
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: theme.primary,
                                  height: "20px",
                                  display: "inline-block",
                                  width: "25%",
                                }}
                              />
                              <span
                                style={{
                                  backgroundColor: theme.primaryDark,
                                  height: "20px",
                                  display: "inline-block",
                                  width: "25%",
                                }}
                              />
                              <span
                                style={{
                                  backgroundColor: theme.secondary,
                                  height: "20px",
                                  display: "inline-block",
                                  width: "25%",
                                }}
                              />
                              <span
                                style={{
                                  backgroundColor: theme.secondaryDark,
                                  height: "20px",
                                  display: "inline-block",
                                  width: "25%",
                                }}
                              />
                            </div>
                          );
                        }}
                        onChange={(e) => setReportTheme(e.target.value)}
                        disabled={generating}
                      >
                        {reportThemeOptions.map((theme) => (
                          <MenuItem value={theme} key={theme}>
                            <div
                              style={{
                                height: "20px",
                                width: "100%",
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: theme.primary,
                                  height: "20px",
                                  display: "inline-block",
                                  width: "25%",
                                }}
                              />
                              <span
                                style={{
                                  backgroundColor: theme.primaryDark,
                                  height: "20px",
                                  display: "inline-block",
                                  width: "25%",
                                }}
                              />
                              <span
                                style={{
                                  backgroundColor: theme.secondary,
                                  height: "20px",
                                  display: "inline-block",
                                  width: "25%",
                                }}
                              />
                              <span
                                style={{
                                  backgroundColor: theme.secondaryDark,
                                  height: "20px",
                                  display: "inline-block",
                                  width: "25%",
                                }}
                              />
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <div
                      style={{
                        backgroundColor: "#f9f9f9",
                        padding: "5px 10px",
                      }}
                    >
                      <Grid container>
                        <Grid item md={5}>
                          <div style={{ textAlign: "center" }}>
                            <Tooltip title={reportLogo === null ? "" : reportLogo.name}>
                              <Button
                                variant="outlined"
                                startIcon={<CloudUpload />}
                                endIcon={reportLogo && <CheckCircle />}
                                style={{ margin: "10px", width: "200px" }}
                                component="label"
                                label="Upload Logo"
                                disabled={generating}
                                required
                              >
                                Upload Logo
                                <input
                                  style={{
                                    width: "1px",
                                    border: "1px solid white",
                                    maxHeight: "1px",
                                    display: "none",
                                  }}
                                  accept="image/png, image/jpeg"
                                  type="file"
                                  onChange={(event) => handleUpload(event)}
                                  required
                                />
                              </Button>
                            </Tooltip>
                            <div
                              style={{
                                fontSize: "12px",
                                color: "gray",
                                fontStyle: "italic",
                              }}
                            >
                              JPEG, PNG only. Under 2MB File.
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={7}>
                          <div
                            style={{
                              fontStyle: "italic",
                              marginTop: "20px",
                              color: "gray",
                            }}
                          >
                            <Info fontSize="small" />
                            &nbsp;&nbsp;Should be 500x160 pixels in size
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ textAlign: "center" }}>
                  {!generating && (
                     <Tooltip title={!logoUpdated ? "Upload Logo" : ""}>
                     <span> {/* Tooltip requires a non-disabled child; span used as workaround */}
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ m: "15px 15px 0px 15px" }}
                      disabled = {!logoUpdated}
                      startIcon={
                        <img
                          src={slides}
                          alt="slides"
                          style={{
                            color: "white",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      }
                    >
                      Generate Report
                    </Button>
                    </span>
  </Tooltip>
                  )}
                  {generating && (
                    <>
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{ height: "10px" }}
                        style={{ marginTop: "20px" }}
                      />
                      <div style={{ color: "gray", margin: "5px 5px 20px 5px" }}>
                        <CircularProgress size={16} />
                        &nbsp;&nbsp;&nbsp;
                        {status}
                      </div>
                    </>
                  )}
                </div>
                {!generating && (
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Data up-to-date till {dataRefreshedOn}{" "}
                    <Tooltip title="Report generated will be built on data snapshot time mentioned and not the time of report generation">
                      <Info fontSize="small" />
                    </Tooltip>
                  </div>
                )}
              </form>
              {needPastReports && (
                <>
                  {" "}
                  <hr style={{ marginTop: "4px" }} />
                  <h4>View Past Reports</h4>
                  <div style={{ maxHeight: "18vh", overflowY: "auto" }}>
                    {reportHistory.map((report, index) => (
                      <Grid container>
                        <Grid item sm={11}>
                          <div style={{ padding: "8px"}}>
                            <Box
                              sx={{
                                bgcolor: "#f9f9f9",
                                "&:hover": {
                                  bgcolor: "#eeeeee",
                                },
                                padding: "10px 20px",
                                cursor: "pointer",
                                height: "68px",
                              }}
                              onClick={() => window.open(report.link, "_blank", "noreferrer")}
                            >
                              <div>
                                <span style={{ fontSize: "18px" }}>{report.title}</span>
                                {markNew && index === 0 && (
                                  <Chip
                                    sx={{
                                      ml: "6px",
                                      transform: "scale(0.8) translateY(-4px)",
                                      bgcolor: "#5682f0",
                                      borderRadius: "12px",
                                      fontSize: "16px",
                                    }}
                                    color="primary"
                                    label="New"
                                  />
                                )}
                              </div>
                              <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                  <div style={{ fontSize: "14px", color: "gray" }}>
                                    {"Built on " + utils.convertDate(report.date, "datetime") + " GMT "}
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div style={{ fontSize: "14px", color: "gray" }}>
                                    {"Using " + utils.convertDate(report.dataUpdateTime, "onlydate") + " data"}
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        </Grid>
                        <Grid item sm={1}>
                          <div style={{ padding: "8px" }}>
                            <Box
                              sx={
                                report.link === ""
                                  ? {
                                      bgcolor: "#f9f9f9",
                                      height: "68px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }
                                  : {
                                      bgcolor: "#f9f9f9",
                                      "&:hover": {
                                        bgcolor: "#eeeeee",
                                      },
                                      cursor: "pointer",
                                      height: "68px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }
                              }
                              onClick={() => {
                                const sepLink = report.link.split("/");
                                const id = sepLink[sepLink.length - 1];
                                downloadPpt(report.title, id);
                              }}
                            >
                              <Download fontSize="medium" sx={report.link === "" && { color: "lightgray" }} />
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-missing_countries-label"
        aria-describedby="modal-missing_countries-description"
        open={openMissingCountriesModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openMissingCountriesModal}>
          <div className="syles_paper" style={{ width: "50%" }}>
            <div id="modal-missing_countries-heading" className="styles_modal_heading">
              <h2 id="modal-missing_countries-heading-text" style={{ padding: "10px" }}>
                Countries Missing
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-missing_countries-body" style={{ padding: "10px" }}>
              Some lists do not have their countries defined. In order to proceed, make sure every list has their country
              selected.
            </div>
            <div
              style={{
                float: "right",
                margin: "15px 20px 15px 0",
              }}
            >
              <Button variant="outlined" onClick={closeModal} style={{ marginRight: "15px" }}>
                Close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default GeneratePptReport;
