import { useEffect, useState } from "react";

import { array, object, string, number, func, bool } from "prop-types";

import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/styles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Checkbox,
  Paper,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Table from "@mui/material/Table";

import utils from "./utils";
import FetchingDetailsLoader from "../components/general/FetchingDetailsLoader";

/*
  This is used to create a styled table: 

  Props Required: 
  headerColumns: array of objects 
  {
      id: <unique_id>,
      label: <displayed_header>,
      field: <identified_name>,
      alignment: <alignment_of_row>,
      numeric: <is_numeric>,
  }

  bodyColumns: columns for the body related to header 

  borderRadius: If there is any rounded colors to the table 

  colors: object of colors 
  header: {
      backgroundColor: <background_color_of_header>,
      textColor: <textColor_in_header>,
    },
    body: {
      backgroundColor: <background_color_in_body>,
      textColor: <text_color_in_body>,
    }
*/
const StyledTable2 = ({
  tableDataLoaded,
  headerColumns,
  bodyColumns,
  borderRadius,
  colors,
  initialOrder,
  columnAlignment,
  rowsPerPageOptions,
  totalRows,
  pageChanged,
  initialRowsPerPage,
  rowsPerPageChanged,
  rowSelectionChanged,
  sortOrderChanged,
  updatePageNo,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState(initialOrder);
  const [order, setOrder] = useState("desc");
  const [selectedRows, setSelectedRow] = useState({});
  const [selectionUpdated, setSelectionUpdated] = useState(false);

  useEffect(() => {
   // setTimeout(() => {

      setIsLoading(!tableDataLoaded);
   // }, 50);
  }, [tableDataLoaded]);


  useEffect(() => {
    if (updatePageNo) {
      setPage(0)
    }
    setOrderBy(initialOrder);
    setOrder("desc");
  }, [headerColumns,updatePageNo, initialOrder]);

  useEffect(() => {
    if (initialRowsPerPage) {
      setRowsPerPage(initialRowsPerPage);
    } else {
      setRowsPerPage(rowsPerPage);
    }
  }, [initialRowsPerPage]);


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colors?.header?.backgroundColor
        ? colors?.header?.backgroundColor
        : "white",
      fontSize: 14,
      width: 200,
      border: "none",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "18px",
      color: colors?.header?.textColor ? colors?.header?.textColor : "black",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "21px",
      color: colors?.body?.textColor ? colors?.header?.textColor : "black",
      backgroundColor: colors?.body?.backgroundColor
        ? colors?.body?.backgroundColor
        : "white",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: theme.palette.common.white,
    },
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (pageChanged) {
      pageChanged(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const updatedRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(updatedRowsPerPage);
    setPage(0);
    if (rowsPerPageChanged) { 
      rowsPerPageChanged(updatedRowsPerPage);
    }
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = (isAsc ? "desc" : "asc")
    setOrder(newOrder);
    setOrderBy(property.toLowerCase());
    if (sortOrderChanged) {
      sortOrderChanged(property, newOrder);
    }
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const rowSelectionUpdated = (row) => {
    const rowId = row.id;
    const rowSelectionObj = selectedRows;
    if (rowSelectionObj[rowId]) {
      delete rowSelectionObj[rowId];
    } else {
      rowSelectionObj[rowId] = true;
    }
    setSelectedRow(rowSelectionObj);
    setSelectionUpdated(!selectionUpdated);
    rowSelectionChanged(rowSelectionObj);
  }

  return (
    (isLoading)?
    (
      <FetchingDetailsLoader />
    ):(
      <Box style={{ borderRadius: borderRadius }}>
        <Paper style={{ borderRadius: borderRadius }}>
          <TableContainer
            component={Paper}
            style={{
              width: "auto",
              overflowX: "scroll",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 1000 }}
              aria-label="customized table"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left" style={{ width: 10 }} />
                  {headerColumns.map((row) => (
                    <StyledTableCell
                      key={row.id}
                      sortDirection={
                        orderBy === row.field.toLowerCase() ? order : false
                      }
                      align={columnAlignment}
                    >
                      <TableSortLabel
                        active={orderBy === row.field.toLowerCase()}
                        direction={
                          orderBy === row.field.toLowerCase() ? order : "asc"
                        }
                        onClick={createSortHandler(row.field.toLowerCase())}
                      >
                        {row.label}
                        {orderBy === row.field.toLowerCase() ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {bodyColumns.length > 0 &&
                  bodyColumns
                    .sort(getComparator(order, orderBy))
                    .map((row) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <StyledTableCell align="left" style={{ width: 10 }}>
                            <Checkbox
                              checked={!!(selectedRows[row.id])}
                              onChange={() => { rowSelectionUpdated(row) } }
                            />
                          </StyledTableCell>
                          {headerColumns.map((header) => {
                            return (
                              <StyledTableCell
                                key={header.id}
                                align={header.alignment}
                                style={{ width: 10 }}
                              >
                                {!header.numeric && row[header.field]}
                                {header.numeric &&
                                  !header.percentage &&
                                  utils.convertToInternationalNumberSystem(
                                    row[header.field]
                                  )}
                                {header.numeric &&
                                  header.percentage &&
                                  `${row[header.field]}%`}
                              </StyledTableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </Box>
    )
  );
};

StyledTable2.propTypes = {
  tableDataLoaded: bool, // checkMe: make this required. so that we don't need to take care of this from the parent component
  headerColumns: array.isRequired,
  bodyColumns: array.isRequired,
  borderRadius: string.isRequired,
  colors: object.isRequired,
  initialOrder: string.isRequired,
  columnAlignment: string.isRequired,
  rowsPerPageOptions: array,
  totalRows: number,
  initialRowsPerPage: number,
  pageChanged: func,
  rowsPerPageChanged: func,
  rowSelectionChanged: func,
  sortOrderChanged: func,
  updatePageNo: bool.isRequired,
};

export default StyledTable2;