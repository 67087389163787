import { PropTypes } from "prop-types";

import {
  Chip,
  Grid,
  Stack,
} from "@mui/material";

import utils from "../../utils/utils";
import MyPaper from "../general/MyPaper";

const ProfileTab_Overview_Insights = ({ insightsDetails }) => {

  const getInsights = () => {
    if (!insightsDetails || insightsDetails.insights.length === 0) {
      return;
    }
    return insightsDetails.insights.map((insight) => {
      const label = "Avg " + utils.capitalizeFirstLetter(insight.label) + ": " + utils.convertToInternationalNumberSystem(insight.value);
      return (
        <Grid item xs={2} sm={2} md={4}>
          <Chip label={label} variant="outlined" />
        </Grid>
      )
    });
  }

  return (
    <MyPaper sx={{height: "100%"}}>
      <div id="ProfileTab_Overview_Insights">
        <Stack spacing={2} justifyContent={"spaceBetween"}>
          <Stack
            id="ProfileTab_Overview_Insights_title"
            direction="row" justifyContent="center"
          >
            <span className="extendedDetailsCard_title">INSIGHTS</span>
          </Stack>
          <Stack
            id="ProfileTab_Overview_Insights_score"
            direction="row" justifyContent="center"
          >
            <span className="extendedDetailsCard_highlightedText">{insightsDetails.insightPercentage}%</span>
          </Stack>
          <Stack
            id="ProfileTab_Overview_Insights_insights"
            direction="row" justifyContent="center"
          >
            <Grid container spacing={2} columns={{xs:4, sm:6, md:12}}>
              {getInsights()}
            </Grid>
          </Stack>
        </Stack>
      </div>
    </MyPaper>
  )
}

export default ProfileTab_Overview_Insights;

ProfileTab_Overview_Insights.propTypes = {
  insightsDetails: PropTypes.object.isRequired,
}