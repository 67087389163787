import { useEffect, useState } from "react";

import axios from "axios";
import { useSearchParams } from "react-router-dom";

import {
  Alert,
} from "@mui/material";

import enums from "./enums";
import { API_HOST } from "../../config/main.json";
import "./styles_profile.css";
import ProfileBasicDetails from "./ProfileBasicDetails";
import FetchingDetailsLoader from "../general/FetchingDetailsLoader";
import ProfileGraphicalDetails from "./ProfileGraphicalDetails";
import ProfileExtendedDetails from "./ProfileExtendedDetails";
import AddInfToListDialog from "../discovery/AddInfToListDialog";
import AddInfToNewListDialog from "../discovery/AddInfToNewListDialog";
import ViewTagDialog from "../discovery/ViewTagDialog";

const Profile = () => {
  const [iid, setIid] = useState(-1);
  const [searchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(true);
  const [profileData, setProfileData] = useState([]);
  const [basicDetails, setBasicDetails] = useState({});
  const [graphicalDetails, setGraphicalDetails] = useState({});
  const [extendedDetails, setExtendedDetails] = useState({});
  const [tagDetails, setTagDetails] = useState({});
  const [addToListDialog, setAddToListDialog] = useState(false);
  const [addInfToNewListDialog, setAddInfToNewListDialog] = useState(false);
  const [viewTagDialog, setViewTagDialog] = useState(false);

  useEffect(() => {
    const infId = Number.parseInt(searchParams.get('id'));
    setIid(infId);
    if(!infId) {
      console.log("Influencer ID not present");
      return;
    }
    getDataFromBackend(infId);
  }, []);

  useEffect(() => {
    if (!profileData || Object.keys(profileData).length === 0) {
      return;
    }
    updateBasicDetails();
    updateGraphicalDetails();
    updateExtendedDetails();
  }, [profileData?.iid]);

  const getDataFromBackend = (infId) => {
    setIsLoaded(false);
    axios.get(API_HOST+"/discovery/profile/"+infId).then((res) => {
      if (profileData !== null) {
        const data = {
          // checkMe: adding platform explicitly for now, get from db later
          "platforms": [{ "id": 1, "name": "linkedin" }],
          ...res.data,
        };
        setProfileData(data);
      }
      setIsLoaded(true);
    });
  }

  const updateBasicDetails = () => {
    const influencerBasicDetail = {
      iid: (profileData.iid)?(profileData.iid):-1,
      name: (profileData.name)?(profileData.name): "",
      photoUrl: (profileData.ProfilePicture)?(profileData.ProfilePicture):"",
      platforms: (profileData.platforms)?(profileData.platforms):[],
      location: (profileData.location)?(profileData.location):"",
      tags: (profileData.tags)?(profileData.tags):[],
      bio: (profileData.bio)?(profileData.bio):"",
      influencerScore: (profileData.influencerScore)?(profileData.influencerScore):0,
      profileLink: (profileData.ProfileLink)?(profileData.ProfileLink):"",

    }
    const influencerTagDetail = {
      id: influencerBasicDetail.iid,
      ...influencerBasicDetail
    }
    setBasicDetails(influencerBasicDetail);
    setTagDetails(influencerTagDetail);
  }

  const updateGraphicalDetails = () => {
    const influencerGraphicalDetails = {
      influenceScore: (profileData.influenceScore)?(profileData.influenceScore):0,
      platformsInfluence: (profileData.platformsInfluence)?(profileData.platformsInfluence):[],
      
      engagement: (profileData.engagement)?(profileData.engagement):0,
      avgEngagement: (profileData.avgEngagement)?(profileData.avgEngagement):0,
      platformsEngagement: (profileData.platformsEngagement)?(profileData.platformsEngagement):[],

      fans: (profileData.fans)?(profileData.fans):0,
      platformsFans: (profileData.platformsFans)?(profileData.platformsFans):[],
    }
    setGraphicalDetails(influencerGraphicalDetails);
  }

  const updateExtendedDetails = () => {
    const influencerExtendedDetails = {
      platforms: (profileData?.platforms)?(profileData?.platforms):[],
      overview: updateExtendedDetails_overview(),
      audience: updateExtendedDetails_audience(),
      content: updateExtendedDetails_content(),
      manage: updateExtendedDetails_manage(),
    };
    setExtendedDetails(influencerExtendedDetails);
  }

  const updateExtendedDetails_overview = () => {
    return {
      insight: {
        insightPercentage: (profileData.insightPercentage)?(profileData.insightPercentage):0,
        insights: (profileData.insights)?(profileData.insights):[],
      },
      followersCredibility: (profileData.followersCredibility)?(profileData.followersCredibility):0,
      brandAffinity: (profileData.brandAffinity)?(profileData.brandAffinity):[],
      interests: (profileData.interests)?(profileData.interests):[],
      postTopics: (profileData.postTopics)?(profileData.postTopics):[],
      educations: updateEducationDetails(),
      experiences: updateExperienceDetails(),
      companies: updateCompanyDetails(),
      groups: updateGroupDetails(),
      profileLink:profileData.ProfileLink
    };
  }
  const updateExtendedDetails_audience = () => {
    return {};
  }
  const updateExtendedDetails_content = () => {
    return {};
  }
  const updateExtendedDetails_manage = () => {
    return {};
  }

  const updateEducationDetails = () => {
    if (!profileData.education) {
      return [];
    }
    return profileData.education.map((educationObj) => {
      return {
        itemOnLeft: educationObj.start_year,
        heading: educationObj.degree,
        subheading: educationObj.title,
        subheadingDesc: 
          (educationObj.start_year&&educationObj.start_year)?
          ("(" + educationObj.start_year + " - " + educationObj.end_year + ")"):
          "",
        website: "",
        description: "",
      };
    });
  }
  const updateExperienceDetails = () => {
    if (!profileData.experience) {
      return [];
    }
    const experiences = [];
    profileData.experience.map((experienceObj) => {
      const exp = {
        itemOnLeft: "", // not getting year here
        heading: experienceObj.company,
        subheading: "",
        subheadingDesc: "",
        website: experienceObj.url,
        description: "",
      };
      experienceObj.positions?.map((pos) => {
        exp.subheading = pos.title;
        exp.subheadingDesc = 
          (pos.duration)?
          ("(" + pos.duration + ")"):
          "";
        exp.description = pos.description;
        experiences.push(exp);
      });
    });

    return experiences;
  }
  const updateCompanyDetails = () => {
    if (!profileData.experience) {
      return [];
    }
    const companies = [];
    profileData.experience.map((experienceObj) => {
      if (experienceObj.company !== "") {
        companies.push(experienceObj.company);
      }
    });
    return companies;
  }
  const updateGroupDetails = () => {
    if (!profileData.groups) {
      return;
    }
    return profileData.groups.map((groupObj) => {
      return groupObj.title;
    });
  }

  const addInfToList = () => {
    setAddToListDialog(true);
  }
  const closeAddInfToListDialog = (_successfullyAddedToList) => {
    setAddToListDialog(false);
  }

  const infToListCreation = () => {
    setAddToListDialog(false)
    setAddInfToNewListDialog(true);
  }
  const closeInfToNewListDialog = () => {
    setAddInfToNewListDialog(false);
  }

  const addTag = () => {
    setViewTagDialog(true);
  }
  const closeViewTagDialog = () => {
    setViewTagDialog(false);
  }
  const updateTags = (tagsData) => {
    // id, tags, isUpdate
  }

  const basicDetails_actionBtnClicked = (actionType) => {
    switch(actionType) {
      case "addInfToList":
        addInfToList();
        break;
      case "updateTags":
        addTag();
        break;
      default:
        console.log("unrecognized action");
    }
  }

  const showHTML = () => {
    if(!iid || iid === -1) {
      return (
        <Alert severity="error">Invalid Influencer ID obtained!</Alert>
      )
    } else if (isLoaded) {
      return (
        <>
          {/* Basic details */}
          {
            basicDetails &&
            Object.keys(basicDetails).length > 0 &&
            <ProfileBasicDetails
              basicDetails={basicDetails}
              actionBtnClicked={basicDetails_actionBtnClicked}
            />
          }
          {/* Graphical details */}
          {/* {
            graphicalDetails &&
            Object.keys(graphicalDetails).length > 0 &&
            <ProfileGraphicalDetails
              graphicalDetails={graphicalDetails}
            />
          } */}
          {/* Extended details */}
          {
            extendedDetails &&
            Object.keys(extendedDetails).length > 0 &&
            <ProfileExtendedDetails
              extendedDetails={extendedDetails}
            />
          }
          {
            addToListDialog &&
            <AddInfToListDialog
              addToListDialog={addToListDialog}
              platform={"linkedin"}
              iidsSelected={[iid]}
              modalClosed={closeAddInfToListDialog}
              newListCreation={infToListCreation}
            />
          }
          {
            addInfToNewListDialog &&
            <AddInfToNewListDialog
              addInfToNewListDialog={addInfToNewListDialog}
              platform={"linkedin"}
              iidsSelected={[iid]}
              modalClosed={closeInfToNewListDialog}
            />
          }

          {
            viewTagDialog &&
            <ViewTagDialog
              platform={"linkedin"}
              influencerAccount={tagDetails}
              viewTagDialog={viewTagDialog}
              closeViewTagDialog={closeViewTagDialog}
              sendInformation={updateTags}
            />
          }
        </>
      );
    } else {
      return (
        <FetchingDetailsLoader />
      )
    }
  }

  return (
    showHTML()
  )
}

export default Profile;