import * as React from "react";
import { useState, useEffect, useMemo } from "react";

import utils from "../../utils/utils";
import "../../styles/internal/modal.css";
import { API_HOST, INF_API_HOST, PROFILE_IMG, DOS, DONTS, TEMPLATE, FAQ } from "../../config/main.json";

import axios from "axios";
import { Link } from "react-router-dom";

import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { Country, State, City } from "country-state-city";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FAQS from "react-faqs-component";

/* mui */
import MUIDataTable from "mui-datatables";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import { Select, MenuItem } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import { Backdrop, CircularProgress, Box, Tab, Tabs, TextField, Button, Grid, TextareaAutosize, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Menu from "@mui/material/Menu";

/* mui icons */
import PreviewIcon from "@mui/icons-material/Insights";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import FeedIcon from "@mui/icons-material/Feed";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { snapshotHeaderIcons, textColor } from "../../components/campaignAnalysis/snapshot";
import ViewUpdateDialog from "../../components/list/ViewUpdateDialog";
import CampaignInfluencerMap from "./CampaignInfleuncerMap";
import {
  Edit,
  MoreVert as MoreVertIcon,
  ContactPage as ContactpageIcon,
  Person as PersonIcon,
  Refresh,
} from "@mui/icons-material";
import ViewContactDialog from "../../components/list/ViewContactDialog";
import WarningIcon from "@mui/icons-material/Warning";
import { refreshInfluencerStats } from "../../services/influencer/Influencer";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function getMissingText(data) {
  let detailsMissingText = "";
  const { name, total_followers, phone, username, email, age_range, gender, location } = data;
  // if all info present , then return nothing
  if (name && total_followers && phone && username && email && age_range && gender && location && email) return null;

  if (!name) formatText("Name");
  if (!total_followers || total_followers == 0) formatText("Followers");
  if (!phone) formatText("Phone");
  if (!username) formatText("Username");
  if (!email) formatText("Email");
  if (!age_range) formatText("Age Range");
  if (!gender) formatText("Gender");
  if (!location || location == "0") formatText("Location");
  return "Missing Details: " + detailsMissingText;

  function formatText(textKey) {
    return detailsMissingText.length > 0 ? (detailsMissingText += `, ${textKey}`) : (detailsMissingText += ` ${textKey}`);
  }
}

export default function App() {
  const dcampaignData = [];
  const dlistData = [];
  const clistData = [];
  const [campaignData, setcampaignData] = useState(dcampaignData);
  const [listData, setlistData] = useState(dlistData);
  const [selectedFilter, setSelectedFilter] = useState("Instagram");
  const [selectedPlatformUpdateProfile, setSelectedPlatformUpdateProfile] = useState("Instagram");
  const [backdropShow, setbackdropShow] = useState(true);
  const [isLoaded, setisLoaded] = useState(false);
  const [companyList, setCompanyList] = useState(clistData);
  const [companyId, setCompanyId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [campaignList, setCampaignList] = useState(clistData);
  const [campaignId, setCampaignId] = useState("");
  const [platformId, setPlatformId] = useState("");
  const [newListName, setNewListName] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [timezone, setTimezone] = useState(0);
  const [timezoneLable] = useState(0);
  const [geoType, setGeoType] = useState(2);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [currency, setCurrency] = useState("");
  const [infBudget, setInfBudget] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [tags, setTags] = React.useState([]);
  const [timezoneList, setTimezoneList] = useState(clistData);
  const [countryList, setCountryList] = useState(clistData);
  const [stateList, setStateList] = useState(clistData);
  const [cityList, setCityList] = useState(clistData);
  const [modalListOpen, setModalListOpen] = useState(false);
  const [anchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [minFollowers, setMinFollowers] = useState(0);
  const [maxFollowers, setMaxFollowers] = useState(0);
  const [displayPT, setDisplayPT] = useState("none");
  const [displayDT, setDisplayDT] = useState("none");
  const [required, setRequired] = useState(false);
  const [noofDays, setNoOfDays] = useState(0);
  const [paymentDate, setPaymentDate] = useState("");
  const [stepMessage, setStepMessage] = useState("Basic Details");
  const [displayGeo, setDisplayGeo] = useState("flex");
  const [countryLable] = useState("");
  const [stateLable] = useState("");
  const [cityLable] = useState("");
  const [convertedContent, setConvertedContent] = useState("");
  const [dos, setDos] = useState(DOS);
  const [donts, setDonts] = useState(DONTS);
  const [faqQuestion, setFaqQuestion] = useState({});
  const [faqAnswer, setFaqAnswer] = useState({});
  const [counter, setCounter] = useState(1);
  const contentBlock = htmlToDraft(TEMPLATE);
  const [viewUpdateDialog, setViewUpdateDialog] = useState(false);
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [follwersFilter] = useState(false);
  const [friendsFilter] = useState(false);
  const [errorAlert, setErrorAlert] = useState("Please complete all required fields.");
  const [infPlatform, setInfPlatform] = useState("");
  const [filterList, setFilterList] = useState([]);

  /* tracking rows selection for selection actions */
  const listRowsSelected = [];
  const [rowsSelectedInTable, setRowsSelectedInTable] = useState(listRowsSelected);
  const [rowSelectedForAction, setRowSelectedForAction] = useState(listRowsSelected);
  const user = useSelector((state) => state.user);

  const [selectedInfluencer, setSelectedInfluencer] = useState({});
  const [rowSelectionSource, setRowSelectionSource] = useState(-1); // 0: table, 1: action

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks))
  );
  const [faqmodalOpen, setFaqmodalOpen] = useState(false);
  const [faqListData] = useState(FAQ);
  const [editMode] = React.useState(false);
  const dispatch = useDispatch();
  const handleEditorChange = (state) => {
    setEditorState(state);
    setConvertedContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const faqList = (event) => {
    setFaqmodalOpen(true);
  };

  const handleClick = (rowIndex, platform) => (event) => {
    setInfPlatform(platform?.toLowerCase());
    setRowSelectedForAction([rowIndex]);
    setRowSelectionSource(1);
    axios.get(`${API_HOST}/lists/type/2/${platform.toLowerCase()}`)
      .then(res => {
        const listData = res.data;
        const newListData = listData.map((item, index) => ({
          sl: index + 1,
          ...item,
          name: `${item.name} - ${item.cname} - ${item.totalInf}`
        }));
        setlistData(newListData);
        setModalOpen(true);
      })
      .catch(error => {
        console.error("Error fetching list data:", error);
        dispatch(
          openSnackbar({
            message: "Error fetching list data",
            severity: "error",
          })
        );
      });
  };
  

  const refreshProfile = (rowIndex) => async (event) => {
    dispatch(
      openSnackbar({
        message: "Please wait... while we reload stats for your profile",
        severity: "info",
      })
    );
    const body = {
      influencerId: [rowIndex.tableData[rowIndex.rowIndex]["influencer_id"]],
      platform: rowIndex.tableData[rowIndex.rowIndex]["platform"].toLowerCase(),
    };
    const response = await refreshInfluencerStats(body);
    dispatch(
      openSnackbar({
        message: response,
        severity: "success",
      })
    );
  };

  const refreshProfiles = async () => {
    let iidsSelected = [];
    rowsSelectedInTable.forEach((rowId) => {
      iidsSelected.push(campaignData[rowId]["id"]);
    });
    if (iidsSelected.length === 0) {
      iidsSelected = [-1];
    }
    dispatch(
      openSnackbar({
        message: "Please wait... while we reload stats for your profile",
        severity: "info",
      })
    );
    const body = {
      influencerId: iidsSelected,
      platform: selectedFilter.toLowerCase(),
    };
    const response = await refreshInfluencerStats(body);
    dispatch(
      openSnackbar({
        message: response,
        severity: "success",
      })
    );
  };

  const handleClose = () => {
    setModalOpen(false);
    if (rowSelectionSource === 1) {
      setRowSelectedForAction([]);
    }
  };

  const handleFaqClose = () => {
    setFaqmodalOpen(false);
  };

  const listClick = (lid) => {
    addToList(lid);
  };

  const newListClick = (event) => {
    addToNewList();
    // window.location = window.location.href;
  };

  const handleDescChange = (event) => {
    event.preventDefault();
    setDescription(event.target.value);
  };

  const handleNotesChange = (event) => {
    event.preventDefault();
    setNotes(event.target.value);
  };

  const handleTimezoneChange = (event) => {
    setTimezone(parseInt(event.id));
  };

  const handleCurrencyChange = (event) => {
    event.preventDefault();
    setCurrency(event.target.value);
  };

  const handleInfBudgetChange = (event) => {
    event.preventDefault();
    setInfBudget(event.target.value);
  };

  const handlePaymentChange = (event) => {
    event.preventDefault();
    var paymentTerm = event.target.value;
    setDisplayPT("none");
    setDisplayDT("none");
    if (parseInt(paymentTerm) === 4) {
      setDisplayDT("none");
      setDisplayPT("flex");
      setRequired(false);
    } else if (parseInt(paymentTerm) === 5) {
      setRequired(true);
      setDisplayPT("none");
      setDisplayDT("flex");
    }
    setPaymentTerm(event.target.value);
  };

  const handleCountryChange = (event, value) => {
    var countryCode = event.isoCode;
    setStateList(State.getStatesOfCountry(countryCode));
    setCountry(countryCode);
    document.getElementById("stateCityDD").style.display = "flex";
    setDisplayGeo("flex");
  };

  const handleStateChange = (event) => {
    var countryCode = event.countryCode;
    var stateCode = event.isoCode;
    setCityList(City.getCitiesOfState(countryCode, stateCode));
    setState(stateCode);
  };

  const handleCityChange = (event) => {
    var country = event.name;
    setCity(country);
  };

  const handleGeoTypeChange = (event) => {
    event.preventDefault();
    setGeoType(event.target.value);
    if (event.target.value === 1) {
      setCountry("");
      setState("");
      setCityList("");
      document.getElementById("countryDD").style.display = "none";
      document.getElementById("stateCityDD").style.display = "none";
    } else {
      document.getElementById("countryDD").style.display = "flex";
    }
  };

  const handleMinFollowersChange = (event) => {
    event.preventDefault();
    setMinFollowers(event.target.value);
  };

  const handleMaxFollowersChange = (event) => {
    event.preventDefault();
    setMaxFollowers(event.target.value);
  };

  const handleNoOfDaysChange = (event) => {
    event.preventDefault();
    setPaymentDate("");
    setNoOfDays(event.target.value);
  };

  const handlePaymentDateChange = (event) => {
    event.preventDefault();
    setNoOfDays(0);
    setPaymentDate(event.target.value);
  };

  const handleKeyUp = (id, event) => {
    var keycode = event.keyCode ? event.keyCode : event.which;
    if (keycode == "13") {
      document.getElementById(id).value += "• ";
    }
    var txtval = document.getElementById(id).value;
    if (txtval.substr(txtval.length - 1) === "\n") {
      document.getElementById(id).value = txtval.substring(0, txtval.length - 1);
    }
    if (id === "dos") {
      setDos(document.getElementById(id).value);
    } else {
      setDonts(document.getElementById(id).value);
    }
  };

  const validateForm = (step) => {
    // This function deals with validation of the form fields
    var valid = true;
    if (parseInt(step) === 1) {
      if (companyId === "" || campaignId === "" || campaignId == 0 || platformId === "" || newListName === "") {
        dispatch(
          openSnackbar({
            message: errorAlert,
            severity: "error",
          })
        );

        valid = false;
      }
    } else if (parseInt(step) === 2) {
      if (country === "") {
        dispatch(
          openSnackbar({
            message: errorAlert,
            severity: "error",
          })
        );
        valid = false;
      }
    }
    return valid; // return the valid status
  };

  const handleClickFAQ = () => {
    setCounter(counter + 1);
  };

  const handleRemoveClick = (index) => (e) => {
    let tmpFaqQ = {};
    let tmpFaqQnew = {};
    let tmpFaqAnew = {};
    tmpFaqQ = faqQuestion;
    let tmpFaqA = faqAnswer;
    delete tmpFaqQ[index];
    delete tmpFaqA[index];
    var i = 0;
    for (var j in tmpFaqQ) {
      tmpFaqQnew[i] = tmpFaqQ[j];
      tmpFaqAnew[i] = tmpFaqA[j];
      i++;
    }
    setFaqQuestion(tmpFaqQnew);
    setFaqAnswer(tmpFaqAnew);
    var count = counter - 1;
    setCounter(count);
  };

  const handleQuestionOnChange = (index) => (e) => {
    const abc = {};
    abc[index] = e.target.value;
    setFaqQuestion({ ...faqQuestion, ...abc });
  };

  const handleAnswerOnChange = (index) => (e) => {
    const abc = {};
    abc[index] = e.target.value;
    setFaqAnswer({ ...faqAnswer, ...abc });
  };

  const next = (event) => {
    if (!validateForm(currentStep)) return false;
    if (parseInt(currentStep) === 1) {
      setStepMessage("Targeting Details");
    } else if (parseInt(currentStep) === 2) {
      setStepMessage("Payment Details");
    } else if (parseInt(currentStep) === 3) {
      setStepMessage("Guidlines");
    } else if (parseInt(currentStep) === 4) {
      setStepMessage("Faq's");
    } else if (parseInt(currentStep) === 5) {
      setStepMessage("Sample Post");
    }
    let currentStepInner = currentStep;
    currentStepInner = currentStepInner >= 5 ? 6 : parseInt(currentStepInner) + 1;
    setCurrentStep(currentStepInner);
  };

  const prev = (event) => {
    if (parseInt(currentStep) === 2) {
      setStepMessage("Basic Details");
    } else if (parseInt(currentStep) === 3) {
      setStepMessage("Targeting Details");
    } else if (parseInt(currentStep) === 4) {
      setStepMessage("Payment Details");
    } else if (parseInt(currentStep) === 5) {
      setStepMessage("Guidlines");
    } else if (parseInt(currentStep) === 6) {
      setStepMessage("Faq's");
    }
    let currentStepInner = currentStep;
    currentStepInner = currentStepInner <= 1 ? 1 : parseInt(currentStepInner) - 1;
    setCurrentStep(currentStepInner);
  };

  const submitButton = (event) => {
    let currentStepInner = currentStep;
    if (editMode) {
      currentStepInner = 6;
    }
    if (currentStepInner === 6) {
      return (
        <FormControl style={{ marginBottom: "15px", marginLeft: "20px", float: "right" }}>
          <button variant="contained" className="btn btn-success float-right" type="submit">
            Submit
          </button>
        </FormControl>
      );
    }
    return null;
  };

  const previousButton = (event) => {
    let currentStepInner = currentStep;
    if (parseInt(currentStepInner) !== 1) {
      return (
        <FormControl style={{ marginBottom: "15px", marginRight: "20px" }}>
          <button variant="contained" className="btn btn-secondary float-right" type="button" onClick={prev}>
            Previous
          </button>
        </FormControl>
      );
    }
    return null;
  };

  const handleListClose = () => {
    setCurrentStep(1);
    document.getElementById("stepForm").reset();
    setModalListOpen(false);
  };

  const nextButton = (event) => {
    let currentStepInner = currentStep;
    if (currentStepInner < 6) {
      return (
        <FormControl style={{ marginBottom: "15px" }}>
          <button variant="contained" className="btn btn-success float-right" type="button" onClick={next}>
            Next
          </button>
        </FormControl>
      );
    }
    return null;
  };

  const addToNewList = (lid) => {
    var optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const iidsSelected = getIidsFromRowsSelected();
    const payload = {
      name: newListName,
      aid: companyId,
      cid: parseInt(campaignId, 10),
      platform: parseInt(platformId, 10),
      iid: iidsSelected,
      description: description,
      tags: tags.join(" , "),
      notes: notes,
      tz: timezone,
      geoType: geoType,
      country: country,
      state: state,
      city: city,
      currency: currency,
      infBudget: infBudget,
      paymentTerm: paymentTerm,
      minFollowers: parseInt(minFollowers),
      maxFollowers: parseInt(maxFollowers),
      noOfDays: parseInt(noofDays),
      paymentDate: paymentDate,
      postContent: convertedContent,
      dos: dos,
      donts: donts,
      status: 1,
    };
    var faqData = [];
    Object.keys(faqQuestion).forEach((c) => {
      var obj = {
        question: faqQuestion[c],
        answer: faqAnswer[c],
      };
      faqData.push(obj);
    });
    payload["faqs"] = JSON.stringify(faqData);
    axios
      .post(API_HOST + "/list/add", payload, optionAxios)
      .then((res) => {
        if (res["data"]["status"].includes("Duplicate entry")) {
          dispatch(
            openSnackbar({
              message: "Influencer already exists in list!",
              severity: "error",
            })
          );
        } else if (res["data"]["status"] !== "success") {
          dispatch(
            openSnackbar({
              message: errorAlert,
              severity: "error",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            message: errorAlert,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setModalOpen(false);

        handleListClose();
        getInfluencerData(selectedFilter);
      });
  };

  const createNewList = (event) => {
    // setRowsSelected([]); //checkMe: what are we doing after creating new list
    setModalOpen(false);
    setModalListOpen(true);
    // setAnchorEl(event.currentTarget);
  };

  const getIidsFromRowsSelected = () => {
    const iidsSelected = [];

    if (rowSelectionSource === 0) {
      rowsSelectedInTable.forEach((rowId) => {
        console.log(campaignData[rowId])
        iidsSelected.push(campaignData[rowId]["id"]);
      });
    } else if (rowSelectionSource === 1) {
      rowSelectedForAction.forEach((rowId) => {
        iidsSelected.push(campaignData[rowId]["id"]);
      });
    }
    return iidsSelected;
  };

  const addToList = (lid) => {
    var optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const iidsSelected = getIidsFromRowsSelected();
    axios
      .post(API_HOST + "/list/influencers/add", { iid: iidsSelected, lid: lid }, optionAxios)
      .then((res) => {

        if (res["data"]["status"].includes("Duplicate entry")) {
          dispatch(
            openSnackbar({
              message: "Influencer already exists in list!",
              severity: "error",
            })
          );
        } else if (res["data"]["status"] === "success") {
          dispatch(
            openSnackbar({
              message: "Influencer has been added successfully",
              severity: "success",
            })
          );
          setModalOpen(false)
        }
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            message: errorAlert,
            severity: "error",
          })
        );
      })
      .finally(() => {
        getInfluencerData(selectedFilter);
      });
  };

  const handleChangeAccount = (event) => {
    const cid = event.currentTarget.dataset.value;
    setCompanyId(cid);
    utils.getCampaignDataById(cid).then((data) => {
      var campaignList = data;
      if (campaignList == null) {
        var newCampaign = {};
        newCampaign["id"] = 0;
        newCampaign["name"] = "Account-Campaign Mapping Not Available";
        setErrorAlert("There is no campaign associated with this account, Please create campaign first to create a list.");
        campaignList = [];
        campaignList.push(newCampaign);
      } else {
        setErrorAlert("Please complete all required fields.");
      }
      setCampaignList(campaignList);
    });
  };

  const handleChangeCampaign = (event) => {
    const cid = event.currentTarget.dataset.value;
    setCampaignId(cid);
  };

  const handleChangePlatform = (event) => {
    const pid = event.currentTarget.dataset.value;
    setPlatformId(pid);
  };

  const handleInputChange = (e) => {
    setNewListName(e.target.value);
  };

  const searchList = (e) => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    ul = document.getElementById("searchUL");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  const initCols = [
    {
      label: " ",
      name: "profile",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta1, updateValue1) => {
          const missingText = getMissingText(value);
          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  {missingText && (
                    <Box sx={{ mr: "8px", mt: "8px" }}>
                      {" "}
                      <Tooltip title={missingText}>
                        <WarningIcon style={{ color: "#FFA500" }} />
                      </Tooltip>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={1}>
                  <Box sx={{ mr: "8px", mt: "8px" }}>
                    <Typography sx={{ ...textColor[value["platform"]], display: "inline-block" }}>
                      {snapshotHeaderIcons[value["platform"]]}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1.5}>
                  {value["socialLink"] != "" ? (
                    <a href={value["socialLink"]} target="_blank" rel="noreferrer">
                      <img
                        alt="profile"
                        style={{
                          borderRadius: "50%",
                          maxHeight: "50px",
                          maxWidth: "50px",
                          marginRight: "15px",
                          border: "1px solid #d0d6dd",
                          overflow: "hidden",
                        }}
                        src={value["url"]}
                        onError={utils.addDefaultSrc}
                      />
                    </a>
                  ) : (
                    <img
                      alt="profile"
                      style={{
                        borderRadius: "50%",
                        maxHeight: "50px",
                        maxWidth: "50px",
                        marginRight: "15px",
                        border: "1px solid #d0d6dd",
                        overflow: "hidden",
                      }}
                      src={value["url"]}
                      onError={utils.addDefaultSrc}
                    />
                  )}
                </Grid>

                <Grid item xs={5}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div>
                        <p>
                          {value["socialLink"] != "" ? (
                            <a href={value["socialLink"]} target="_blank" rel="noreferrer">
                              {value["name"]}
                            </a>
                          ) : (
                            value["name"]
                          )}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {value["socialLink"] != "" && (
                        <a
                          style={{ "text-decoration": "none", color: "inherit" }}
                          href={value["socialLink"]}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>
                            {value["username"] !== "" && "@"} {value["username"]}
                          </span>
                        </a>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={1}>
                  <Box sx={{ mr: "8px", mt: "8px" }}>
                    <CampaignInfluencerMap data={value["campaignsAssociated"]} />
                  </Box>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: "platform",
      label: "Platform",
      options: {
        filter: false,
        filterList: ["Instagram"],
        display: false,
      },
    },
    {
      name: "total_followers",
      label: "Followers",
      options: utils.rangeOption("Followers", follwersFilter, "followersRange")[0],
    },
    {
      name: "friends_count",
      label: "Following",
      options: utils.rangeOption("Friends", friendsFilter, "friendsRange")[0],
    },
    {
      name: "age_range",
      label: "Age Range",
    },
    {
      name: "gender",
      label: "Gender",
    },
    {
      name: "location",
      label: "Location",
    },
    {
      name: "iStatus",
      label: "Status",
    },
    {
      name: "profileName",
      label: "Influencer",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let rowID = tableMeta.rowData[0]["id"];
          let platform = tableMeta.rowData[1];
          let type = tableMeta.rowData[0]["onboared_type"];
          const rowIndex = tableMeta.rowIndex;
          const actionItems = [
            {
              icon: <PreviewIcon fontSize="small" />,
              link: "../discovery/view?id=" + rowID + "&platform=" + platform + "&type=" + type,
              id: "View-" + tableMeta.rowData[0],
              text: "View Influencer Profile",
              onClick: null,
            },
            {
              icon: <Refresh fontSize="small" />,
              link: null,
              id: "Refresh-" + tableMeta.rowData[0],
              text: "Refresh Influencer Profile",
              onClick: refreshProfile(tableMeta),
            },
            {
              icon: <PlaylistAddIcon fontSize="small" />,
              link: null,
              id: "add_influencer_to_list-button-" + tableMeta.rowData[0],
              text: "Add to list",
              onClick: handleClick(rowIndex, platform),
            },
            {
              icon: <FeedIcon fontSize="small" />,
              link: "../posts/?iid=" + rowID,
              id: "Posts-" + tableMeta.rowData[0],
              text: "Review Posts",
              onClick: null,
            },
            {
              icon: <PersonIcon fontSize="small" />,
              link: null,
              id: "Update_Profile-" + tableMeta.rowData[0],
              text: "Update Profile Details",
              onClick: updateProfile(tableMeta),
            },
            {
              icon: <ContactpageIcon fontSize="small" />,
              link: null,
              id: "Update_Contact-" + tableMeta.rowData[0],
              text: "Update Contact Details",
              onClick: updateContactDetails(tableMeta),
            },
          ];
          return [<ActionsMenu itemsData={actionItems} />];
        },
        filter: false,
        sort: false,
        download: false,
      },
    },
  ];
  const [cols, setCols] = useState(initCols);

  const getColumns = useMemo(() => {
    setRowsSelectedInTable([]);
    setRowSelectedForAction([]);
    const columns = [...cols];
    // check if any filter values are present
    if (filterList.length) {
      //iterate over different filter values
      for (let i = 0; i < filterList.length; i++) {
        const [min, max] = filterList[i];
        let arr = [];
        if (min) arr.push(min);
        if (max) arr.push(max);
        //check if a specific filter is applied on column
        if (columns[i]?.options?.filterList) {
          // retain the same filter value for the column
          columns[i].options.filterList = arr;
        }
      }
    }
    return columns;
  }, [filterList]);

  const updateProfile = (rowIndex) => (event) => {
    if (rowIndex.tableData[rowIndex.rowIndex]["name"] === "") {
      rowIndex.tableData[rowIndex.rowIndex]["name"] =
        rowIndex.tableData[rowIndex.rowIndex]["first_name"] + " " + rowIndex.tableData[rowIndex.rowIndex]["last_name"];
    }
    setSelectedPlatformUpdateProfile(rowIndex.tableData[rowIndex.rowIndex]["platform"]);
    setSelectedInfluencer(rowIndex.tableData[rowIndex.rowIndex]);
    setViewUpdateDialog(true);
  };

  const updateContactDetails = (rowIndex) => (event) => {
    if (rowIndex.tableData[rowIndex.rowIndex]["name"] === "") {
      rowIndex.tableData[rowIndex.rowIndex]["name"] =
        rowIndex.tableData[rowIndex.rowIndex]["first_name"] + " " + rowIndex.tableData[rowIndex.rowIndex]["last_name"];
    }
    setSelectedInfluencer(rowIndex.tableData[rowIndex.rowIndex]);
    setShowContactDialog(true);
  };
  const data = [
    { platform: "All" },
    { platform: "Instagram" },
    { platform: "Twitter" },
    //  { platform: "Google" },
    //  { platform: "Facebook" },
    { platform: "Tiktok" },
    { platform: "Youtube" },
    { platform: "Linkedin" },
    { platform: "Facebook" },
  ];

  useEffect(() => {
    getInfluencerData(selectedFilter);
  }, []);

  const getInfluencerData = (value) => {
    axios.get(`${INF_API_HOST}/influencer/list`).then((res) => {
      const campaignData = res.data;
      let newData = [];
      if (campaignData !== null) {
        Object(campaignData).forEach((item, index) => {
          newData.push({ sl: index + 1, ...item });
          const profileData = [];
          profileData["status"] = newData[index]["status_name"];
          profileData["name"] = newData[index]["name"];
          if (newData[index]["influencer_id"]) {
            profileData["influencer_id"] = newData[index]["influencer_id"];
          }
          profileData["email"] = newData[index]["email"];
          profileData["total_followers"] = utils.convertToInternationalNumberSystem(newData[index]["total_followers"]);
          profileData["phone"] = newData[index]["phone"];
          profileData["campaignsAssociated"] = newData[index]["campaignsAssociated"];

          profileData["url"] = PROFILE_IMG;
          if (newData[index]["url"] !== undefined && newData[index]["url"] !== "") {
            profileData["url"] = newData[index]["url"];
          }
          profileData["id"] = newData[index]["influencer_id"];
          profileData["onboared_type"] = newData[index]["onboared_type"];
          profileData["platform"] = newData[index]["platform"];
          if (newData[index]["onboared_type"] === "direct") {
            profileData["name"] = profileData["name"] + " *";
            profileData["status"] = "Added Directly";
          } else if (profileData["status"] === "Signup / Onboarded") {
            profileData["status"] = "Onboarded";
          }
          profileData["age_range"] = item["age_range"];
          profileData["location"] = item["location"];
          profileData["gender"] = item["gender"];
          profileData["socialLink"] = newData[index]["profile_url"];
          profileData["username"] = newData[index]["username"];
          newData[index]["platform"] = utils.capitalizeFirstLetter(newData[index]["platform"]);
          newData[index]["profileName"] = profileData["name"];
          newData[index]["profile"] = profileData;
          newData[index]["type"] = newData[index]["onboared_type"];
          newData[index]["id"] = newData[index]["influencer_id"];
          newData[index]["photoUrl"] = newData[index]["url"];
          newData[index]["iStatus"] = profileData["status"];
          newData[index]["age_range"] = item["age_range"];
          newData[index]["location"] = item["location"];
          newData[index]["gender"] = item["gender"];
        });
      }
      setcampaignData(newData);
      axios.get(API_HOST + "/lists/type/2/" + value.toLowerCase()).then((res) => {
        const listData = res.data;
        let newlistData = [];
        if (listData !== null) {
          Object(listData).forEach((item, index) => {
            newlistData.push({ sl: index + 1, ...item });
            newlistData[index]["name"] =
              newlistData[index]["name"] + " - " + newlistData[index]["cname"] + " - " + newlistData[index]["totalInf"];
          });
        }
        setlistData(newlistData);
      });
      utils.getCompanyData().then((data) => {
        setCompanyList(data);
      });

      utils.getTimezoneData("all").then((data) => {
        const timezoneData = data;
        setTimezoneList(timezoneData);
      });
      setCountryList(Country.getAllCountries());
      setbackdropShow(false);
      setisLoaded(true);
    });
  };

  const onFilter = ({ target: { value } }) => {
    setSelectedFilter((prevState) => (prevState = value));
    // if (value !== 'All')
    getInfluencerData(value);
    setlistData([]);
    setRowsSelectedInTable([]);
    const filteredCols = [...cols];
    let filterList = [];
    filterList = value === "All" ? [] : [value];
    // Target the column to filter on.
    filteredCols[1].options.filterList = filterList;
    setCols(filteredCols);
  };

  const options = {
    viewColumns: false,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter With Platform",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectToolbarPlacement: "none",
    // show confirm filter button for better search
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: "40px" }}>
          <Button variant="contained" onClick={() => applyNewFilters()}>
            Apply Filters
          </Button>
        </div>
      );
    },
    rowsSelected: rowsSelectedInTable,
    onRowSelectionChange: function (currentRowsSelected, allRowsSelected, rowsSelected) {
      setRowsSelectedInTable(rowsSelected);
    },
    onFilterChange: function (changedColumn, filterList, type, changedColumnIndex) {
      setFilterList(filterList);
    },
    onFilterChipClose: function (index, removedFilter, filterList) {
      if (index === 1) {
        setSelectedFilter("All");
      }
    },
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
  };

  const addInfToList = (event) => {
    if (rowsSelectedInTable.length === 0) {
      dispatch(
        openSnackbar({
          message: "please select influencers first",
          severity: "info",
        })
      );
      return;
    }

    setRowSelectionSource(0);

    // Get the platforms of the selected influencers and remove duplicates
    const selectedPlatforms = Array.from(new Set(rowsSelectedInTable.map(rowIndex => campaignData[rowIndex].platform.toLowerCase())));

    // Fetch lists for the selected platforms
    Promise.all(selectedPlatforms.map(platform => axios.get(`${API_HOST}/lists/type/2/${platform}`)))
      .then(results => {
        const combinedListData = results.flatMap(res => res.data);
        const newListData = combinedListData.map((item, index) => ({
          sl: index + 1,
          ...item,
          name: `${item?.name} - ${item?.cname} - ${item?.totalInf}`
        }));
        setlistData(newListData);
        setModalOpen(true);
      })
      .catch(error => {
        console.error("Error fetching list data:", error);
        dispatch(
          openSnackbar({
            message: "Error fetching list data",
            severity: "error",
          })
        );
      });
  };


  const closeViewUpdateDialog = () => {
    setViewUpdateDialog(false);
  };

  const closeViewContactUpdateDialog = () => {
    setShowContactDialog(false);
  };

  const setInfluencerAccountList = (list) => {
    getInfluencerData(selectedFilter);
  };

  return isLoaded ? (
    <>
      <Box m={6}>
        {["super-user", "manager"].includes(user.group) && (
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button variant="contained" onClick={refreshProfiles}>
              {rowsSelectedInTable.length === 0 ? (
                <span>Refresh All {selectedFilter} profiles</span>
              ) : (
                <span>Refresh selected {selectedFilter} profiles</span>
              )}
            </Button>

            <Button variant="contained" onClick={addInfToList}>
              Add Bulk Influencers to list
            </Button>
          </Stack>
        )}
        <Tabs style={{ width: "auto", float: "left" }}>
          <Tab label="Influencer Platform: " id="account-details-tab-0" />
          <Stack direction="row" justifyContent="end">
            <Select onChange={onFilter} value={selectedFilter}>
              {data.map((x) => (
                <MenuItem key={x.platform} value={x.platform}>
                  {x.platform}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Tabs>
        <Stack direction="row" justifyContent="end"></Stack>
      </Box>

      {/* Modal to choose one of existing lists or creating new list to add selected Influencers */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-add_influencer_to_list-label"
        aria-describedby="modal-add_influencer_to_list-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={modalOpen}>
          <div className="syles_paper">
            <div id="modal-add_influencer_to_list-heading" className="styles_modal_heading">
              <h2 id="modal-add_influencer_to_list-heading-text" style={{ padding: "10px" }}>
                Add To List
              </h2>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div
              id="modal-add_influencer_to_list-body"
              style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", paddingTop: "0px !important" }}
            >
              <Stack direction="row" justifyContent="center">
                <Button
                  variant="contained"
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  onClick={createNewList}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  style={{ width: "100%" }}
                >
                  + New List
                </Button>
              </Stack>
              <div className="ui vertical divider" style={{ height: "100px" }}>
                <div className="ortext">
                  <span className="orSpan">OR</span>
                </div>
              </div>
              <div className="verticaDiv">
                <div>
                  <TextField
                    variant="outlined"
                    id="searchInput"
                    className="searchBox"
                    label="Search for list..."
                    style={{ width: "100%  !important", marginLeft: "0px !important" }}
                    refs="searchInput"
                    onKeyDown={searchList}
                    placeholder="Search for list..."
                  />
                  <div id="listOfLists">
                    <ul id="searchUL">
                      {listData.map((x) => {
                        const nameParts = x.name.split(' - ');
                        return (
                          <li onClick={() => listClick(x.id)} key={x.id} value={x.id}>
                            <Typography sx={{ ...textColor[x.platform], display: "inline-block" }}>
                              {snapshotHeaderIcons[x.platform]}
                              &nbsp;&nbsp;
                              <span
                                style={{
                                  color: "black",
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "14px",
                                }}
                              >
                                <span style={{ fontSize: '14px', }}>
                                  {nameParts[0]}
                                </span>
                                {nameParts[1] && (
                                  <>
                                    &nbsp;-&nbsp;
                                    <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                                      {nameParts[1]}
                                    </span>
                                  </>
                                )}
                                {nameParts[2] && (
                                  <>
                                    &nbsp;-&nbsp;
                                    <span style={{ fontSize: '14px', }}>
                                      {nameParts[2]}
                                    </span>
                                  </>
                                )}
                              </span>
                            </Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>


      {/* Modal to list all FAQs */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-faqs_list-label"
        aria-describedby="modal-faqs_list-description"
        open={faqmodalOpen}
        onClose={handleFaqClose}
        closeAfterTransition
      >
        <Fade in={faqmodalOpen}>
          <div class="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-faqs_list-heading" className="styles_modal_heading">
              <h2 id="modal-faqs_list-heading-text" style={{ padding: "10px" }}>
                All Faqs
              </h2>
              <IconButton onClick={handleFaqClose}>
                {" "}
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div id="modal-faqs_list-body" style={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Box>
                  <Grid item xs={12} sm={12} style={{ display: "block", maxWidth: "100%", maxHeight: "160px", overflow: "auto" }}>
                    <Grid item xs={12} sm={6} style={{ display: "block", maxWidth: "100%" }}>
                      <FAQS data={faqListData} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* Modal to create new list to add selected Influencers */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-create_list-label"
        aria-describedby="modal-create_list-description"
        open={modalListOpen}
        onClose={handleListClose}
        closeAfterTransition
      >
        <Fade in={modalListOpen}>
          <div class="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-create_list-heading" className="styles_modal_heading">
              <h2 id="modal-create_list-heading-text" style={{ padding: "10px" }}>
                Add List - {stepMessage}
              </h2>
              <IconButton onClick={handleListClose}>
                {" "}
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div id="modal-create_list-body" style={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Box>
                  <React.Fragment>
                    <form id="stepForm" onSubmit={newListClick}>
                      {currentStep == 1 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                Account *
                              </InputLabel>
                              <Select
                                required={true}
                                label="Account *"
                                id="companyId"
                                InputLabelProps={{ shrink: false }}
                                value={companyId || ""}
                                onChange={handleChangeAccount}
                                input={<OutlinedInput label="Account *" id="demo-dialog-native" />}
                              >
                                {companyList.map((aname) => (
                                  <MenuItem value={aname.id} key={aname.id}>
                                    {aname.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                Campaign Name *
                              </InputLabel>
                              <Select
                                required={true}
                                label="Campaign Name *"
                                InputLabelProps={{ shrink: false }}
                                value={campaignId || ""}
                                id="campaignId"
                                onChange={handleChangeCampaign}
                                refs="campaignId"
                                input={<OutlinedInput label="Campaign Name *" id="demo-dialog-native" />}
                              >
                                {campaignList.map((cname) => (
                                  <MenuItem value={cname.id} key={cname.id}>
                                    {cname.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                Platform *
                              </InputLabel>
                              <Select
                                required={true}
                                label="Platform *"
                                InputLabelProps={{ shrink: false }}
                                id="platformId"
                                value={platformId || ""}
                                onChange={handleChangePlatform}
                                refs="platformId"
                                input={<OutlinedInput label="Platform *" id="demo-dialog-native" />}
                              >
                                <MenuItem value={1} key={1}>
                                  Instagram
                                </MenuItem>
                                <MenuItem value={2} key={2}>
                                  Twitter
                                </MenuItem>
                                <MenuItem value={3} key={3}>
                                  TikTok
                                </MenuItem>
                                <MenuItem value={4} key={4}>
                                  YouTube
                                </MenuItem>
                                <MenuItem value={5} key={5}>
                                  LinkedIn
                                </MenuItem>
                                <MenuItem value={6} key={6}>
                                  Facebook
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <TextField
                                variant="outlined"
                                id="newListName"
                                required={true}
                                refs="newListName"
                                label="List Name"
                                value={newListName}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <TextField
                                variant="outlined"
                                required={false}
                                type="text"
                                value={description}
                                id="description"
                                refs="description"
                                name="description"
                                label="Description"
                                onChange={handleDescChange}
                              />
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <ReactTagInput
                                variant="outlined"
                                sx={{ height: "1.4375em" }}
                                tags={tags}
                                placeholder="Tags"
                                refs="tags"
                                name="tags"
                                label="Tags"
                                maxTags={3}
                                onChange={(newTags) => setTags(newTags)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <TextareaAutosize
                                variant="outlined"
                                minRows={2}
                                required={false}
                                refs="note"
                                placeholder="Notes"
                                value={notes}
                                onChange={handleNotesChange}
                                style={{ height: 56, borderRadius: "4px", borderColor: "#c4c4c4" }}
                              />
                            </FormControl>

                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <Autocomplete
                                options={timezoneList}
                                getOptionLabel={(timezone) => timezone.text}
                                defaultValue={timezoneLable || timezoneLable[0]}
                                onChange={(event, value) => handleTimezoneChange(value)}
                                id="timezone-data"
                                renderInput={(params) => <TextField {...params} label="Timezone" />}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}></Grid>
                        </div>
                      )}
                      {currentStep == 2 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <TextField
                                variant="outlined"
                                required={false}
                                refs="minfollowers"
                                label="Min Followers"
                                type="number"
                                value={minFollowers}
                                onChange={handleMinFollowersChange}
                              />
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <TextField
                                variant="outlined"
                                required={false}
                                refs="maxfollowers"
                                label="Max Followers"
                                type="number"
                                value={maxFollowers}
                                onChange={handleMaxFollowersChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <Grid item xs={12} sm={3} style={{ display: "flex", maxWidth: "50%", marginRight: "10px" }}>
                              <FormControl style={{ width: "230px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                <InputLabel id="demo-simple-select-standard-label">Geo Target</InputLabel>
                                <Select
                                  required={false}
                                  label="Geo Target"
                                  id="geoTypeId"
                                  value={geoType}
                                  onChange={handleGeoTypeChange}
                                  refs="geoTypeId"
                                  input={<OutlinedInput label="Geo Target" id="demo-dialog-native" />}
                                  disabled
                                >
                                  <MenuItem value={1} key={1}>
                                    Gobal
                                  </MenuItem>
                                  <MenuItem value={2} key={2}>
                                    Specific
                                  </MenuItem>
                                </Select>
                              </FormControl>

                              <FormControl
                                id="countryDD"
                                style={{
                                  width: "230px",
                                  marginBottom: "15px",
                                  flex: 1,
                                  marginRight: "10px",
                                  display: displayGeo,
                                }}
                              >
                                <Autocomplete
                                  options={countryList}
                                  required={true}
                                  getOptionLabel={(country) => country.name}
                                  defaultValue={countryLable || countryLable[0]}
                                  onChange={(event, value) => handleCountryChange(value)}
                                  id="country-data"
                                  renderInput={(params) => <TextField {...params} label="Country *" />}
                                />
                              </FormControl>
                            </Grid>
                            <Grid id="stateCityDD" item xs={12} sm={3} style={{ maxWidth: "50%", flex: 1, display: displayGeo }}>
                              <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                <Autocomplete
                                  options={stateList}
                                  getOptionLabel={(state) => state.name}
                                  defaultValue={stateLable || stateLable[0]}
                                  onChange={(event, value) => handleStateChange(value)}
                                  id="state-data"
                                  renderInput={(params) => <TextField {...params} label="State" />}
                                />
                              </FormControl>

                              <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "10px" }}>
                                <Autocomplete
                                  options={cityList}
                                  getOptionLabel={(city) => city.name}
                                  defaultValue={cityLable || cityLable[0]}
                                  onChange={(event, value) => handleCityChange(value)}
                                  id="state-data"
                                  renderInput={(params) => <TextField {...params} label="City" />}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}></Grid>
                        </div>
                      )}
                      {currentStep == 3 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <InputLabel id="demo-simple-select-standard-label">Currency</InputLabel>
                              <Select
                                required={false}
                                label="Currency"
                                id="currencyId"
                                value={currency}
                                onChange={handleCurrencyChange}
                                refs="currencyId"
                                input={<OutlinedInput label="Currency" id="demo-dialog-native" />}
                              >
                                <MenuItem value="$">$</MenuItem>
                                <MenuItem value="₹">₹</MenuItem>
                                <MenuItem value="€">€</MenuItem>
                                <MenuItem value="£">£</MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <TextField
                                variant="outlined"
                                required={false}
                                refs="bugdet"
                                label="Per Influencer Budget"
                                type="number"
                                value={infBudget}
                                onChange={handleInfBudgetChange}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} style={{ display: "flex", width: "100%" }}>
                            <Grid item xs={12} sm={6} style={{ display: "flex", width: "50%" }}>
                              <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1, marginRight: "10px" }}>
                                <InputLabel id="demo-simple-select-standard-label">Payment Term</InputLabel>
                                <Select
                                  required={false}
                                  label="Payment Term"
                                  id="paymentId"
                                  value={paymentTerm}
                                  onChange={handlePaymentChange}
                                  refs="paymentId"
                                  input={<OutlinedInput label="Payment Term" id="demo-dialog-native" />}
                                >
                                  <MenuItem value={1} key={1}>
                                    Weekly
                                  </MenuItem>
                                  <MenuItem value={2} key={2}>
                                    Bi-Weekly/Fortnightly
                                  </MenuItem>
                                  <MenuItem value={3} key={3}>
                                    Monthly
                                  </MenuItem>
                                  <MenuItem value={4} key={4}>
                                    No. Days after Task Completion
                                  </MenuItem>
                                  <MenuItem value={5} key={5}>
                                    Particular Payment Date (s) - On a particular day
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid id="noofDays" item xs={12} sm={6} style={{ maxWidth: "50%", flex: 1, display: displayPT }}>
                              <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginLeft: "10px" }}>
                                <TextField
                                  variant="outlined"
                                  required={true}
                                  refs="noofDays"
                                  label="No Of Days"
                                  type="number"
                                  value={noofDays}
                                  onChange={handleNoOfDaysChange}
                                />
                              </FormControl>
                            </Grid>
                            <Grid id="onDate" item xs={12} sm={6} style={{ maxWidth: "50%", flex: 1, display: displayDT }}>
                              <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginLeft: "10px" }}>
                                <TextField
                                  clearable
                                  value={paymentDate}
                                  id="paymentDate"
                                  variant="outlined"
                                  name="paymentDate"
                                  label="Payment Date"
                                  InputLabelProps={{ shrink: true, required: true }}
                                  type="date"
                                  required={required}
                                  onChange={handlePaymentDateChange}
                                  sx={{ width: "100%" }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}></Grid>
                        </div>
                      )}
                      {currentStep == 4 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl
                              style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px", minHeight: "240px" }}
                            >
                              <TextField
                                variant="outlined"
                                id="dos"
                                name="dos"
                                label="Do's"
                                multiline
                                minRows={4}
                                required={false}
                                refs="dos"
                                placeholder="Do's"
                                onKeyUp={(event) => {
                                  handleKeyUp("dos", event);
                                }}
                                style={{ height: "240px", borderRadius: "4px", borderColor: "#c4c4c4", minHeight: "240px" }}
                                defaultValue={dos}
                              />
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <TextField
                                variant="outlined"
                                id="donts"
                                name="donts"
                                label="Dont's"
                                multiline
                                minRows={4}
                                required={false}
                                refs="Dont"
                                placeholder="Dont's"
                                onKeyUp={(event) => {
                                  handleKeyUp("donts", event);
                                }}
                                style={{ height: "auto", borderRadius: "4px", borderColor: "#c4c4c4", minHeight: "240px" }}
                                defaultValue={donts}
                              />{" "}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}></Grid>
                          <br />
                        </div>
                      )}
                      {currentStep == 5 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "90%", marginBottom: "15px" }}>
                              <Button
                                variant="contained"
                                className="btn btn-success float-right"
                                type="button"
                                id="demo-customized-button"
                                aria-controls={open ? "demo-customized-menu" : undefined}
                                onClick={faqList}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                style={{ float: "right", width: "100px" }}
                              >
                                {" "}
                                All FAQ
                              </Button>
                            </FormControl>
                            <FormControl style={{ marginBottom: "30px", display: "inline" }}>
                              <Button
                                variant="contained"
                                className="btn btn-success float-right"
                                type="button"
                                onClick={handleClickFAQ}
                              >
                                {" "}
                                + FAQ
                              </Button>
                            </FormControl>
                          </Grid>
                          <div style={{ maxHeight: "330px", overflow: "auto" }}>
                            {Array.from(Array(counter)).map((c, index) => {
                              return (
                                <div>
                                  <FormControl
                                    style={{
                                      width: "100%",
                                      marginBottom: "15px",
                                      flex: 1,
                                      marginRight: "20px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <TextField
                                      variant="outlined"
                                      required={false}
                                      type="text"
                                      value={faqQuestion[index]}
                                      id="question"
                                      refs="question"
                                      name="question"
                                      label="Question"
                                      onChange={handleQuestionOnChange(index)}
                                    />
                                  </FormControl>
                                  <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                    <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                      <TextField
                                        variant="outlined"
                                        required={false}
                                        type="text"
                                        value={faqAnswer[index]}
                                        id="answer"
                                        refs="answer"
                                        name="answer"
                                        label="Answer"
                                        onChange={handleAnswerOnChange(index)}
                                      />
                                    </FormControl>
                                    <FormControl style={{ marginBottom: "30px", display: "block", marginTop: "15px" }}>
                                      <RemoveCircleOutlineIcon style={{ display: "block" }} onClick={handleRemoveClick(index)} />
                                    </FormControl>
                                  </Grid>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {currentStep == 6 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <Editor
                                editorState={editorState}
                                onEditorStateChange={handleEditorChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                toolbar={{
                                  options: ["image", "emoji", "link", "history"],
                                  image: { inDropdown: true },
                                  emoji: { inDropdown: true },
                                  link: {
                                    inDropdown: true,
                                    showOpenOptionOnHover: true,
                                    defaultTargetOption: "_self",
                                    options: ["link", "unlink"],
                                  },
                                  history: { inDropdown: true },
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={3}></Grid>
                        </div>
                      )}
                      {previousButton()}
                      {nextButton()}
                      {submitButton()}
                    </form>
                  </React.Fragment>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
      {
        <ViewUpdateDialog
          influencerAccounts={campaignData}
          platform={selectedPlatformUpdateProfile}
          influencerAccount={selectedInfluencer}
          viewUpdateDialog={viewUpdateDialog}
          closeViewUpdateDialog={closeViewUpdateDialog}
          setInfluencerAccountList={setInfluencerAccountList}
        />
      }
      {showContactDialog && (
        <ViewContactDialog
          influencerAccounts={campaignData}
          platform={selectedFilter}
          influencerAccount={selectedInfluencer}
          viewContactDialog={showContactDialog}
          closeViewUpdateDialog={closeViewContactUpdateDialog}
          setInfluencerAccountList={setInfluencerAccountList}
        />
      )}
      <div>
        <MUIDataTable columns={getColumns} data={campaignData} options={options} />
        <br />
        <div> * indicates influencers are onboared directly.</div>
      </div>
    </>
  ) : (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, flexDirection: "column" }} open={backdropShow}>
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
}

function ActionsMenu({ itemsData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: "#4f4f4f" }}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {itemsData.map((item) => (
          <MenuItem>
            <Box
              component={item.link ? Link : "div"}
              to={item.link ? item.link : null}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              p={"2px"}
              color="inherit"
              sx={{ textDecoration: "none" }}
              onClick={
                item.onClick
                  ? () => {
                    item.onClick();
                    handleClose();
                  }
                  : null
              }
            >
              <Box sx={{ mr: "6px" }}>{item.icon}</Box>
              <Typography fontSize={"16px"}>{item.text}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
