import React, { useState, useEffect } from "react";
import CustomTable from "../../common/CustomTable/CustomTable";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Fade,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Popper,
  TextField,
  Tooltip,
} from "@mui/material";
import styles from "../../styles/internal/UserAccess.module.css";
import {
  AccountCircleRounded,
  ArrowDropDownRounded,
  Block,
  Campaign,
  Close,
  Delete,
  Done,
  Email,
  ManageAccounts,
  MoreVertOutlined,
  RemoveRedEye,
  ViewAgenda,
} from "@mui/icons-material";
import RoleMenu from "./RoleMenu";
import { useDispatch, useSelector } from "react-redux";
import { rolesData } from "./enum";
import { API_HOST, HEIMDALL_HOST } from "../../config/main.json";
import axios from "axios";
import Loader from "../../pages/account/Loader";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import tableStyle from "../../styles/theme/pageStyling/TableStyle.module.css";
import utils from "../../utils/utils";
import { getUserAccessTableHeaders } from "../../utils/tableHeaders";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const style = (theme) => ({
  popper: {
    maxWidth: "fit-content",
  },
});

const CustomPopper = function (props) {
  return <Popper {...props} style={style.popper} placement="bottom" />;
};

function UserAccessTable({ type, search, refreshTable, setRefreshTable, clearPageNumber, setClearPageNumber }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userData, setUserData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [tablePageNo, setTablePageNo] = useState(0); // page no starting from 1, as opposed to mui table (which starts from 0)
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortCol, setSortCol] = useState("createdAt");
  const [anchorEl, setAnchorEl] = useState([]);
  const [subMenuanchorEl, setSubMenuAnchorEl] = useState([]);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [openAccountManagerModal, setOpenAccountManagerModal] = useState(false);
  const [openViewAccessModal, setOpenViewAccessModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const [userCampaignList, setUserCampaignList] = useState([]);
  const [accountManagerList, setAccountManagerList] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  
  const getTableData = async (tablePageNo, tableRowsPerPage, sortCol, sortOrder, search) => {
    const data = {
      type: type,
      page: tablePageNo,
      rows: tableRowsPerPage,
      sortCol: sortCol,
      sortOrder: sortOrder,
      search: search,
    };
    const response = await utils.getAiFluenceUsers(data);
    let userData = [];
    let total = 0;
    if (response) {
      userData = response.users || [];
      total = response.total;
    }
    setUserData(userData);
    setTotalRows(total);
    if (total <= tablePageNo * tableRowsPerPage && total > 0) {
      setTablePageNo((prev) => prev - 1);
    }
    setRefreshTable(false);
    setIsLoaded(true);
  };

  const handleInformationChange = ({ page, rowsPerPage, order, orderBy }) => {
    setTablePageNo(page);
    setTableRowsPerPage(rowsPerPage);
    setSortCol(orderBy);
    setSortOrder(order);
  };

  const handleMember = (event, index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null; //setting the opened anchor to null
    if (newAnchorEl[index] === null) {
      newAnchorEl[index] = event.currentTarget;
    } else {
      newAnchorEl[index] = null;
    }
    setAnchorEl(newAnchorEl);
  };

  const handleMenuMember = (i) => {
    return (index) => {
      setSelectedUser(userData[i]);
      setSelectedRole(rolesData[index].value);
      setOpenRoleModal(true);
      handleClose();
    };
  };

  const handleClose = () => {
    const anchorEl = [];
    for (let i = 0; i < 4; i++) {
      anchorEl.push(null);
    }
    setAnchorEl(anchorEl);
  };

  const handleCloseSubMenu = () => {
    const anchorEl = [];
    for (let i = 0; i < 4; i++) {
      anchorEl.push(null);
    }
    setSubMenuAnchorEl(anchorEl);
  };

  const handleInvite = (id) => {
    handleCloseSubMenu();

    axios.get(API_HOST + "/access/user/resendinvite/" + id).then((res) => {
      if (res.status === 200 && res.data === "success") {
        dispatch(
          openSnackbar({
            message: "Invite Sent!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: "Error!",
            severity: "error",
          })
        );
      }
    });
  };

  const handleStatus = (user) => {
    setSelectedUser(user);
    handleCloseSubMenu();

    setOpenStatusModal(true);
  };

  const handleClick = (event, index) => {
    const anchorEl = [];
    for (let i = 0; i < userData.length; i++) {
      if (i === index) anchorEl.push(event.currentTarget);
      else anchorEl.push(null);
    }
    setSubMenuAnchorEl(anchorEl);
  };

  const changeStatus = () => {
    closeModal();

    const data = {
      id: selectedUser.id,
    };

    setIsLoaded(false);

    axios.post(HEIMDALL_HOST + "/oryx/changestatus", data, optionAxios).then((res) => {
      if (res.status === 200) {
        getTableData(tablePageNo, tableRowsPerPage, sortCol, sortOrder, search);
      }
    });
  };

  const changeRole = () => {
    closeModal();

    const data = {
      id: selectedUser.id,
      prevRole: selectedUser.role,
      newRole: selectedRole,
    };

    setIsLoaded(false);

    axios.post(HEIMDALL_HOST + "/oryx/changerole", data, optionAxios).then((res) => {
      if (res.status === 200) {
        getTableData(tablePageNo, tableRowsPerPage, sortCol, sortOrder, search);
      }
    });
  };

  const handleAccess = async (user) => {
    setOpenAccessModal(true);
    setCampaignList([]);
    setUserCampaignList([]);
    setSelectedUser(user);
    handleCloseSubMenu();

    axios.get(API_HOST + "/campaign/byplatform/all").then((res) => {
      let campaignList = [];
      if (res.status === 200) {
        if (res.data) {
          campaignList = res.data;
        }
        setCampaignList(campaignList);
        const data = {
          id: user.id,
        };
        axios.post(API_HOST + "/access/member/getcampaigns", data, optionAxios).then((res) => {
          if (res.status === 200) {
            let userCampaignList = [];
            if (res.data) {
              userCampaignList = campaignList.filter((campaign) => res.data.cids.includes(campaign.id));
            }
            const aIds = new Set(userCampaignList.map((item) => item.id));

            campaignList.sort((itemA, itemB) => {
              const isAInA = aIds.has(itemA.id);
              const isBInA = aIds.has(itemB.id);

              if (isAInA && !isBInA) {
                return -1; // itemA comes before itemB
              } else if (!isAInA && isBInA) {
                return 1; // itemB comes before itemA
              } else {
                return 0; // no change in order
              }
            });

            setUserCampaignList(userCampaignList);
          }
        });
      }
    });
  };

  const assignManager = async (user) => {
    setOpenAccountManagerModal(true);
    setSelectedUser(user);
    handleCloseSubMenu();
    setAccountList([]);
    setAccountManagerList([]);
    axios.get(`${API_HOST}/account/list/type/1/all`).then((res) => {
      let accountList = [];
      if (res.status === 200) {
        if (res.data) {
          accountList = res.data;
        }
        setAccountList(accountList);
        const data = {
          id: user.id,
        };
        axios.post(API_HOST + "/access/manager/getaccounts", data, optionAxios).then((res) => {
          if (res.status === 200) {
            let accountManagerList = [];
            if (res.data) {
              accountManagerList = accountList.filter((account) => res.data.aids.includes(account.id));
            }
            const aIds = new Set(accountManagerList.map((item) => item.id));
            accountList.sort((itemA, itemB) => {
              const isAInA = aIds.has(itemA.id);
              const isBInA = aIds.has(itemB.id);

              if (isAInA && !isBInA) {
                return -1; // itemA comes before itemB
              } else if (!isAInA && isBInA) {
                return 1; // itemB comes before itemA
              } else {
                return 0; // no change in order
              }
            });

            setAccountManagerList(accountManagerList);
          }
        });
      }
    });
  };

  const viewAccess = async (user) => {
    setOpenViewAccessModal(true);
    setCampaignList([]);
    setUserCampaignList([]);
    setSelectedUser(user);
    handleCloseSubMenu();
    axios.get(API_HOST + "/campaign/byplatform/all").then((res) => {
      let campaignList = [];
      if (res.status === 200) {
        if (res.data) {
          campaignList = res.data;
        }
        setCampaignList(campaignList);
        const data = {
          id: user.id,
        };
        axios.post(API_HOST + "/access/member/getcampaigns", data, optionAxios).then((res) => {
          if (res.status === 200) {
            let userCampaignList = [];
            if (res.data) {
              userCampaignList = campaignList.filter((campaign) => res.data.cids.includes(campaign.id));
            }
            setUserCampaignList(userCampaignList);
          }
        });
      }
    });
  };

  const changeMemberAccess = () => {
    closeModal();
    const data = {
      userId: selectedUser.id,
      cids: userCampaignList.map((campaign) => campaign.id),
    };

    axios.post(API_HOST + "/access/member/changeaccess", data, optionAxios).then((res) => {
      if (res.status === 200 && res.data === "success") {
        dispatch(
          openSnackbar({
            message: "Campaign Access Updated!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: "Error!",
            severity: "error",
          })
        );
      }
    });
  };

  const changeManagerAccess = async () => {
    closeModal();
    const data = {
      userId: selectedUser.id,
      aids: accountManagerList.map((account) => account.id),
    };

    const response = await utils.assignAccountManager(data);
    if (response === "success") {
      dispatch(
        openSnackbar({
          message: "Campaign Access Updated!",
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: "Error!",
          severity: "error",
        })
      );
    }
  };

  const handleDelete = (user) => {
    setSelectedUser(user);
    handleCloseSubMenu();

    setOpenDeleteModal(true);
  };

  const deleteUser = () => {
    closeModal();

    setIsLoaded(false);

    const id = selectedUser.id;

    axios.get(HEIMDALL_HOST + "/oryx/delete/" + id).then((res) => {
      if (res.status === 200) {
        getTableData(tablePageNo, tableRowsPerPage, sortCol, sortOrder, search);
      }
    });
  };

  const closeModal = () => {
    setOpenRoleModal(false);
    setOpenAccessModal(false);
    setOpenViewAccessModal(false);
    setOpenDeleteModal(false);
    setOpenStatusModal(false);
    setOpenAccountManagerModal(false);
  };

  useEffect(() => {
    setIsLoaded(false);
    if (clearPageNumber) {
      setClearPageNumber(false);
      setTablePageNo(0);
      getTableData(0, tableRowsPerPage, sortCol, sortOrder, search);
    } else {
      getTableData(tablePageNo, tableRowsPerPage, sortCol, sortOrder, search);
    }
  }, [refreshTable, tablePageNo, tableRowsPerPage, sortCol, sortOrder, search]);

  useEffect(() => {
    const open = anchorEl.map((val) => val !== null);
    const openSubMenu = subMenuanchorEl.map((val) => val !== null);

    const createData = (users) => {
      const tableData = [];
      users?.forEach((row, i) => {
        const actions = (
          <div>
            {["super-user", "manager"].includes(user.group) && ["invited"].includes(row.status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => handleInvite(row.id)}>
                  <Email className={tableStyle.icon} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Resend Invite
                </MenuItem>
              </>
            )}
            {["super-user", "manager"].includes(user.group) &&
              ["invited", "active"].includes(row.status) &&
              ["member"].includes(row.role) && (
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => handleAccess(row)}>
                    <Campaign className={tableStyle.icon} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Assign Campaign
                  </MenuItem>
                </>
              )}

            {["super-user", "manager"].includes(user.group) &&
              ["invited", "active"].includes(row.status) &&
              ["manager"].includes(row.role) && (
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => assignManager(row)}>
                    <ManageAccounts className={tableStyle.icon} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Assign Account Manager
                  </MenuItem>
                </>
              )}

            {["super-user", "manager"].includes(user.group) &&
              ["invited", "active"].includes(row.status) &&
              ["member"].includes(row.role) && (
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => viewAccess(row)}>
                    <RemoveRedEye className={tableStyle.icon} />
                    &nbsp;&nbsp;&nbsp;&nbsp;View Campaign Access
                  </MenuItem>
                </>
              )}
            {["super-user"].includes(user.group) &&
              ["invited", "active"].includes(row.status) &&
              row.email !== user.attributes.email && (
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => handleStatus(row)}>
                    <Block className={tableStyle.icon} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Disable
                  </MenuItem>
                </>
              )}

            {["super-user"].includes(user.group) && ["disabled"].includes(row.status) && row.email !== user.attributes.email && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => handleStatus(row)}>
                  <Done className={tableStyle.icon} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Enable
                </MenuItem>
              </>
            )}

            {["super-user"].includes(user.group) && row.email !== user.attributes.email && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => handleDelete(row)}>
                  <Delete className={tableStyle.icon} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Delete
                </MenuItem>
              </>
            )}
          </div>
        );
        let object = {
          id: row.id,
          name: (
            <Grid container spacing={0.5} sx={{ display: "flex", justifyContent: "left", ml: "20px" }}>
              <AccountCircleRounded
                fontSize="large"
                style={{
                  transform: "scale(1.4)",
                  marginRight: "15px",
                  color: "gray",
                }}
              />
              <Grid item>
                <Grid item xs={12} className={styles.bodycell}>
                  <span>{row.name}</span>
                </Grid>

                <Grid item xs={12} className={styles.subbodycell}>
                  <span>{row.email}</span>
                </Grid>
              </Grid>
            </Grid>
          ),
          companyName: (
            <Grid container spacing={0.5}>
              <Grid item>
                <Grid item xs={12} className={styles.bodycell}>
                  <span>{row.companyName}</span>
                </Grid>
              </Grid>
            </Grid>
          ),
          status: (
            <div style={{ textAlign: "center" }}>
              {row.status === "active" && (
                <span
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    display: "inline-block",
                    backgroundColor: "#26DE81",
                    transform: "translateY(5px)",
                  }}
                />
              )}
              {row.status === "invited" && (
                <span
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    display: "inline-block",
                    backgroundColor: "#FED330",
                    transform: "translateY(5px)",
                  }}
                />
              )}
              {row.status === "disabled" && (
                <span
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    display: "inline-block",
                    backgroundColor: "#F08995",
                    transform: "translateY(5px)",
                  }}
                />
              )}
              &nbsp;&nbsp;
              <span style={{ transform: "translateY(-40px)" }}>
                {row.status === "active" && "Active"}
                {row.status === "invited" && "Invited"}
                {row.status === "disabled" && "Disabled"}
              </span>
            </div>
          ),
          role: ["super-user"].includes(user.group) ? (
            <>
              <Button
                style={{
                  width: "150px",
                  borderRadius: "10px",
                  backgroundColor: "#E7E8EA",
                  color: "#464E5F",
                  height: "50px",
                  textTransform: "none",
                }}
                variant="contained"
                disabled={row.email === user.attributes.email}
                onClick={(event) => handleMember(event, i)}
                endIcon={row.email !== user.attributes.email && <ArrowDropDownRounded style={{ transform: "scale(1.5)" }} />}
              >
                {rolesData.filter((role) => role.value === row.role)[0]?.title}
              </Button>
              <RoleMenu
                roles={rolesData}
                anchorEl={anchorEl[i]}
                open={open[i]}
                handleClose={handleClose}
                handleMenuMember={handleMenuMember(i)}
              />
            </>
          ) : (
            <div>{rolesData.filter((role) => role.value === row.role)[0]?.title}</div>
          ),
          actions: (
            <Grid item xs={4}>
              <IconButton onClick={(event) => handleClick(event, i)} size="small" sx={{ ml: 2 }}>
                <MoreVertOutlined />
              </IconButton>
              <Box style={{ borderRadius: "30%" }}>
                <Menu
                  anchorEl={subMenuanchorEl[i]}
                  getContentAnchorEl={null}
                  open={openSubMenu[i]}
                  onClose={handleCloseSubMenu}
                  className={tableStyle.menuitem}
                >
                  {actions}
                </Menu>
              </Box>
            </Grid>
          ),
        };
        tableData.push(object);
      });
      setTableData(tableData);
    };

    createData(userData);
  }, [anchorEl, userData, openRoleModal, subMenuanchorEl]);

  const userAccessTableHeaders = getUserAccessTableHeaders;

  return (
    <>
      {isLoaded ? (
        <div id="table">
          {userData.length > 0 && (
            <CustomTable
              tableHeaders={userAccessTableHeaders}
              bodyColumns={tableData}
              sendInformation={handleInformationChange}
              totalRows={totalRows}
              pageNo={tablePageNo}
              rowsPerPageNo={tableRowsPerPage}
              sortOrder={sortOrder}
              sortOrderBy={sortCol}
            />
          )}
          {userData.length === 0 && (
            <Box
              sx={{
                p: "20px",
                transform: "translateY(10px)",
                textAlign: "center",
                bgcolor: "#fff",
              }}
            >
              Table is empty
            </Box>
          )}
        </div>
      ) : (
        <Loader />
      )}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-confirm_role-label"
        aria-describedby="modal-confirm_role-description"
        open={openRoleModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openRoleModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-confirm_role-heading" className="styles_modal_heading">
              <h2 id="modal-confirm_role-heading-text" style={{ padding: "10px" }}>
                Change Role
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-confirm_role-body" style={{ padding: "10px" }}>
              <div>Are you sure you want to change role for {selectedUser?.name}?</div>
              <div
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
              >
                <Button variant="outlined" sx={{ mr: "10px" }} onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={changeRole}>
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-confirm_status-label"
        aria-describedby="modal-confirm_status-description"
        open={openStatusModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openStatusModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-confirm_status-heading" className="styles_modal_heading">
              <h2 id="modal-confirm_status-heading-text" style={{ padding: "10px" }}>
                Change Status
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-confirm_status-body" style={{ padding: "10px" }}>
              <div>
                Are you sure you want to {selectedUser?.status === "disabled" ? "Enable" : "Disable"} access for{" "}
                {selectedUser?.name}?
              </div>
              <div
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
              >
                <Button variant="outlined" sx={{ mr: "10px" }} onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={changeStatus}>
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-change_member_campaigns-label"
        aria-describedby="modal-change_member_campaigns-description"
        open={openAccessModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openAccessModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-change_member_campaigns-heading" className="styles_modal_heading">
              <h2 id="modal-change_member_campaigns-heading-text" style={{ padding: "10px" }}>
                Manage Campaign Access
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-change_member_campaigns-body" style={{ padding: "10px" }}>
              <div>
                <div>Select the campaigns accessible by {selectedUser?.name}</div>
                <FormControl size="small" sx={{ width: "50%", mt: "20px" }}>
                  <Autocomplete
                    multiple
                    limitTags={0}
                    options={campaignList}
                    disableCloseOnSelect
                    disableClearable
                    PopperComponent={CustomPopper}
                    value={userCampaignList}
                    onChange={(e, val) => {
                      setUserCampaignList(val);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li key={option.id} {...props}>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Checkbox checked={selected} />
                            &nbsp;{option.name}
                          </Grid>
                          <Grid item>
                            {option.platform === "oryx" ? (
                              <Chip
                                label="Oryx"
                                sx={{
                                  bgcolor: "#33cc99",
                                  color: "white",
                                  transform: "scale(0.8)",
                                  fontSize: "18px",
                                }}
                              />
                            ) : (
                              <Chip
                                label="Empowr"
                                sx={{
                                  bgcolor: "#37adca",
                                  color: "white",
                                  transform: "scale(0.8)",
                                  fontSize: "18px",
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    renderTags={(selected, _, state) =>
                      state.popupOpen ? "" : `${selected.length} Campaign${selected.length > 1 ? "s" : ""} Selected`
                    }
                    renderInput={(params) => <TextField {...params} label="Campaigns" />}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
              >
                <Button variant="outlined" sx={{ mr: "10px" }} onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={changeMemberAccess}>
                  Grant Access
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        className="styles_modal"
        aria-labelledby="modal-change_member_campaigns-label"
        aria-describedby="modal-change_member_campaigns-description"
        open={openViewAccessModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openViewAccessModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-change_member_campaigns-heading" className="styles_modal_heading">
              <h2 id="modal-change_member_campaigns-heading-text" style={{ padding: "10px" }}>
                View Campaign Access by {selectedUser?.name}
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-change_member_campaigns-body" style={{ padding: "10px" }}>
              {userCampaignList.length > 0 ? (
                userCampaignList.map((campaign) => <li key={campaign.id}>{campaign.name}</li>)
              ) : (
                <li>No campaigns available</li>
              )}
              <div
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
              >
                <Button variant="outlined" sx={{ mr: "10px" }} onClick={closeModal}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        className="styles_modal"
        aria-labelledby="modal-change_member_campaigns-label"
        aria-describedby="modal-change_member_campaigns-description"
        open={openAccountManagerModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openAccountManagerModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-change_member_campaigns-heading" className="styles_modal_heading">
              <h2 id="modal-change_member_campaigns-heading-text" style={{ padding: "10px" }}>
                Assign Account Manager
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-change_member_campaigns-body" style={{ padding: "10px" }}>
              <div>
                <div>Select the accounts managed by {selectedUser?.name}</div>
                <FormControl size="small" sx={{ width: "50%", mt: "20px" }}>
                  <Autocomplete
                    multiple
                    limitTags={0}
                    options={accountList}
                    disableCloseOnSelect
                    disableClearable
                    PopperComponent={CustomPopper}
                    value={accountManagerList}
                    onChange={(e, val) => {
                      // Filter out options with accountManager assigned but keep the selected ones
                      const filteredVal = val.filter(
                        (option) => option.accountManager === "" || accountManagerList.includes(option)
                      );
                      setAccountManagerList(filteredVal);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li key={option.id} {...props}>
                        <Grid
                          container
                          justifyContent="space-between"
                          style={{
                            opacity: option.accountManager !== "" && !selected ? 0.5 : 1,
                            pointerEvents: option.accountManager !== "" && !selected ? "none" : "auto",
                          }}
                        >
                          <Grid item>
                            <Checkbox checked={selected} disabled={option.accountManager !== "" && !selected} />
                            &nbsp;{option.name} &nbsp;{" "}
                            {option.accountManager !== "" && !selected && (
                              <span style={{ fontSize: "12px" }}>(Account Manager assigned)</span>
                            )}
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    renderTags={(selected, _, state) =>
                      state.popupOpen ? "" : `${selected.length} Account${selected.length > 1 ? "s" : ""} Selected`
                    }
                    renderInput={(params) => <TextField {...params} label="Accounts" />}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
              >
                <Button variant="outlined" sx={{ mr: "10px" }} onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={changeManagerAccess}>
                  Grant Access
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-confirm_delete-label"
        aria-describedby="modal-confirm_delete-description"
        open={openDeleteModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openDeleteModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-confirm_delete-heading" className="styles_modal_heading">
              <h2 id="modal-confirm_delete-heading-text" style={{ padding: "10px" }}>
                Delete User
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-confirm_delete-body" style={{ padding: "10px" }}>
              <div>Are you sure you want to delete access for {selectedUser?.name}?</div>
              <div
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
              >
                <Button variant="outlined" sx={{ mr: "10px" }} onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={deleteUser}>
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default UserAccessTable;
