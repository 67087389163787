import PropTypes from 'prop-types';

import {
  Chip,
  Grid,
  IconButton
} from "@mui/material";
import {
  People,
  RecordVoiceOver,
  CancelRounded
} from "@mui/icons-material";

import "./styles.css";

const DiscoveryAppliedFilters = ({ appliedPrimaryFilters, appliedAdvanceFilters_inf, appliedAdvanceFilters_aud, removeAllPrimaryFilters, removeAllAdvanceFilters }) => {
  const filterIcons = {
    influencer: <RecordVoiceOver />,
    audience: <People />,
  };

  const chipForSingleFilter = (value, appliedFilterName, iconName, filterType, appliedFilters) => {
    if (value) {
      let label = value;
      if (typeof value === "object") {
        label = value.value;
      }
      label = appliedFilterName + ": " + label;
      let icon = <></>;
      if (iconName in filterIcons) {
        icon = filterIcons[iconName];
      }
      return (
        <Chip key={filterType+"-"+appliedFilters+"-"+label} icon={icon} label={label} style={{marginRight: "10px", marginBottom: "10px"}}/>
      )
    }
  }

  const createFilterChips = (appliedFilters, iconName, filterType) => {
    return Object.keys(appliedFilters).map((appliedFilterName) => {
      const values = appliedFilters[appliedFilterName];
      if (Array.isArray(values)) { // multiple values
        return values.map((value) => {
          return chipForSingleFilter(value, appliedFilterName, iconName, filterType, appliedFilters);
        });
      } else { // single values only
        return chipForSingleFilter(values, appliedFilterName, iconName, filterType, appliedFilters);
      }
    });
  }

  const clearAllPrimaryFilter = () => {
    removeAllPrimaryFilters();
  }

  const clearAllAdvanceFilter = () => {
    removeAllAdvanceFilters();
  }

  const renderPrimaryFilters = () => {
    if (!appliedPrimaryFilters || Object.keys(appliedPrimaryFilters).length === 0 ) {
      return;
    }

    return(
      <Grid container columns={{xs:4, sm:6, md:12}}>
        <Grid item xs={3} sm={5} md={11}>
          <div className="filterChips" id="primaryFilterChips" >
            <span className="appliedFilters-label" id="appliedFilters-searchParams-label">Search Params:</span>
            {createFilterChips(appliedPrimaryFilters, "primary", "primary")}
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <IconButton onClick={() => clearAllPrimaryFilter()} style={{float: "right"}}>
            <CancelRounded className="filter-close-icon" />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const renderAdvanceFilters = () => {
    if (
      (!appliedAdvanceFilters_inf || Object.keys(appliedAdvanceFilters_inf).length === 0) && 
      (!appliedAdvanceFilters_aud || Object.keys(appliedAdvanceFilters_aud).length === 0 )) {
        return;
    }

    return(
      <Grid container columns={{xs:4, sm:6, md:12}}>
        <Grid item xs={3} sm={5} md={11}>
          <div className="filterChips" id="advanceFilterChips" style={{ overflowX: "scroll" }}>
          <span className="appliedFilters-label" id="appliedFilters-filters-label">Filters:</span>
            {createFilterChips(appliedAdvanceFilters_inf, "influencer", "influencer")}
            {createFilterChips(appliedAdvanceFilters_aud, "audience", "audience")}
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <IconButton onClick={() => clearAllAdvanceFilter()} style={{float: "right"}}>
            <CancelRounded className="filter-close-icon" />
          </IconButton>
        </Grid>
      </Grid>
    )

  }
  return(
    <Grid container columns={{xs:4, sm:6, md:12}}>
      <Grid item xs={4} sm={6} md={12}>
        {renderPrimaryFilters()}
      </Grid>
      <Grid item xs={4} sm={6} md={12}>
        {renderAdvanceFilters()}
      </Grid>
    </Grid>
  )
}


DiscoveryAppliedFilters.propTypes = {
  appliedPrimaryFilters: PropTypes.object.isRequired,
  appliedAdvanceFilters_inf: PropTypes.object.isRequired,
  appliedAdvanceFilters_aud: PropTypes.object.isRequired,
  removeAllPrimaryFilters: PropTypes.func.isRequired,
  removeAllAdvanceFilters: PropTypes.func.isRequired
}

export default DiscoveryAppliedFilters;