import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { bool, func, object, string } from "prop-types";
import { useEffect, useRef, useState } from "react";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import { LocationOn } from "@mui/icons-material";
import utils from "../../utils/utils";
import { PROFILE_IMG } from "../../config/main.json";

const ViewProfileDialog = ({
  influencerAccount,
  viewProfileDialog,
  closeViewProfileDialog,
  platform,
  additionalDetail,
  engagementDisplay,
}) => {
  const [scroll, setScroll] = useState("paper");
  const descriptionElementRef = useRef(null);
  const [fullBioShown, setFullBioShown] = useState(false);
  const [bioToShow, setBioToShow] = useState("");

  useEffect(() => {
    console.log(influencerAccount);
    if (viewProfileDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [viewProfileDialog]);

  useEffect(() => {
    const bio = influencerAccount.Bio;
    if (!bio) {
      return;
    }
    let displayBio = bio;
    let displayFullBio = true;

    if (displayBio.length > 100) {
      displayBio = bio.slice(0, 97) + "...";
      displayFullBio = false;
    }

    setFullBioShown(displayFullBio);
    setBioToShow(displayBio);
  }, [influencerAccount?.Bio]);

  const handleBioActionBtnClick = (showFullBio) => {
    if (showFullBio === fullBioShown) {
      return;
    }
    setFullBioShown(showFullBio);
    let bioToDisplay = influencerAccount.Bio;
    if (!showFullBio) {
      bioToDisplay = bioToDisplay.slice(0, 97) + "...";
    }
    setBioToShow(bioToDisplay);
  };
  const renderBioActionBtn = () => {
    if (fullBioShown) {
      return (
        <Button onClick={() => handleBioActionBtnClick(false)}>
          Show less
        </Button>
      );
    } else {
      return (
        <Button onClick={() => handleBioActionBtnClick(true)}>Show more</Button>
      );
    }
  };
  const renderBio = () => {
    if (!bioToShow) {
      return;
    }

    return (
      <>
        <span id="basicDetails_bio">{bioToShow}</span>
        {influencerAccount.Bio.length > 100 && renderBioActionBtn()}
      </>
    );
  };

  const handleClose = () => {
    closeViewProfileDialog(false);
  };

  return (
    <Dialog
      open={viewProfileDialog}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <CardMedia
              component="img"
              image={
                influencerAccount["Profile_picture"]
                  ? influencerAccount["Profile_picture"]
                  : influencerAccount["photoUrl"]
              }
              alt={
                influencerAccount["Name"]
                  ? influencerAccount["Name"]
                  : additionalDetail["name"]
              }
              onError={(e) => {
                e.target.src = PROFILE_IMG;
              }}
            />
          </Grid>
          <Grid item xs={9} className={styles.profileHeading}>
            {influencerAccount["Name"]
              ? influencerAccount["Name"]
              : additionalDetail["name"]}
            <br />
            <span className={styles.username}>
              <a
                href={
                  influencerAccount["Profile_Link"]
                    ? influencerAccount["Profile_Link"]
                    : influencerAccount.profileurl
                }
                target="_blank"
              >
                @{" "}
                {influencerAccount["Username"]
                  ? influencerAccount["Username"]
                  : additionalDetail["username"]}
              </a>
            </span>
            <br />
            <span className={styles.location}>
              <LocationOn />{" "}
              {influencerAccount["Location"] === "0" ||
              influencerAccount["location"] === "0"
                ? "-"
                : influencerAccount["Location"]}
            </span>
          </Grid>
          <Grid item xs={1}>
            <Typography sx={textColor[platform?.toLowerCase()]}>
              <a
                href={
                  influencerAccount["Profile_Link"]
                    ? influencerAccount["Profile_Link"]
                    : additionalDetail.profileurl
                }
                target="_blank"
              >
                {" "}
                {snapshotHeaderIcons[platform?.toLowerCase()]}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div>{renderBio()}</div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        {/* <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        > */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <h3 className={styles.cardHeading}>Total Followers</h3>
                <h4 className={styles.cardBody}>
                  {influencerAccount["Following"]
                    ? utils.convertToInternationalNumberSystem(
                        influencerAccount["Following"]
                      )
                    : utils.convertToInternationalNumberSystem(
                        influencerAccount["followers"]
                      )}
                </h4>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <h3 className={styles.cardHeading}>Total Friends</h3>
                <h4 className={styles.cardBody}>
                  {influencerAccount["Friends"]
                    ? utils.convertToInternationalNumberSystem(
                        additionalDetail.friends_count
                      )
                    : utils.convertToInternationalNumberSystem(0)}
                </h4>
              </CardContent>
            </Card>
          </Grid>
          {engagementDisplay && (
            <>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <h3 className={styles.cardHeading}>Engagement Achieved</h3>
                    <h4 className={styles.cardBody}>
                      {influencerAccount["Engagement"]
                        ? utils.convertToInternationalNumberSystem(
                            influencerAccount["Engagement"]
                          )
                        : utils.convertToInternationalNumberSystem(
                            influencerAccount.engagement
                          )}
                    </h4>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <h3 className={styles.cardHeading}>Enagagement Rate</h3>
                    <h4 className={styles.cardBody}>
                      {influencerAccount["Engagementrate"]
                        ? utils.convertToInternationalNumberSystem(
                            influencerAccount["Engagementrate"]
                          )
                        : utils.convertToInternationalNumberSystem(
                            influencerAccount.engagementrate
                          )}
                      %
                    </h4>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewProfileDialog.propTypes = {
  influencerAccount: object.isRequired,
  viewProfileDialog: bool.isRequired,
  closeViewProfileDialog: func.isRequired,
  platform: string.isRequired,
  additionalDetail: object.isRequired,
  engagementDisplay: bool,
};

export default ViewProfileDialog;
