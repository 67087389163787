import { PropTypes } from "prop-types";

import {
  Stack
} from "@mui/material";

import MyPaper from "../general/MyPaper";

import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../utils/tiktok-brands.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

// checkMe: move this to some common enums file
const socialIcons = {
  instagram: <InstagramIcon sx={{ color: "#BC2A8D" }} />,
  twitter: <TwitterIcon sx={{ color: "#00ACEE" }} />,
  tiktok: (
    <TikTokIcon
      style={{ width: "24px", height: "24px" }}
      sx={{ color: "#000000" }}
    />
  ),
  youtube: <YouTubeIcon sx={{ color: "#FF0100" }} />,
  linkedin: <LinkedInIcon sx={{ color: "#0a66c2" }} />,
  facebook: <FacebookIcon sx={{ color: "#1b74e4" }} />,
};

const ProfileEngagement = ({ engagement, avgEngagement, platformsEngagement }) => {

  const getPlatformWiseEngagement = () => {
    if (!platformsEngagement) {
      return;
    }
    return platformsEngagement.map((platformEngagement) => {
      const platformName = platformEngagement.platform;
      const platformEng = platformEngagement.engagement;
      return (
        <Stack direction="column" style={{padding: "10px"}}>
          <span className="graphicalDetails_platformEngagement">{platformEng}</span>
          <span className="graphicalDetails_platformIcon">{socialIcons[platformName]}</span> {/* checkMe: get platform icon here */}
        </Stack>
      )
    })
  }

  return(
    <MyPaper sx={{height: "100%"}}>
      <Stack spacing={2}>
        <Stack 
          id="ProfileEngagement_title"
          direction="row" justifyContent="center"
        >
          <span className="graphicalDetails_title">ENGAGEMENT</span>
        </Stack>
        <Stack 
          id="ProfileEngagement_score"
          direction="row" justifyContent="center"
        >
          <span className="graphicalDetails_highlightedText">{engagement} % <ReportProblemIcon color="report"/><sub className="graphicalDetails_subtext">vs {avgEngagement}% average</sub></span>
        </Stack>
        <Stack 
          id="ProfileEngagement_platforms"
          direction="row" justifyContent="center"
        >
          {getPlatformWiseEngagement()}
        </Stack>
      </Stack>
    </MyPaper>
  );
}

export default ProfileEngagement;

ProfileEngagement.propTypes = {
  engagement: PropTypes.number.isRequired,
  avgEngagement: PropTypes.number.isRequired,
  platformsEngagement: PropTypes.array.isRequired,
}