import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datatables from "mui-datatables";
import axios from "axios";
import { API_HOST } from "../../config/main.json";
import DraftEditor from "../../components/general/DraftEditor";
import { Close, Download, Email, InfoOutlined, NotificationsActive, Preview } from "@mui/icons-material";
import utils from "../../utils/utils";
import FileSaver from "file-saver";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { downloadContractFromAPI, remindContract, resendContract } from "../../services/influencer/InfluencerContractDetails";
import slides from "../../common/googleSlides.png";
import GeneratePptReport from "../../components/generatePpt/GeneratePptReport";
import moment from "moment";
import CampaignActions from "./CampaignActions";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";
import Loader from "./Loader";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Contracts() {
  const [cid, setCid] = useState(0);
  const [contractList, setContractList] = useState([]);
  const [previewContent, setPreviewContent] = useState(false);
  const [previewModalType, setPreviewModalType] = useState("accepted");
  const [previewModalContent, setPreviewModalContent] = useState(null);
  const [contractInputFieldsData, setContractInputFieldsData] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [responses, setResponses] = useState([]);
  const [acceptedOn, setAcceptedOn] = useState("");
  const [place, setPlace] = useState("");
  const [kycRequired, setKycRequired] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [templateCount, setTemplateCount] = useState(0);
  const [tabArray, setTabArray] = useState([]);
  const [loding, setLoding] = useState(true);

  const dispatch = useDispatch();

  const [pptReportId, setPptReportId] = useState(0);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    const tabArr = campaignTabsArray(campId);
    setCid(campId);
    setTabArray(tabArr);
    getContractList(campId);
    getTemplates(campId);
    getContractInputFields();
    getCampaignInfo(campId);
  }, []);

  const getContractList = (id) => {
    axios.get(API_HOST + "/contract/list/" + id).then((res) => {
      if (res) {
        setLoding(false)
      }
      setContractList(res.data || []);
    });
  };

  const getTemplates = (id) => {
    axios.get(API_HOST + "/contract/templates/" + id).then((res) => {
      const templateCount = res.data?.length || 0;
      setTemplateCount(templateCount);
    });
  };

  const getContractInputFields = () => {
    axios.get(API_HOST + "/contract/inputs").then((res) => {
      setContractInputFieldsData(res.data);
    });
  };

  const getCampaignInfo = (id) => {
    utils.getCampaignData(id).then((data) => {
      setCampaignName(data.name);
    });
  };

  const handleContractPreview = (id, status) => {
    axios.get(API_HOST + "/contract/content/" + id).then((res) => {
      if (status === "Accepted") {
        const place = res.data[0].place;
        const acceptedOn = moment(res.data[0].acceptedOn).format("M/D/YYYY, h:mm:ss A");
        const kycRequired = res.data[0].kycRequired;
        setPlace(place);
        setAcceptedOn(acceptedOn);
        setKycRequired(kycRequired);
        axios.get(API_HOST + "/contract/responses/" + id).then((res) => {
          const responses = res.data !== null ? res.data : [];
          setResponses(responses);
          setPreviewModalType("accepted");
        });
      } else {
        const autocompleteData = contractInputFieldsData.filter((value) => {
          const inputFields = res.data[0].inputFields.split(",");
          if (inputFields.length > 0) {
            return inputFields.map((v) => +v).includes(value.id);
          }
          return [];
        });
        const kycRequired = res.data[0].kycRequired;
        setKycRequired(kycRequired);
        setAutocompleteData(autocompleteData);
        setPreviewModalType("other");
      }
      setPreviewModalContent(res.data);
      setPreviewContent(true);
    });
  };

  const handleResendContract = async (id) => {
    const response = await resendContract(id);
    if (response.status === "success") {
      dispatch(
        openSnackbar({
          message: "Contract sent successfully",
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: "Error occurred while sending the contract!",
          severity: "error",
        })
      );
    }
  };

  const downloadContract = async (id) => {
    dispatch(
      openSnackbar({
        message: "Please wait... we are fetching the contract",
        severity: "info",
      })
    );
    const response = await downloadContractFromAPI(id);
    if (response === null) {
      dispatch(
        openSnackbar({
          message: "Error occurred while downloading the contract!",
          severity: "error",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: "Contract downloaded successfully",
          severity: "success",
        })
      );
      await FileSaver(response, `${id}.pdf`);
    }
  };

  const handleRemindContract = async (id) => {
    const response = await remindContract(id);
    if (response.status === "success") {
      dispatch(
        openSnackbar({
          message: "Contract reminder sent successfully",
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: "Error occurred while sending the reminder!",
          severity: "error",
        })
      );
    }
  };

  let tableData = [];
  if (contractList !== null) {
    tableData = contractList.map((contract, index) => {
      const data = { ...contract };
      if (contract.accepted) {
        data["status"] = "Accepted";
      } else {
        if (contract.timeDiff < 0) {
          data["status"] = "Invite Expired";
        } else {
          if (contract.timeDiff >= 24) {
            const days = Math.floor(contract.timeDiff / 24);
            if (days === 1) data["status"] = "Pending (" + days + " day left)";
            else data["status"] = "Pending (" + days + " days left)";
          } else {
            const hours = contract.timeDiff;
            if (hours === 1) data["status"] = "Pending (" + hours + " hour left)";
            else data["status"] = "Pending (" + hours + " hours left)";
          }
        }
      }

      data["influencer"] = {
        name: contract.infName,
        email: contract.email,
      };

      return data;
    });
  }

  const columns = [
    {
      label: "Influencer",
      name: "influencer",
      options: {
        download: false,
        filter: false,
        customBodyRender: (value, tableMeta, updatedValue) => {
          return (
            <div>
              <IconButton>
                <Tooltip title={<span style={{ whiteSpace: "pre-line" }}>Email: {value.email}</span>}>
                  <InfoOutlined style={{ color: "#6b778c" }} />
                </Tooltip>
              </IconButton>
              {value.name}
            </div>
          );
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            return obj1.data.name.localeCompare(obj2.data.name) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      label: "Name",
      name: "infName",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      label: "Template",
      name: "title",
    },
    {
      label: "Sent On",
      name: "createdAt",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("ddd MMM DD YYYY");
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const d1 = moment(obj1.data).valueOf();
            const d2 = moment(obj2.data).valueOf();
            return (d1 - d2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const acceptedOn = tableMeta.rowData[6];
          return acceptedOn === "" ? (
            <span>{value}</span>
          ) : (
            <span>
              {value}{" "}
              <Tooltip title={"Accepted on " + moment(acceptedOn).format("ddd MMM DD YYYY")}>
                <InfoOutlined fontSize="medium" />
              </Tooltip>
            </span>
          );
        },
      },
    },
    {
      label: "Accepted Date",
      name: "acceptedOn",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      label: "Actions",
      name: "contractId",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const contractId = value;
          const status = tableMeta.rowData[5];

          return (
            <div>
              <IconButton onClick={(event) => handleContractPreview(contractId, status)}>
                <Tooltip title={"Preview"}>
                  <Preview fontSize="small" />
                </Tooltip>
              </IconButton>
              {status === "Accepted" && (
                <>
                  <IconButton onClick={(event) => handleResendContract(contractId)}>
                    <Tooltip title={"Resend Contract"}>
                      <Email fontSize="small" />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={(event) => downloadContract(contractId)}>
                    <Tooltip title={"Download Contract"}>
                      <Download fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </>
              )}
              {status.startsWith("Pending") && (
                <IconButton onClick={(event) => handleRemindContract(contractId)}>
                  <Tooltip title={"Send Reminder"}>
                    <NotificationsActive fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    download: false,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
  };

  const exportCSV = () => {
    const iids = [];
    for (let i = 0; i < contractList.length; i++) {
      iids.push(contractList[i].iid);
    }
    const uniqueIids = iids.filter((value, index, self) => self.indexOf(value) === index);

    const data = {
      ids: uniqueIids.join(","),
    };

    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios.post(API_HOST + "/contract/influencers/paymentDetails", data, optionAxios).then((res) => {
      const paymentDetails = res.data.paymentDetails !== null ? res.data.paymentDetails : [];
      const csvHeader = [
        "Name",
        "List",
        "Template Name",
        "Sent On",
        "Status",
        "Age",
        "Document/Id Number",
        "Currency",
        "Payment Type",
        "Payment Name",
        "Account Number",
        "Bank Name",
        "Branch Name",
        "Swift",
        "Country",
        "Phone Number",
        "Card Number",
        "Card Name",
      ];
      let csvRes = csvHeader.join(",") + "\n";

      tableData.forEach((row) => {
        let paymentData = {};

        for (let i = 0; i < paymentDetails.length; i++) {
          if (row.iid === paymentDetails[i].iid) {
            paymentData = paymentDetails[i];
            break;
          }
        }

        let rowData = [];
        rowData.push(row.infName);
        rowData.push(row.listName);
        rowData.push(row.title);
        rowData.push(row.sentOn);
        rowData.push(row.status);
        rowData.push(paymentData.age !== 0 ? paymentData.age : "");
        rowData.push(paymentData.documentId ? `=TRIM("` + paymentData.documentId + `")` : "");
        rowData.push(paymentData.Currency || "");
        rowData.push(paymentData.paymentType || "");
        rowData.push(paymentData.paymentName || "");
        rowData.push(paymentData.accountNumber ? `=TRIM("` + paymentData.accountNumber + `")` : "");
        rowData.push(paymentData.bankName || "");
        rowData.push(paymentData.branchName || "");
        rowData.push(paymentData.swift || "");
        rowData.push(paymentData.country || "");
        rowData.push(paymentData.phoneNumber ? `=TRIM("` + paymentData.phoneNumber + `")` : "");
        rowData.push(paymentData.cardNumber ? `=TRIM("` + paymentData.cardNumber + `")` : "");
        rowData.push(paymentData.cardName || "");

        csvRes += rowData.join(",") + "\n";
      });

      const blob = new Blob([csvRes], { type: "text/csv" });
      FileSaver(blob, campaignName + "_Payment Details");
    });
  };

  const tabArray2 = [
    {
      label: "Contracts",
      id: "contracts-tab-0",
      to: `/campaigns/contracts?id=${cid}`,
      component: Link,
    },
    {
      label: "Contract Templates",
      id: "contract-templates-tab-0",
      to: `/campaigns/contracts/templates?id=${cid}`,
      component: Link,
    },
  ]

  return (
    <>
      <CampaignActions name={campaignName} id={cid} />
      <br />
      <CustomTabs tabArray={tabArray} selectedTab={"5"} />

      <Box m={3}>
        <CustomTabs tabArray={tabArray2} selectedTab={"0"} />
        <Stack direction="row" justifyContent="end">
          <Tooltip title={templateCount === 0 ? "No contracts exist!" : ""}>
            <div>
              <Button
                variant="contained"
                style={{ marginRight: "30px" }}
                onClick={exportCSV}
                disabled={contractList.length === 0}
              >
                Export CSV
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={templateCount === 0 ? "Please create a template first" : ""}>
            <div>
              <Button
                variant="contained"
                component={Link}
                to={`../campaigns/contracts/send?id=${cid}`}
                disabled={templateCount === 0}
              >
                Send Contract
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Box>
      {loding ? (
        <Loader />
      ) : (
        <Datatables data={tableData} columns={columns} options={options} />
      )}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-preview_contract-label"
        aria-describedby="modal-preview_contract-description"
        open={previewContent}
        onClose={() => setPreviewContent(false)}
        closeAfterTransition
      >
        <Fade in={previewContent}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-preview_contract-heading" className="styles_modal_heading">
              <h2 id="modal-preview_contract-heading-text" style={{ padding: "10px" }}>
                {previewModalContent && previewModalContent[0].name + " - " + previewModalContent[0].title}
              </h2>
              <IconButton onClick={() => setPreviewContent(false)}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-preview_contract-body" style={{ padding: "10px" }}>
              {previewModalType === "accepted" && (
                <Grid item xs={12} className="hide-toolbar-accepted">
                  <hr style={{ margin: "0" }} />
                  <DraftEditor value={previewModalContent && previewModalContent[0].content} readOnly={true} />
                </Grid>
              )}
              {previewModalType === "other" && (
                <Grid item xs={12} className="hide-toolbar">
                  <hr style={{ margin: "0" }} />
                  <DraftEditor value={previewModalContent && previewModalContent[0].content} readOnly={true} />
                </Grid>
              )}
              <br />
              <Grid container spacing={2}>
                {previewModalType === "accepted" &&
                  responses.map((response) => {
                    const label = contractInputFieldsData[response.responseId - 1].input;
                    const value = response.response;
                    return (
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth inputProps={{ readOnly: true }} value={value} label={label} />
                      </Grid>
                    );
                  })}
                {previewModalType === "accepted" && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField fullWidth inputProps={{ readOnly: true }} value={acceptedOn} label="Acceptence Time" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField fullWidth inputProps={{ readOnly: true }} value={place} label="Place" />
                    </Grid>
                  </>
                )}
                {previewModalType === "other" && (
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      multiple
                      readOnly
                      id="checkboxes-tags-demo"
                      options={contractInputFieldsData}
                      getOptionLabel={(option) => option.input}
                      value={autocompleteData}
                      freeSolo
                      disabled={autocompleteData.length === 0}
                      renderInput={(params) => (
                        <>
                          {autocompleteData.length > 0 && <TextField {...params} label="Input Fields" />}
                          {autocompleteData.length === 0 && <TextField {...params} label="Input Fields (None Selected)" />}
                        </>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <FormControlLabel label="Mandatory KYC" control={<Checkbox checked={kycRequired} />} />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default Contracts;
