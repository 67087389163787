import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";

import {
  Grid,
  Stack,
} from "@mui/material";

import enums from "./enums";
import ProfileTab_Overview_Insights from "./ProfileTab_Overview_Insights";
import ProfileTab_Overview_FollowersCredibility from "./ProfileTab_Overview_FollowersCredibility";
import ProfileTab_Overview_BrandAffinity from "./ProfileTab_Overview_BrandAffinity";
import ProfileListsListing from "./ProfileListsListing";
import ProfileTimeline from "./ProfileTimeline";
import ProfileStringsListing from "./ProfileStringsListing";

import MyPaper from "../general/MyPaper";

const ProfileTab_Overview = ({ overviewDetails }) => {
  
  const [displayOptions, setDisplayOptions] = useState({});

  const [insightsDetails, setInsightsDetails] = useState({});
  const [followersCredibilityDetails, setFollowersCredibilityDetails] = useState({});
  const [brandAffinityDetails, setBrandAffinityDetails] = useState({});
  const [interestsDetails, setInterestsDetails] = useState([]);
  const [postTopicsDetails, setPostTopicsDetails] = useState([]);
  const [educationsDetails, setEducationsDetails] = useState([]);
  const [experiencesDetails, setExperiencesDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [groupsDetails, setGroupsDetails] = useState([]);

  // checkMe: update, when required
  // useEffect(() => {
  //   switch(platform) {
  //     case "linkedin":
  //       break;
  //     case "instagram":
  //       break;
  //     case "facebook":
  //       break;
  //     case "twitter":
  //       break;
  //     case "youtube":
  //       break;
  //     case "tiktok":
  //       break;
  //   }
  // }, [platform]);

  useEffect(() => {
    // remove later, move to the previous useEffect()
    const options = enums.displayOptions;
    options.educations = true;
    options.experiences = true;
    options.companies = true;
    options.groups = true;
    options.insights = false;
    options.followersCredibility = false;
    options.brandAffinity = false;
    options.interests = false;
    options.postTopics = false;
    setDisplayOptions(options);

    if (options.insights) {
      const insight = overviewDetails.insight;
      setInsightsDetails(insight);
    }
    if (options.followersCredibility) {
      const followersCredibility = overviewDetails.followersCredibility;
      setFollowersCredibilityDetails(followersCredibility);
    }
    if (options.brandAffinity) {
      const brandAffinity = overviewDetails.brandAffinity;
      setBrandAffinityDetails(brandAffinity);
    }
    if (options.interests) {
      const interests = overviewDetails.interests;
      setInterestsDetails(interests);
    }
    if (options.postTopics) {
      const postTopics = overviewDetails.postTopics;
      setPostTopicsDetails(postTopics);
    }
    if (options.educations) {
      const educations = overviewDetails.educations;
      setEducationsDetails(educations);
    }
    if (options.experiences) {
      const experiences = overviewDetails.experiences;
      setExperiencesDetails(experiences);
    }
    if (options.companies) {
      const companies = overviewDetails.companies;
      setCompanyDetails(companies);
    }
    if (options.groups) { 
      const groups = overviewDetails.groups;
      setGroupsDetails(groups);
    }
  }, [overviewDetails]);

  return (
    <div id="ProfileTab_Overview">
      <Grid container spacing={2} columns={{xs:3, sm:6, md:12}}>
        {
          displayOptions.experiences &&
          experiencesDetails &&
          <Grid item xs={3} sm={4} md={8}>
            <ProfileTimeline
              listName={"WORK EXPERIENCE"}
              listData={experiencesDetails}
            />
          </Grid>
        }
        {
          ((displayOptions.educations && educationsDetails && companyDetails.length > 0) ||
          (displayOptions.insights && insightsDetails && educationsDetails.length > 0)) &&
          <Grid item xs={1} sm={2} md={4}>
            <MyPaper sx={{height: "100%"}}>
              <Grid container direction="row" spacing={2} columns={{xs:3}} alignItems="stretch">
                {
                  displayOptions.companies &&
                  companyDetails &&
                  companyDetails.length>0 &&
                  <Grid item xs={3}>
                    <ProfileListsListing
                      listName={"COMPANIES WORKED AT"}
                      listData={companyDetails}
                    />
                  </Grid>
                }
                {
                  displayOptions.educations &&
                  educationsDetails &&
                  educationsDetails.length>0 &&
                  <Grid item xs={3}>
                    <ProfileTimeline
                      listName={"EDUCATION"}
                      listData={educationsDetails}
                    />
                  </Grid>
                }
              </Grid>
            </MyPaper>
          </Grid>
        }
        {
          displayOptions.insights &&
          insightsDetails &&
          <Grid item xs={1} sm={2} md={4}>
            <ProfileTab_Overview_Insights
              insightsDetails={insightsDetails}
            />
          </Grid>
        }
        {
          displayOptions.followersCredibility &&
          followersCredibilityDetails &&
          <Grid item xs={1} sm={2} md={4}>
            <ProfileTab_Overview_FollowersCredibility
              followersCredibilityDetails={followersCredibilityDetails}
            />
          </Grid>
        }
        {
          displayOptions.brandAffinity &&
          brandAffinityDetails &&
          <Grid item xs={1} sm={2} md={4}>
            <ProfileTab_Overview_BrandAffinity
              brandAffinityDetails={brandAffinityDetails}
            />
          </Grid>
        }
        {
          displayOptions.interests &&
          interestsDetails &&
          <Grid item xs={1} sm={2} md={4}>
            <ProfileListsListing
              listName={"INFLUENCER INTEREST"}
              listData={interestsDetails}
            />
          </Grid>
        }
        {
          displayOptions.postTopics &&
          postTopicsDetails &&
          <Grid item xs={1} sm={2} md={4}>
            <ProfileListsListing
              listName={"POST TOPICS"}
              listData={postTopicsDetails}
            />
          </Grid>
        }
        {
          displayOptions.groups &&
          groupsDetails &&
          groupsDetails.length>0 &&
          <Grid item xs={1} sm={2} md={4}>
            <ProfileListsListing
              listName={"GROUPS"}
              listData={groupsDetails}
            />
          </Grid>
        }
      </Grid>
    </div>
  )
}

export default ProfileTab_Overview;

ProfileTab_Overview.propTypes = {
  overviewDetails: PropTypes.object.isRequired
}