import { PropTypes } from "prop-types";

const ProfileTab_Audience = ({ audienceDetails }) => {

  return (
    <></>
  );
}

export default ProfileTab_Audience;

ProfileTab_Audience.propTypes = {
  audienceDetails: PropTypes.object.isRequired
}