import { useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import {
  Button,
  Grid,
  Stack,
} from "@mui/material";

import { Favorite, Badge, Add, LocalOffer, LocationOn } from "@mui/icons-material";

import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../utils/tiktok-brands.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

import { snapshotHeaderIcons } from "../campaignAnalysis/snapshot";
import utils from "../../utils/utils";
import MyPaper from "../general/MyPaper";
import "./styles_profile.css";

// checkMe: move this to some common enums file
const socialIcons = {
  instagram: <InstagramIcon sx={{ color: "#BC2A8D" }} />,
  twitter: <TwitterIcon sx={{ color: "#00ACEE" }} />,
  tiktok: (
    <TikTokIcon
      style={{ width: "24px", height: "24px" }}
      sx={{ color: "#000000" }}
    />
  ),
  youtube: <YouTubeIcon sx={{ color: "#FF0100" }} />,
  linkedin: <LinkedInIcon sx={{ color: "#0a66c2" }} fontSize="large" />,
  facebook: <FacebookIcon sx={{ color: "#1b74e4" }} />,
};

// basicDetails: { iid, name, photoUrl, platforms, location, tags, bio, influencerScore }
const ProfileBasicDetails = ({ basicDetails, actionBtnClicked }) => {

  const [fullBioShown, setFullBioShown] = useState(false);
  const [bioToShow, setBioToShow] = useState("");

  useEffect(() => {
    const bio = basicDetails.bio;
    if (!bio) {
      return;
    }
    let displayBio = bio;
    let displayFullBio = true;

    if (displayBio.length > 200) {
      displayBio = bio.slice(0, 197) + "...";
      displayFullBio = false;
    }

    setFullBioShown(displayFullBio);
    setBioToShow(displayBio);
  }, [basicDetails?.bio]);

  const renderName = () => {
    if (basicDetails.name) {
      return (basicDetails.name);
    }
    return "";
  }

  const renderPlatforms = () => {
    if (!basicDetails.platforms) {
      return;
    }
    return basicDetails.platforms.map((platform) => {
      return (
        <span key={"platformSelection-"+platform.name}>
          <label
            className="platform-icon"
            style={{marginLeft: "20px", paddingBottom: "20px"}}
            // onClick={() => )}
          >
            <a href={basicDetails.profileLink} target="_blank">
            {socialIcons[platform.name]}
            </a>
          </label>
        </span>
        
      );
    });
  }

  const renderLocation = () => {
    return (
      <>
        <span id="basicDetails_locationIcon"><LocationOn /></span>
        <span id="basicDetails_location">{basicDetails.location}</span>
        {/* checkMe - get location icon here */}
        <span id="basicDetails_countryFlag"></span>
      </>
    )
  }

  const renderTags = () => {
    if (!basicDetails.tags || basicDetails.tags.length === 0) {
      return;
    }
    const tagsForDisplay = basicDetails.tags.join(", ");
    return (
      <Stack direction={"row"} spacing={2}>
        <span id="basicDetails_tagsIcon"><Favorite /></span>
        <span id="basicDetails_tags">{tagsForDisplay}</span>
      </Stack>
    )
  }

  const handleBioActionBtnClick = (showFullBio) => {
    if (showFullBio===fullBioShown) {
      return;
    }
    setFullBioShown(showFullBio);
    let bioToDisplay = basicDetails.bio;
    if(!showFullBio) {
      bioToDisplay = bioToDisplay.slice(0, 197) + "...";
    }
    setBioToShow(bioToDisplay);
  }

  const renderBioActionBtn = () => {
    if(fullBioShown) {
      return (
        <Button 
          onClick={() => handleBioActionBtnClick(false)}
        >
          Show less
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handleBioActionBtnClick(true)}
        >
          Show more
        </Button>
      )
    }
  }
  const renderBio = () => {
    if (!bioToShow) {
      return;
    }

    return (
      <Stack direction={"row"} spacing={2}>
        <span id="basicDetails_bioIcon"><Badge /></span>
        <span id="basicDetails_bio">
          {bioToShow}
          {
            basicDetails.bio.length>200 &&
            renderBioActionBtn()
          }
        </span>
      </Stack>
      // <Grid container spacing={2} columns={{xs:12, sm:12, md:12}}>
      //   <Grid item xs={1} sm={1} md={1}>
      //     <span id="basicDetails_bioIcon"><Badge /></span>
      //   </Grid>
      //   <Grid item xs={11} sm={11} md={11}>
      //     <span id="basicDetails_bio">{bioToShow}</span>
      //     {
      //       basicDetails.bio.length>200 &&
      //       renderBioActionBtn()
      //     }
      //   </Grid>
      // </Grid>
    )
  }

  const addInfToList = () => {
    actionBtnClicked("addInfToList");
  }

  const updateTags = () => {
    actionBtnClicked("updateTags");
  }

  return (
    <div id="ProfileBasicDetails">
      <MyPaper>
        <Grid container spacing={2} columns={{xs:3, sm:6, md:12}}>
          <Grid item xs={1} sm={2} md={4}>
            <img src={basicDetails.photoUrl} onError={utils.addDefaultSrc} style={{width: "100%", height: "auto", maxHeight: "300px", objectFit: "contain", float: "left"}} />
          </Grid>
          <Grid item xs={2} sm={4} md={8}>
            <Grid container spacing={2} columns={{xs:4, sm:6, md:12}}>
              <Grid item xs={4} sm={6} md={12}>
                <Stack direction="row" justifyContent="space-between">
                  <div id="basicDetails_name_platfor_div">
                    <span id="basicDetails_name">{renderName()}{renderPlatforms()}</span>
                  </div>
                  <div id="basicDetails_location_div">{renderLocation()}</div>
                </Stack>
              </Grid>
              {/* <Grid item xs={4} sm={6} md={12}>
                <div id="basicDetails_tags_div">{renderTags()}</div>
              </Grid> */}
              <Grid item xs={4} sm={6} md={12}>
                <div id="basicDetails_bio_div">{renderBio()}</div>
              </Grid>
              <Grid item xs={4} sm={6} md={12}>
                <div id="basicDetails_actionBtns_div">
                  <Button 
                    className="basicDetails_actionBtn"
                    onClick={addInfToList}
                    startIcon={<Add />}
                    variant="outlined"
                  >
                    Add To List
                  </Button>
                  {/* <Button 
                    className="basicDetails_actionBtn"
                    onClick={updateTags}
                    startIcon={<LocalOffer />}
                    variant="outlined"
                  >
                    Tags
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MyPaper>
    </div>
  )
}

export default ProfileBasicDetails;


ProfileBasicDetails.propTypes = { 
  basicDetails: PropTypes.object.isRequired,
  actionBtnClicked: PropTypes.func.isRequired,
}