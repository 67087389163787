import { useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";

import MyPaper from "../general/MyPaper";
import enums from "./enums";

const ProfileListsListing = ({ listName, listData }) => {

  const [viewDialog, setViewDialog] = useState(false);
  const [enableShowMoreBtn, setEnableShowMoreBtm] = useState(false);

  useEffect(() => {
    setEnableShowMoreBtm(listData.length > enums.maxItemsInListView);
  }, [listData]);

  const getLists = (all) => {
    if (!listData || listData.length===0) {
      return;
    }
    let listDataToDisplay = listData;
    if (!all && listDataToDisplay.length>enums.maxItemsInListView) {
      listDataToDisplay = listData.slice(0, enums.maxItemsInListView);
    }


    return listDataToDisplay.map((listItem, index) => {
      const id = listName + "-" + index;
      return (
        <span className="list-item" id={id}>{listItem}</span>
      );
    });
  }

  const handleDialogOpen = () => {
    setViewDialog(true);
  }

  const handleDialogClose = () => {
    setViewDialog(false);
  }

  return (
    <>
      <MyPaper sx={{height: "100%"}}>
        <div id="ProfileListsListing">
          <Stack spacing={2} justifyContent={"start"}>
            <span className="list-title">{listName}</span>
            {getLists(false)}
            <Button className="seeMore-btn" hidden={!enableShowMoreBtn} onClick={handleDialogOpen}>see more...</Button>
          </Stack>
        </div>
      </MyPaper>
      <Dialog
        open={viewDialog}
        onClose={handleDialogClose}
      >
        <DialogContent>
          <Stack spacing={2} justifyContent={"start"}>
            <span className="list-title">{listName}</span>
            {getLists(true)}
          </Stack>
        </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDialogClose}>
          Close
        </Button>
      </DialogActions>
      </Dialog>
    </>
  );

}

export default ProfileListsListing;

ProfileListsListing.propTypes = {
  listName: PropTypes.string.isRequired,
  listData: PropTypes.array.isRequired,
}