import React, { useState, useEffect, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import utils from "../../utils/utils";
import MyPaper from "../general/MyPaper";
import {
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { BarChart } from "../../common/chart";

const colorList = {
  instagram: "rgb(188, 42, 141)",
  twitter: "#00ACEE",
  x: "#00ACEE",
  tiktok: "#000000",
  youtube: "#FF0100",
  linkedin: "#0a66c2",
  facebook: "#1b74e4",
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = String(date.getFullYear()).slice(-2);
  return `${day} ${month} ${year}`;
};

const TotalEngagementAnalysis = (props) => {
  const chartRef = useRef();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    fetchInitialData();
  }, [
    props.campId,
    props.startDate,
    props.endDate,
    props.filterBy,
    props.selectedPlatform,
    props.selectedListIds,
    props.selectedInfluencersIds,
  ]);

  const fetchInitialData = async () => {
    await fetchTotalEngagementData(
      props.campId,
      props.startDate,
      props.endDate,
      props.filterBy,
      props.selectedPlatform,
      props.selectedListIds,
      props.selectedInfluencersIds
    );
  };

  const fetchTotalEngagementData = async (
    campId,
    startDate,
    endDate,
    filterBy,
    platformId,
    listIds,
    influencerIds
  ) => {
    if (listIds.length === 0) listIds = [-1];
    if (influencerIds.length === 0) influencerIds = [-1];
    setLoading(true);

    try {
      const data = await utils.getTotalEngagementData(
        campId,
        startDate,
        endDate,
        filterBy,
        platformId,
        [-1],
        listIds,
        influencerIds
      );
      const processedData = processData(data);
      setData(processedData);
    } catch (error) {
      console.error("Failed to fetch data", error);
    } finally {
      setLoading(false);
    }
  };

  const processData = (data) => {
    if (!data || !data.Stats) return { labels: [], datasets: [] };

    if (props.selectedPlatformName === "all") {
      let allListsDataSet = [];
      let allListsLabels = [];
      let allKeys = [];
      let platforms = Object.keys(data.Stats || {});
      platforms.forEach((platform) => {
        let dataset = data.Stats[platform][props.filterBy];
        let keys = Object.keys(dataset || {});
        keys.forEach((key) => {
          if (!allKeys.includes(key)) allKeys.push(key);
        });
      });
      allKeys.sort();
      platforms.forEach((platform) => {
        let bar = createBarData(platform, data.Stats[platform][props.filterBy], allKeys);
        allListsDataSet.push(bar);
      });
      return {
        labels: allKeys.map(formatDate), 
        datasets: allListsDataSet,
      };
    } else {
      let platformData = data.Stats[props.selectedPlatformName][props.filterBy];
      let labels = Object.keys(platformData || {});
      let bar = createBarData(props.selectedPlatformName, platformData, labels);
      return {
        labels: labels.map(formatDate), 
        datasets: [bar],
      };
    }
  };

  const createBarData = (platform, dataset, labels) => {
    let engagementData = labels.map((key) => dataset[key]?.engagement || 0);
    return {
      type: "bar",
      label: utils.capitalizeFirstLetter(platform),
      data: engagementData,
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: colorList[platform.toLowerCase()] || "#ddd",
      order: 2,
      maxBarThickness: 20,
      borderRadius: labels.length > 5 ? [5, 5, 0, 0]: 0,
    };
  };

  const prepareOption = () => {
    const { labels, datasets } = data;
    
    const filteredDatasets = datasets.filter(dataset => 
      dataset.data.some(value => value > 0)
    );
  
    const seriesData = filteredDatasets.map(dataset => ({
      name: dataset.label,
      type: 'bar',
      data: dataset.data,
      itemStyle: {
        color: colorList[dataset.label.toLowerCase()] || '#ddd',
        borderRadius: dataset.data.length > 5 ? [5, 5, 0, 0] : 0,
      }
    }));
  
    return BarChart(labels, true, seriesData, filteredDatasets.map(dataset => dataset.label), ["line", "bar", "stack"]);
  };

  return (
    <MyPaper sx={{ marginTop: "20px", boxShadow: "none" }}>
      {loading ? (
        <>
          <CircularProgress
            style={{
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            color="success"
          />
          <div
            style={{
              ...props.parentStyle.centerElement,
              ...props.parentStyle.fetchingDetails,
            }}
          >
            Fetching details...
          </div>
        </>
      ) : (
        <>
          <Stack direction="row" justifyContent="end">
            <Typography
              variant="h4"
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#1E2022",
                alignSelf: "flex-start",
                marginRight: "auto",
                display: "flex",
              }}
            >
              Overall Engagement By Platform
            </Typography>
          </Stack>
          <div style={{ height: "50vh", width: "100%", marginTop:'2vw' }}>
            {data.labels.length > 0 ? (
              <ReactEcharts ref={chartRef} option={prepareOption()} style={{ height: "100%", width: "100%" }} />
            ) : (
              <p style={props.parentStyle.noData}>No Graph found</p>
            )}
          </div>
        </>
      )}
    </MyPaper>
  );
};

export default TotalEngagementAnalysis;
