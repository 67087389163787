import { useState, useEffect } from "react";

import PropTypes from 'prop-types';

import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Stack,
  FormControl,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import MyPaper from "../general/MyPaper";
import utils from "../../utils/utils";
import enums_discovery from "./enums_discovery";


const DiscoveryFilters = ({ defaultValues, primary_searchBtnClicked, primary_filterBtnClicked }) => {
  const [interests, setInterests] = useState([]);
  const [locations, setLocations] = useState([]);

  const [interestsSelected, setInterestsSelected] = useState([]);
  const [keywordsSelected, setKeywordsSelected] = useState([]);
  const [locationsSelected, setLocationsSelected] = useState([]);

  useEffect(() => {
    if (Object.keys(defaultValues).length === 0) {
      resetAllFilters();
    } else {
      restructureDefaultValues();
    }
    
  }, [defaultValues]);

  useEffect(() => {
    /* get interest and location from API */
    const interestsData = ["i1","i2","i3","i4"];
    setInterests(interestsData);
    utils.getDiscoveryLocation().then((data) => {
      setLocations(data);
    });
    utils.getDiscoveryLanguage().then((data) => {
      let enumData = enums_discovery.filterationData_influencer
      enumData['linkedin']['languages'] = data
    });
  }, []);

  const resetAllFilters = () => {
    setInterestsSelected([]);
    setKeywordsSelected([]);
    setLocationsSelected([]);
  }

  const setFilter = (filterName, filterValue) => {
    if (filterName==="Interest") {
      setInterestsSelected(filterValue);
    } else if (filterName === "Keyword") {
      setKeywordsSelected(filterValue);
    } else if (filterName === "Location") {
      setLocationsSelected(filterValue);
    }
  }

  const restructureDefaultValues = () => {
    const restructedDefaultValues = {};
    Object.keys(defaultValues).map((filterName) => {
      const values = defaultValues[filterName];
      if (values.length === 0) {
        setFilter(filterName, []);
        return;
      }
      const updatedValues = [];
      if (typeof values[0] === "object") {
        values.map((value) => {
          updatedValues.push(value);
        });
      } else {
        values.map((value) => {
          updatedValues.push(value);
        })
      }

      restructedDefaultValues[filterName] = updatedValues;
      setFilter(filterName, updatedValues);
    });
  }

  const handleInterestSelection = (selectedValues) => {
    setInterestsSelected(selectedValues);
  }

  const handleKeywordSelection = (selectedValues) => {
    setKeywordsSelected(selectedValues);
  }

  const handleLocationSelection = (selectedValues) => {
    setLocationsSelected(selectedValues);
  }

  const handleSearchBtnClick = () => {
    primary_searchBtnClicked(interestsSelected, keywordsSelected, locationsSelected);
  }

  const handleFilterBtnClick = () => {
    primary_filterBtnClicked();
  }

  return (
    <div id="primaryFilter">
      <MyPaper sx={{ marginBottom: "20px" }}>
        <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={1} columns={{xs: 4, md: 12}}>
              {/*<Grid item xs={4} md={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    multiple
                    limitTags={1}
                    id="primary_filter-interests"
                    options={interests}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    value={interestsSelected}
                    onChange={(_e, v) => handleInterestSelection(v)}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          maxRows={1}
                          label={"Interests"}
                        />
                      );
                    }}
                  />
                </FormControl> 
                  </Grid>*/}
              <Grid item xs={4} md={6}>
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={1}
                  id="primary_filter-keyword_or_phrase"
                  options={[]}
                  freeSolo
                  value={keywordsSelected}
                  onChange={(_e, v) => handleKeywordSelection(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Keyword or Phrase"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    multiple
                    limitTags={1}
                    id="primary_filter-locations"
                    options={locations}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    value={locationsSelected}
                    onChange={(_e, v) => handleLocationSelection(v)}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={"Locations"}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid> 
          </Grid>
          <Grid item xs={4} md={4}>
            <Stack direction="row" spacing={2} justifyContent="end">
              <Button variant="contained" size="large" onClick={() => handleSearchBtnClick()}>
                Search
              </Button>
              <Button variant="contained" size="large" startIcon={<FilterListIcon />} onClick={() => handleFilterBtnClick()}>
                Filter
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </MyPaper>
    </div>
  )
}

DiscoveryFilters.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  primary_searchBtnClicked: PropTypes.func.isRequired,
  primary_filterBtnClicked: PropTypes.func.isRequired,
}

export default DiscoveryFilters;