import React, { useEffect, useRef } from "react";
import $ from "jquery";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PropTypes from "prop-types";
import moment from "moment";

const FormDateRangeCustomPicker = ({
  sendSelectedDates,
  parentStartDate,
  parentEndDate,
  filterStatus = false,
  disabled = false,
  needRange = false,
}) => {
  const dateRangePickerRef = useRef(null);
  const [localStartDate, setLocalStartDate] = React.useState(moment(parentStartDate));
  const [localEndDate, setLocalEndDate] = React.useState(moment(parentEndDate));

  const handleCalendarChange = (start, end) => {
    if (disabled) return;
    const startDate = moment(start);
    const endDate = moment(end);
    sendSelectedDates({ startDate, endDate });
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  };

  useEffect(() => {
    // Update local state when parent state changes
    setLocalStartDate(moment(parentStartDate));
    setLocalEndDate(moment(parentEndDate));
    // Call updateDateRangePicker when local state changes
    updateDateRangePicker();
  }, [parentStartDate, parentEndDate]);

  useEffect(() => {
    // Initialize DateRangePicker when the component mounts
    initializeDateRangePicker();
  }, []); // empty dependency array to run it only once when mounted

  const updateDateRangePicker = () => {
    // Check if DateRangePicker has been initialized
    if (dateRangePickerRef.current) {
      // Destroy the existing DateRangePicker instance
      dateRangePickerRef.current.data("daterangepicker").remove();

      // Reinitialize the DateRangePicker
      initializeDateRangePicker();
    }
  };

  const initializeDateRangePicker = () => {
    // Initialize the DateRangePicker
    dateRangePickerRef.current = $(`.form-control`).daterangepicker(
      {
        startDate: localStartDate,
        endDate: localEndDate,
        // ... other settings
      },
      handleCalendarChange
    );

    // Optionally, you can set additional event listeners or configurations here
  };

  return (
    <DateRangePicker>
      <input
        style={{
          lineHeight: 1.6,
          minWidth: "7vw",
          height: "41px", // 55
          marginRight: "5px",
          borderColor: "#c0c0c0", 
        }}
        type="text"
        className="form-control"
        disabled={filterStatus}
      />
    </DateRangePicker>
  );
};

FormDateRangeCustomPicker.propTypes = {
  sendSelectedDates: PropTypes.func.isRequired,
  parentStartDate: PropTypes.string.isRequired,
  parentEndDate: PropTypes.string.isRequired,
};

export default FormDateRangeCustomPicker;
