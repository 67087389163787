/* for post review timeline */
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import PublishIcon from "@mui/icons-material/Publish";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Delete } from "@material-ui/icons";

const enums = {
  /* list of fileExtensions divided by file type for post attachments - please update when new fileExtension is observed */
  fileExtensions: {
    images: ["jpg", "jpeg", "png", "gif", "webp"],
    video: ["mp4", "avi", "mkv", "webm", "mpeg", "mpeg4", "3gp", "mov"],
    pdf: ["pdf"],
  },

  // default options for table. Import this, and then add new / remove existing options as per requirement.
  tableDefaultOptions: {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
  },

  /* status to statusId mapping, for passing it to backend */
  statusMapping: {
    "-": 0,
    "Submitted For Review": 1,
    Approved: 2,
    Rejected: 3,
    "Improvement Suggested": 4,
    Deleted: 5,
    "Edit With Suggestions": 6,
    Resubmitted: 7,
    Published: 8,
    Discard: 9,
  },

  /* based on the status, following icons and descriptions are shown in the post history timeline */
  timelineDetails: {
    "Submitted For Review": {
      icon: <PublishIcon />,
      iconColor: "#2874F7",
      chipColor: "#e1ebfc",
      statusDescription: "Post submitted for review",
    },
    Approved: {
      icon: <CheckCircleIcon />,
      iconColor: "#04CF55",
      chipColor: "#b0c9f5",
      statusDescription: "Post Approved",
    },
    Rejected: {
      icon: <CancelIcon />,
      iconColor: "red",
      chipColor: "#b0c9f5",
      statusDescription: "Post Rejected",
    },
    "Improvement Suggested": {
      icon: <SettingsSuggestIcon />,
      iconColor: "#eb8934",
      chipColor: "#f2efb6",
      statusDescription: "Suggested changes for the Post",
    },
    Deleted: {
      icon: <DeleteForeverIcon />,
      iconColor: "black",
      chipColor: "#b0c9f5",
      statusDescription: "Post Deleted",
    },
    "Edit With Suggestions": {
      icon: <EditIcon />,
      iconColor: "#588b0a",
      chipColor: "#d1f49a",
      statusDescription: "Edited Content With Suggestions",
    },
    Resubmitted: {
      icon: <KeyboardDoubleArrowUpIcon />,
      iconColor: "#bb6bbe",
      chipColor: "#fbe1fc",
      statusDescription: "Resubmitted By Influencer",
    },
    Discard: {
      icon: <Delete />,
      iconColor: "#bb6bbe",
      chipColor: "#fbe1fc",
      statusDescription: "Discarded",
    },
  },

  platformIdMapping: {
    Instagram: 1,
    Twitter: 2,
    X: 2,
    Tiktok: 3,
    YouTube: 4,
    Linkedin: 5,
    Facebook: 6,
  },
  postTypeMapping: {
    1: "Post",
    2: "Reel",
    3: "Story",
    4: "Post",
    5: "Post",
    6: "Post",
    7: "Post",
    8: "Post",
    9: "Poll",
    10: "Space",
  },
  postlinkExample: {
    1: "https://www.instagram.com/p/tyuCgtytu/",
    2: "https://twitter.com/user/status/1588512906710410208",
    3: "https://www.tiktok.com/@user/video/70194496285325366533",
    4: "https://www.youtube.com/watch?v=uHJNe5kVTLY",
    5: "https://www.linkedin.com/posts/ystsdgdhjd",
    6: "https://www.facebook.com/photo/?fbid=75387523517257",
  },
  charLengthSupported: {
    instagram: 2200,
    twitter: 280,
    tiktok: 150,
    youtube: 5000,
    linkedin: 63206,
    facebook: 110000,
  },
  accountMembershipColor: {
    active: "#26DE81",
    expired: "#f08995",
  },
};

export default enums;
