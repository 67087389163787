import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Platforms from "./Platforms";
import DiscoveryTable from "./DiscoveryTable";
import DiscoveryAppliedFilters from "./FilterComponents/DiscoveryAppliedFilters";
import { Box, Button, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SortByColumns from "./FilterComponents/SortByColumns";
import EditColumns from "./FilterComponents/EditColumns";
import KeyEnum from "./KeyEnum";
import { useNavigate } from "react-router-dom";
import MoreFilters from "./FilterComponents/MoreFilters";
import ExportData from "./FilterComponents/ExportData";
import { DownloadingRounded } from "@mui/icons-material";
import FilterEnum from "./FilterEnum";

const MyPaper = ({ children, elevation, fitHeight, sx }) => (
  <Paper
    elevation={elevation}
    sx={{
      p: 3,
      borderRadius: 2,
      border: 1,
      borderColor: "#fdfdfd",
      boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
      height: fitHeight ? "100%" : "auto",
      ...sx,
      padding: "1rem",
    }}
  >
    {children}
  </Paper>
);

const Discovery = ({ sortOrderChanged }) => {
  const [appliedFilters, setAppliedFilters] = useState({});
  const [resetPageNo, setResetPageNo] = useState(false);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [tablePageNo, setTablePageNo] = useState(0); // page no starting from 1, as opposed to mui table (which starts from 0)
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const [isChanged, setIsChanged] = useState(false);
  const [tableRowHeaders, setTableRowHeaders] = useState([]);
  const [finalTableRowHeaders, setFinalTableRowHeaders] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("instagram");
  const [tableDataUpdated, setTableDataUpdated] = useState(false);
  const [sortCol, setSortCol] = useState({
    field: "inf_engagements",
    name: KeyEnum["inf_engagements"],
  }); //sorted by default by lastposted date
  // const [sortOrder, setSortOrder] = useState("desc"); //sorted in descending order by default
  const [customCols, setCustomCols] = useState([{}]);
  const [allFiltersData, setAllFiltersData] = useState([{ inf_hidden: [{ name: "Exclude private accounts" }] }]);
  const [selectedCol, setSelectedCol] = useState([{}]);
  const [selectedData, setSelectedData] = useState(null);
  const [payload, setPayload] = useState({ inf_hidden: false });
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [isMoreFiltersExpanded, setIsMoreFiltersExpanded] = useState(false);
  const navigate = useNavigate();

  const tablePageChanged = (pageNo) => {
    setTablePageNo(pageNo * tableRowsPerPage);
    setResetPageNo(false);
    setIsChanged(!isChanged);
  };

  const tableRowsPerPageChanged = (rowsPerPage) => {
    setTableRowsPerPage(rowsPerPage);
    setResetPageNo(false);
    setIsChanged(!isChanged);
  };

  const handleInformationChange = (newPage) => {
    setTablePageNo(newPage.page);
    setResetPageNo(false);
    setIsChanged(!isChanged);
    setTableRowsPerPage(newPage.rowsPerPage);
    if (newPage.orderBy) {
      console.log(FilterEnum);
      const order_col = FilterEnum.headers.filter((value) => value.field === newPage?.orderBy)[0]?.sortCol;
      if (order_col)
        setSortCol({
          field: `inf_${order_col.toLowerCase()}`,
          name: KeyEnum[`inf_${order_col.toLowerCase()}`],
        });
    }

    // if (newPage.rowsPerPage !== tableRowsPerPage);
    setTableRowsPerPage(newPage.rowsPerPage);
  };

  const clearAllFilter = () => {
    setPayload({});
    setCustomCols([{}]);
    setSelectedCol([{}]);
    setAllFiltersData([{}]);
    setIsChanged(!isChanged);
  };

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleExpandMoreFilters = (panel) => (isExpanded) => {
    setIsMoreFiltersExpanded(isExpanded ? panel : false);
  };

  return (
    <div ref={elementRef}>
      {width > 768 && (
        <>
          {/* <Typography variant="h3" sx={{ mb: 2, mt: 2 }}>
            Influencer Search
          </Typography> */}
          <MyPaper>
            <Platforms
              setSelectedData={setSelectedData}
              setTableDataUpdated={setTableDataUpdated}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setTableDataLoaded={setTableDataLoaded}
              tablePageChanged={tablePageChanged}
              tableRowsPerPageChanged={tableRowsPerPageChanged}
              tableRowsPerPage={tableRowsPerPage}
              tablePageNo={tablePageNo}
              setTablePageNo={setTablePageNo}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              resetPageNo={resetPageNo}
              setResetPageNo={setResetPageNo}
              payload={payload}
              setPayload={setPayload}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              selectedCol={selectedCol}
              setSelectedCol={setSelectedCol}
              sortCol={sortCol}
              selectedPlatform={selectedPlatform}
              setSelectedPlatform={setSelectedPlatform}
              clearAllFilter={clearAllFilter}
              expandMoreFilters={handleExpandMoreFilters}
              isMoreFiltersExpanded={isMoreFiltersExpanded}
              appliedFilters={appliedFilters}
            />
          </MyPaper>
          {appliedFilters && Object.keys(appliedFilters).length > 0 && (
            <DiscoveryAppliedFilters
              appliedFilters={appliedFilters}
              payload={payload}
              setPayload={setPayload}
              customCols={customCols}
              setCustomCols={setCustomCols}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              setSelectedCol={setSelectedCol}
            />
          )}
          <MoreFilters
            expanded={isMoreFiltersExpanded}
            setExpanded={handleExpandMoreFilters}
            setSelectedData={setSelectedData}
            setTableDataUpdated={setTableDataUpdated}
            selectedPlatform={selectedPlatform}
            customCols={customCols}
            setCustomCols={setCustomCols}
            tableDataLoaded={tableDataLoaded}
            setTableDataLoaded={setTableDataLoaded}
            tablePageChanged={tablePageChanged}
            tableRowsPerPageChanged={tableRowsPerPageChanged}
            tableRowsPerPage={tableRowsPerPage}
            tablePageNo={tablePageNo}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            resetPageNo={resetPageNo}
            setTablePageNo={setTablePageNo}
            payload={payload}
            setPayload={setPayload}
            allFiltersData={allFiltersData}
            setAllFiltersData={setAllFiltersData}
            selectedCol={selectedCol}
            setSelectedCol={setSelectedCol}
            sortCol={sortCol}
            clearAllFilter={clearAllFilter}
          />

          <Box
            sx={{
              my: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <SortByColumns columnValue={sortCol} columnHandler={setSortCol} allFiltersData={allFiltersData} />

              <EditColumns tableRowHeaders={tableRowHeaders} setFinalTableRowHeaders={setFinalTableRowHeaders} />
            </Box>
            {selectedData && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    color: "black",
                    background: "white",
                    border: "1px solid lightgrey",
                    borderRadius: "12px",
                    "&:hover": { bgcolor: "lightgray" },
                    whiteSpace: "nowrap",
                    px: "12px",
                    textTransform: "none",
                    justifyContent: "space-between",
                    mr: "1rem",
                  }}
                  variant="contained"
                  disableElevation
                  onClick={() => navigate("/advance/discovery/exports")}
                  startIcon={<DownloadingRounded />}
                >
                  My Exports
                </Button>
                <ExportData
                  selectedData={selectedData}
                  tableRowsPerPage={tableRowsPerPage}
                  payload={payload}
                  sortCol={sortCol}
                  selectedPlatform={selectedPlatform}
                  tablePageNo={tablePageNo}
                  customCols={customCols}
                />
              </Box>
            )}
          </Box>
          <DiscoveryTable
            platform={selectedPlatform}
            tableDataLoaded={tableDataLoaded}
            tableData={selectedData}
            tableDataUpdated={tableDataUpdated}
            tablePageChanged={tablePageChanged}
            tableRowsPerPageChanged={tableRowsPerPageChanged}
            sortOrderChanged={sortOrderChanged}
            updatePageNo={resetPageNo}
            tableRowHeaders={tableRowHeaders}
            finalTableRowHeaders={finalTableRowHeaders}
            selectedData={selectedData}
            customCols={customCols}
            setTableRowHeaders={setTableRowHeaders}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            allFiltersData={allFiltersData}
            tablePageNo={tablePageNo}
            setAllFiltersData={setAllFiltersData}
            tableRowsPerPage={tableRowsPerPage}
            handleInformationChange={handleInformationChange}
            sortCol={sortCol}
          />
        </>
      )}
    </div>
  );
};
export default Discovery;
