import { useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../utils/tiktok-brands.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

import {
  Stack,
  Tab,
  Tabs,
} from "@mui/material";

import ProfileTab_Overview from "./ProfileTab_Overview";
import ProfileTab_Audience from "./ProfileTab_Audience";
import ProfileTab_Content from "./ProfileTab_Content";
import ProfileTab_Manage from "./ProfileTab_Manage";

// checkMe: move this to some common enums file
const socialIcons = {
  instagram: <InstagramIcon sx={{ color: "#BC2A8D" }} />,
  twitter: <TwitterIcon sx={{ color: "#00ACEE" }} />,
  tiktok: (
    <TikTokIcon
      style={{ width: "24px", height: "24px" }}
      sx={{ color: "#000000" }}
    />
  ),
  youtube: <YouTubeIcon sx={{ color: "#FF0100" }} />,
  linkedin: <LinkedInIcon sx={{ color: "#0a66c2" }} />,
  facebook: <FacebookIcon sx={{ color: "#1b74e4" }} />,
};


const ProfileExtendedDetails = ({ extendedDetails }) => {

  const [tabValue, setTabValue] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");

  const [overviewDetails, setOverviewDetails] = useState({});
  const [audienceDetails, setAudienceDetails] = useState({});
  const [contentDetails, setContentDetails] = useState({});
  const [manageDetails, setManageDetails] = useState({});

  useEffect(() => {
    const overview = extendedDetails.overview;
    setOverviewDetails(overview);
    const audience = extendedDetails.audience;
    setAudienceDetails(audience);
    const content = extendedDetails.content;
    setContentDetails(content);
    const manage = extendedDetails.manage;
    setManageDetails(manage);
    setTabValue("overview");
  }, [extendedDetails]);

  const renderPlatforms = () => {
    const platforms = extendedDetails.platforms;
    if (!platforms) {
      return;
    }
    return platforms.map((platform) => {
      return (
        <span key={"platformSelection-"+platform.name}>
          <label className="platform-icon" style={{marginRight: "10px"}} onClick={() => handlePlatformIconClick(platform.name)}><a href={extendedDetails.overview.profileLink} target="_blank">{socialIcons[platform.name]}</a></label>
        </span>
      )
    })
  }

  const handlePlatformIconClick = (platformName) => {
    if (platformName == selectedPlatform) {
      return;
    }

    const prevSelectedPlatform_id = "platformSelection-" + selectedPlatform;
    const prevEle = document.getElementById(prevSelectedPlatform_id);
    if (prevEle) {
      prevEle.style.border = "";
    }
    const newSelectedPlatform_id = "platformSelection-" + platformName;
    const newEle = document.getElementById(newSelectedPlatform_id);
    if (newEle) {
      newEle.style.border = "2px solid red";
    }
    setSelectedPlatform(platformName);
    // sendSelectedPlatform(platformName);
  }

  const tabSelected = (newTabValue) => {
    setTabValue(newTabValue);
    let offsetTop  = document.getElementById(newTabValue).offsetTop;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth"
    });
  }

  const checkIfDataExists = (label) => {
    switch (label) {
      case "overview":
        if (overviewDetails && Object.keys(overviewDetails).length>0) return true;
        break;
      case "audience":
        if (audienceDetails && Object.keys(audienceDetails).length>0) return true;
        break;
      case "content":
        if (contentDetails && Object.keys(contentDetails).length>0) return true;
        break;
      case "manage":
        if (manageDetails && Object.keys(manageDetails).length>0) return true;
    }
  }

  const renderTabLabels = () => {
    if (!extendedDetails || Object.keys(extendedDetails).length === 0) {
      return;
    }
    return Object.keys(extendedDetails).map((tabLabel) => {
      if (!checkIfDataExists(tabLabel)) {
        return;
      }
      return (
        <Tab
          value={tabLabel}
          label={tabLabel}
          wrapped
        />
      )
    });

  }
  return (
    <div id="ProfileExtendedDetails">
      <Stack direction={"row"} justifyContent={"end"} style={{"padding": "20px"}}>
        {renderPlatforms()}
      </Stack>
      <Tabs
        value={tabValue.toLowerCase()}
        onChange={tabSelected}
        aria-label="wrapped label tabs example"
        style={{paddingBottom: "10px"}}
      >
        {renderTabLabels()}
      </Tabs>
      {
        overviewDetails && 
        Object.keys(overviewDetails).length > 0 &&
        <>
          {/* <div className="tabTitle" id="overview">Overview</div> */}
          <ProfileTab_Overview
            overviewDetails={overviewDetails}
          />
        </>
      }
      {
        audienceDetails &&
        Object.keys(audienceDetails).length > 0 &&
        <>
          <div className="tabTitle" id="audience">Audience</div>
          <ProfileTab_Audience
            audienceDetails={audienceDetails}
          />
        </>
      }
      {
        contentDetails &&
        Object.keys(contentDetails).length > 0 &&
        <>
          <div className="tabTitle" id="content">Content</div>
          <ProfileTab_Content
            contentDetails={contentDetails}
          />
        </>
      }
      {
        manageDetails &&
        Object.keys(manageDetails).length > 0 &&
        <>
          <div className="tabTitle" id="manage">Manage</div>
          <ProfileTab_Manage
            manageDetails={manageDetails}
          />
        </>
      }
    </div>
  )
}

export default ProfileExtendedDetails;

ProfileExtendedDetails.propTypes = {
  extendedDetails: PropTypes.object.isRequired,
}