import { useState, useEffect } from "react";

import { PropTypes } from "prop-types";

import {
  Avatar,
  Button,
  Grid,
  Stack,
  Tooltip,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";

import enums_discovery from "./enums_discovery";

import StyledTable2 from "../../utils/styledTable2";
import utils from "../../utils/utils";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import DiscoveryActionButton from "./DiscoveryActionButton";
import utils_discovery from "./utils_discovery";
import ViewProfileDialog from "../list/ViewProfileDialog";
import AddInfToListDialog from "./AddInfToListDialog";
import AddInfToNewListDialog from "./AddInfToNewListDialog";
import MyPaper from "../general/MyPaper";

const tableColors = {
  header: {
    backgroundColor: "#F3F6F9",
    textColor: "#B5B5C3",
  },
}
const DiscoveryTable = ({ 
  platform,
  tableDataLoaded,
  tableData,
  tableDataUpdated,
  setInfluencerList,
  tablePageChanged,
  tableRowsPerPageChanged,
  sortOrderChanged,
  updatePageNo,
}) => {
  const [bodyColumns, setBodyColumns] = useState(0);

  const [tableRowHeaders, setTableRowHeaders] = useState([]);
  const [influencers, setInfluencers] = useState([]);
  const [totalTableRows, setTotalTableRows] = useState(0);

  const [selectedRow, setSelectedRow] = useState({}); // for 3-dot menu action
  const [additionalDetail, setAdditionalDetail] = useState({});
  const [viewProfileDialog, setViewProfileDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedRows_iid, setSelectedRows_iid] = useState([]);

  const [addToListDialog, setAddToListDialog] = useState(false);
  const [addInfToNewListDialog, setAddInfToNewListDialog] = useState(false);

  useEffect(() => {
    const platformForTableHeader = utils.capitalizeFirstLetter(platform);
    const tableHeaders = utils_discovery.getTableRowHeader(platformForTableHeader);
    setTableRowHeaders(tableHeaders);
  }, [platform]);

  useEffect(() => {
    if (!tableData || !tableData.Records || tableData.Records.length===0) {
      setBodyColumns([]);
      return;
    }

    const totalRows = tableData.TotalRecords;
    setTotalTableRows(totalRows);

    const bodyCols = [];
    const records = tableData.Records;
    records.forEach((row, index) => {
      const iid = row['Iid'];
      const infPlatform = row['platform'];
      let name = row['Name'];
      let displayName = name;
      if (displayName.length > 18) {
        displayName = displayName.slice(0, 15) + "...";
      }
      let username = row.Username;
      let displayUsername = username;
      let profileLink = row.Profile_Link
      if (displayUsername.length > 10) {
        displayUsername = displayUsername.slice(0, 7) + "..."
      }
      const photoUrl = (row['Profile_picture'])?(row['Profile_picture']):"";
      const bodyCol = {
        id: index+1,
        iid,
        profile: (
          <Grid container columns={{xs:4, sm:6, md:12}}>
            {/* Avatar */}
            <Grid item xs={4}>
              <Avatar
                variant="square"
                src={photoUrl}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container columns={{xs:4, sm:6, md:12}}>
                <Grid item xs={10}>
                  <Tooltip title={name}>
                    <span className="name containsTootlip">{displayName}</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={username}>
                    <span className="subtext username containsTooltip"><a href={profileLink} target="_blank">@{displayUsername}</a></span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ),
        following: (
          <Grid container columns={{xs:4, sm:6, md:12}}>
            <Grid item xs={4} sm={6} md={12}>
              {
                (infPlatform==="facebook")?
                (utils.convertToInternationalNumberSystem(row.friends_count)):
                (utils.convertToInternationalNumberSystem(row.Following))
              }
            </Grid>
            {/*<Grid item xs={4} sm={6} md={12}>
              <span className="subtext">{Math.floor(Math.random()*10000)/100}% Genuine</span>
            </Grid>*/}
          </Grid>
        ),
        engagementrate: (
          <Grid container columns={{xs:4, sm:6, md:12}}>
            <Grid item xs={4} sm={6} md={12}>
              {row.engagementrate}
            </Grid>
            {/*<Grid item xs={4} sm={6} md={12}>
              <span className="subtext">TBD</span>
        </Grid> */}
          </Grid>
        ),
        location: (
          <Grid container columns={{xs:4, sm:6, md:12}}>
            <Grid item xs={4} sm={6} md={12}>
            {row.Location}
            </Grid>
          {/* <Grid item xs={4} sm={6} md={12}>
            <span className="subtext">India</span>
          </Grid>*/}
          </Grid>
        ),
        /* inf_score: (
          <span className="infScore">{Math.floor(Math.random()*10000)/100}</span>
        ),*/
        action: (
          <Grid container>
            <Grid item xs={7}>
              <Button
                variant="contained"
                type="submit"
                size="small"
                style={{ color: "white", backgroundColor: "#51A6FF" }}
                className={styles.button}
                onClick={(event) => quickView(event, row)}
              >
                Quick View
              </Button>
            </Grid>
            <Grid item xs={3}>
              <DiscoveryActionButton
                influencerAccounts={influencers}
                platform={platform}
                influencerAccount={row}
                setInfluencerList={setInfluencerList}
              />
            </Grid>
          </Grid>
        )
      }
      bodyCols.push(bodyCol);
    });
    setBodyColumns(bodyCols);
  }, [tableDataUpdated]);

  const quickView = (_event, row) => {
    setSelectedRow(row);
    setAdditionalDetail({
      username: row.username,
      bio: row.bio,
      friends_count: row.friends_count,
    });
    setViewProfileDialog(true);
  }
  const closeViewProfileDialog = () => {
    setAdditionalDetail({});
    setViewProfileDialog(false);
  };

  const pageChanged = (pageNo) => {
    tablePageChanged(pageNo, -1);
  }
  const rowsPerPageChanged = (rowsPerPage) => {
    tableRowsPerPageChanged(rowsPerPage);
  }

  const rowSelectionChanged = (rowIdsSelected) => {
    setSelectedRows(rowIdsSelected);
  }

  const handleTableAction = (event) => {
    if (!selectedRows || Object.keys(selectedRows).length===0) {
      alert("please select at least one row!");
      return;
    }
    const action = event.target.value;
    // use switch case, in case of multiple table actions
    if (action === "addInfToList") {
      const selectedIids = Object.keys(selectedRows).map((selectedRowId) => (
        bodyColumns[selectedRowId-1].iid
      ));
      setSelectedRows_iid(selectedIids);
      setAddToListDialog(true);
    }
  }

  const closeAddInfToListDialog = (_successfullyAddedToList) => {
    setAddToListDialog(false);
  }

  const infToListCreation = () => {
    setAddToListDialog(false)
    setAddInfToNewListDialog(true);
  }
  const closeInfToNewListDialog = () => {
    setAddInfToNewListDialog(false);
  }

  return (
    (bodyColumns?.length > 0)?
    (
      <>
        <div id="tableMenu">
          <Paper>
            <Stack direction="row" justifyContent="space-between" padding={"10px"}>
              <div id="resultsInfo">
                <span className="search-heading">Search Results</span><br/>
                <span className="search-subtext">Found {totalTableRows} Influencers</span>
              </div>
              <Select
                value="actions"
                onChange={(e) => handleTableAction(e)}
                sx={{ '& fieldset': { legend:  {width: 'unset'} } } }
              >
                <MenuItem key={"actions"} value={"actions"} selected disabled>Actions</MenuItem>
                <MenuItem key={"addInfToList"} value={"addInfToList"}>Add Influencers to List</MenuItem>

              </Select>
            </Stack>
          </Paper>
        </div>
        <div id="table">
          <StyledTable2
            tableDataLoaded={tableDataLoaded}
            headerColumns={tableRowHeaders}
            bodyColumns={bodyColumns}
            initialOrder={enums_discovery.initialOrder}
            borderRadius={"10px"}
            colors={tableColors}
            columnAlignment={"left"}
            rowsPerPageOptions={[10,20,30]}
            totalRows={totalTableRows}
            initialRowsPerPage={10}
            pageChanged={pageChanged}
            rowsPerPageChanged={rowsPerPageChanged}
            rowSelectionChanged={rowSelectionChanged}
            sortOrderChanged={sortOrderChanged}
            updatePageNo={updatePageNo}
          />
        </div>
        {viewProfileDialog && (
          <ViewProfileDialog
            influencerAccount={selectedRow}
            viewProfileDialog={viewProfileDialog}
            closeViewProfileDialog={closeViewProfileDialog}
            additionalDetail={additionalDetail}
            platform={platform}
            engagementDisplay={false}
          />
        )}
        {/* checkMe: later on, fetch next 2 components into a new component - duplicay in DiscoveryActionButton */}
        {addToListDialog && (
          <AddInfToListDialog
            addToListDialog={addToListDialog}
            platform={platform}
            iidsSelected={selectedRows_iid}
            modalClosed={closeAddInfToListDialog}
            newListCreation={infToListCreation}
          />
        )}
        {addInfToNewListDialog && (
          <AddInfToNewListDialog
            addInfToNewListDialog={addInfToNewListDialog}
            platform={platform}
            iidsSelected={selectedRows_iid}
            modalClosed={closeInfToNewListDialog}
          />
        )}
      </>
    ):(
      <MyPaper>
        No data available. Please expand the filters.
      </MyPaper>
    )
  )
}

DiscoveryTable.propTypes = {
  platform: PropTypes.string.isRequired,
  tableDataLoaded: PropTypes.bool.isRequired,
  tableData: PropTypes.object.isRequired,
  tableDataUpdated: PropTypes.bool.isRequired,
  setInfluencerList: PropTypes.func.isRequired,
  tablePageChanged: PropTypes.func.isRequired,
  tableRowsPerPageChanged: PropTypes.func.isRequired,
  sortOrderChanged: PropTypes.func.isRequired,
  updatePageNo: PropTypes.bool.isRequired,
}

export default DiscoveryTable;