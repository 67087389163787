import "../../../styles/internal/Influencers.css";
import { useState } from "react";
import { useEffect } from "react";
import { INF_API_HOST, PROFILE_IMG } from "../../../config/main.json";
import {
  FormControl,
  Box,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  Backdrop,
  Tab,
  Tabs,
} from "@mui/material";
import MyPaper from "../../general/MyPaper";
import { BadgeTwoTone } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import utils from "../../../utils/utils";

const ViewInfluencers = () => {
  const [influencer, setInfluencer] = useState({});
  const [backdrop, setBackdrop] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [selectedTab] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const infId = params.get("id");
  const platform = params.get("platform");
  const nodata = "N/A";
  const callType = params.get("type");
  useEffect(() => {
    //fetchData();
    getData();
  }, []);
  let urls = [];
  const getData = () => {
    if (callType === "direct") {
      urls = [
        INF_API_HOST +
          "/profile/social_platform/" +
          infId +
          "/" +
          platform +
          "/" +
          callType,
      ];
    } else {
      urls = [
        INF_API_HOST + "/profile/basic/" + infId + "/" + callType,
        INF_API_HOST +
          "/profile/social_platform/" +
          infId +
          "/" +
          platform +
          "/" +
          callType,
        INF_API_HOST + "/profile/contact/" + infId,
        INF_API_HOST + "/profile/education/" + infId,
        INF_API_HOST + "/profile/employment/" + infId,
        INF_API_HOST + "/profile/interests/" + infId,
      ];
    }

    Promise.all(
      urls.map((url) =>
        fetch(url)
          .then(checkStatus)
          .then(parseJSON)
          .catch((error) => console.log("There was a problem!", error))
      )
    ).then((response) => {
      influencer["address"] = nodata;
      influencer["interest"] = nodata;
      influencer["email"] = nodata;
      influencer["phone"] = nodata;
      influencer["instituteName"] = nodata;
      influencer["degreeName"] = nodata;
      influencer["companyName"] = nodata;
      influencer["jobTitle"] = nodata;
      influencer["nationality"] = nodata;
      influencer["ethnicity"] = nodata;
      influencer["age"] = nodata;
      influencer["religion"] = nodata;
      influencer["political_ideology"] = nodata;
      influencer["bio"] = nodata;
      influencer["profilePhoto"] = PROFILE_IMG;
      if (callType !== "direct") {
        const basic = response[0];
        const socialData = response[1];
        const contact = response[2];
        const education = response[3];
        const employment = response[4];
        const interests = response[5];
        influencer["name"] = basic["first_name"] + " " + basic["last_name"];
        if (basic["primary_photo"] !== undefined) {
          if (basic["primary_photo"] !== "") {
            influencer["profilePhoto"] = basic["primary_photo"];
          } else if (
            basic["primary_photo"] !== undefined &&
            basic["primary_photo"] == ""
          ) {
            influencer["profilePhoto"] = socialData[0]?.["photo_url"];
          }
        }
        influencer["accounts"] = basic["Accounts"];
        influencer["education"] = basic["Education"];
        influencer["employment"] = basic["Employment"];
        influencer["bio"] = basic["bio"] || nodata;
        influencer["gender"] = basic["gender"] || nodata;
        influencer["nationality"] = basic["nationality"] || nodata;
        influencer["ethnicity"] = basic["ethnicity"] || nodata;
        influencer["age"] =
          socialData[0]?.["age_range"] || basic["age"] || nodata;
        influencer["religion"] = basic["religion"] || nodata;
        influencer["political_ideology"] =
          basic["political_ideology"] || nodata;
        if (contact["Email"] != null) {
          influencer["email"] = contact["Email"][0];
        }
        if (contact["Phone"] != null) {
          influencer["phone"] =
            "+" +
            contact["Phone"][0]["dial_code"] +
            "" +
            contact["Phone"][0]["phone_number"];
        }
        if (contact != null && contact["Address"] != null) {
          influencer["address"] =
            contact["Address"][0]["address_line_1"] +
            "\n" +
            contact["Address"][0]["address_line_2"] +
            ", " +
            contact["Address"][0]["location"] +
            ", " +
            contact["Address"][0]["state"] +
            ", " +
            contact["Address"][0]["country"] +
            "-" +
            contact["Address"][0]["zip_code"];
        }
        if (education != null) {
          influencer["instituteName"] = education[0]["name"];
          influencer["degreeName"] =
            education[0]["degree"] + "-" + education[0]["grad_year"];
        }
        if (employment != null) {
          influencer["companyName"] = employment[0]["name"];
          influencer["jobTitle"] = employment[0]["title"];
        }
        if (interests != null && interests != null) {
          influencer["interest"] = interests.join(", ");
        }
        if (socialData != null) {
          influencer["followers"] = socialData[0]?.["total_followers"];
          influencer["friends"] = socialData[0]?.["friends_count"];
          influencer["socialLink"] = socialData[0]?.["profile_url"];
        }
      } else {
        const basic = response[0][0];
        if (basic["first_name"] === "" || basic["last_name"] === "") {
          influencer["name"] = basic["name"];
        } else {
          influencer["name"] = basic["first_name"] + " " + basic["last_name"];
        }
        influencer["bio"] = basic["bio"] || nodata;
        if (basic["email"] != null) {
          influencer["email"] = basic["email"];
        }
        if (basic["phone"] != null) {
          influencer["phone"] = basic["phone"];
        }
        influencer["followers"] = basic["total_followers"];
        influencer["friends"] = basic["friends_count"];
        influencer["socialLink"] = basic["profile_url"];
        influencer["gender"] = basic["gender"] || nodata;
      }
      setInfluencer(influencer);
      setBackdrop(false);
      setLoaded(true);
    });
  };

  function checkStatus(response) {
    if (response.ok) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  }

  function parseJSON(response) {
    return response.json();
  }

  return loaded ? (
    <>
      <Box m={6}>
        <Tabs
          value={selectedTab}
          style={{ width: "200px", float: "left" }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Influencer Details" id="account-details-tab-0" />
        </Tabs>
        <Stack direction="row" justifyContent="end"></Stack>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyPaper>
            <Typography variant="h5" sx={{ mb: 2 }}>
              <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
              Account Details
            </Typography>
            <Divider />
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <div className="general-info">
                    <div className="general-info__picture-holder">
                      <div className="general-info__picture-frame">
                        <a
                          href={influencer["socialLink"]}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            alt=""
                            className="general-info__picture"
                            src={influencer["profilePhoto"]}
                            onError={utils.addDefaultSrc}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="general-info__holder">
                      <div>
                        <strong className="fname">
                          <a
                            href={influencer["socialLink"]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {influencer["name"]}
                          </a>
                        </strong>
                        <span className="ng-star-inserted">
                          {influencer["bio"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Gender: <span>{influencer["gender"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Age: <span>{influencer["age"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Nationality: <span>{influencer["nationality"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Ethnicity: <span>{influencer["ethnicity"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Religion: <span>{influencer["religion"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Political Ideology:{" "}
                    <span>{influencer["political_ideology"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>

            <br />
            <br />

            <Typography variant="h5" sx={{ mb: 2 }}>
              <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
              Contact Details
            </Typography>
            <Divider />
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Email: <span>{influencer["email"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Contact: <span>{influencer["phone"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Address:{" "}
                    {
                      <span style={{ whiteSpace: "pre-line" }}>
                        {influencer["address"]}
                      </span>
                    }
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
            <Typography variant="h5" sx={{ mb: 2 }}>
              <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
              Social Details
            </Typography>
            <Divider />
            <Grid container spacing={1} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Followers: {influencer["followers"]}
                    <span></span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Friends: {influencer["friends"]}
                    <span></span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
            <Typography variant="h5" sx={{ mb: 2 }}>
              <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
              Interests
            </Typography>
            <Divider />
            <Grid container spacing={1} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    <span>{influencer["interest"]}</span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <br />
            <Typography variant="h5" sx={{ mb: 2 }}>
              <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
              Educational Details
            </Typography>
            <Divider />
            <Grid container spacing={1} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Institution Name: {influencer["instituteName"]}
                    <span></span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Degree: {influencer["degreeName"]}
                    <span></span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <br />
            <Typography variant="h5" sx={{ mb: 2 }}>
              <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
              Work Details
            </Typography>
            <Divider />
            <Grid container spacing={1} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Company Name: {influencer["companyName"]}
                    <span></span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Job Title: {influencer["jobTitle"]}
                    <span></span>
                  </InputLabel>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <Divider />
          </MyPaper>
        </Grid>
      </Grid>
    </>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
      open={backdrop}
    >
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
};

export default ViewInfluencers;
