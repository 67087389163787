import { Box } from "@mui/system";
import Posts from "../../posts/Posts";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../general/TabPanel";
import { useBreadcrumbs } from "../../../context/BreadcrumbsProvider";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DraftPostAnalysis = () => {
  const [value, setValue] = React.useState(0);
  const { setActiveTab } = useBreadcrumbs();

  React.useEffect(() => {
    const tabNames = ["Drafts to Review", "Approved", "Discarded"];
    setActiveTab(`Drafts > ${tabNames[value]}`);
  }, [value, setActiveTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        paddingTop: "15px"
      }}
      mb={3}
    >
      <Tabs style={{ width: "auto", float: "left", height: "50px" }} value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Drafts to Review" {...a11yProps(0)} />
        <Tab label="Approved" {...a11yProps(1)} />
        <Tab label="Discarded" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Posts fromCampaign={true} type={1} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Posts fromCampaign={true} type={2} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Posts fromCampaign={true} type={3} />
      </TabPanel>
      <br />
    </Box>
  );
};

export default DraftPostAnalysis;
