import { useState } from "react";

import { PropTypes } from "prop-types";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  CachedTwoTone,
  EmailTwoTone,
  InsightsTwoTone,
  LabelTwoTone,
  MoreVertOutlined,
  PersonTwoTone,
} from "@mui/icons-material";

import "./styles.css";
import ViewUpdateDialog from "../list/ViewUpdateDialog";
import ViewTagDialog from "./ViewTagDialog";
import AddInfToListDialog from "./AddInfToListDialog";
import AddInfToNewListDialog from "./AddInfToNewListDialog";

const DiscoveryActionButton = ({ influencerAccounts, platform, influencerAccount, setInfluencerList }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [addToListDialog, setAddToListDialog] = useState(false);
  const [viewUpdateDialog, setViewUpdateDialog] = useState(false);
  const [viewTagDialog, setViewTagDialog] = useState(false);
  const [addInfToNewListDialog, setAddInfToNewListDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <PersonTwoTone className="menuIcon" />,
      label: "Add To List",
      name: "addInfToList",
    },
    {
      id: 2,
      icon: <CachedTwoTone className="menuIcon" />,
      label: "Update Profile",
      name: "updateProfile",
    },
    {
      id: 3,
      icon: <EmailTwoTone className="menuIcon" />,
      label: "Invite & Add to List",
      name: "inviteAndAddToList",
    },
    {
      id: 4,
      icon: <LabelTwoTone className="menuIcon" />,
      label: "Add Tag",
      name: "addTag",
    },
    {
      id: 5,
      icon: <InsightsTwoTone className="menuIcon" />,
      label: "Analyze Account",
      name: "analyzeAccount",
    },
  ];

  const invokeAction = (name) => {
    switch(name) {
      case "addInfToList":
        addInfToList();
        break;
      case "updateProfile":
        alert("coming soon!!!");
        // updateProfile();
        break;
      case "inviteAndAddToList":
        alert("coming soon!!!");
        break;
      case "addTag":
        alert("coming soon!!!");
        // addTag();
        break;
      case "analyzeAccount":
        analyzeAccount();
        break;
    }
    handleClose();
  };

  const addInfToList = () => {
    setAddToListDialog(true);
  }
  const closeAddInfToListDialog = (_successfullyAddedToList) => {
    setAddToListDialog(false);
  }

  const infToListCreation = () => {
    setAddToListDialog(false)
    setAddInfToNewListDialog(true);
  }
  const closeInfToNewListDialog = () => {
    setAddInfToNewListDialog(false);
  }

  const updateProfile = () => {
    setViewUpdateDialog(true);
  }
  const closeViewUpdateDialog = () => {
    setViewUpdateDialog(false);
  }

  const addTag = () => {
    setViewTagDialog(true);
  }
  const closeViewTagDialog = () => {
    setViewTagDialog(false);
  }

  const analyzeAccount = () => {
    const url = "/linkedin/profile?id=" + influencerAccount.Iid;
    window.open(url);
  }

  const setInfluencerAccountList = (updatedInfluencersList) => {
    setInfluencerList(updatedInfluencersList);
  }

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreVertOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30%" }}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="menuitem"
        >
          {arrayOfOptions.map((value) => (
            <div key={value.id}>
              <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
              </MenuItem>
              {value.id < 5 && <Divider sx={{ my: 0.5 }} />}
            </div>
          ))}
        </Menu>
      </Box>
      {addToListDialog && (
        <AddInfToListDialog
          addToListDialog={addToListDialog}
          platform={platform}
          iidsSelected={[influencerAccount.Iid]}
          modalClosed={closeAddInfToListDialog}
          newListCreation={infToListCreation}
        />
      )}
      {addInfToNewListDialog && (
        <AddInfToNewListDialog
          addInfToNewListDialog={addInfToNewListDialog}
          platform={platform}
          iidsSelected={[influencerAccount.Iid]}
          modalClosed={closeInfToNewListDialog}
        />
      )}
      {viewUpdateDialog && (
        <ViewUpdateDialog
          influencerAccounts={influencerAccounts}
          platform={platform}
          influencerAccount={influencerAccount}
          viewUpdateDialog={viewUpdateDialog}
          // setNotificationContent={getNotificationContent}
          closeViewUpdateDialog={closeViewUpdateDialog}
          setInfluencerAccountList={setInfluencerAccountList}
        />
      )}
      {/* {viewTagDialog && (
        <ViewTagDialog
          influencerAccounts={influencerAccounts}
          platform={platform}
          influencerAccount={influencerAccount}
          viewTagDialog={viewTagDialog}
          closeViewTagDialog={closeViewTagDialog}
          setInfluencerAccountList={setInfluencerAccountList}
        />
      )} */}
    </>
  );
};

DiscoveryActionButton.propTypes = {
  influencerAccounts: PropTypes.array.isRequired,
  platform: PropTypes.string.isRequired,
  influencerAccount: PropTypes.object.isRequired,
  setInfluencerList: PropTypes.func.isRequired,
};

export default DiscoveryActionButton;
