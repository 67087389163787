import {
  Backdrop,
  CircularProgress,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import TabPanel from "../../components/general/TabPanel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CampaignForm from "../../components/account/details/CampaignForm";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

const EditCampaigns = () => {
  const [cancleModal, setCancleModal] = useState(false);
  const { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const isEdit = query.get("isEdit") === "true";

  const [selectedTab] = useState(0);

  const postCampaignCancel = () => {
    setCancleModal(true);
  };

  const closeModal = () => {
    setCancleModal(false);;
  };

  const cancelCampaign = () => {
    window.location = "/campaigns";
  };


  return true ? (
    <>
      <Typography variant="h3" sx={{ mb: 2, mt: 4 }}>
        Campaign
      </Typography>
      <Box sx={{ width: "100%", mb: 3 }}>
        <Tabs
          value={selectedTab}
          style={{ width: "200px", float: "left" }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Campaign Details" id="account-details-tab-0" />
        </Tabs>
        <Stack direction="row" justifyContent="end" spacing={1}>
          <Button
            variant="contained"
            component={Link}
            to={`/campaigns/viewdetails?id=${id}&isEdit=${!isEdit}`}
          >
            {isEdit ? "View Campaign" : "Edit Campaign"}
          </Button>
          <Button
            variant="contained"
            onClick={postCampaignCancel}
          >
            All Campaigns
          </Button>
        </Stack>
      </Box>
      <TabPanel index={0} name="account-details-tab" value={selectedTab}>
        <CampaignForm isEdit={isEdit} type={"EditCampaign"} />
      </TabPanel>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={cancleModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={cancleModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              // border: '3px solid #000',
              borderRadius: "15px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography>
              <div style={{ marginBottom: 10 }}>
                This action will result in the loss of the data you have entered.
              </div>

              <div>Do you want to discard and exit?</div>
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
              <Button variant="outlined" size="medium" type="button" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="contained" size="medium" type="button" onClick={cancelCampaign}>
                Yes, Discard
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : (
    <Backdrop
      open
      sx={{
        position: "absolute",
      }}
    >
      <CircularProgress sx={{ color: "white" }} />
    </Backdrop>
  );
};

export default EditCampaigns;
