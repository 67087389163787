import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Divider,
  Menu,
  Typography,
  ButtonGroup,
  TextField,
  Modal,
  Fade,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { API_HOST, HEIMDALL_HOST } from "../../config/main.json";
import Tooltip from "@mui/material/Tooltip";
import CustomTable from "../../common/CustomTable/CustomTable";
import InfoIcon from "@mui/icons-material/Info";
import {
  Circle,
  Delete,
  MoreVertOutlined,
  Close,
  Visibility,
  Campaign,
  ChangeCircle,
  AddBusiness,
  PersonAdd,
  Unarchive,
  Info,
} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import ArchiveIcon from "@mui/icons-material/Archive";
import tableStyle from "../../styles/theme/pageStyling/TableStyle.module.css";
import SearchItem from "./SearchItem";
import Loader from "./Loader";
import { getTimeZone, onDownloadHandle } from "../../utils/exportData";
import ExportFiles from "./ExportFiles";
import { useDispatch, useSelector } from "react-redux";
import { campaignStatusColors, snapshotHeaderIcons, textColor, statusToNum } from "../../components/campaignAnalysis/snapshot";
import { pink } from "@mui/material/colors";
import { getAccountsTableHeaders } from "../../utils/tableHeaders";
import utils from "../../utils/utils";
import { validateAiFluenceEmails, validateBusinessEmails } from "../../services/account/verification";
import enums from "../../utils/enums";
import { getAccountList } from "../../services/account/AccountDetails";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import CustomTabs from "../../common/CustomTabs/CustomTabs";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AccountTable = (props) => {
  const [accountPlatform, setAccountPlatform] = useState(props.platfrom ? props.platfrom : "oryx"); //defines which type of campaigns wil be display oryx/empowr
  const [type, setType] = useState(1); // present type of brand (1- Active, 0- Archived, 2- Deleted)
  const [selectedTab, setSelectedTab] = useState("0");
  const [searchBy] = useState("Account Name"); // as we seacrch only by Account Name(used in palceholder)
  const [filter, setFilter] = useState(""); // refers to the value/accounts we are searching in seacrch field
  const [tablePageNo, setTablePageNo] = useState(0); // refers to page no of pagination (default - 0)
  const [isExport, setIsExport] = useState(0); // refers we want to export or not, 0 - not, 1 - yes
  const [isLoaded, setIsLoaded] = useState(false);
  const [accountData, setAccountData] = useState([]); //  contains detailed list of all the accounts
  const [brands, setBrands] = useState([]); // // detailed list of all the brand ( for filter by brand dropdown)
  //const [brand, setBrand] = useState(0); //  refers to  a single brand by which we are searching in the filter by brand field
  const [dataFound, setDataFound] = useState(false); // shows that we have/recive accoumts data from API or not
  const [tableData, setTableData] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);
  const [campaignList, setCampaignList] = useState([]); // list of campaign for a particular brand( when click on "view all campaign" this list apperas )
  const [openCampaignModal, setOpenCampaignModal] = useState(false); // controlls the view all campaigns modal
  const [accountName, setAccountName] = useState(""); // contains name of the selected account
  const [accountId, setAccountId] = useState(0);
  const [addUserModal, setAddUserModal] = useState(false); // controlls the modal for ading a user
  const [businessTypeValue, setBusinessTypeValue] = useState(0); //// type of business i.e 1- Product/Service or Brand, 2-Marketing  agency, 3- NGO/NPO, Donor or a Charity
  const [membershipPlan, setMembershipPlan] = useState(0);
  const [membershipDetails, setMembershipDetails] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [agencyId, setAgencyId] = useState(0);
  const [addAgencyClientModal, setAddAgencyClientModal] = useState(false); // controlls the modal for ading a clent in agency
  const [fields, setFields] = useState({});
  const [businessTypeList, setBusinessTypeList] = useState([]); // contains "Business Type" field list
  const [sectorList, setSectorList] = useState([]); // contains "Brand Secondary Category" field list
  const [countryList, setCountryList] = useState([]); // contains detailed list of all the country
  const [totalRecords, setTotalRecords] = useState(); // refers to total no of accounts
  const [resetPageNo, setResetPageNo] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10); //refers to no of rows dispaly at a time on page
  const [sortCol, setSortCol] = useState("updatedAt"); //refers on what base all col are sorted
  const [sortOrder, setSortOrder] = useState("desc"); //refers on what base all rows are sorted
  const [account] = useState(0); //  refers to  account (0 - aifluence)
  const [mimeType] = useState("csv"); //refers to the type in which we want to export
  const [totalActive, setTotalActive] = useState(0); //  refers to no of total active account
  const [totalInactive, setTotalInactive] = useState(0); // refers to no of total archived account
  const [totalDeleted, setTotalDeleted] = useState(0); // refers to no of total deletd account
  const [planList, setPlanList] = useState([]);
  const [plan, setPlan] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const moment = require("moment");
  const timeZone = getTimeZone(); // to set the time on platform
  const styles = {
    noData: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      fontSize: "large",
      fontStyle: "italic",
    },
  };

  const fetchData = () => {
    setIsLoaded(() => false);
    let type = 1;
    if (props.type === 0) {
      setType(0);
      setSelectedTab("1");
      type = 0;
    } else if (props.type === 2) {
      setType(2);
      setSelectedTab("2");
      type = 2;
    }
    getAccountsList(
      account,
      type,
      isExport,
      tablePageNo,
      tableRowsPerPage,
      sortCol,
      sortOrder,
      filter,
      mimeType,
      accountPlatform,
      timeZone,
      plan
    );
  };

  useEffect(() => {
    fetchData();
  }, [account, type, isExport, tablePageNo, tableRowsPerPage, sortCol, sortOrder, filter, mimeType, accountPlatform, plan]);

  const getAccountsList = async (
    account,
    type,
    isExport,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    filter,
    mimeType,
    accountPlatform,
    timeZone,
    plan
  ) => {
    setIsLoaded(false);
    // API used to get the details of the accounts
    const res = await getAccountList(
      account,
      type,
      isExport,
      tablePageNo + 1,
      tableRowsPerPage,
      sortCol,
      sortOrder,
      filter,
      mimeType,
      accountPlatform,
      timeZone,
      plan
    );

    if (isExport === 1) {
      onDownloadHandle(res, "Accounts." + mimeType);
      setIsExport(0);
    }

    const { total_records, accountList, active, inactive, deleted } = res;
    setTotalActive(active); // active - gives a number that represent total active account
    setTotalInactive(inactive); // inactive - gives a number that represent total archived account
    setTotalDeleted(deleted); // deleted - gives a number that represent total deleted account
    if (total_records > 0 && accountList) {
      setAccountData(accountList); // accountList - gives the list of accounts of a particular page (not all)
      setTotalRecords(total_records); // total_records - refers to no of account individualy ( active,archived,deleted )
      setDataFound(true);
    } else {
      setDataFound(false);
    }

    setIsLoaded(true);
  };

  //to do (search by brand)
  // useEffect(() => {
  //     getBrandList(type, accountPlatform);
  // }, [accountPlatform, type]);

  // const getBrandList = (type, accountPlatform) => {
  //     utils.getAllBrandData(type, accountPlatform).then((data) => {
  //         const brandData = data;
  //         setBrands(brandData);
  //     });
  // };

  useEffect(() => {
    getBusinessTypes();
    getSecondaryList();
    getCountryList();
    getAllPlans();
  }, []);

  const getBusinessTypes = () => {
    utils.getBtData().then((data) => {
      setBusinessTypeList(data);
    });
  };

  const getSecondaryList = () => {
    utils.getScatData().then((data) => {
      setSectorList(data);
    });
  };

  const getCountryList = () => {
    utils.getAllCountries().then((data) => {
      setCountryList(data);
    });
  };

  const getAllPlans = () => {
    utils.getAllThePlans().then((data) => {
      setPlanList(data);
    });
  };

  // function to set search value in "filter" variable and change page no to 0
  const sendSearchedValue = (event) => {
    setFilter(event);
    setTablePageNo(0);
  };

  // function to set in which from(csv) we want to export and decide whether we want to export or not
  const sendExportSelected = (event) => {
    setIsExport(event === "csv" ? 1 : 0);
  };

  // when click on "View All Campaigns"
  const getCampaignsForAccount = (accountId) => {
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    dispatch(
      openSnackbar({
        message: "Please wait... we are loading the campaigns for this account",
        severity: "info",
      })
    );

    axios.get(`${API_HOST}/account/campaign/${accountId}`, optionAxios).then((res) => {
      // this.setState({ campaignList: res.data, anchorEl: false });
      setCampaignList(res.data);
    });
  };

  const closeCampaignModal = () => {
    setOpenCampaignModal(false);
  };

  const getMembershipDetails = (id) => {
    utils.getMembershipDetails(id).then((data) => {
      const membershipDetails = data;
      setMembershipDetails(membershipDetails);
    });
  };

  const getAgencyClientList = (rowID) => {
    utils.getAgencyClientList(rowID).then((data) => {
      setAgencyList(data);
    });
  };

  const closeAddUserModal = () => {
    setAddUserModal(false);
  };

  // adding user
  const handleAddUser = (event) => {
    event.preventDefault();
    // const { user } = this.props;
    setAddUserModal(false);
    //add user if exists
    if (userName && userEmail) {
      const name = userName.trim();
      const email = userEmail.toLowerCase().trim();
      const clientId = agencyId.toString();
      let role = "Admin";
      let roleId = 1;
      if (agencyId > 0) {
        role = "Member";
        roleId = 3;
      } else {
        role = "Admin";
        roleId = 1;
      }
      const users = [{ name, email, role, roleId }];
      const data = {
        users,
        companyName: accountName,
        agencyId: accountId.toString(),
        clientId,
        addedBy: user.attributes.email,
      };
      //console.log(data)

      const optionAxios = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      dispatch(
        openSnackbar({
          message: "Please wait... we are adding the user in our database",
          severity: "info",
        })
      );
      axios.post(HEIMDALL_HOST + "/users", data, optionAxios).then((res) => {
        if (res.data.statusCode === 200) {
          dispatch(
            openSnackbar({
              message: "User added successfully!",
              severity: "success",
            })
          );
        } else {
          dispatch(
            openSnackbar({
              message: "User could not be added",
              severity: "error",
            })
          );
        }
      });
    }
  };

  const handleMembershipChange = (event) => {
    const agencyId = accountId.toString();
    const membershipId = event.target.value.toString();
    utils.changeMembership({ agencyId, membershipId }).then((res) => {
      if (res.status === "success") {
        fetchData();
        dispatch(
          openSnackbar({
            message: "Membership changed successfully!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: res.status,
            severity: "error",
          })
        );
      }
    });
  };

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleAgencyChange = (event) => {
    setAgencyId(event.target.value);
  };

  const closeAddCompanyModal = () => {
    setAddAgencyClientModal(false);
  };

  const handleAddClient = (event) => {
    event.preventDefault();
    setAddAgencyClientModal(false);
    // setAnchorEl(false);

    const { name, businessType, sector, country, website } = fields;
    const data = {
      name,
      businessType,
      sector,
      country,
      website,
      agencyId: accountId,
    };

    dispatch(
      openSnackbar({
        message: "Please wait... we are adding the client in our database",
        severity: "info",
      })
    );
    utils.createAgencyClient(data).then((res) => {
      if (res.status === "success") {
        dispatch(
          openSnackbar({
            message: "Agency Client added successfully!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: res.status,
            severity: "error",
          })
        );
      }
    });
  };

  const handleChange = (field, event) => {
    const value = event.target.value;

    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
    setTablePageNo(newPage.page);
    setResetPageNo(false);
    setIsChanged(!isChanged);
    setTableRowsPerPage(newPage.rowsPerPage);
    setSortCol(newPage.orderBy);
    setSortOrder(newPage.order);
  };

  const getUpdatedDate = (updatedAt) => {
    const date = moment(updatedAt, "YYYY-MM-DD HH:mm:ss");
    const formattedDate = date.format("Do MMMM YYYY");
    return formattedDate;
  };

  useEffect(() => {
    const handleClick = (event, index) => {
      const anchorEl = [];
      for (let i = 0; i < accountData.length; i++) {
        if (i === index) anchorEl.push(event.currentTarget);
        else anchorEl.push(null);
      }
      setAnchorEl(anchorEl);
    };

    const handleClose = (i) => {
      const anchorEl = [];
      for (let i = 0; i < accountData.length; i++) {
        anchorEl.push(null);
      }
      setAnchorEl(anchorEl);
    };

    const data = [];
    if (accountData) {
      accountData.forEach((item, index) => {
        const {
          accountManager,
          addedFrom,
          brandCount, // 9
          businessType, //1
          businessTypeValue, // "Product/Service or Brand(s)"
          campaignCount, //13
          campaignStatus, //"Live:1,Paused:1,Completed:10,Archived:1"
          createdAt, //"2021-12-14 05:07:49"
          id, //2
          membershipPlan, //10
          membershipPlanName, //"Reach Basic"
          membershipStatus, //"active"
          name, //"Sony Corporation of America"
          updatedAt, //"2024-03-20 03:21:51"
          addedBy,
        } = item;

        let liveCampaigns = 0;
        const statusBreakup = campaignCount > 0 ? campaignStatus.split(",") : [];
        statusBreakup.filter((statusValue) => {
          const [status, statusCount] = statusValue.split(":");
          if (status.toLowerCase() === "live") liveCampaigns = parseInt(statusCount);
        });

        let updatedDate = getUpdatedDate(updatedAt);

        const statusColor = enums.accountMembershipColor[membershipStatus];

        // deals with actions on active account page
        const actions = (
          <div>
            <>
              <MenuItem key={1} component={Link} to={"../accounts/edit?id=" + id} id={"Edit-" + id}>
                <Visibility sx={{ color: "#3498db" }} /> &nbsp;&nbsp; View All Brands
              </MenuItem>
            </>

            {campaignCount > 0 && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  key={1}
                  onClick={(event) => {
                    getCampaignsForAccount(id);
                    handleClose(index);
                    setOpenCampaignModal(true);
                    setAccountName(name);
                  }}
                  id={"Campaign-" + id}
                >
                  <Campaign sx={{ color: "#2ecc71" }} /> &nbsp;&nbsp; View All Campaigns
                </MenuItem>
              </>
            )}

            <>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                key={3}
                onClick={() => {
                  setAccountId(id);
                  setAccountName(name);
                  setAddUserModal(true);
                  setBusinessTypeValue(businessType);
                  setMembershipPlan(membershipPlan);

                  getMembershipDetails(membershipPlan);
                  handleClose(index);
                  if (businessType === 2) getAgencyClientList(id);
                }}
              >
                {(id === 0 || membershipPlan > 0) && (
                  <span>
                    <PersonAdd sx={{ color: "#e67e22" }} /> &nbsp;&nbsp; Add a new user
                  </span>
                )}
                {id !== 0 && membershipPlan === 0 && (
                  <span>
                    <ChangeCircle sx={{ color: "#922b21" }} /> &nbsp;&nbsp; Convert to SAAS account
                  </span>
                )}
              </MenuItem>
            </>

            <Tooltip title={"You can only archieve accounts with no running/live campaigns"}>
              <span>
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    key={2}
                    id={"Archive-" + id}
                    disabled={campaignCount > 0 && liveCampaigns > 0}
                    onClick={(event) => {
                      handleClose(index);
                      if (window.confirm("Archive the account?")) {
                        // this.removeToCollection(11);
                        var body = {};
                        body["id"] = id;
                        body["is_active"] = 0;
                        var optionAxios = {
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                          },
                        };
                        axios.post(API_HOST + "/account/edit", body, optionAxios).then((res) => {
                          // window.location = "/accounts/"; //This line of code will redirect you once the submission is succeed
                          fetchData();
                        });
                      }
                    }}
                  >
                    {" "}
                    <ArchiveIcon sx={{ color: "#95a5a6" }} /> &nbsp;&nbsp; Archive This Account{" "}
                  </MenuItem>
                </>
              </span>
            </Tooltip>

            {businessType === 2 && membershipPlan > 0 && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  key={4}
                  onClick={() => {
                    setAccountId(id);
                    setAccountName(name);
                    setAddAgencyClientModal(true);

                    this.handleClose(index);
                  }}
                >
                  <AddBusiness sx={{ color: "#008080" }} /> &nbsp;&nbsp; Add an agency client{" "}
                </MenuItem>
              </>
            )}

            <Tooltip title={"You can only delete accounts with no running/live campaigns"}>
              <span>
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    key={5}
                    id={"Delete-" + id}
                    onClick={(event) => {
                      handleClose(index);
                      if (window.confirm("Do you want to delete this account?")) {
                        var body = {};
                        body["id"] = id;
                        body["is_active"] = 2;
                        var optionAxios = {
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                          },
                        };
                        axios.post(API_HOST + "/account/edit", body, optionAxios).then((res) => {
                          fetchData();
                        });
                      }
                    }}
                    disabled={campaignCount > 0 && liveCampaigns > 0}
                  >
                    {" "}
                    <Delete sx={{ color: pink[500] }} /> &nbsp;&nbsp; Delete This Account{" "}
                  </MenuItem>
                </>
              </span>
            </Tooltip>
          </div>
        );

        // deals with actions on archive account page
        const actionsUnarchived = (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title="Unacrhive Account" arrow>
              <IconButton
                component="Button"
                id={"Unarchive-" + id}
                onClick={(event) => {
                  if (window.confirm("Restore the account?")) {
                    // this.removeToCollection(11);
                    var body = {};
                    body["id"] = id;
                    body["is_active"] = 1;
                    var optionAxios = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    };
                    axios.post(`${API_HOST}/account/edit`, body, optionAxios).then((res) => {
                      // window.location = "/accounts/"; //This line of code will redirect you once the submission is succeed
                      fetchData();
                    });
                  }
                }}
              >
                <Unarchive />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Account" arrow>
              <IconButton
                component="Button"
                id={"Delete-" + id}
                onClick={(event) => {
                  if (window.confirm("Do you want to delete this account?")) {
                    var body = {};
                    body["id"] = id;
                    body["is_active"] = 2;
                    var optionAxios = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    };
                    axios.post(API_HOST + "/account/edit", body, optionAxios).then((res) => {
                      fetchData();
                    });
                  }
                }}
              >
                <Delete sx={{ color: pink[500] }} />
              </IconButton>
            </Tooltip>
          </div>
        );

        // deals with actions on deleted account page
        const actionsDeleted = (
          <div>
            <Tooltip title="Delete Account for Forever" arrow>
              <IconButton
                component="Button"
                id={"Delete-" + id}
                onClick={(event) => {
                  if (window.confirm("Delete this account forever?")) {
                    var optionAxios = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    };
                    axios.get(`${API_HOST}/account/delete/${id}`, optionAxios).then((res) => {
                      // window.location = "/accounts/"; //This line of code will redirect you once the submission is succeed
                      fetchData();
                    });
                  }
                }}
              >
                <Delete sx={{ color: pink[500] }} />
              </IconButton>
            </Tooltip>
          </div>
        );

        const open = anchorEl.map((val) => val !== null);

        const object = {
          id: index + 1,
          name: (
            <>
              <Grid container spacing={1} sx={{ justifyContent: "flex-start" }}>
                <Grid item md={8} sm={5} sx={{ marginTop: 2 }}>
                  <Box
                    component={"a"}
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 800,
                      fontSize: "1.1rem",
                      lineHeight: "1rem",
                      color: "#1461cc",
                    }}
                    href={`../account/dashboard?id=${id}`}
                  >
                    {name}
                  </Box>
                </Grid>
                {addedBy && (
                  <Grid item md={1} sx={{ marginTop: 1.5 }}>
                    <Tooltip title={`Created By : ${addedBy}`}>
                      <Info fontSize="small" />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </>
          ),
          businessType: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                {businessTypeValue === "Product/Service or Brand(s)" ? (
                  <p className={styles.bodycell}>Brand</p>
                ) : businessTypeValue === "NGO/NPO, Donor or a Charity" ? (
                  <p className={styles.bodycell}>NGO</p>
                ) : (
                  <p className={styles.bodycell}>businessTypeValue</p>
                )}
              </Grid>
            </Grid>
          ),
          noOfBrands: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingTop: "20px", paddingLeft: "20px" }}>
                {brandCount !== "-" ? <p className={styles.bodycell}>{brandCount}</p> : <p className={styles.bodycell}>-</p>}
              </Grid>
            </Grid>
          ),
          noOfCampaigns: (
            <Grid container sx={{ paddingTop: "20px", paddingLeft: "20px" }}>
              <Grid item xs={2}>
                {campaignCount > 0 ? (
                  <p className={styles.bodycell}>{campaignCount}</p>
                ) : (
                  <p className={styles.bodycell}>
                    <span style={{ paddingLeft: "20px" }}>-</span>
                  </p>
                )}
              </Grid>
              <Grid item xs={10}>
                <Tooltip
                  title={
                    campaignCount > 0 ? (
                      <>
                        <Typography color="inherit">Campaign Breakup</Typography>
                        <hr />
                        {statusBreakup.map((statusValue) => {
                          const [status, statusCount] = statusValue.split(":");
                          return (
                            <>
                              <span
                                style={{ fontSize: "14px", marginBottom: "15px" }}
                              >{`${status} Campaigns : ${statusCount}`}</span>
                              <br />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )
                  }
                >
                  <InfoIcon fontSize="small" style={{ opacity: campaignCount > 0 ? "0.5" : "0" }} />
                </Tooltip>
              </Grid>
            </Grid>
          ),
          updatedAt: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingTop: "20px", paddingLeft: "10px" }}>
                {updatedDate !== "-" ? <p className={styles.bodycell}>{updatedDate}</p> : <p className={styles.bodycell}>-</p>}
              </Grid>
            </Grid>
          ),
          planSubscribed: (
            <Grid container>
              <Grid item xs={12} sx={{ fontSize: "17px" }}>
                <b className={styles.bodycell}>{membershipPlanName}</b>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={1}>
                    <Circle htmlColor={statusColor} sx={{ width: "15px", height: "15px" }} />
                  </Grid>
                  <Grid item xs={10} sx={{ fontSize: "14px", fontStyle: "italic", marginLeft: "5px" }}>
                    {membershipStatus.slice(0, 1).toUpperCase() + membershipStatus.slice(1)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ),

          action: (
            <Grid container>
              <Grid item xs={3}>
                {type === 1 && (
                  <>
                    {" "}
                    <IconButton onClick={(event) => handleClick(event, index)} size="small" sx={{ ml: 2 }}>
                      <MoreVertOutlined />
                    </IconButton>
                    <Box style={{ borderRadius: "30%" }}>
                      <Menu
                        anchorEl={anchorEl[index]}
                        getContentAnchorEl={null}
                        open={open[index]}
                        onClose={handleClose}
                        className={tableStyle.menuitem}
                      >
                        {actions}
                      </Menu>
                    </Box>
                  </>
                )}

                {type === 0 && <> {actionsUnarchived}</>}

                {type === 2 && <> {actionsDeleted}</>}
              </Grid>
            </Grid>
          ),
        };

        data.push(object);
      });
      setTableData(data);
    }
  }, [accountData, anchorEl]);

  const accountsTableHeaders = getAccountsTableHeaders;

  const renderContentHandler = () => {
    let content = null;
    if (isLoaded) {
      if (dataFound) {
        content = (
          <>
            <div id="table">
              {/* This is a custom made component, take care while changing field names */}
              <CustomTable
                tableHeaders={accountsTableHeaders}
                bodyColumns={tableData}
                sendInformation={handleInformationChange}
                totalRows={totalRecords}
                pageNo={tablePageNo}
                rowsPerPageNo={tableRowsPerPage}
                sortOrder={sortOrder}
                sortOrderBy={sortCol}
              />
            </div>
            {/* This is a custom made component, take care while changing field names */}
            {/* <GeneratePptReport pptReportId={pptReportId} setPptReportId={setPptReportId} needPastReports={true} /> */}
          </>
        );
      } else {
        content = (
          <Grid container spacing={2}>
            {/* No account found for this account */}
            <Grid style={styles.noData} item xs={12}>
              You don't have any account at the moment.
            </Grid>
          </Grid>
        );
      }
    } else content = <Loader />;

    return content;
  };

  const handlePlanChange = (event, value) => {
    if (value !== null) {
      const selected = planList.find((plan) => plan.publicName === value);
      if (selected) {
        setPlan(selected.id);
      } else {
        setPlan("");
      }
    } else {
      setPlan("");
    }
  };

  const tabArray = [
    {
      label: `Active Accounts (${totalActive})`,
      id: "account-details-tab-0",
      to: `/accounts?platform=${accountPlatform}`,
      component: Link,
    },
    {
      label: `Archived Accounts (${totalInactive})`,
      id: "account-details-tab-0",
      to: `/accounts/archived?platform=${accountPlatform}`,
      component: Link,
    },
    {
      label: `Deleted Accounts (${totalDeleted})`,
      id: "account-details-tab-0",
      to: `/accounts/deleted?platform=${accountPlatform}`,
      component: Link,
    },
  ];

  const accountPlatformHandler = (platform) => {
    setAccountPlatform(platform);
    setTablePageNo(0);
    if (type === 2) {
      navigate(`/accounts/deleted?platform=${platform}`);
    } else if (type === 0) {
      navigate(`/accounts/archived?platform=${platform}`);
    } else {
      navigate(`/accounts?platform=${platform}`);
    }
  };

  return (
    <>
      <Box mt={4} mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant={"h5"} mr={2}>
            Platform:{" "}
          </Typography>
          <ButtonGroup variant="outlined" aria-label="platform button group" disableElevation>
            <Button
              variant={accountPlatform === "oryx" ? "contained" : "outlined"}
              onClick={() => accountPlatformHandler("oryx")}
            >
              Oryx
            </Button>
            <Button
              variant={accountPlatform === "empowr" ? "contained" : "outlined"}
              onClick={() => accountPlatformHandler("empowr")}
            >
              Empowr
            </Button>
          </ButtonGroup>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <SearchItem sendSearchedValue={sendSearchedValue} searchBy={searchBy} />
          <ExportFiles sendExportSelected={sendExportSelected} />
        </Box>
      </Box>

      <Box m={3}>
        <CustomTabs tabArray={tabArray} selectedTab={selectedTab} />
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" component={Link} to="../accounts/add">
            + Create An Account{" "}
          </Button>
        </Stack>
      </Box>

      <Box m={1}>
        <Stack direction="row" justifyContent="end">
          <Grid container spacing={0.5}>
            <Grid item xs={12} sm={9} md={9}></Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Autocomplete
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    fontSize: "0.875rem",
                    borderRadius: "10px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.875rem",
                  },
                }}
                disablePortal
                id="filter-by-plan"
                options={planList.map((option) => option.publicName)}
                onChange={handlePlanChange}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Plan" variant="outlined" size="small" MenuProps={MenuProps} />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box my={1}>{renderContentHandler()}</Box>

      {/* Handles the modal of view all campaigns */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-add_user-label"
        aria-describedby="modal-add_user-description"
        open={openCampaignModal}
        onClose={closeCampaignModal}
        closeAfterTransition
      >
        <Fade in={openCampaignModal}>
          <div className="syles_paper_list" style={{ height: "500px", width: "50%" }}>
            <div id="modal-add_user-heading" className="styles_modal_heading">
              <h2 id="modal-add_user-heading-text" style={{ padding: "10px" }}>
                Campaigns under {accountName}
              </h2>
              <IconButton onClick={closeCampaignModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-add_brand_to_account-body" style={{ padding: "10px", maxHeight: "400px", overflowY: "auto" }}>
              <Grid item xs={12}>
                {campaignList.map((campaign) => (
                  <Box key={campaign.campaignName}>
                    <Circle htmlColor={campaignStatusColors[campaign.campaignStatus]} sx={{ width: "20px", height: "20px" }} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {campaign.campaignName}
                  </Box>
                ))}
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>

      {/*Handles the modal to invite a user in the account*/}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-add_user-label"
        aria-describedby="modal-add_user-description"
        open={addUserModal}
        onClose={closeAddUserModal}
        closeAfterTransition
      >
        <Fade in={addUserModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-add_user-heading" className="styles_modal_heading">
              <h2 id="modal-add_user-heading-text" style={{ padding: "10px" }}>
                {(accountId === 0 || membershipPlan > 0) && <span>Invite New User</span>}
                {accountId !== 0 && membershipPlan === 0 && <span>Change Membership</span>}
              </h2>
              <IconButton onClick={closeAddUserModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-add_brand_to_account-body" style={{ padding: "10px" }}>
              {accountId !== 0 && membershipPlan === 0 && (
                <>
                  <Grid item xs={12}>
                    <Box>
                      <form id="addBrandForm" onSubmit={handleAddUser}>
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">Plan Subscription</InputLabel>
                              <Select onChange={handleMembershipChange} variant="outlined" label="Plan Subscription" required>
                                {membershipDetails.map((btype) => (
                                  <MenuItem key={btype.id} value={btype.id}>
                                    {btype.publicName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </div>
                      </form>
                    </Box>
                  </Grid>
                  <hr />
                </>
              )}
              <Grid item xs={12}>
                <Box>
                  {accountId !== 0 && membershipPlan === 0 && (
                    <>
                      {" "}
                      <h4>
                        Invite New User <span>(Optional)</span>
                      </h4>{" "}
                    </>
                  )}
                  <form id="addBrandForm" onSubmit={handleAddUser}>
                    <div>
                      <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <TextField
                            onChange={handleNameChange}
                            variant="outlined"
                            label="Full Name"
                            required={accountId !== 0 && membershipPlan === 0 ? false : true}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <TextField
                            onChange={handleEmailChange}
                            variant="outlined"
                            label="Email"
                            required={accountId !== 0 && membershipPlan === 0 ? false : true}
                          />
                          {validateBusinessEmails(userEmail) && (
                            <p style={{ padding: "10px", color: "red" }}>Please use business email only</p>
                          )}
                          {validateAiFluenceEmails(userEmail, accountName) && (
                            <p style={{ padding: "10px", color: "red" }}>
                              You can only invite AiFluence users under the selected account
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                      {businessTypeValue === 2 && (
                        <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">Agency Client</InputLabel>
                            <Select onChange={handleAgencyChange} variant="outlined" label="Agency Client">
                              {agencyList?.map((btype) => (
                                <MenuItem key={btype.id} value={btype.id}>
                                  {btype.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </div>
                    <button
                      variant="contained"
                      className="btn btn-success float-right"
                      style={{
                        float: "right",
                        marginBottom: "15px",
                        marginRight: "20px",
                      }}
                      type="submit"
                    >
                      {(accountId === 0 || membershipPlan > 0) && <span>Invite User</span>}
                      {accountId !== 0 && membershipPlan === 0 && <span>Update Account</span>}
                    </button>
                  </form>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>

      {/*Handles the modal to invite a client in the agency*/}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-add_user-label"
        aria-describedby="modal-add_user-description"
        open={addAgencyClientModal}
        onClose={closeAddCompanyModal}
        closeAfterTransition
      >
        <Fade in={addAgencyClientModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-add_user-heading" className="styles_modal_heading">
              <h2 id="modal-add_user-heading-text" style={{ padding: "10px" }}>
                Add a client in the agency
              </h2>
              <IconButton onClick={closeAddCompanyModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-add_brand_to_account-body" style={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Box>
                  <form id="addClientForm" onSubmit={handleAddClient}>
                    <div>
                      <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <TextField
                            onChange={(e) => handleChange("name", e)}
                            variant="outlined"
                            label="Client Name"
                            required={true}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">Business Type</InputLabel>
                          <Select
                            onChange={(e) => handleChange("businessType", e)}
                            variant="outlined"
                            label="Business Type"
                            required={true}
                          >
                            {businessTypeList.map(
                              (btype) =>
                                btype.id !== 2 && (
                                  <MenuItem key={btype.id} value={btype.id}>
                                    {btype.type}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">Sector</InputLabel>
                          <Select onChange={(e) => handleChange("sector", e)} variant="outlined" label="Sector" required={true}>
                            {sectorList.map((secondaryCategory) => (
                              <MenuItem value={secondaryCategory.id} key={secondaryCategory.id}>
                                {secondaryCategory.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                          <Select onChange={(e) => handleChange("country", e)} variant="outlined" label="Country" required={true}>
                            {countryList.map((btype) => (
                              <MenuItem key={btype.id} value={btype.id}>
                                {btype.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <TextField onChange={(e) => handleChange("website", e)} variant="outlined" label="Website" />
                        </FormControl>
                      </Grid>
                    </div>
                    <button
                      variant="contained"
                      className="btn btn-success float-right"
                      style={{
                        float: "right",
                        marginBottom: "15px",
                        marginRight: "20px",
                      }}
                      type="submit"
                    >
                      Add agency client
                    </button>
                  </form>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default AccountTable;
