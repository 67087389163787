import { Alert, AlertTitle, Autocomplete, Box, Button, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { func } from "prop-types";
import { useEffect, useState } from "react";
import { getCampaignListData } from "../../services/campaign/CampaignDetails";
import { inviteInfluencerByList } from "../../services/influencer/InfluencerInvite";
import { getListsByCampaign } from "../../services/list/ListDetails";
import { getInfluencerIdByList, getInfluencerWithInvitationStatus } from "../../services/list/ListInfluencerMap";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import TransferList from "../general/TransferList";
import { useDispatch, useSelector } from "react-redux";
import { PLATFORM_NAME } from "../../config/main.json";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const InviteByList = ({ setNavigateBack }) => {
  const [campaignData, setCampaignData] = useState([]);
  const [listData, setListData] = useState([{ name: "Please select a campaign to get list information" }]);
  const [transferList, setTransferList] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({ name: "" });
  const [selectedList, setSelectedList] = useState({ name: "" });
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    async function init() {
      getAllCampaigns();
    }
    init();
  }, []);

  const getAllCampaigns = async () => {
    const data = await getCampaignListData();
    setCampaignData(data);
  };

  const chooseCampaign = async (newValue) => {
    setSelectedCampaign(newValue);
    setDisabled(true);
    if (newValue && newValue.id) {
      const data = await getListsByCampaign(newValue.id);
      setRight([]);
      setSelectedList({ name: "" });
      setTransferList(false);
      setListData(data);
    }
  };

  const chooseList = async (newValue) => {
    var iids = [];
    if (newValue && newValue.id && newValue.platform) {
      const data = await getInfluencerIdByList(newValue.id);
      setTransferList(true);
      data?.map((value) => {
        iids.push(value.iid);
      });
      let influencers;
      if (iids.length > 0) {
        influencers = await getInfluencerWithInvitationStatus(iids, newValue.id.toString(), newValue.platform);
      }
      const left = [];
      const right = [];
      if (influencers && influencers.length > 0) {
        influencers.forEach((influencer) => {
          const inf = {};
          const details = getDetails(influencer);
          inf["id"] = influencer.influencer_id;
          inf["label"] = details.name;
          inf["disabled"] = details.disabled;
          left.push(inf);
        });
      }
      setDisabled(false);
      setLeft(left);
      setRight(right);
    }
  };

  const getDetails = (influencer) => {
    let name;
    let disabled;
    if (influencer.invitecode < 0) {
      name = `${influencer.name} (Not Invited)`;
      disabled = false;
    } else if (influencer.invitecode === 0) {
      name = `${influencer.name} (Invitation Accepted)`;
      disabled = true;
    } else {
      name = `${influencer.name} (Invited but not accepted)`;
      disabled = true;
    }
    return { name, disabled };
  };

  const goback = () => {
    setNavigateBack(true);
  };
  const rollOutInvitation = () => {
    if (!selectedCampaign.id) {
      dispatch(
        openSnackbar({
          message: "Campaign is mandatory",
          severity: "error",
        })
      );
    } else if (selectedCampaign.id && !selectedList.id) {
      dispatch(
        openSnackbar({
          message: "List is mandatory",
          severity: "error",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: "Please wait while we send out invitations to the influencers..",
          severity: "info",
        })
      );
      var influencer_ids = [];
      if (right.length === 0) influencer_ids = left.map((influencer) => influencer.id);
      else influencer_ids = right.map((influencer) => influencer.id);
      const notificationMetadata = {
        userId: JSON.parse(user.attributes.identities)[0].userId,
        sourcePlatform: PLATFORM_NAME,
        notificationTypeId: "4",
        notificationActionId: "2",
        notificationCategoryId: "5",
        generatedBy: user.attributes.name,
        priority: "medium",
        consumedBy: "gazelle",
      };
      inviteInfluencerByList(
        selectedCampaign.id,
        selectedList.id,
        influencer_ids,
        selectedCampaign.name,
        selectedList.platform,
        "invite",
        notificationMetadata
      ).then((result) => {
        if (result) {
          setSelectedList({ name: "" });
          setTransferList(false);
          setSelectedCampaign({ name: "" });
          setRight([]);
          dispatch(
            openSnackbar({
              message: "Email Sent to the influencers",
              severity: "success",
            })
          );
        }
      });
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(option) => (option.name !== "" ? `${option.name} (${option.companyName})` : "")}
            options={campaignData}
            value={selectedCampaign}
            onChange={(event, newValue) => chooseCampaign(newValue)}
            renderInput={(params) => <TextField {...params} label="Campaign for list (mandatory)" />}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(option) => option.name}
            options={listData ? listData : []}
            value={selectedList}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <Typography sx={textColor[option.platform]}>{snapshotHeaderIcons[option.platform]}</Typography>
                &nbsp;
                {option.name}
              </Box>
            )}
            onChange={(event, newValue) => {
              setSelectedList(newValue);
              chooseList(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="List which has all your influencers" />}
          />
        </Grid>
      </Grid>
      <br />
      {transferList && <TransferList left={left} setLeft={setLeft} right={right} setRight={setRight} />}
      <Grid item xs={12} spacing={2} sx={{ textAlign: "right" }}>
        <Box sx={{ mt: 4 }}>
          <Button variant="outlined" size="large" type="submit" onClick={goback}>
            Go back
          </Button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Button variant="contained" size="large" type="submit" disabled={disabled} onClick={rollOutInvitation}>
            Invite
          </Button>
        </Box>
      </Grid>
    </>
  );
};

InviteByList.propTypes = {
  setNavigateBack: func.isRequired,
};
export default InviteByList;
