import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import { API_HOST, HEIMDALL_HOST } from "../../config/main.json";
import { validateAiFluenceEmails, validateBusinessEmails, validateEmail } from "../../services/account/verification";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function NewUserModal({ newUserModal, setNewUserModal, userAdded }) {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [agency, setAgency] = useState("");
  const [accounts, setAccounts] = useState([]);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const closeModal = () => {
    setNewUserModal(false);
  };

  const handleExternalSubmit = (event) => {
    event.preventDefault();
    //add user if exists
    if (firstName && email) {
      const clientId = agency.id.toString();
      let role = "Manager";
      let roleId = 1;
      const users = [{ name: firstName, email: email.toLowerCase().trim(), role, roleId }];
      const data = {
        users,
        companyName: agency.name,
        agencyId: clientId,
        clientId: "0",
        addedBy: user.attributes.email,
      };

      const optionAxios = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      dispatch(
        openSnackbar({
          message: "Please wait... we are adding the user in our database",
          severity: "info",
        })
      );
      axios.post(HEIMDALL_HOST + "/users", data, optionAxios).then((res) => {
        if (res.data.statusCode === 200) {
          closeModal();
          userAdded(true);
          dispatch(
            openSnackbar({
              message: "User Added!",
              severity: "success",
            })
          );
        } else {
          if (res.data.message.includes("1062")) {
            dispatch(
              openSnackbar({
                message: "User already exists in the database",
                severity: "error",
              })
            );
          } else {
            dispatch(
              openSnackbar({
                message: res.data.message,
                severity: "error",
              })
            );
          }
        }
      });
    }
  };

  useEffect(() => {
    getAccountsList();
  }, []);

  const getAccountsList = () => {
    axios.get(`${API_HOST}/account/list/type/1/all`).then((res) => {
      const accountData = res.data;
      setAccounts(accountData);
    });
  };

  return (
    <Modal
      className="styles_modal"
      aria-labelledby="modal-new_user-label"
      aria-describedby="modal-new_user-description"
      open={newUserModal}
      onClose={closeModal}
      closeAfterTransition
    >
      <Fade in={newUserModal}>
        <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
          <div id="modal-new_user-heading" className="styles_modal_heading">
            <h2 id="modal-new_user-heading-text" style={{ padding: "10px" }}>
              Invite Customer
            </h2>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </div>
          <hr />
          <div id="modal-new_user-body" style={{ padding: "10px" }}>
            <form id="addBrandForm" onSubmit={handleExternalSubmit} style={{ paddingTop: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    disablePortal
                    id="combo-box-demo"
                    options={accounts.map((option) => option.name)}
                    onChange={(event, value) => setAgency(accounts.filter((acc) => acc.name === value)[0])}
                    renderInput={(params) => (
                      <TextField {...params} label="Select the Account" variant="outlined" MenuProps={MenuProps} />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <TextField onChange={(event) => setFirstName(event.target.value)} variant="outlined" label="Full Name" />
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <TextField onChange={(event) => setEmail(event.target.value.trim().toLowerCase())} variant="outlined" label="Email" />
                  </FormControl>
                  {validateBusinessEmails(email) && (
                    <p style={{ padding: "10px", color: "red" }}>Please use business email only</p>
                  )}
                  {validateAiFluenceEmails(email, agency) && (
                    <p style={{ padding: "10px", color: "red" }}>
                      You can only invite AiFluence users under the selected account
                    </p>
                  )}
                </Grid>
              </Grid>
              <button
                variant="contained"
                className="btn btn-success float-right"
                style={{
                  float: "right",
                  margin: "20px",
                }}
                type="submit"
                disabled={
                  firstName === "" ||
                  email === "" ||
                  agency === "" ||
                  !validateEmail(email) ||
                  validateBusinessEmails(email) ||
                  validateAiFluenceEmails(email, agency)
                }
              >
                + Invite User
              </button>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default NewUserModal;
