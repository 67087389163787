import utils from "../../utils/utils";
import "../../styles/internal/modal.css";
import { API_HOST, DOS, DONTS, TEMPLATE, FAQ } from "../../config/main.json";

import * as React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import axios from "axios";

import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
    Backdrop,
    Box,
    Button,
    ButtonGroup as BGroup,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    TextField,
    TextareaAutosize,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Menu,
    Divider,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MuiAlert from "@mui/material/Alert";
import { Select, MenuItem } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Loader from "./Loader";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@mui/material/Tooltip";
import PreviewIcon from "@mui/icons-material/Preview";
import CloseIcon from "@material-ui/icons/Close";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import Autocomplete from "@mui/material/Autocomplete";
import { Country, State, City } from "country-state-city";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import CachedIcon from "@mui/icons-material/Cached";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FAQS from "react-faqs-component";
import { useDispatch, useSelector } from "react-redux";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FeedIcon from "@mui/icons-material/Feed";
import { getCampaignListData } from "../../services/campaign/CampaignDetails";
import { Circle, Delete, Info, MoreVertOutlined } from "@mui/icons-material";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { groupCodes, snapshotHeaderIcons, textColor } from "../../components/campaignAnalysis/snapshot";
import { styled } from "@mui/material/styles";
import tableStyle from "../../styles/theme/pageStyling/TableStyle.module.css";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import SearchItem from "./SearchItem";
import ExportFiles from "./ExportFiles";
import { getTimeZone, onDownloadHandle } from "../../utils/exportData";
import CustomTable from "../../common/CustomTable/CustomTable";
import { getListTableHeaders, getListTableHeadersArchived } from "../../utils/tableHeaders";
import { getListData } from "../../services/list/ListDetails";
import CustomTabs from "../../common/CustomTabs/CustomTabs";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    "& .MuiDialog-paper": {
        width: "2500px", // Adjust the width as needed
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const ListTable = (props) => {
    const clistData = [];
    const [anchor] = useState(null);
    const [type, setType] = useState(1); // present type of list (1- Active, 2- Archived, 3- Deleted)
    const [selectedTab, setSelectedTab] = useState("0");
    const [companyId, setCompanyId] = useState(""); // its account id
    const [listPlatform, setListPlatform] = useState(props.platfrom ? props.platfrom : "oryx"); // refers to platform oryx or empower
    const [filter, setFilter] = useState(""); // refers to the value/list we are searching in seacrch field
    const [searchBy] = useState("List Name"); // as we seacrch only by List Name(used in palceholder)
    const [tablePageNo, setTablePageNo] = useState(0); // refers to page no of pagination (default - 0)
    const [isExport, setIsExport] = useState(0); // refers we want to export or not, 0 - not, 1 - yes
    const [editing, setEditing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // defines that modal for creating list will be open or not
    const [totalActive, setTotalActive] = useState(0); //  refers to no of total active list
    const [totalInactive, setTotalInactive] = useState(0); // refers to no of total archived list
    const [totalDeleted, setTotalDeleted] = useState(0); // refers to no of total deletd list
    const infRowsSelected = [];
    const [rowsSelected, setRowsSelected] = useState(infRowsSelected); // refers to an arry that have all the selected list
    const [removeListMod, setRemoveListMod] = useState(false); //  defines that modal for removing list will be open or not
    const [stepMessage, setStepMessage] = useState("Basic Details"); // defines the title in the process of creating a list
    const [currentStep, setCurrentStep] = useState("1"); // defines the step no in the process of creating a list
    const [listId, setListId] = useState("");
    const [editMode, setEditMode] = React.useState(false); // defines we are editing an already existing list or not
    const [listStatus, setListStatus] = useState(2);
    const [counter, setCounter] = useState(1); // defines no of "Q & A" rows are there in FAQs section of create list
    const [faqQuestion, setFaqQuestion] = useState({}); // contains all the questions from FAQs section of create list
    const [faqAnswer, setFaqAnswer] = useState({}); // contains all the answers from FAQs section of create list
    const [campaignId, setCampaignId] = useState("");
    const [platformId, setPlatformId] = useState("");
    const [newListName, setNewListName] = useState(""); // name of the list, which we are creating
    const [notes, setNotes] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = React.useState([]);
    const [minFollowers, setMinFollowers] = useState(0);
    const [maxFollowers, setMaxFollowers] = useState(0);
    const [geoType, setGeoType] = useState(2);
    const [currency, setCurrency] = useState(""); // currancy selected while creating new list
    const [infBudget, setInfBudget] = useState(0); // budget selected while creating new list
    const [paymentTerm, setPaymentTerm] = useState(""); // type of payment term (num) while creating new list
    const [timezoneLable, setTimezoneLable] = useState(0);
    const [timezone, setTimezone] = useState(0);
    const [dos, setDos] = useState(DOS); // coniatins the dos statements while creating new list
    const [donts, setDonts] = useState(DONTS); // coniatins the donts statements while creating new list
    const contentBlock = htmlToDraft(TEMPLATE);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks))
    );
    const [tableData, setTableData] = useState([]);
    const [convertedContent, setConvertedContent] = useState(""); // contains content of sample post section of create list
    const [displayGeo, setDisplayGeo] = useState("flex");
    const [countryLable, setCountryLable] = useState(""); // contains details of a specific/selected country
    const [country, setCountry] = useState(""); // country code
    const [stateList, setStateList] = useState(clistData); // contains detailed list of all the state of a specific/selectrd country
    const [stateLable, setStateLable] = useState(""); // contains details of a specific/selected state
    const [state, setState] = useState(""); // state code
    const [cityList, setCityList] = useState(clistData); // contains detailed list of all the city of a specific/selectrd state
    const [cityLable, setCityLable] = useState(""); // contains details of a specific/selected city
    const [city, setCity] = useState(""); // city name
    const [displayPT, setDisplayPT] = useState("none"); // defines that display of "No Of Days" firld is "flex" or "none"
    const [noofDays, setNoOfDays] = useState(0); // no of days for payment selected while creating new list
    const [displayDT, setDisplayDT] = useState("none"); // defines that display of "Payment date" firld is "flex" or "none"
    const [paymentDate, setPaymentDate] = useState(""); // date of payment selected while creating new list
    const [campaignList, setCampaignList] = useState(clistData); // list of compaigns that is display for" Campaign Name" while creating new list
    const [companyList, setCompanyList] = useState(clistData); //list of all active accounts that is display for Accounts field in the modal of new list
    const [backdropShow, setbackdropShow] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const dclistsData = [];
    const [listsData, setlistsData] = useState(dclistsData); // contains details of all the lista
    const [timezoneList, setTimezoneList] = useState(clistData);
    const [countryList, setCountryList] = useState(clistData); // contains detailed list of all the country
    const [errorAlert, setErrorAlert] = useState("Please complete all required(*) fields.");
    const [required, setRequired] = useState(false);
    const [faqmodalOpen, setFaqmodalOpen] = useState(false); //  defines that modal of "All FAQ" is be open or not
    const [faqListData, setFaqListData] = useState(FAQ);
    const open = Boolean(anchor);
    const [resetPageNo, setResetPageNo] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [anchorEl, setAnchorE1] = useState([]);
    const [dataFound, setDataFound] = useState(false); // shows that we have/recive accoumts data from API or not
    const [campaignID] = useState(0);// will send to backend, 0 to get all lists, camapignid to get list of specific campaign
    const [account] = useState(0); //  refers to  account (0 - aifluence)
    const [client] = useState(0);
    const [mimeType] = useState("csv"); //refers to the type in which we want to export
    const [tableRowsPerPage, setTableRowsPerPage] = useState(10); //refers to no of rows dispaly at a time on page
    const [SortCol, setSortCol] = useState("createdOn"); //refers on what base all col are sorted
    const [sortOrder, setSortOrder] = useState("desc"); //refers on what base all rows are sorted
    const [disableNext, setDisableNext] = useState(false);
    const [listNameCount, setListNameCount] = useState(0);
    const [startDate] = useState(null);
    const [endDate] = useState(null);
    const [isRefreshed] = useState(false);
    const [campaignName] = useState("");
    const [addedFrom] = useState("oryx");
    const [totalRecords, setTotalRecords] = useState(); // refers to total no of accounts
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const moment = require('moment');
    const timeZone = getTimeZone(); // to set the time on platform
    const user = useSelector((state) => state.user);
    const styles = {
        noData: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            fontSize: "large",
            fontStyle: "italic",
        },
    };


    const fetchData = () => {
        setIsLoaded(() => false);
        let type = 1;
        if (props.type === 2) {
            setType(2);
            setSelectedTab("1")
            type = 2;
        } else if (props.type === 3) {
            setType(3);
            setSelectedTab("2")
            type = 3;
        }
        getData(
            campaignID,
            tablePageNo,
            tableRowsPerPage,
            SortCol,
            sortOrder,
            isExport,
            filter,
            startDate,
            endDate,
            isRefreshed,
            mimeType,
            campaignName,
            account,
            client,
            timezone,
            listPlatform,
            type,
        );
    };

    useEffect(() => {
        fetchData();
    }, [
        tablePageNo,
        tableRowsPerPage,
        SortCol,
        sortOrder,
        isExport,
        filter,
        mimeType,
        account,
        listPlatform,
        type,
    ]);



    const getData = async (
        campaignID,
        tablePageNo,
        tableRowsPerPage,
        SortCol,
        sortOrder,
        isExport,
        filter,
        startDate,
        endDate,
        isRefreshed,
        mimeType,
        campaignName,
        account,
        client,
        timezone,
        listPlatform,
        type,
    ) => {
        setIsLoaded(false);
        // API used to get the details of the accounts
        const res = await getListData(
            campaignID,
            tablePageNo + 1,
            tableRowsPerPage,
            SortCol,
            sortOrder,
            isExport,
            filter,
            startDate,
            endDate,
            isRefreshed,
            mimeType,
            campaignName,
            account,
            client,
            timezone,
            listPlatform,
            type,
        );

        if (isExport === 1) {
            onDownloadHandle(res, "List." + mimeType);
            setIsExport(0);
        }

        const { total_records, records, active, inactive, deleted } = res;
        setTotalActive(active); // active - gives a number that represent total active list
        setTotalInactive(inactive); // inactive - gives a number that represent total archived list
        setTotalDeleted(deleted); // deleted - gives a number that represent total deleted list
        if (total_records > 0 && records) {
            setlistsData(records); // records - gives the list of List of a particular page (not all)
            setTotalRecords(total_records); // total_records - refers to no of account individualy ( active,archived,deleted )
            setDataFound(true);
        } else {
            setDataFound(false);
        }

        utils.getCompanyData().then((data) => {
            //get details of active accounts
            setCompanyList(data);
        });
        const data = await getCampaignListData();
        const campaignList = data;
        setCampaignList(campaignList);
        utils.getTimezoneData("all").then((data) => {
            const timezoneData = data;
            setTimezoneList(timezoneData);
        });
        setCountryList(Country.getAllCountries());

        setIsLoaded(true);
    };

    // function to set search value in "filter" variable and change page no to 0
    const sendSearchedValue = (event) => {
        setFilter(event);
        setTablePageNo(0);
    };

    // function to set in which from(csv) we want to export and decide whether we want to export or not
    const sendExportSelected = (event) => {
        setIsExport(event === "csv" ? 1 : 0);
    };

    const createNewList = (event) => {
        setEditing(false);
        clearState();
        setModalOpen(true);
    };

    const removeListClick = () => {
        if (rowsSelected.length === 0) {
            dispatch(
                openSnackbar({
                    message: "Please select list first",
                    severity: "info",
                })
            );
            return;
        }
        setRemoveListMod(true);
    };

    // close the delete list modal
    const handleCloseConfModal = () => {
        setRowsSelected([]);
        setRemoveListMod(false);
    };

    const removeListModalConfirmation = () => {
        //handleClose();
        if (rowsSelected.length === 0) {
            dispatch(
                openSnackbar({
                    message: "Please select list first",
                    severity: "info",
                })
            );
            return;
        }
        removeList();
    };

    /*  main method to remove list(s) based on the selection (stored in rowsSelected) */
    const removeList = () => {
        if (rowsSelected.length === 0) {
            dispatch(
                openSnackbar({
                    message: "Please select list first",
                    severity: "info",
                })
            );
            return;
        }
        dispatch(
            openSnackbar({
                message: "Please wait... we are deleting the list",
                severity: "info",
            })
        );
        const lidsSelected = getLidsFromRowsSelected();
        var optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        axios
            .post(API_HOST + "/list/remove", { ids: lidsSelected.join(",") }, optionAxios)
            .then((res) => {
                dispatch(
                    openSnackbar({
                        message: "List Deleted successfully!",
                        severity: "success",
                    })
                );
                setRemoveListMod(false);
                // window.location = "/list"
                fetchData();
                if (res["data"]["status"] !== "success") {
                    dispatch(
                        openSnackbar({
                            message: "Error...Please try again!",
                            severity: "error",
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(
                    openSnackbar({
                        message: "Error...Please try again!",
                        severity: "error",
                    })
                );
            });
    };

    const getLidsFromRowsSelected = () => {
        const lidsSelected = [];
        rowsSelected.forEach((rowId) => {
            lidsSelected.push(listsData[rowId]["id"]);
        });
        return lidsSelected;
    };

    // close the create list modal
    const handleCloseAddList = () => {
        clearState();
        setModalOpen(false);
    };

    const clearState = () => {
        setListId("");
        setEditMode(false);
        setCurrentStep(1);
        setListStatus(1);
        setCounter(1);
        setFaqQuestion({});
        setFaqAnswer({});
        // setFaqListData(tmpFaq)
        setCompanyId("");
        setCampaignId("");
        setPlatformId("");
        setNewListName("");
        setNotes("");
        setDescription("");
        setTags([]);
        setMinFollowers(0);
        setMaxFollowers(0);
        setGeoType(2);
        setCurrency("");
        setInfBudget(0);
        setPaymentTerm("");
        setTimezoneLable(0);
        setTimezone(0);
        setDos(DOS);
        setDonts(DONTS);
        const contentBlock = htmlToDraft(TEMPLATE);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)));
        setConvertedContent("");
        setDisplayGeo("flex");
        setCountryLable("");
        setCountry("");
        setStateList(clistData);
        setStateLable("");
        setState("");
        setCityList(clistData);
        setCityLable("");
        setCity("");
        setDisplayPT("none");
        setNoOfDays(0);
        setDisplayDT("none");
        setPaymentDate("");
    };

    const handleChangeAccount = (event) => {
        const cid = event.currentTarget.dataset.value;
        setCompanyId(cid);
        utils.getCampaignDataById(cid).then((data) => {
            // give details of those campaigns that have utilized the given/common account
            var campaignList = data;
            if (campaignList == null) {
                var newCampaign = {};
                newCampaign["id"] = 0;
                newCampaign["name"] = "Account-Campaign Mapping Not Available";
                setErrorAlert("There is no campaign associated with this account, Please create campaign first to create a list.");
                campaignList = [];
                campaignList.push(newCampaign);
            } else {
                setErrorAlert("Please complete all required(*) fields.");
            }
            setCampaignList(campaignList);
        });
    };

    const handleChangeCampaign = (event) => {
        const cid = event.currentTarget.dataset.value;
        setCampaignId(cid);
    };

    const handleInputChange = (event) => {
        let val = event.target.value;
        setListNameCount(val.length)
        if (val.length > 24) {
            if (!disableNext) {
                setDisableNext(true);
            }
        } else {
            if (disableNext) {
                setDisableNext(false);
            }
        }
        setNewListName(event.target.value);
    };

    const handleChangePlatform = (event) => {
        const pid = event.currentTarget.dataset.value;
        setPlatformId(pid);
    };

    const handleDescChange = (event) => {
        event.preventDefault();
        setDescription(event.target.value);
    };

    const handleNotesChange = (event) => {
        event.preventDefault();
        setNotes(event.target.value);
    };

    const handleTimezoneChange = (event) => {
        setTimezone("");
        if (event !== null) {
            setTimezone(parseInt(event.id));
        }
    };

    const handleMinFollowersChange = (event) => {
        event.preventDefault();
        setMinFollowers(event.target.value);
    };

    const handleMaxFollowersChange = (event) => {
        event.preventDefault();
        setMaxFollowers(event.target.value);
    };

    const handleGeoTypeChange = (event) => {
        event.preventDefault();
        setGeoType(event.target.value);
        if (event.target.value === 1) {
            setCountry("");
            setState("");
            setCityList("");
            setDisplayGeo("none");
        } else {
            setDisplayGeo("flex");
        }
    };

    const getCity = (array, value) => {
        const result = array.find(({ name }) => name === value);
        return result;
    };
    const getCountry = (array, value) => {
        const result = array.find(({ isoCode }) => isoCode === value);
        return result;
    };
    const getState = (array, value) => {
        const result = array.find(({ isoCode }) => isoCode === value);
        return result;
    };

    const handleCountryChange = (event, value) => {
        if (event === null) {
            setState("");
            setStateLable(null);
            setCityList([]);
            setCity("");
            setCityLable(null);
            return;
        }
        var countryCode = event.isoCode;
        setStateList(State.getStatesOfCountry(countryCode));
        setCountry(countryCode);
        setCountryLable(getCountry(countryList, countryCode));
        setDisplayGeo("flex");
    };

    const handleStateChange = (event) => {
        if (event === null) {
            setState("");
            setStateLable(null);
            setCityList([]);
            setCity("");
            setCityLable(null);
            return;
        }
        var countryCode = event.countryCode;
        var stateCode = event.isoCode;
        setCityList(City.getCitiesOfState(countryCode, stateCode));
        setStateLable(getState(stateList, stateCode));
        setState(stateCode);
    };

    const handleCityChange = (event) => {
        if (event === null) {
            setCity("");
            setCityLable(null);
            return;
        }
        var city = event.name;
        setCity(city);
        setCityLable(getCity(cityList, event.name));
    };

    const handleCurrencyChange = (event) => {
        event.preventDefault();
        setCurrency(event.target.value);
    };

    const handleInfBudgetChange = (event) => {
        event.preventDefault();
        setInfBudget(event.target.value);
    };

    const handlePaymentChange = (event) => {
        event.preventDefault();
        var paymentTerm = event.target.value;
        setDisplayPT("none");
        setDisplayDT("none");
        if (parseInt(paymentTerm) === 4) {
            setDisplayPT("flex");
            setDisplayDT("none");
            setRequired(false);
        } else if (parseInt(paymentTerm) === 5) {
            setDisplayPT("none");
            setDisplayDT("flex");
            setRequired(true);
        }
        setPaymentTerm(event.target.value);
    };

    const handleNoOfDaysChange = (event) => {
        event.preventDefault();
        setPaymentDate("");
        setNoOfDays(event.target.value);
    };

    const handlePaymentDateChange = (event) => {
        event.preventDefault();
        setNoOfDays(0);
        setPaymentDate(event.target.value);
    };

    const handleKeyUp = (id, event) => {
        var keycode = event.keyCode ? event.keyCode : event.which;
        if (keycode == "13") {
            document.getElementById(id).value += "• ";
        }
        var txtval = document.getElementById(id).value;
        if (txtval.substr(txtval.length - 1) === "\n") {
            document.getElementById(id).value = txtval.substring(0, txtval.length - 1);
        }
        if (id === "dos") {
            setDos(document.getElementById(id).value);
        } else {
            setDonts(document.getElementById(id).value);
        }
    };

    const faqList = (event) => {
        setFaqmodalOpen(true);
    };

    const handleCloseFQA = () => {
        setCounter(counter + 1);
    };

    const handleRemoveClick = (index) => (e) => {
        let tmpFaqQ = {};
        let tmpFaqQnew = {};
        let tmpFaqAnew = {};
        tmpFaqQ = faqQuestion;
        let tmpFaqA = faqAnswer;
        delete tmpFaqQ[index];
        delete tmpFaqA[index];
        var i = 0;
        for (var j in tmpFaqQ) {
            tmpFaqQnew[i] = tmpFaqQ[j];
            tmpFaqAnew[i] = tmpFaqA[j];
            i++;
        }
        setFaqQuestion(tmpFaqQnew);
        setFaqAnswer(tmpFaqAnew);
        var count = counter - 1;
        setCounter(count);
    };


    const handleQuestionOnChange = (index) => (e) => {
        const abc = {};
        abc[index] = e.target.value;
        setFaqQuestion({ ...faqQuestion, ...abc });
    };

    const handleAnswerOnChange = (index) => (e) => {
        const abc = {};
        abc[index] = e.target.value;
        setFaqAnswer({ ...faqAnswer, ...abc });
    };

    const handleFaqClose = () => {
        setFaqmodalOpen(false);
    };

    const handleEditorChange = (state) => {
        setEditorState(state);
        setConvertedContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const draftButton = (event) => {
        if (editMode === false) {
            return (
                <FormControl style={{ marginBottom: "10px", float: "right", margin: "18px" }}>
                    <Button variant="outlined" color="secondary" onClick={(event) => draft(event)} size="large">
                        Save as Draft
                    </Button>
                </FormControl>
            );
        } else {
            if (parseInt(listStatus) === 6) {
                return (
                    <FormControl style={{ marginBottom: "10px", float: "right", margin: "18px" }}>
                        <Button variant="outlined" color="secondary" onClick={(event) => draft(event)} size="large">
                            Save as Draft
                        </Button>
                    </FormControl>
                );
            }
        }
    };

    const draft = (event) => {
        setListStatus(6);
        newListClick(event);
    };

    const previousButton = (event) => {
        let currentStepInner = currentStep;
        if (parseInt(currentStepInner) !== 1) {
            return (
                <FormControl style={{ marginBottom: "10px", float: "right", margin: "18px" }}>
                    <Button variant="outlined" onClick={prev} size="large">
                        Previous
                    </Button>
                </FormControl>
            );
        }
        return null;
    };

    const prev = (event) => {
        if (parseInt(currentStep) === 2) {
            setStepMessage("Basic Details");
        } else if (parseInt(currentStep) === 3) {
            setStepMessage("Targeting Details");
        } else if (parseInt(currentStep) === 4) {
            setStepMessage("Payment Details");
        } else if (parseInt(currentStep) === 5) {
            setStepMessage("Guidlines");
        } else if (parseInt(currentStep) === 6) {
            setStepMessage("Faq's");
        }
        let currentStepInner = currentStep;
        currentStepInner = currentStepInner <= 1 ? 1 : parseInt(currentStepInner) - 1;
        setCurrentStep(currentStepInner);
    };

    const nextButton = (event) => {
        let currentStepInner = currentStep;
        if (currentStepInner < 6) {
            return (
                <FormControl style={{ marginBottom: "10px", float: "right", margin: "20px" }}>
                    <Button variant="contained" color="success" onClick={next} size="large" disabled={disableNext}>
                        Next
                    </Button>
                </FormControl>
            );
        }
        return null;
    };

    const next = (event) => {
        if (!validateForm(currentStep)) return false;
        if (parseInt(currentStep) === 1) {
            setStepMessage("Targeting Details");
        } else if (parseInt(currentStep) === 2) {
            setStepMessage("Payment Details");
        } else if (parseInt(currentStep) === 3) {
            setStepMessage("Guidlines");
        } else if (parseInt(currentStep) === 4) {
            setStepMessage("Faq's");
        } else if (parseInt(currentStep) === 5) {
            setStepMessage("Sample Post");
        }
        let currentStepInner = currentStep;
        currentStepInner = currentStepInner >= 5 ? 6 : parseInt(currentStepInner) + 1;
        setCurrentStep(currentStepInner);
    };

    const validateForm = (step) => {
        // This function deals with validation of the form fields
        var valid = true;
        if (parseInt(step) === 1) {
            if (companyId === "" || campaignId === "" || campaignId == 0 || platformId === "" || newListName === "") {
                dispatch(
                    openSnackbar({
                        message: errorAlert,
                        severity: "error",
                    })
                );
                valid = false;
            }
        } else if (parseInt(step) === 2) {
            if (country === "") {
                dispatch(
                    openSnackbar({
                        message: errorAlert,
                        severity: "error",
                    })
                );
                valid = false;
            }
        }
        return valid; // return the valid status
    };

    const submitButton = (event) => {
        let currentStepInner = currentStep;
        if (editMode) {
            currentStepInner = 6;
        }
        if (currentStepInner === 6) {
            return (
                <FormControl style={{ marginBottom: "10px", float: "right", margin: "20px" }}>
                    <Button variant="contained" color="success" onClick={(event) => newListClick(event)} size="large">
                        Submit
                    </Button>
                </FormControl>
            );
        }
        return null;
    };

    // creates a new list 
    const newListClick = (event) => {
        var optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        var endPoint = "/list/add";

        const payload = {
            name: newListName,
            aid: companyId,
            cid: parseInt(campaignId, 10),
            platform: parseInt(platformId, 10),
            description: description,
            tags: tags.join(" , "),
            notes: notes,
            tz: timezone,
            geoType: 2,
            country: country,
            state: state,
            city: city,
            currency: currency,
            infBudget: infBudget,
            paymentTerm: paymentTerm,
            minFollowers: parseInt(minFollowers),
            maxFollowers: parseInt(maxFollowers),
            noOfDays: parseInt(noofDays),
            paymentDate: paymentDate,
            postContent: convertedContent,
            dos: dos,
            donts: donts,
            status: listStatus,
            addedFrom: "oryx",
        };
        var faqData = [];
        Object.keys(faqQuestion).forEach((c) => {
            var obj = {
                question: faqQuestion[c],
                answer: faqAnswer[c],
            };
            faqData.push(obj);
        });
        payload["faqs"] = JSON.stringify(faqData);
        if (listId > 0) {
            payload["id"] = listId;
            endPoint = "/list/edit";
            dispatch(
                openSnackbar({
                    message: "List updated successfully",
                    severity: "success",
                })
            );
        }
        axios.post(API_HOST + endPoint, payload, optionAxios).then((res) => {
            if (res["data"]["status"] !== "success") {
                dispatch(
                    openSnackbar({
                        message: "Could not create/update list",
                        severity: "error",
                    })
                );
            }
            setModalOpen(false);
            dispatch(
                openSnackbar({
                    message: `List ${newListName} has been created successfully!`,
                    severity: "success",
                })
            );
            //getData();
            fetchData()
        });
        event.preventDefault();
        return false;
    };

    const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
        setTablePageNo(newPage.page);
        setResetPageNo(false);
        setIsChanged(!isChanged);
        setTableRowsPerPage(newPage.rowsPerPage);
        setSortCol(newPage.orderBy);
        setSortOrder(newPage.order);
        setRowsSelected([]);
    };

    const handleCheckBox = (rowSelected) => {
        // setRowsSelected(rowSelected);       
        const selectedArray = Object.keys(rowSelected).map(key => parseInt(key) - 1);
        setRowsSelected(selectedArray);
    }


    // for editing a list , the below function provides the existing data of that particular list
    const getListDetails = (id, event) => {
        setListId(id);
        setbackdropShow(true);
        setEditing(true);
        utils.getListById(id).then((data) => {
            var listData = data;
            if (parseInt(listData[0]["status"]) === 6) {
                setEditMode(false);
                setListStatus(1);
            } else {
                setEditMode(true);
                setListStatus(listData[0]["status"]);
            }
            var tmpFaq = faqListData;
            var questions = {};
            var answers = {};
            var i = 0;
            if (listData[0]["faqs"] !== "" && listData[0]["faqs"] !== "[]") {
                var faqData = JSON.parse(listData[0]["faqs"]);
                faqData.forEach((element) => {
                    questions[i] = element["question"];
                    answers[i] = element["answer"];
                    tmpFaq["faqs"][tmpFaq["faqs"].length] = element;
                    i++;
                });
                setCounter(i);
                setFaqQuestion(questions);
                setFaqAnswer(answers);
            } else {
                setCounter(1);
            }
            setFaqListData(tmpFaq);
            setCompanyId(listData[0]["aid"]);
            setCampaignId(listData[0]["cid"]);
            setPlatformId(listData[0]["platform"]);
            setNewListName(listData[0]["name"]);
            setNotes(listData[0]["notes"]);
            setDescription(listData[0]["description"]);
            setTags(listData[0]["tags"].split(","));
            setMinFollowers(listData[0]["minFollowers"]);
            setMaxFollowers(listData[0]["maxFollowers"]);
            setGeoType(listData[0]["geoType"]);
            setCurrency(listData[0]["currency"]);
            setInfBudget(listData[0]["infBudget"]);
            setPaymentTerm(listData[0]["paymentTerm"]);
            setTimezoneLable(timezoneList[listData[0]["tz"]]);
            setTimezone(listData[0]["tz"]);
            if (listData[0]["dos"] !== "") {
                setDos(listData[0]["dos"]);
            }
            if (listData[0]["donts"] !== "") {
                setDonts(listData[0]["donts"]);
            }
            let html = TEMPLATE;
            if (listData[0]["postContent"] !== "") {
                html = listData[0]["postContent"];
            }
            const contentBlock = htmlToDraft(html);
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)));
            setConvertedContent(listData[0]["postContent"]);

            if (parseInt(listData[0]["geoType"]) === 2) {
                setDisplayGeo("flex");
                const country = listData[0]["country"];
                const state = listData[0]["state"];
                const city = listData[0]["city"];
                setCountryLable(getCountry(countryList, country));
                setCountry(country);
                const tmpStateList = State.getStatesOfCountry(country);
                setStateList(tmpStateList);
                setStateLable(getState(tmpStateList, state));
                setState(state);
                const tmpCityList = City.getCitiesOfState(country, state);
                setCityList(tmpCityList);
                setCityLable(getCity(tmpCityList, city));
                setCity(city);
            }
            if (parseInt(listData[0]["paymentTerm"]) === 4) {
                setDisplayPT("flex");
                setNoOfDays(listData[0]["noOfDays"]);
            } else if (parseInt(listData[0]["paymentTerm"]) === 5) {
                setDisplayPT("none");
                setDisplayDT("flex");
                setPaymentDate(listData[0]["paymentDate"]);
            }
            setModalOpen(true);
            setbackdropShow(false);
            // setisLoaded(true);
        });
    };

    const GetStatusId = (status) => {
        let statusId;

        if (status === "Populating") {
            statusId = 1;
        }
        if (status === "Active") {
            statusId = 2;
        }
        if (status === "Locked") {
            statusId = 3;
        }
        if (status === "Finished") {
            statusId = 4;
        }
        if (status === "Archived") {
            statusId = 5;
        }
        if (status === "Draft") {
            statusId = 6;
        }
        if (status === "Delete") {
            statusId = 7;
        }

        return statusId;
    }

    // for refreshing the list
    const refreshList = (lid, status) => {
        dispatch(
            openSnackbar({
                message: "Please wait we are refreshing the status of your list",
                severity: "info",
            })
        );
        var optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const payload = {
            lid: lid,
            status,
        };
        axios
            .post(API_HOST + "/list/status/update", payload, optionAxios)
            .then((res) => {
                // const statusCode = res['data']['StatusCode'];
                dispatch(
                    openSnackbar({
                        message: res.data ? "Status refreshed successfully" : "Unable to update list status",
                        severity: res.data ? "success" : "error",
                    })
                );
                fetchData()
            })
            .catch((error) => {
                console.error("Error while updating list status:", error);
            });
    };

    // for changing the status of the list
    const changeListStatus = (lid, status) => {
        dispatch(
            openSnackbar({
                message: "Please wait we are updating the status of your list",
                severity: "info",
            })
        );
        var optionAxios = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const payload = {
            listId: lid,
            status,
        };
        axios
            .post(API_HOST + "/list/changeStatus", payload, optionAxios)
            .then((res) => {
                // const statusCode = res['data']['StatusCode'];
                dispatch(
                    openSnackbar({
                        message: res.data ? "Status updated successfully" : "Unable to update list status",
                        severity: res.data ? "success" : "error",
                    })
                );
                fetchData()
            })
            .catch((error) => {
                console.error("Error while updating list status:", error);
            });
    };

    const getCreatedDate = (createdAt) => {
        const parts = createdAt.split('-');
        const dateString = parts[1]?.trim();
        return dateString
    }

    useEffect(() => {
        const handleClick = (event, index) => {
            const anchorEl = [];
            for (let i = 0; i < listsData.length; i++) {
                if (i === index) anchorEl.push(event.currentTarget);
                else anchorEl.push(null);
            }
            setAnchorE1(anchorEl);
        };

        const handleClose = (i) => {
            const anchorEl = [];
            for (let i = 0; i < listsData.length; i++) {
                anchorEl.push(null);
            }
            setAnchorE1(anchorEl);
        };

        const data = [];
        if (listsData) {
            listsData.forEach((item, index) => {
                const {

                    accountName,
                    campaignId,
                    campaignName,
                    comment,
                    country,
                    createdOn,
                    directCount,
                    emailCount,
                    engagement,
                    groupName,
                    id,
                    lastPostedOn,
                    like,
                    organicCount,
                    platform,
                    profileClick,
                    reply,
                    retweet,
                    share,
                    status,
                    tags,
                    totalInf,
                    totalPost,
                    totalReach,
                } = item;

                let statusId = GetStatusId(status);
                let createdDate = getCreatedDate(createdOn);

                const actions = (
                    <div>

                        {type == 1 && (
                            <>
                                <MenuItem
                                    component={Link}
                                    to={
                                        "../list/details?id=" +
                                        id +
                                        "&name=" +
                                        groupName +
                                        "&cid=" +
                                        campaignId +
                                        "&cname=" +
                                        campaignName +
                                        "&platform=" +
                                        platform +
                                        "&aname=" +
                                        accountName +
                                        "&type=unarchived"
                                    }
                                    key={1}
                                    id={"View-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                    }}
                                >
                                    <PreviewIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;View Influencers in list
                                </MenuItem>
                            </>
                        )}

                        {type != 1 && (
                            <>
                                <MenuItem
                                    component={Link}
                                    to={
                                        "../list/details?id=" +
                                        id +
                                        "&name=" +
                                        groupName +
                                        "&platform=" +
                                        platform +
                                        "&cname=" +
                                        campaignName +
                                        "&type=archived"
                                    }
                                    key={1}
                                    id={"View-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                    }}
                                >
                                    <PreviewIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;View Influencers in list
                                </MenuItem>
                            </>
                        )}

                        {type == 1 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={2}
                                    id={"Edit-" + index}
                                    onClick={() => {
                                        handleClose(index);
                                        getListDetails(id);
                                    }}
                                >
                                    <EditIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Edit this list
                                </MenuItem>
                            </>
                        )}

                        {type == 1 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={3}
                                    component={Link}
                                    to={"../posts/draft/?id=" + campaignId}
                                    id={"Posts-" + index}
                                >
                                    <FeedIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Review Unapproved Drafts
                                </MenuItem>
                            </>
                        )}

                        {type == 1 && (
                            <>
                                {/* Refresh */}
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={4}
                                    id={"Refresh-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                        refreshList(id, -1);
                                    }}
                                >
                                    <CachedIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Refresh this list
                                </MenuItem>
                            </>
                        )}


                        {/* Archive */}
                        {type == 1 && statusId === 4 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={5}
                                    id={"Archive-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                        if (window.confirm("Archive this list ?")) {
                                            changeListStatus(id, 5);
                                        }
                                    }}
                                >
                                    <ArchiveIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Archive this list
                                </MenuItem>
                            </>
                        )}

                        {/* Lock */}
                        {type == 1 && statusId === 1 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={7}
                                    id={"Lock-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                        if (window.confirm("Lock this list ?")) {
                                            changeListStatus(id, 3);
                                        }
                                    }}
                                >
                                    <LockIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Lock this list
                                </MenuItem>
                            </>
                        )}

                        {/* Unlock */}
                        {type == 1 && statusId === 3 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={8}
                                    id={"Unlock-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                        if (window.confirm("Unlock this list ?")) {
                                            changeListStatus(id, 6);
                                        }
                                    }}
                                >
                                    <LockOpenIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Unlock this list
                                </MenuItem>
                            </>
                        )}

                        {type == 2 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={2}
                                    id={"Archive-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                        if (window.confirm("Restore the List ?")) {
                                            changeListStatus(id, 4);
                                        }
                                    }}
                                >
                                    <UnarchiveIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Unarchive this list
                                </MenuItem>
                            </>
                        )}

                        {type == 2 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={3}
                                    id={"Delete-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                        if (window.confirm("Delete this list ?")) {
                                            changeListStatus(id, 7);
                                        }
                                    }}
                                >
                                    <UnarchiveIcon fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Delete this list
                                </MenuItem>
                            </>
                        )}

                        {type == 3 && (
                            <>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                    key={2}
                                    id={"Delete-" + index}
                                    onClick={(event) => {
                                        handleClose(index);
                                        if (window.confirm("Delete this list forever ?")) {
                                            // this.removeToCollection(11);
                                            var optionAxios = {
                                                headers: {
                                                    "Content-Type": "application/x-www-form-urlencoded",
                                                },
                                            };
                                            /* only finished lists can be archived. So when unarchiving, mark it finished */
                                            const payload = {
                                                lid: id,
                                            };
                                            axios
                                                .post(`${API_HOST}/list/delete`, payload, optionAxios)
                                                .then((res) => {
                                                    // const statusCode = res['data']['StatusCode'];
                                                    dispatch(
                                                        openSnackbar({
                                                            message: "List deleted successfully!",
                                                            severity: "success",
                                                        })
                                                    );
                                                    window.location = `/list/deleted?platform=${listPlatform}`; //This line of code will redirect you once the submission is succeed
                                                })
                                                .catch((error) => {
                                                    console.error("Error while updating list status:", error);
                                                });
                                        }
                                    }}
                                >
                                    <Delete fontSize="small" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;Delete this list
                                </MenuItem>
                            </>
                        )}


                    </div>
                );

                const open = anchorEl.map((val) => val !== null);

                const object = {
                    id: index + 1,
                    sl: (
                        <Grid container>

                        </Grid>
                    ),
                    name: (
                        <>
                            <Grid container spacing={1} sx={{ justifyContent: "flex-start" }}>
                                <Grid item sx={{ width: "40px" }}>

                                    <Typography sx={textColor[platform]}>{snapshotHeaderIcons[platform]}</Typography>

                                </Grid>
                                <Grid item md={8} sm={5}>
                                    <Box
                                        component={"a"}
                                        sx={{
                                            cursor: "pointer",
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 800,
                                            fontSize: "1.1rem",
                                            lineHeight: "1rem",
                                            color: "#1461cc",
                                        }}
                                        href={`../list/details?id=${id}&name=${groupName}&cid=${campaignId}&cname=${campaignName}&platform=${platform}&aname=${accountName}&type=unarchieved`}
                                    >
                                        {groupName}
                                    </Box>
                                    {/* <div style={{ fontStyle: "italic", paddingLeft: "1px", color: "#999" }}>by account</div> */}
                                </Grid>
                            </Grid>
                        </>
                    ),
                    cname: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                <p className={styles.bodycell}>{campaignName}</p>
                            </Grid>
                        </Grid>
                    ),
                    influencersCnt: (
                        <Grid container sx={{ paddingTop: "20px", paddingLeft: "10px" }}>
                            <Grid item xs={2}>
                                {totalInf > 0 ? (
                                    <p className={styles.bodycell}>{totalInf}</p>
                                ) : (
                                    <p className={styles.bodycell}>
                                        <span style={{ paddingLeft: "20px" }}>-</span>
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Tooltip
                                    title={
                                        totalInf > 0 ? (
                                            <>
                                                <Typography color="inherit">Influencers Breakup</Typography>
                                                {organicCount > 0 && (
                                                    <>
                                                        <b>{"Signed up :"}</b> <span>{organicCount}</span>
                                                    </>
                                                )}
                                                {directCount > 0 && (
                                                    <>
                                                        <br />
                                                        <b>{"Direct :"}</b> <span>{directCount}</span>
                                                    </>
                                                )}
                                                {emailCount > 0 && (
                                                    <>
                                                        <br />
                                                        <b>{"Email Invited :"}</b> <span>{emailCount}</span>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }
                                >
                                    <InfoIcon fontSize="small" style={{ opacity: totalInf > 0 ? "0.5" : "0" }} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    postCount: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px", paddingLeft: "40px" }}>
                                {totalPost !== 0 ? (
                                    <p className={styles.bodycell}>{totalPost}</p>
                                ) : (
                                    <p className={styles.bodycell}>{"-"}</p>
                                )}
                            </Grid>
                        </Grid >
                    ),
                    status: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingRight: "20px" }}>
                                <DropdownButton
                                    as={ButtonGroup}
                                    key={status.toLowerCase()}
                                    id={`dropdown-variants-${status.toLowerCase()}`}
                                    variant={status.toLowerCase()}
                                    title={
                                        <span>
                                            <Circle
                                                htmlColor={groupCodes[status.toLowerCase()]?.color}
                                                style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                                            />
                                            <span className="statusCell" style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                                                {status}
                                            </span>
                                        </span>
                                    }
                                >
                                    {Object.keys(groupCodes).map((status) => {
                                        return (
                                            status.toLowerCase() !== "delete" &&
                                            status.toLowerCase() !== "draft" && (
                                                <Dropdown.Item
                                                    key={status}
                                                    eventKey={status}
                                                    className="dropdownItem"
                                                    onClick={() => changeListStatus(id, groupCodes[status]["id"])}
                                                >
                                                    <span>
                                                        <Circle
                                                            htmlColor={groupCodes[status.toLowerCase()].color}
                                                            style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                                                        />
                                                        <span className="statusCell" style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                                                            {utils.capitalizeFirstLetter(status)}
                                                        </span>
                                                    </span>
                                                </Dropdown.Item>
                                            )
                                        );
                                    })}
                                </DropdownButton>
                            </Grid>
                        </Grid >
                    ),
                    createdOn: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px", paddingLeft: "10px" }}>
                                {createdDate !== "-" ? (
                                    <p className={styles.bodycell}>{createdDate}</p>
                                ) : (
                                    <p className={styles.bodycell}>-</p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    Action: (
                        <Grid container>
                            <Grid item xs={3}>
                                <>
                                    {" "}
                                    <IconButton onClick={(event) => handleClick(event, index)} size="small" sx={{ ml: 2 }}>
                                        <MoreVertOutlined />
                                    </IconButton>
                                    <Box style={{ borderRadius: "30%" }}>
                                        <Menu
                                            anchorEl={anchorEl[index]}
                                            getContentAnchorEl={null}
                                            open={open[index]}
                                            onClose={handleClose}
                                            className={tableStyle.menuitem}
                                        >
                                            {actions}
                                        </Menu>
                                    </Box>
                                </>
                            </Grid>
                        </Grid>
                    ),
                };

                data.push(object);
            });
            setTableData(data);
        }
    }, [listsData, anchorEl]);

    const tableHeaders = getListTableHeaders;

    const tableHeadersArchived = getListTableHeadersArchived;

    const renderContentHandler = () => {
        let content = null;
        if (isLoaded) {
            if (dataFound) {
                content = (
                    <>
                        <div id="table">
                            {/* This is a custom made component, take care while changing field names */}
                            <CustomTable
                                tableHeaders={type == 1 ? tableHeaders : tableHeadersArchived}
                                bodyColumns={tableData}
                                sendInformation={handleInformationChange}
                                totalRows={totalRecords}
                                pageNo={tablePageNo}
                                rowsPerPageNo={tableRowsPerPage}
                                sortOrder={sortOrder}
                                sortOrderBy={SortCol}
                                rowSelectionChanged={handleCheckBox}
                            />
                        </div>
                        {/* This is a custom made component, take care while changing field names */}
                        {/* <GeneratePptReport pptReportId={pptReportId} setPptReportId={setPptReportId} needPastReports={true} /> */}
                    </>
                );
            } else {
                content = (
                    <Grid container spacing={2}>
                        <Grid style={styles.noData} item xs={12}>
                            You don't have any list at the moment.
                        </Grid>
                    </Grid>
                );
            }
        } else content = <Loader />;

        return content;
    };

    const tabArray = [
        {
            label: `Active Lists (${totalActive})`,
            id: "list-details-tab-0",
            to: `/list?platform=${listPlatform}`,
            component: Link,
        },
        {
            label: `Archived Lists (${totalInactive})`,
            id: "list-details-tab-1",
            to: `/list/archived?platform=${listPlatform}`,
            component: Link,
        },
        {
            label: `Deleted Lists (${totalDeleted})`,
            id: "list-details-tab-2",
            to: `/list/deleted?platform=${listPlatform}`,
            component: Link,
        },
    ]

    const listPlatformHandler = (platform) => {
        setListPlatform(platform);
        navigate(`/list?platform=${platform}`);
        setTablePageNo(0);
        if (type === 3) {
            navigate(`/list/deleted?platform=${platform}`);
        } else if (type === 2) {
            navigate(`/list/archived?platform=${platform}`);
        } else {
            navigate(`/list?platform=${platform}`);
        }
    };
    return (
        <>
            <Box mt={4} mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography variant={"h5"} mr={2}>
                        Platform:{" "}
                    </Typography>
                    <ButtonGroup variant="outlined" aria-label="platform button group" disableElevation>
                        <Button
                            variant={listPlatform === "oryx" ? "contained" : "outlined"}
                            onClick={() => listPlatformHandler("oryx")}
                        >
                            Oryx
                        </Button>
                        <Button
                            variant={listPlatform === "empowr" ? "contained" : "outlined"}
                            onClick={() => listPlatformHandler("empowr")}
                        >
                            Empowr
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <SearchItem sendSearchedValue={sendSearchedValue} searchBy={searchBy} />
                    <ExportFiles sendExportSelected={sendExportSelected} />
                </Box>
            </Box>

            <Box m={3}>
                <CustomTabs tabArray={tabArray} selectedTab={selectedTab} />
                <Stack direction="row" justifyContent="end">
                    {listPlatform === "oryx" && (
                        <div>
                            <Button
                                sx={{ marginRight: "30px" }}
                                variant="contained"
                                id="demo-customized-button"
                                onClick={createNewList}
                            >
                                + Create A List
                            </Button>

                            {type === 1 && (
                                <Button
                                    variant="contained"
                                    onClick={removeListClick}
                                    disabled={rowsSelected.length === 0}
                                >
                                    Remove List
                                </Button>
                            )}
                        </div>
                    )}
                </Stack>
            </Box>

            <Box my={1}>{renderContentHandler()}</Box>

            {/* handles create a list */}
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                onClose={handleCloseAddList}
                closeAfterTransition
                fullWidth
                maxWidth="md"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <h2>Add List - {stepMessage}</h2>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseAddList}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div id="modal-add_influencer_to_list-body" style={{ padding: "10px" }}>
                        <Grid item xs={12}>
                            <Box>
                                <React.Fragment>
                                    <form id="stepForm" onSubmit={newListClick}>
                                        {currentStep == 1 && (
                                            <div>
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                                        <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                                            Account *
                                                        </InputLabel>
                                                        <Select
                                                            disabled={editing}
                                                            required={true}
                                                            label="Account *"
                                                            id="companyId"
                                                            InputLabelProps={{ shrink: true }}
                                                            value={companyId || ""}
                                                            onChange={handleChangeAccount}
                                                            input={<OutlinedInput label="Account *" id="demo-dialog-native" />}
                                                        >
                                                            {companyList.map((aname) => (
                                                                <MenuItem
                                                                    value={aname.id}
                                                                    key={aname.id}
                                                                    style={{ height: '50px' }}
                                                                >
                                                                    {aname.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                                                        <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                                            Campaign Name *
                                                        </InputLabel>
                                                        <Select
                                                            required={true}
                                                            disabled={editing}
                                                            label="Campaign Name *"
                                                            InputLabelProps={{ shrink: false }}
                                                            value={campaignId || ""}
                                                            id="campaignId"
                                                            onChange={handleChangeCampaign}
                                                            refs="campaignId"
                                                            input={<OutlinedInput label="Campaign Name *" id="demo-dialog-native" />}
                                                        >
                                                            {campaignList?.map((cname) => (
                                                                <MenuItem value={cname.id} key={cname.id}>
                                                                    {cname.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                                        <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                                            Platform *
                                                        </InputLabel>
                                                        <Select
                                                            disabled={editing}
                                                            required={true}
                                                            label="Platform *"
                                                            InputLabelProps={{ shrink: false }}
                                                            id="platformId"
                                                            value={platformId}
                                                            onChange={handleChangePlatform}
                                                            refs="platformId"
                                                            input={<OutlinedInput label="Platform *" id="demo-dialog-native" />}
                                                        >
                                                            <MenuItem value={1} key={1}>
                                                                Instagram
                                                            </MenuItem>
                                                            <MenuItem value={2} key={2}>
                                                                Twitter
                                                            </MenuItem>
                                                            <MenuItem value={3} key={3}>
                                                                TikTok
                                                            </MenuItem>
                                                            <MenuItem value={4} key={4}>
                                                                YouTube
                                                            </MenuItem>
                                                            <MenuItem value={5} key={5}>
                                                                LinkedIn
                                                            </MenuItem>
                                                            <MenuItem value={6} key={6}>
                                                                Facebook
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="newListName"
                                                            required={true}
                                                            refs="newListName"
                                                            label="List Name"
                                                            value={newListName}
                                                            onChange={handleInputChange}
                                                            helperText={
                                                                listNameCount <= 24 ? (
                                                                    <div style={{ marginTop: -5 }}>list Name can have maximum 24 characters</div>
                                                                ) : (
                                                                    <div style={{ color: "red", marginTop: -5 }}>You've exceeded the 24 character limit for the list name.</div>
                                                                )
                                                            }
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <span style={{ fontSize: 12 }}>{listNameCount} /25</span>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                                        <TextField
                                                            variant="outlined"
                                                            required={false}
                                                            type="text"
                                                            value={description}
                                                            id="description"
                                                            refs="description"
                                                            name="description"
                                                            label="Description"
                                                            onChange={handleDescChange}
                                                        />
                                                    </FormControl>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                                                        <ReactTagInput
                                                            variant="outlined"
                                                            sx={{ height: "1.4375em" }}
                                                            tags={tags}
                                                            placeholder="Tags"
                                                            refs="tags"
                                                            name="tags"
                                                            label="Tags"
                                                            maxTags={3}
                                                            onChange={(newTags) => setTags(newTags)}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                                        <TextareaAutosize
                                                            variant="outlined"
                                                            minRows={2}
                                                            required={false}
                                                            refs="note"
                                                            placeholder="Notes"
                                                            value={notes}
                                                            onChange={handleNotesChange}
                                                            style={{ height: 56, borderRadius: "4px", borderColor: "#c4c4c4" }}
                                                        />
                                                    </FormControl>

                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                                                        <Autocomplete
                                                            options={timezoneList}
                                                            getOptionLabel={(timezone) => timezone.text}
                                                            defaultValue={timezoneLable || timezoneLable[0]}
                                                            onChange={(event, value) => handleTimezoneChange(value)}
                                                            id="timezone-data"
                                                            renderInput={(params) => <TextField {...params} label="Timezone" />}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}></Grid>
                                            </div>
                                        )}
                                        {currentStep == 2 && (
                                            <div>
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    variant="outlined"
                                                                    required={false}
                                                                    refs="minfollowers"
                                                                    label="Min Followers"
                                                                    type="number"
                                                                    value={minFollowers}
                                                                    onChange={handleMinFollowersChange}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    variant="outlined"
                                                                    required={false}
                                                                    refs="maxfollowers"
                                                                    label="Max Followers"
                                                                    type="number"
                                                                    value={maxFollowers}
                                                                    onChange={handleMaxFollowersChange}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-standard-label">Geo Target</InputLabel>
                                                                <Select
                                                                    required={false}
                                                                    label="Geo Target"
                                                                    id="geoTypeId"
                                                                    value={geoType}
                                                                    onChange={handleGeoTypeChange}
                                                                    refs="geoTypeId"
                                                                    input={<OutlinedInput label="Geo Target" id="demo-dialog-native" />}
                                                                    disabled
                                                                >
                                                                    <MenuItem value={1} key={1}>
                                                                        Gobal
                                                                    </MenuItem>
                                                                    <MenuItem value={2} key={2}>
                                                                        Specific
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth>
                                                                <Autocomplete
                                                                    options={countryList}
                                                                    required={true}
                                                                    getOptionLabel={(country) => country.name}
                                                                    defaultValue={countryLable || ""}
                                                                    value={countryLable || null}
                                                                    onChange={(event, value) => handleCountryChange(value)}
                                                                    id="country-data"
                                                                    renderInput={(params) => <TextField {...params} label="Country *" />}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth>
                                                                <Autocomplete
                                                                    options={stateList}
                                                                    getOptionLabel={(state) => state.name}
                                                                    defaultValue={stateLable || ""}
                                                                    value={stateLable || null}
                                                                    onChange={(event, value) => handleStateChange(value)}
                                                                    id="state-data"
                                                                    renderInput={(params) => <TextField {...params} label="State" />}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth>
                                                                <Autocomplete
                                                                    options={cityList}
                                                                    getOptionLabel={(city) => city.name}
                                                                    defaultValue={cityLable || ""}
                                                                    value={cityLable || null}
                                                                    onChange={(event, value) => handleCityChange(value)}
                                                                    id="state-data"
                                                                    renderInput={(params) => <TextField {...params} label="City" />}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={3}></Grid>
                                            </div>
                                        )}
                                        {currentStep == 3 && (
                                            <div>
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Currency</InputLabel>
                                                        <Select
                                                            required={false}
                                                            label="Currency"
                                                            id="currencyId"
                                                            value={currency}
                                                            onChange={handleCurrencyChange}
                                                            refs="currencyId"
                                                            input={<OutlinedInput label="Currency" id="demo-dialog-native" />}
                                                        >
                                                            <MenuItem value="$">US Dollar ($)</MenuItem>
                                                            <MenuItem value="£">British Pound (£)</MenuItem>
                                                            <MenuItem value="€">Euro (€)</MenuItem>
                                                            <MenuItem value="KSh">Kenyan Shilling (KSh) </MenuItem>
                                                            <MenuItem value="R">South African Rand (R) </MenuItem>
                                                            <MenuItem value="₦">Nigerian Naira (₦)</MenuItem>
                                                            <MenuItem value="₹">Indian Rupee (₹)</MenuItem>


                                                        </Select>
                                                    </FormControl>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                                                        <TextField
                                                            variant="outlined"
                                                            required={false}
                                                            refs="bugdet"
                                                            label="Per Influencer Budget"
                                                            type="number"
                                                            value={infBudget}
                                                            onChange={handleInfBudgetChange}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6} style={{ display: "flex", width: "100%" }}>
                                                    <Grid item xs={12} sm={6} style={{ display: "flex", width: "50%" }}>
                                                        <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1, marginRight: "10px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Payment Term</InputLabel>
                                                            <Select
                                                                required={false}
                                                                label="Payment Term"
                                                                id="paymentId"
                                                                value={paymentTerm}
                                                                onChange={handlePaymentChange}
                                                                refs="paymentId"
                                                                input={<OutlinedInput label="Payment Term" id="demo-dialog-native" />}
                                                            >
                                                                <MenuItem value={1} key={1}>
                                                                    Weekly
                                                                </MenuItem>
                                                                <MenuItem value={2} key={2}>
                                                                    Bi-Weekly/Fortnightly
                                                                </MenuItem>
                                                                <MenuItem value={3} key={3}>
                                                                    Monthly
                                                                </MenuItem>
                                                                <MenuItem value={4} key={4}>
                                                                    No. Days after Task Completion
                                                                </MenuItem>
                                                                <MenuItem value={5} key={5}>
                                                                    Particular Payment Date (s) - On a particular day
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid id="noofDays" item xs={12} sm={6} style={{ maxWidth: "50%", flex: 1, display: displayPT }}>
                                                        <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginLeft: "10px" }}>
                                                            <TextField
                                                                variant="outlined"
                                                                required={true}
                                                                refs="noofDays"
                                                                label="No Of Days"
                                                                type="number"
                                                                value={noofDays}
                                                                onChange={handleNoOfDaysChange}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid id="onDate" item xs={12} sm={6} style={{ maxWidth: "50%", flex: 1, display: displayDT }}>
                                                        <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginLeft: "10px" }}>
                                                            <TextField
                                                                clearable
                                                                value={paymentDate}
                                                                id="paymentDate"
                                                                variant="outlined"
                                                                name="paymentDate"
                                                                label="Payment Date"
                                                                InputLabelProps={{ shrink: true, required: true }}
                                                                type="date"
                                                                required={required}
                                                                onChange={handlePaymentDateChange}
                                                                sx={{ width: "100%" }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={3}></Grid>
                                            </div>
                                        )}
                                        {currentStep == 4 && (
                                            <div>
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl
                                                        style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px", minHeight: "240px" }}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            id="dos"
                                                            name="dos"
                                                            label="Do's"
                                                            multiline
                                                            minRows={4}
                                                            required={false}
                                                            refs="dos"
                                                            placeholder="Do's"
                                                            onKeyUp={(event) => {
                                                                handleKeyUp("dos", event);
                                                            }}
                                                            style={{ height: "240px", borderRadius: "4px", borderColor: "#c4c4c4", minHeight: "240px" }}
                                                            defaultValue={dos}
                                                        />
                                                    </FormControl>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="donts"
                                                            name="donts"
                                                            label="Dont's"
                                                            multiline
                                                            minRows={4}
                                                            required={false}
                                                            refs="Dont"
                                                            placeholder="Dont's"
                                                            onKeyUp={(event) => {
                                                                handleKeyUp("donts", event);
                                                            }}
                                                            style={{ height: "auto", borderRadius: "4px", borderColor: "#c4c4c4", minHeight: "240px" }}
                                                            defaultValue={donts}
                                                        />{" "}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={3}></Grid>
                                                <br />
                                            </div>
                                        )}
                                        {currentStep == 5 && (
                                            <div>
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl style={{ width: "90%", marginBottom: "15px" }}>
                                                        <Button
                                                            variant="contained"
                                                            className="btn btn-success float-right"
                                                            type="button"
                                                            id="demo-customized-button"
                                                            aria-controls={open ? "demo-customized-menu" : undefined}
                                                            onClick={faqList}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? "true" : undefined}
                                                            style={{ float: "right", width: "100px" }}
                                                        >
                                                            {" "}
                                                            All FAQ
                                                        </Button>
                                                    </FormControl>
                                                    <FormControl style={{ marginBottom: "30px", display: "inline" }}>
                                                        <Button
                                                            variant="contained"
                                                            className="btn btn-success float-right"
                                                            type="button"
                                                            onClick={handleCloseFQA}
                                                        >
                                                            {" "}
                                                            + FAQ
                                                        </Button>
                                                    </FormControl>
                                                </Grid>
                                                <div style={{ maxHeight: "330px", overflow: "auto" }}>
                                                    {/* Array.from() is amethod takes an array-like or iterable object as its first argument and creates 
                                                       a new array from it. In this case, the array-like object is the one created by Array(counter). */}
                                                    {Array.from(Array(counter)).map((c, index) => {
                                                        return (
                                                            <div>
                                                                <FormControl
                                                                    style={{
                                                                        width: "100%",
                                                                        marginBottom: "15px",
                                                                        flex: 1,
                                                                        marginRight: "20px",
                                                                        marginTop: "5px",
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        variant="outlined"
                                                                        required={false}
                                                                        type="text"
                                                                        value={faqQuestion[index]}
                                                                        id="question"
                                                                        refs="question"
                                                                        name="question"
                                                                        label="Question"
                                                                        onChange={handleQuestionOnChange(index)}
                                                                    />
                                                                </FormControl>
                                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                                    <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            required={false}
                                                                            type="text"
                                                                            value={faqAnswer[index]}
                                                                            id="answer"
                                                                            refs="answer"
                                                                            name="answer"
                                                                            label="Answer"
                                                                            onChange={handleAnswerOnChange(index)}
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl style={{ marginBottom: "30px", display: "block", marginTop: "15px" }}>
                                                                        <RemoveCircleOutlineIcon style={{ display: "block" }} onClick={handleRemoveClick(index)} />
                                                                    </FormControl>
                                                                </Grid>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {currentStep == 6 && (
                                            <div>
                                                <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                    <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                                        <Editor
                                                            editorState={editorState}
                                                            onEditorStateChange={handleEditorChange}
                                                            wrapperClassName="wrapper-class"
                                                            editorClassName="editor-class"
                                                            toolbarClassName="toolbar-class"
                                                            toolbar={{
                                                                options: ["image", "emoji", "link", "history"],
                                                                image: { inDropdown: true },
                                                                emoji: { inDropdown: true },
                                                                link: {
                                                                    inDropdown: true,
                                                                    showOpenOptionOnHover: true,
                                                                    defaultTargetOption: "_self",
                                                                    options: ["link", "unlink"],
                                                                },
                                                                history: { inDropdown: true },
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={3}></Grid>
                                            </div>
                                        )}
                                    </form>
                                </React.Fragment>
                            </Box>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {draftButton()}
                        {previousButton()}
                    </div>
                    <div>
                        {nextButton()}
                        {submitButton()}
                    </div>
                </DialogActions>
            </BootstrapDialog>

            {/* Modal of All FAQ */}
            <Modal
                className="styles_modal"
                aria-labelledby="modal-add_influencer_to_list-label"
                aria-describedby="modal-add_influencer_to_list-description"
                open={faqmodalOpen}
                onClose={handleFaqClose}
                closeAfterTransition
            >
                <Fade in={faqmodalOpen}>
                    <div class="syles_paper_list" style={{ height: "auto", width: "50%" }}>
                        <div id="modal-add_influencer_to_list-heading" className="styles_modal_heading">
                            <h2 id="modal-add_influencer_to_list-heading-text" style={{ padding: "10px" }}>
                                All Faqs
                            </h2>
                            <IconButton onClick={handleFaqClose}>
                                {" "}
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <hr />
                        <div id="modal-add_influencer_to_list-body" style={{ padding: "10px" }}>
                            <Grid item xs={12}>
                                <Box>
                                    <Grid item xs={12} sm={12} style={{ display: "block", maxWidth: "100%", maxHeight: "160px", overflow: "auto" }}>
                                        <Grid item xs={12} sm={6} style={{ display: "block", maxWidth: "100%" }}>
                                            <FAQS data={faqListData} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3}></Grid>
                                </Box>
                            </Grid>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* modal for deleating the list */}
            <Modal
                className="styles_modal"
                aria-labelledby="modal-remove_influencer_from_list-label"
                aria-describedby="modal-remove_influencer_from_list-description"
                open={removeListMod}
                onClose={handleCloseConfModal}
                closeAfterTransition
            >
                <Fade in={removeListMod}>
                    <div style={{ backgroundColor: "white", outline: "none", padding: "10px", width: "35rem" }}>
                        <div id="modal-remove_influencer_from_list-heading" className="styles_modal_heading">
                            <h2 id="modal-remove_influencer_from_list-heading-text" style={{ padding: "10px" }}>
                                Remove List
                            </h2>
                            <IconButton onClick={handleCloseConfModal}>
                                {" "}
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <hr />
                        <div id="modal-remove_influencer_from_list-body" style={{ padding: "10px" }}>
                            Are you sure want to remove?
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                <Button style={{ margin: "0.5rem" }} variant="contained" onClick={handleCloseConfModal}>
                                    No
                                </Button>
                                <Button style={{ margin: "0.5rem" }} variant="contained" onClick={removeListModalConfirmation}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default ListTable;

