import { PropTypes } from "prop-types";

import {
  Stack,
} from "@mui/material";

import MyPaper from "../general/MyPaper";

const ProfileTab_Overview_BrandAffinity = ({ brandAffinityDetails }) => {

  // checkMe: return data from this function. not required as of now.
  const getBrandAffinity = () => {
    return;
  }

  return (
    <MyPaper sx={{height: "100%"}}>
      <div id="ProfileTab_Overview_BrandAffinity">
        <Stack spacing={2} justifyContent={"space-between"}>
          <Stack
            id="ProfileTab_Overview_BrandAffinity_title"
            direction="row" justifyContent="center"
          >
            <span className="extendedDetailsCard_title">BRAND AFFINITY</span>
          </Stack>
          <Stack
            id="ProfileTab_Overview_BrandAffinity_brands"
            direction="row" justifyContent="center"
          >
            {getBrandAffinity()}
          </Stack>
        </Stack>
      </div>
    </MyPaper>
  );

}

export default ProfileTab_Overview_BrandAffinity;

ProfileTab_Overview_BrandAffinity.propTypes = {
  brandAffinityDetails: PropTypes.array.isRequired,
}