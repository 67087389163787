import { colors } from "@mui/material";

const Palette = {
  background: {
    default: "#F4F6F8",
    paper: colors.common.white,
  },
  primary: {
    contrastText: "#ffffff",
    main: "#33cc99",
    light: "#3af0b3",
    dark: "#289470",
  },
  secondary: {
    main: "#757575",
    light: "#949494",
    dark: "#5c5c5c",
  },
  text: {
    primary: "#4f4f4f",
    secondary: "#6b778c",
  },
  danger: {
    contrastText: "#ffffff",
    main: "#FF505F",
    light: "#FF303F",
    dark: "#FF303F",
  },
  report: {
    main: "#B00020",
    light: "#B00020",
    dark: "#B00020",
  },
  instagram: {
    main: "#BC2A8D",
  },
  twitter: {
    main: "#00ACEE",
  },
  youtube: {
    main: "#FF0100",
  },
  linkedin: {
    main: "#0a66c2",
  },
  facebook: {
    main: "#1b74e4",
  },
  profileTimelineIcon: {
    main: "#2F80ED",
  }
};
export default Palette;
