import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_HOST } from "../../config/main.json";
import Datatables from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DraftEditor from "../../components/general/DraftEditor";
import utils from "../../utils/utils";
import CampaignActions from "./CampaignActions";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";
import Loader from "./Loader";


function ContractTemplates() {
  const [cid, setCid] = useState(0);
  const [templateData, setTemplateData] = useState(null);
  const [preivewModalOpen, setPreivewModalOpen] = useState(false);
  const [previewModalContent, setPreviewModalContent] = useState(null);
  const [contractInputFieldsData, setContractInputFieldsData] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [kycRequired, setKycRequired] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [tabArray, setTabArray] =  useState([]);
  const [loding, setLoding] = useState(true);
  

  const [pptReportId, setPptReportId] = useState(0);

  const getTemplates = (id) => {
    axios.get(API_HOST + "/contract/templates/" + id).then((res) => {
      if (res) {
        setLoding(false)
      }
      const templateData = res.data;
      setTemplateData(templateData);
    });
  };

  const getContractInputFields = () => {
    axios.get(API_HOST + "/contract/inputs").then((res) => {
      setContractInputFieldsData(res.data);
    });
  };

  const getCampaignInfo = (id) => {
    utils.getCampaignData(id).then((data) => {
      setCampaignName(data.name);
    });
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    const tabArr = campaignTabsArray(campId);
    setCid(campId);
    setTabArray(tabArr);
    getTemplates(campId);
    getContractInputFields();
    getCampaignInfo(campId);
  }, []);

  const handleContractPreview = (id) => {
    axios.get(API_HOST + "/contract/templateContent/" + id).then((res) => {
      const autocompleteData = contractInputFieldsData.filter((value) => {
        const inputFields = res.data[0].inputFields.split(",");
        if (inputFields.length > 0) {
          return inputFields.map((v) => +v).includes(value.id);
        }
        return [];
      });
      const kycRequired = res.data[0].kycRequired;
      setAutocompleteData(autocompleteData);
      setKycRequired(kycRequired);
      setPreviewModalContent(res.data);
      setPreivewModalOpen(true);
    });
  };

  let tableData = [];
  if (templateData !== null) {
    tableData = templateData.map((template, index) => {
      const data = { ...template };
      data["sl"] = index + 1;
      let updatedDate = new Date(template["updatedAt"]);
      if (new Date() - updatedDate < 1000 * 60 * 60 * 24) {
        data["updatedDate"] = updatedDate.toString().split("GMT")[0];
      } else {
        data["updatedDate"] = updatedDate.toDateString();
      }
      data["used"] = template["isUse"] ? "Yes" : "No";

      return data;
    });
  }

  const columns = [
    {
      label: "Template Name",
      name: "title",
      options: {
        filter: false,
      },
    },
    {
      label: "Description",
      name: "description",
      options: {
        filter: false,
      },
    },
    {
      label: "Last Updated",
      name: "updatedDate",
    },
    {
      label: "Used",
      name: "used",
    },
    {
      label: "Actions",
      name: "id",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowID = value;
          const used = tableMeta.rowData[3];

          return (
            <div>
              <IconButton
                component={Link}
                to={"./edit?cid=" + cid + "&id=" + rowID}
                id={"Edit-" + tableMeta.rowData[0]}
                onClick={(event) => {}}
              >
                <Tooltip title={"Edit"}>
                  <EditIcon fontSize="small" />
                </Tooltip>
              </IconButton>
              <IconButton onClick={(event) => handleContractPreview(rowID)}>
                <Tooltip title={"Preview"}>
                  <PreviewIcon fontSize="small" />
                </Tooltip>
              </IconButton>
              {used === "No" && (
                <IconButton
                  onClick={(event) => {
                    if (window.confirm("Are you sure you want to delete the template?")) {
                      axios.get(API_HOST + "/contract/template/delete/" + rowID).then((res) => {
                        window.location = "/campaigns/contracts/templates?id=" + cid;
                      });
                    }
                  }}
                >
                  <Tooltip title={"Delete"}>
                    <DeleteIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    download: false,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
  };

  const tabArray2 = [
    {
      label: "Contracts",
      id: "contracts-tab-0",
      to: `/campaigns/contracts?id=${cid}`,
      component: Link,
    },
    {
      label: "Contract Templates",
      id: "contract-templates-tab-0",
      to: `/campaigns/contracts/templates?id=${cid}`,
      component: Link,
    },
  ]

  return (
    <>
      <CampaignActions name={campaignName} id={cid} />
      <br />
      <CustomTabs tabArray={tabArray} selectedTab={"5"} />

      <Box m={2}>
      <CustomTabs tabArray={tabArray2} selectedTab={"1"} />
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" component={Link} to={"./add?id=" + cid}>
            + Template
          </Button>
        </Stack>
      </Box>
      {loding ? (
        <Loader />
      ) : (
        <Datatables data={tableData} columns={columns} options={options} />
      )}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-preview_contract-label"
        aria-describedby="modal-preview_contract-description"
        open={preivewModalOpen}
        onClose={() => setPreivewModalOpen(false)}
        closeAfterTransition
      >
        <Fade in={preivewModalOpen}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-preview_contract-heading" className="styles_modal_heading">
              <h2 id="modal-preview_contract-heading-text" style={{ padding: "10px" }}>
                {previewModalContent && previewModalContent[0].title}
              </h2>
              <IconButton onClick={() => setPreivewModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div id="modal-preview_contract-body" style={{ padding: "10px" }}>
              <Grid item xs={12} className="hide-toolbar">
                <DraftEditor value={previewModalContent && previewModalContent[0].content} readOnly={true} />
              </Grid>
              <br />
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  readOnly
                  id="checkboxes-tags-demo"
                  options={contractInputFieldsData}
                  getOptionLabel={(option) => option.input}
                  value={autocompleteData}
                  freeSolo
                  disabled={autocompleteData.length === 0}
                  renderInput={(params) => (
                    <>
                      {autocompleteData.length > 0 && <TextField {...params} label="Input Fields" />}
                      {autocompleteData.length === 0 && <TextField {...params} label="Input Fields (None Selected)" />}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormControlLabel label="Mandatory KYC" control={<Checkbox checked={kycRequired} />} />
                </FormControl>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default ContractTemplates;
