import React from "react";
import { Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";

function CampaignTabs(props) {
  const linkToAnalyticsTab = `/campaigns/analysis?id=${props.campId}`;
  const linkToInfluencersTab = `/campaigns/influencers?id=${props.campId}`;
  const linkToLinksTab = `/campaigns/linkAnalysis?id=${props.campId}`;
  const linkToContractsTab = `/campaigns/contracts?id=${props.campId}`;
  // const linkToAudienceTab = "/campaigns/audience?id=" + props.campId;
  const postAnalysisLink = `/campaigns/postAnalysis?id=${props.campId}`;
  const listAnalysisLink = `/campaigns/listAnalysis?id=${props.campId}`;
  const linkToSentimentsTab = `/campaigns/sentiment/analysis?id=${props.campId}`;
  const linkToAppLinkTab = `/campaigns/Applinks?id=${props.campId}`;
  const taskLinkTab = `/campaigns/tasks?id=${props.campId}`;
  const reportsLinkTab = `/campaigns/reports?id=${props.campId}`;

  return (
    <Tabs
      ml={4}
      variant="scrollable"
      scrollButtons="auto"
      value={props.selectedTab}
      sx={{
        ".MuiTab-root": {
          textTransform: "none",
          fontSize: "14px"
        },
      }}
    >
      <Tab label="Analytics" id="0" value="0" to={linkToAnalyticsTab} component={Link} />
      {/* <Tab label="Audience" id="1" value="1" to={linkToAudienceTab} component={Link} /> */}
      <Tab label="Influencers" id="2" value="2" to={linkToInfluencersTab} component={Link} />
      <Tab label="Posts" id="3" value="3" to={postAnalysisLink} component={Link} />
      <Tab label="Lists" id="4" value="4" to={listAnalysisLink} component={Link} />
      <Tab label="Links" id="5" value="5" to={linkToLinksTab} component={Link} />
      <Tab label="Contracts" id="6" value="6" to={linkToContractsTab} component={Link} />
      <Tab label="Sentiment" id="7" value="7" to={linkToSentimentsTab} component={Link} />
      <Tab label="Firebase" id="8" value="8" to={linkToAppLinkTab} component={Link} />
      <Tab label="Tasks" id="9" value="9" to={taskLinkTab} component={Link} />
      <Tab label="Reports" id="10" value="10" to={reportsLinkTab} component={Link} />
    </Tabs>
  );
}

export default CampaignTabs;
