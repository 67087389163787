import { Avatar } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Button from "@mui/material/Button";
import { number, object } from "prop-types";
import { FavoriteBorderOutlined, Verified } from "@mui/icons-material";
import stringAvatar from "../../utils/avatar";
import TwitterCommentIcon from "../../styles/icons/twittercomment";
import TwitterRetweetIcon from "../../styles/icons/twitterretweet";
import TwitterShare from "../../styles/icons/twittershare";
import showIcon from "../../utils/icons";
import { GetPostText, RenderCardMedia } from "../../utils/mediacontent";
import PostActionButton from "../../pages/account/posts/PostActionButton";
import { renderData } from "../../utils/platform-asset-calculator";
import statusMapping from "../../models/notification";
import { format } from "date-fns";

const xStyle = {
  twitterPost: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #e1e8ed',
    borderRadius: '10px',
    padding: '15px',
    width: '600px',
    margin: '20px auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  userInfo: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',  // Align items in a single row
  },
  userName: {
    fontWeight: 'bold',
  },
  userHandle: {
    color: '#657786',
    fontSize: '14px',
    marginLeft: '5px',  
  },
  postDate: {
    color: '#657786',
    fontSize: '14px',
    marginLeft: '5px', 
  },
  content: {
    marginTop: '10px',
  },
  postImage: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "MMMM d, yyyy");
};

const TwitterPost = ({ formData, cid }) => {
  return (
    <>
      <div style={xStyle.header}>
        <Avatar
          sx={{ width: 55, height: 55 }}
          {...stringAvatar(formData.influencerName)}
        />
        <div style={xStyle.userInfo}>
          <span style={xStyle.userName}>
            {formData.influencerName}
            {formData.isVerified && (
              <Verified style={{ color: '#1DA1F2', marginLeft: '5px' }} />
            )}
          </span>
          <span style={xStyle.userHandle}>@{formData.influencerHandle ? formData.influencerHandle : "twitter" }</span>
          <span style={xStyle.postDate}>{formatDate(formData.createdAt)}</span>
        </div>
        <div className="d-flex justify-content-end">
          {/* {showIcon(formData.platform)} */}
          {formData.preview && (
            <PostActionButton id={formData.id} cid={cid} />
          )}
        </div>
      </div>
      <div style={xStyle.content}>
        <p>{renderData(formData.postContent, formData.preview)}</p>
        <Carousel fullHeightHover={false} style={{ marginBottom: "10px" }}>
          {RenderCardMedia(formData)}
        </Carousel>
        {formData.preview && (
          <Button
            style={{
              backgroundColor: statusMapping.filter(
                (value) => value.id === formData.currentStatus
              )[0]?.color,
              width: "100%",
              fontSize: "12px",
              borderRadius: "10px",
            }}
            variant="contained"
          >
            {GetPostText(formData)}
          </Button>
        )}
      </div>
      <div style={xStyle.actions}>
        <Button startIcon={<TwitterCommentIcon />} size="small">
          {formData.comments}
        </Button>
        <Button startIcon={<TwitterRetweetIcon />} size="small">
          {formData.retweets}
        </Button>
        <Button startIcon={<FavoriteBorderOutlined />} size="small">
          {formData.likes}
        </Button>
        <Button startIcon={<TwitterShare />} size="small" />
      </div>
    </>
  );
};

TwitterPost.propTypes = {
  formData: object.isRequired,
  cid: number.isRequired,
};

export default TwitterPost;
