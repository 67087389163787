import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from '@mui/lab/TimelineDot';

import Typography from "@mui/material/Typography";
import MyPaper from '../general/MyPaper';

import { PropTypes } from "prop-types";

import {
  School,
  Work,
  ExpandMore,
} from '@mui/icons-material';

import {
  Stack,
} from "@mui/material";

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const titleIcon = {
  "WORK EXPERIENCE": <Work fontSize="large" color="profileTimelineIcon" />,
  "EDUCATION": <School fontSize="large" color="profileTimelineIcon" />,
}

const ProfileTimeline = ({ listName, listData }) => {

  const renderPostHistoryTimeline = () => {
    return listData.map((dataPoint, ind) => {
      return (
        <TimelineItem key={ind}>
          <TimelineOppositeContent style={{ flex: 0.1 }}>
          <span className="timeline-itemOnLeft">{dataPoint.itemOnLeft}</span>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot style={{backgroundColor: "#000000"}} />
            <TimelineConnector style={{backgroundColor: "#000000"}} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            {
              dataPoint.heading &&
              <Typography variant="h4" component="span">
                <span className="timeline-heading">{dataPoint.heading}</span>
                <br/>
              </Typography>
            }
            {
              (dataPoint.subheading || dataPoint.subheadingDesc) &&
              <Typography variant="h5" component="span">
                <span className="timeline-subheading">{dataPoint.subheading}&nbsp;</span>
                <span className="timeline-subheadingDesc">{dataPoint.subheadingDesc}</span>
                <br/>
              </Typography>
            }
            {
              dataPoint.website &&
              <Typography variant="h5" component="span">
                <span className="timeline-website"><a href={dataPoint.website}>{dataPoint.website}</a></span>
                <br/>
              </Typography>
            }
            {
              dataPoint.description &&
              <Typography variant="h5" component="span">
                <span className="timeline-description">{dataPoint.description}</span>
              </Typography>
            }
          </TimelineContent>
        </TimelineItem>
      )
    });
  }

  const drawTimeline = () => {
    if (!listData || listData.length===0) {
      return;
    }
    return (
      <Timeline align="alternate">
        {renderPostHistoryTimeline()}
        <TimelineItem>
          <TimelineOppositeContent style={{ flex: 0.1 }}>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot style={{backgroundColor: "#000000", padding: "0px"}}>
              <KeyboardArrowDownOutlinedIcon style={{fontSize: "8px", padding: "0px"}}/>
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  }

  return (
    <MyPaper sx={{height: "100%"}}>
      <div id="ProfileTimeline">
        <Stack direction={"row"} spacing={2}>
          <span className="timelineTitle-icon">{titleIcon[listName]}</span>
          <span className="timelineTitle">{listName}</span>
        </Stack>
        {drawTimeline()}
      </div>
    </MyPaper>
  )
}

ProfileTimeline.propTypes = {
  listName: PropTypes.string.isRequired,
  listData: PropTypes.array.isRequired,
}

export default ProfileTimeline;