import { useState, useEffect } from "react";

import PropTypes from 'prop-types';

import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseIcon from "@material-ui/icons/Close";

import MyPaper from "../general/MyPaper";


const DiscoveryAdvanceFilters = ({ defaultValues, applyAdvFilters, clearAdvFilters, advFilters_inf, advFilters_aud, advFilterData_inf, advFilterData_aud, advance_CloseBtnClicked }) => {

  const [infDataSelected, setInfDataSelected] = useState({});
  const [audDataSelected, setAudDataSelected] = useState({});

  const [defaultValuesUpdated, setDefaultValuesUpdated] = useState(true);

  useEffect(() => {
    const defaultValues_inf = defaultValues.Influencer;
    const defaultValues_aud = defaultValues.Audience;

    if (Object.keys(defaultValues_inf).length === 0) {
      setInfDataSelected({});
    } else {
      const restructedDefaultValues = {};
      Object.keys(defaultValues_inf).map((filterName) => {
        const defualtValues_filter = defaultValues_inf[filterName];
        const filters = advFilters_inf.filter((advFilter) => (advFilter.title===filterName));
        if (filters.length > 0) {
          restructedDefaultValues[filterName] = defualtValues_filter;
        }
      });
      setInfDataSelected(restructedDefaultValues);
    }

    if (Object.keys(defaultValues_aud).length === 0) {
      setAudDataSelected({});
    } else {
      const restructedDefaultValues = {};
      Object.keys(defaultValues_aud).map((filterName) => {
        const defualtValues_filter = defaultValues_aud[filterName];
        const filters = advFilters_aud.filter((advFilter) => (advFilter.title===filterName));
        if (filters.length > 0) {
          restructedDefaultValues[filterName] = defualtValues_filter;
        }
      });
      setAudDataSelected(restructedDefaultValues);
    }

    const updation = !defaultValuesUpdated
    setDefaultValuesUpdated(updation);
  }, [defaultValues]);

  const handleSelection = (filterType, title, values, multiple) => {
    let tempValue = [];
    if (!multiple) {
      tempValue = values;
    } else {
      tempValue.push(...values);
    }

    if (filterType==="Influencer") {
      const updateSelectedData = infDataSelected;
      updateSelectedData[title] = tempValue;
      setInfDataSelected(updateSelectedData);
      const updation = !defaultValuesUpdated;
      setDefaultValuesUpdated(updation);
    } else if (filterType==="Audience") {
      const updateSelectedData = audDataSelected;
      updateSelectedData[title] = tempValue;
      setAudDataSelected(updateSelectedData);
      const updation = !defaultValuesUpdated;
      setDefaultValuesUpdated(updation);
    }
  }

  const closeAdvanceFilter = () => {
    advance_CloseBtnClicked();
  }

  const clearAdvanceFilters = () => {
    clearAdvFilters();
  }

  const applyAdvanceFilters = () => {
    applyAdvFilters(infDataSelected, audDataSelected);
  }

  /* filterType: "Influencer" / "Audience" */
  const renderSingleAdvanceFilter = (filter, filterData, filterType) => {
    const type = ("type" in filter)?(filter.type):("");
    const title = ("title" in filter)?(filter.title):("");
    const multiple = ("multiple" in filter)?(filter.multiple):(false);
    const isObject = ("isObject" in filter)?(filter.isObject):(false);

    let val = "";
    if (filterType==="Influencer" && title in infDataSelected) {
      val = infDataSelected[title];
    } else if (filterType==="Audience" && title in audDataSelected) {
      val = audDataSelected[title];
    } else {
      if (multiple) {
        val = [];
      } else {
        if (filterData && filterData.length > 0) {
          if (typeof filterData[0]==="object") {
            val = null;
          } else { // checkMe: check this later on
            val = ""
          }
        } else { // checkMe: check this later on
          val=""
        }
      }
    }

    if(type==="textArea") {
      return(
        <Grid item xs={2} sm={2} md={2}>
          <Autocomplete
            fullWidth
            multiple={multiple}
            limitTags={1}
            options={[]}
            value={val}
            freeSolo
            onChange={(_e, v) => handleSelection(filterType, title, v, multiple)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={title}
              />
            )}
          />
        </Grid>
      )
    } else if (type==="select") {
      return (
        <Grid item xs={2} sm={2} md={2}>
          <Autocomplete
            fullWidth
            multiple={multiple}
            limitTags={1}
            options={filterData}
            value={val}
            getOptionLabel={(option) => (isObject?option:option)}
            onChange={(_e, v) => handleSelection(filterType, title, v, multiple)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={title}
                />
              );
            }}
          />
        </Grid>
      )
    } else if (type==="number") {
      return (
        <Grid item xs={2} sm={2} md={2}>
          <FormControl fullWidth>
            <InputLabel>
              {title}
            </InputLabel>
            <Select
              fullWidth
              variant="outlined"
              label={title}
              value={val}
              onChange={(e) => handleSelection(filterType, title, e.target.value, false)}
            >
              {
                filterData.map((v, ind) => (
                  <MenuItem key={ind} value={v.value}>{v.id}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      )
    }
  }

  const renderGroupAdvanceFilters = ((advFilters, advFiltersData, title) => {
    if (!advFilters || advFilters.length===0) {
      return(<></>);
    }
    return advFilters.map((advFilter) => {
      const name = advFilter.name;
      const filterData = (advFiltersData[name])?(advFiltersData[name]):([]);
      return renderSingleAdvanceFilter(advFilter, filterData, title);
    });

  });

  return ((advFilters_inf && advFilters_inf.length > 0) || (advFilters_aud && advFilters_aud.length > 0)) ?
  (
    <MyPaper sx={{ marginBottom: "20px" }}>
      <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 12 }}>
        <Grid item xs={4} sm={6} md={12}>
          <Stack direction="row" justifyContent="space-between">
            <div className="advancedFilterTitle">Filters</div>
            <IconButton onClick={() => closeAdvanceFilter()}><CloseIcon className="filter-close-icon" /></IconButton>
          </Stack>
        </Grid>
        <Grid item xs={4} sm={6} md={12}>
          <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid item xs={4} sm={6} md={12}>
              Influencer:
            </Grid>
            <Grid item xs={4} sm={6} md={12}>
              <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 10 }}>
                {renderGroupAdvanceFilters(advFilters_inf, advFilterData_inf, "Influencer")}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*<Grid item xs={4} sm={6} md={12}>
          <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid item xs={4} sm={6} md={12}>
              Audience:
            </Grid>
            <Grid item xs={4} sm={6} md={12}>
              <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 10 }}>
                {renderGroupAdvanceFilters(advFilters_aud, advFilterData_aud, "Audience")}
              </Grid>
            </Grid>
          </Grid>
        </Grid>*/}
        <Grid item xs={4} sm={6} md={12}>
          <Stack direction="row" justifyContent="end">
            <Stack id="advancedFilterButtons" direction="row" justifyContent="end" spacing={{ xs: 1, sm: 2, md: 2 }}>
              <Button variant="outlined" onClick={clearAdvanceFilters}>Clear Filters</Button>
              <Button variant="contained" onClick={applyAdvanceFilters}>Apply</Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </MyPaper>
  ):(
    <Grid container spacing={2} columns={{xs: 5, sm: 5, md: 10}}>
      <Grid item xs={5} sm={5} md={10}>
        <Stack direction="row" justifyContent="space-between">
          <div id="noAdvancedFilter">No filter data available.</div>
          <IconButton onClick={() => closeAdvanceFilter()} style={{float: "right"}}><CloseIcon className="filter-close-icon" /></IconButton>
        </Stack>
      </Grid>
    </Grid>
  )
}

DiscoveryAdvanceFilters.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  applyAdvFilters: PropTypes.func.isRequired,
  clearAdvFilters: PropTypes.func.isRequired,
  advFilters_inf: PropTypes.array.isRequired,
  advFilters_aud: PropTypes.array.isRequired,
  advFilterData_inf: PropTypes.object.isRequired,
  advFilterData_aud: PropTypes.object.isRequired,
  advance_CloseBtnClicked: PropTypes.func.isRequired,
}

export default DiscoveryAdvanceFilters;