import { PropTypes } from "prop-types";

import {
  Stack
} from "@mui/material";

import { InfoOutlined } from '@mui/icons-material';

import MyPaper from "../general/MyPaper";

const ProfileTab_Overview_FollowersCredibility = ({ followersCredibilityDetails }) => {

  return (
    <MyPaper sx={{height: "100%"}}>
      <div id="ProfileTab_Overview_FollowersCredibility">
        <Stack spacing={2} justifyContent={"spaceBetween"}>
          <Stack
            id="ProfileTab_Overview_FollowersCredibility_title"
            direction="row" justifyContent="center"
          >
            <span className="extendedDetailsCard_title">FOLLOWERS CREDIBILITY</span>
            {/* checkMe: add tooltip here */}
            <span className="extendedDetailsCard_titleIcon">&nbsp;<InfoOutlined fontSize="xx-small"/></span>
          </Stack>
          <Stack
            id="ProfileTab_Overview_FollowersCredibility_score"
            direction="row" justifyContent="center"
          >
            <span id="extendedDetailsCard_followersCredibility_highlightedText">{followersCredibilityDetails}%</span>
          </Stack>
        </Stack>
      </div>
    </MyPaper>
  );

}

export default ProfileTab_Overview_FollowersCredibility;

ProfileTab_Overview_FollowersCredibility.propTypes = {
  followersCredibilityDetails: PropTypes.number.isRequired,
}