const enums = {
  "maxItemsInListView": 5,
  "displayOptions": {
    "insights": false,
    "followersCredibility": false,
    "brandAffinity": false,
    "interests": false,
    "postTopics": false,
    "educations": false,
    "experiences": false,
    "groups": false,
  },
  "dummy_data": {
    "iid": "62d8184c4758a2c6799be0c6",
    "username": "mario-fernsebner-3627791a",
    "vendorId": "BD",
    "bio": "Langjährige Erfahrung im Langstrecken Bereich. Ziel: Erweiterung der Geschäftsbeziehung im Europäischen Raum (Rückholdienst). Abwicklung der Transporte für Internationale Assistance in ganz Österreich.Spezialgebiete: Kranken und Notfalltransporte, Rückholdienst National - und International, Intensivtransporte, Organisation von Arztvisiten in ganz Österreich.■ Inter hospital transfers ■ Repatriations abroad ■ Intensive care transfers ■ Qualified patient transfers ■ Dialysis and radiation therapy transport ■ Disabled and wheelchair transport ■ Transfers to and from home ■ Driving services ■ Ambulance services ■ Flight accompaniment ■ Organizing doctor visits throughout Austria",
    "profile_picture": "",
    "certifications": [],
    "location": "Austria area",
    "country": "AT",
    "courses": [],
    "current_company": {
      "name": "",
      "link": null
    },
    "education": [
      {
        "title": "Handelsschule Zell am See",
        "meta": "",
        "degree": "",
        "field": "",
        "url": "",
        "start_year": "2020",
        "end_year": ""
      }
    ],
    "educations_details": "",
    "experience": [
      {
        "company": "Grünes Kreuz Rettungsdienst",
        "location": "",
        "url": "",
        "positions": [
          {
            "title": "Unternehmensinhaber",
            "subtitle": "Grünes Kreuz Rettungsdienst",
            "description": "Managment",
            "duration": "Dec 2008 - Present12 years 4 months",
            "start_date": "",
            "end_date": "",
            "duration_short": ""
          }
        ]
      },
      {
        "company": "Grünes Kreuz Rettungsdienst Zell am See Austria",
        "location": "Zell am See - Austria",
        "url": "",
        "positions": [
          {
            "title": "General Manager",
            "subtitle": "Grünes Kreuz Rettungsdienst Zell am See Austria",
            "description": "Das Grüne Kreuz ist eine Private Rettungsorganisation und hat ihre Leistungen auf europaweite Rückholungen und Intensivtransporte spezialisiert. Weiters werden Transporte (Ambulance, Taxi, Ersatzfahrer, Intensivtransporte) für Assistance im In- und Ausland von unserer Zentrale aus organisiert und selbst durchgeführt. Organisation von Arztvisiten ist ein weiterer Zweig welchen wir ganz Österreich anbieten können.",
            "duration": "Apr 1997 - Present24 years",
            "start_date": "",
            "end_date": "",
            "duration_short": ""
          }
        ]
      },
      {
        "company": "Grünes Kreuz Zell am See",
        "location": "",
        "url": "",
        "positions": [
          {
            "title": "Operation/Network",
            "subtitle": "Grünes Kreuz Zell am See",
            "description": "",
            "duration": "May 1999 - Nov 20078 years 7 months",
            "start_date": "",
            "end_date": "",
            "duration_short": ""
          }
        ]
      }
    ],
    "following": 500,
    "groups": [
      {
        "title": "Insurance Agents of Southern California",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "International Assistance Group",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Saudi Arabia Business and Professional Network",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Treatment Abroad, Evacuations, Repatriations, Doctor on-site",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Forum for Travel, Medical \u0026 Security Assistance",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "International Private Medical Insurance Magazine - iPMI Magazine",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Allianz Network",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Arabian Travel Market",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "The International Assistance Network",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Medical Travel \u0026 Tourism to China, Hong Kong, Korea \u0026 Taiwan",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Emergency Medical Services",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Dutch Health Network ® het platform voor Zorg en Welzijn",
        "subtitle": "-",
        "img": null
      },
      {
        "title": "Russia \u0026 CIS Healthcare | Insurance, Benefits, Medical Travel, Buyers of Healthcare, Medical Tourism",
        "subtitle": "-",
        "img": null
      }
    ],
    "languages": [
      {
        "title": "Deutsch",
        "subtitle": "Native or bilingual proficiency"
      },
      {
        "title": "Englisch",
        "subtitle": "Full professional proficiency"
      }
    ],
    "name": "Mario Fernsebner",
    "lookalikes": [],
    "job_title": "",
    "posts": [
      {
        "title": "",
        "attribuion": "Liked by Mario Fernsebner"
      },
      {
        "title": "",
        "attribuion": "Liked by Mario Fernsebner"
      },
      {
        "title": "",
        "attribuion": "Liked by Mario Fernsebner"
      }
    ],
    "region": "EU",
    "last_updated": "0001-01-01T00:00:00Z",
    "profile_link": "",
    "avatar_cached": null,



    "tags": ["Fashion", "Cosmetics", "Design"],
    "influenceScore": 88,
    "platformsInfluence": [
      {
        "platform": "twitter",
        "influence": 90,
      }, {
        "platform": "facebook",
        "influence": 75,
      },
    ],
    "engagement": 4.5,
    "avgEngagement": 9,
    "platformsEngagement": [
      {
        "platform": "twitter",
        "engagement": 2.3,
      }, {
        "platform": "facebook",
        "engagement": 2.3,
      }, {
        "platform": "instagram",
        "engagement": 2.3,
      }
    ],
    "fans": 34789,
    "platformsFans": [
      {
        "platform": "Facebook",
        "fans": 3000,
      }, {
        "platform": "Twitter",
        "fans": 7800,
      }, {
        "platform": "Instagram",
        "fans": 9300,
      }
    ],
    "platforms": [
      {
        "id": 1,
        "name": "linkedin",
      }
    ],
    "insightPercentage": 4.5,
    "insights": [
      {
        "label": "likes",
        "value": 3300,
      }, {
        "label": "comments",
        "value": 9,
      }, {
        "label": "views",
        "value": 100,
      }, {
        "label": "shares",
        "value": 1200,
      },
    ],
    "followersCredibility": 83.64,
    "brandAffinity": [
      "BMW",
      "Netflix",
      "Amex",
      "Playstation"
    ],
    "interests": [
      "Friends, Family & Relationships",
      "Friends, Family & Relationships",
      "Friends, Family & Relationships",
      "Friends, Family & Relationships",
      "Friends, Family & Relationships",
    ],
    "postTopics": [
      "raghudixit",
      "bangalore",
      "gokarnabeach",
      "magictricks",
      "legomovie",
    ],
  },
}

export default enums;