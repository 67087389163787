import { BadgeTwoTone, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tab,
  TableCell,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyPaper from "../../components/general/MyPaper";
import TabPanel from "../../components/general/TabPanel";
import TransferList from "../../components/general/TransferList";
import { API_HOST, PLATFORM_NAME } from "../../config/main.json";
import utils from "../../utils/utils";
import { snapshotHeaderIcons, textColor } from "../../components/campaignAnalysis/snapshot";
import CampaignTabs from "./CampaignTabs";
import Datatables from "mui-datatables";
import { useSelector } from "react-redux";
import CampaignActions from "./CampaignActions";

const SendContract = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [backdropShow, setBackdropShow] = useState(false);
  const [cid, setCid] = useState(0);
  const [listData, setListData] = useState(null);
  const [list, setList] = useState(null);
  const [templateData, setTemplateData] = useState(null);
  const [template, setTemplate] = useState("");
  const [selectedTemplateContent, setSelectedTemplateContent] = useState("");
  const [showTransfer, setShowTransfer] = useState(false);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [templateTags, setTemplateTags] = useState([]);
  const [tagsModal, setTagsModal] = useState(false);
  const [commonTagValues, setCommonTagValues] = useState([]);
  const [tagValues, setTagValues] = useState([]);
  const [tagModalData, setTagModalData] = useState([]);
  const [tagModalColumn, setTagModalColumn] = useState([]);
  const [tagModalOptions, setTagModalOptions] = useState([]);
  const [disableTagsSend, setDisableTagsSend] = useState(true);
  const user = useSelector((state) => state.user);
  const notificationMetadata = {
    userId: JSON.parse(user.attributes.identities)[0].userId,
    sourcePlatform: PLATFORM_NAME,
    notificationTypeId: "4",
    notificationActionId: "2",
    notificationCategoryId: "7",
    generatedBy: user.attributes.name,
    priority: "medium",
    consumedBy: "gazelle",
    navigateTo: "/account/contracts",
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    setCid(campId);
    getTemplateData(campId);
    getListData(campId);
    getCampaignInfo(campId);
  }, []);

  const getTemplateData = (id) => {
    axios.get(API_HOST + "/contract/templates/" + id).then((res) => {
      const templateData = res.data;
      setTemplateData(templateData ? templateData : []);
    });
  };

  const getListData = (id) => {
    axios.get(API_HOST + "/lists/camp/id/" + id).then((res) => {
      const listData = res.data;
      setListData(listData ? listData : []);
    });
  };

  const getCampaignInfo = (id) => {
    utils.getCampaignData(id).then((data) => {
      setCampaignName(data.name);
    });
  };

  useEffect(() => {
    if (templateData !== null && listData !== null) {
      setIsLoaded(true);
      setBackdropShow(false);
    }
  }, [templateData, listData]);

  const handleTemplateChange = (e) => {
    const value = e.target.value;
    setTemplate(value);
    axios.get(API_HOST + `/contract/templateContent/${value}`).then((res) => {
      if (res.data.length > 0) {
        setSelectedTemplateContent(res.data[0].content);
      }
    });
  };

  useEffect(() => {
    if (template !== null && list !== null) {
      getInfluencers(cid, template, list.id);
    }
  }, [template, list]);

  useEffect(() => {
    setSelectedCount(right.filter((inf) => !inf.disabled).map((inf) => inf.id).length);
  }, [right]);

  const getInfluencers = (cid, template, list) => {
    axios.get(API_HOST + `/contract/influencers/organic/${cid}/${template}/${list}`).then((res) => {
      const influencers = res.data?.length > 0 ? res.data : [];
      const left = [];
      const right = [];
      influencers.forEach((influencer) => {
        const inf = {};
        inf["id"] = influencer.id;
        if (influencer.infName === "") {
          return;
        }
        inf["label"] = influencer.infName;
        inf["disabled"] = false;
        if (influencer.status === "") {
          left.push(inf);
        } else if (influencer.status === "Expired") {
          inf["label"] += " (Expired)";
          left.push(inf);
        } else if (["Pending", "Accepted"].includes(influencer.status)) {
          inf["label"] += ` (${influencer.status})`;
          inf["disabled"] = true;
          right.push(inf);
        }
      });

      setLeft(left);
      setRight(right);
      setShowTransfer(true);
    });
  };

  const checkMergeTags = (template) => {
    const tags = [];
    let content = template;
    while (content.indexOf("{{") !== -1) {
      const start = content.indexOf("{{");
      const len = content.substring(start).indexOf("}}") + 1;
      if (len === 0) {
        content = content.replace("{{", "");
        continue;
      } else if (len > 50) {
        content = content.replace("{{", "");
        content = content.replace("}}", "");
        continue;
      }
      const tag = content.substring(start + 2, start + len - 1);
      content = content.replace("{{", "");
      content = content.replace("}}", "");

      if (!tags.includes(tag)) {
        tags.push(tag);
      }
    }

    return tags;
  };

  const closeTagsModal = () => {
    setTagsModal(false);
  };

  const checkTagValues = (tagValues) => {
    let flag = false;
    for (let i = 0; i < tagValues.length; i++) {
      for (let j = 0; j < tagValues[i].length; j++) {
        if (tagValues[i][j] === "") {
          flag = true;
          break;
        }
      }
      if (flag) break;
    }

    setDisableTagsSend(flag);
  };

  const handleTagValueChange = (value, i, j) => {
    const newTagValues = [];
    tagValues.forEach((row) => newTagValues.push([...row]));
    newTagValues[i][j] = value;
    setTagValues(newTagValues);
    checkTagValues(newTagValues);
  };

  const handleCommonTagValueChange = (value, i) => {
    const newCommonTagValues = [...commonTagValues];
    newCommonTagValues[i] = value;
    setCommonTagValues(newCommonTagValues);

    setTagValues((prev) => {
      const newTagValues = [];
      prev.forEach((row) => newTagValues.push([...row]));
      const selectedInfluencers = right.filter((inf) => !inf.disabled);
      for (let j = 0; j < selectedInfluencers.length; j++) {
        newTagValues[j][i] = value;
      }
      checkTagValues(newTagValues);
      return newTagValues;
    });
  };

  useEffect(() => {
    const selectedInfluencers = right.filter((inf) => !inf.disabled);

    const tagModalData = [];
    selectedInfluencers.forEach((influencer, i) => {
      const row = {};
      row.influencerName = influencer.label;
      templateTags.forEach((tag, j) => {
        row["_" + tag] = (
          <TextField
            value={tagValues[i][j]}
            onChange={(e) => handleTagValueChange(e.target.value, i, j)}
            required={true}
            key={`cell-${i}_${j}`}
            variant="standard"
          />
        );
      });
      tagModalData.push(row);
    });
    setTagModalData(tagModalData);
  }, [tagValues]);

  useEffect(() => {
    const tagModalColumn = [
      {
        label: "Influencer Name",
        name: "influencerName",
      },
    ];
    templateTags.forEach((tag, i) => {
      const column = {};
      column.name = "_" + tag;
      column.options = {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index}>
              <TextField
                value={commonTagValues[i]}
                onChange={(e) => handleCommonTagValueChange(e.target.value, i)}
                key={`header-${i}`}
                variant="outlined"
                label={tag}
                size="small"
                style={{ width: "100%" }}
              />
            </TableCell>
          );
        },
      };
      tagModalColumn.push(column);
    });
    setTagModalColumn(tagModalColumn);
  }, [commonTagValues]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const selectedInfluencers = right.filter((inf) => !inf.disabled);

    if (selectedInfluencers.length === 0) return;

    const tags = checkMergeTags(selectedTemplateContent);
    setTemplateTags(tags);

    if (tags.length > 0) {
      const values = [];
      for (let i = 0; i < selectedInfluencers.length; i++) {
        const row = [];
        for (let j = 0; j < tags.length; j++) {
          row.push("");
        }
        values.push(row);
      }
      setTagValues(values);

      const commonValues = [];
      for (let i = 0; i < tags.length; i++) {
        commonValues.push("");
      }
      setCommonTagValues(commonValues);

      const tagModalOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        pagination: false,
        responsive: "scroll",
        selectableRows: "none",
        tableBodyMaxHeight: "60vh",
        fixedHeader: false,
      };
      setTagModalOptions(tagModalOptions);

      setTagsModal(true);
    } else {
      setBackdropShow(true);

      const body = {};
      body["cid"] = +cid;
      body["tid"] = template;
      body["lid"] = list.id;
      body["inf"] = selectedInfluencers.map((inf) => {
        const res = {};
        notificationMetadata.userId = inf.id.toString();
        res.iid = inf.id;
        res.tags = {};
        return res;
      });

      body["notificationMetadata"] = notificationMetadata;

      const optionAxios = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios.post(API_HOST + "/contract/create", body, optionAxios).then((res) => {
        setTimeout(function () {
          window.location = "/campaigns/contracts?id=" + cid;
        }, 500);
      });
    }
  };

  const handleTagsSubmit = () => {
    setBackdropShow(true);
    setTagsModal(false);
    const body = {};
    body["cid"] = +cid;
    body["tid"] = template;
    body["lid"] = list.id;
    body["inf"] = right
      .filter((inf) => !inf.disabled)
      .map((inf, i) => {
        const res = {};
        notificationMetadata.userId = inf.id.toString();
        res.iid = inf.id;
        res.tags = {};
        templateTags.forEach((tag, j) => {
          res.tags[tag] = tagValues[i][j];
        });
        return res;
      });
    body["notificationMetadata"] = notificationMetadata;

    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios.post(API_HOST + "/contract/create", body, optionAxios).then((res) => {
      setTimeout(function () {
        window.location = "/campaigns/contracts?id=" + cid;
      }, 500);
    });
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: "column",
        }}
        open={backdropShow}
      >
        <CircularProgress color="inherit" />
        <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
      </Backdrop>

      <CampaignActions name={campaignName} id={cid} />
      <br />
      <CampaignTabs campId={cid} selectedTab={"6"} />

      <Box m={3}>
        <Tabs value={0} style={{ width: "200px", float: "left" }} variant="scrollable" scrollButtons="auto">
          <Tab label="Send Contract" id="contract-details-tab-0" />
        </Tabs>
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" component={Link} to={"/campaigns/contracts?id=" + cid}>
            All Contracts
          </Button>
        </Stack>
      </Box>
      <TabPanel index={0} name="template-imformation-tab" value={0}>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MyPaper>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                  Select contract recipients from left box
                </Typography>
                <Divider />

                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="template">Template</InputLabel>
                      <Select
                        required={true}
                        variant="outlined"
                        label="Template"
                        id="template"
                        value={template}
                        onChange={handleTemplateChange}
                      >
                        {isLoaded &&
                          templateData.map((template) => (
                            <MenuItem key={template.id} value={template.id}>
                              {template.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.name}
                        options={listData ? listData : []}
                        value={list}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            <Typography sx={textColor[option.platform]}>{snapshotHeaderIcons[option.platform]}</Typography>
                            &nbsp;
                            {option.name}
                          </Box>
                        )}
                        onChange={(e, value) => {
                          setList(value);
                        }}
                        renderInput={(params) => <TextField {...params} label="List" />}
                      />
                    </FormControl>
                  </Grid>
                  {isLoaded && showTransfer && (
                    <Grid item xs={12} sm={12} style={{ marginTop: "1rem" }}>
                      <FormControl fullWidth>
                        <TransferList left={left} setLeft={setLeft} right={right} setRight={setRight} />
                      </FormControl>
                      <FormHelperText style={{ marginTop: "1rem", marginLeft: "2rem" }}>
                        * The transfer list does not include any direct influencers
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </MyPaper>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Box sx={{ mt: 4 }}>
                <Tooltip title={selectedCount === 0 ? "Select at least 1 influencer" : ""}>
                  <div>
                    <Button variant="contained" size="large" type="submit" disabled={selectedCount === 0}>
                      Send ({selectedCount})
                    </Button>
                  </div>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </form>
      </TabPanel>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-tags_modal-label"
        aria-describedby="modal-tags_modal-description"
        open={tagsModal}
        onClose={closeTagsModal}
        closeAfterTransition
      >
        <Fade in={tagsModal}>
          <div className="syles_paper" style={{ width: "50%" }}>
            <div id="modal-tags_modal-heading" className="styles_modal_heading">
              <h2 id="modal-tags_modal-heading-text" style={{ padding: "10px" }}>
                Set Merge Tag Values
              </h2>
              <IconButton onClick={closeTagsModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-tags_modal-body" style={{ padding: "10px" }}>
              <Datatables data={tagModalData} columns={tagModalColumn} options={tagModalOptions} />
            </div>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Button style={{ margin: "0.5rem" }} variant="contained" onClick={handleTagsSubmit} disabled={disableTagsSend}>
                Send ({selectedCount})
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default SendContract;
